import React from 'react'
import startCase from 'lodash/startCase'
import { reorderWithChildren } from '../../selectors/standards'

export default class ScopeList extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      scopes: this.formatScope(this.props.scopes || []),
      openNew: false,
      onEdit: null,
      requesting: false,
      subScope: []
    }
  }

  formatScope (scopes) {
    const subs = scopes.filter(x => x.detail.parent).reduce((sum, x, i) => {
      if (sum[x.detail.parent]) {
        sum[x.detail.parent].push(x)
      } else {
        sum[x.detail.parent] = [x]
      }
      return sum
    }, {})
    const parents = scopes
      .filter(x => !x.detail.parent)
      .map((x, i) => {
        return {
          ...x,
          sub: subs[x.id]
            ? subs[x.id].map(xx => ({
              ...xx,
              level: xx.detail.level
            }))
            : []
        }
      })
    return parents
  }

  componentWillReceiveProps (nextProps) {
    this.setState({
      scopes: this.formatScope(nextProps.scopes)
    })
  }

  toggleNew () {
    this.setState({
      openNew: !this.state.openNew,
      scopes: this.state.scopes.map(x => {
        delete x.editing
        return x
      }),
      onEdit: null
    })
  }

  addNew () {
    this.setState({
      requesting: true
    })
    const scope = {
      name: this.refs.newName.value,
      detail: {
        number: this.refs.newNumber.value,
        obs_allocation: this.refs.newObsAllocation.value,
        description: this.refs.newDescription.value,
        accreditation: this.refs.newAccreditation.value,
        active: this.refs.newActive.value,
        level: this.refs.newLevel.value,
        parent: null
      },
      standard_id: this.props.standard.id
    }
    this.props.createStandardScope(scope)
      .then(async (newScope) => {
        if (this.state.subScope.length > 0) {
          for (let child of this.state.subScope) {
            const formatChild = {
              ...child,
              standard_id: scope.standard_id,
              detail: {
                ...scope.detail,
                level: child.level,
                parent: newScope.id
              }
            }
            await this.props.createStandardScope(formatChild)
          }
          return true
        }
      })
      .then(() => {
        this.setState({
          requesting: false,
          onEdit: null,
          openNew: false
        })
      })
  }

  toggleEdit (n) {
    this.setState({
      openNew: false,
      scopes: this.state.scopes.map(x => {
        if (x.id === n) {
          x.editing = true
        } else {
          delete x.editing
        }
        return x
      }),
      onEdit: n,
      subScope: this.state.scopes.find(x => x.id === n).sub
    })
  }

  cancel () {
    this.setState({
      openNew: false,
      scopes: this.state.scopes.map(x => {
        delete x.editing
        return x
      }),
      onEdit: null,
      subScope: []
    })
  }

  saveUpdate () {
    this.setState({
      requesting: true
    })
    const newScope = {
      id: this.state.onEdit,
      name: this.refs.updateName.value,
      detail: {
        number: this.refs.updateNumber.value,
        obs_allocation: this.refs.updateObsAllocation.value,
        description: this.refs.updateDescription.value,
        level: this.refs.updateLevel.value,
        accreditation: this.refs.updateAccreditation.value,
        active: this.refs.updateActive.value
      },
      standard_id: this.props.standard.id
    }
    this.props.updateScope(newScope)
      .then(async () => {
        if (this.state.subScope.length > 0) {
          for (let child of this.state.subScope) {
            if (child.toDelete) {
              await this.props.deleteScope(child)
            } else {
              const formatChild = {
                ...child,
                standard_id: newScope.standard_id,
                detail: {
                  ...newScope.detail,
                  level: child.level,
                  number: child.number,
                  parent: newScope.id
                }
              }
              if (!child.id) {
                await this.props.createStandardScope(formatChild)
              } else {
                await this.props.updateScope(formatChild)
              }
            }
          }
          return true
        }
      })
      .then(() => {
        this.setState({
          requesting: false,
          onEdit: null,
          openNew: false
        })
      })
  }

  deleteItem () {
    this.setState({
      requesting: true
    })
    const scope = this.state.scopes.find(x => {
      return x.editing
    })
    this.props.deleteScope(scope)
      .then(x => {
        for (let subScope of this.state.subScope) {
          if (subScope.toDelete) {
            this.props.deleteScope(subScope)
          }
        }
        return true
      })
      .then(() => {
        this.setState({
          requesting: false,
          onEdit: null,
          openNew: false
        })
      })
  }

  addSubscope () {
    this.setState({
      subScope: [ ...this.state.subScope, { name: '', level: 'default' } ]
    })
  }

  removeSubscope (index) {
    const subScope = this.state.subScope.find((x, i) => i === index)
    if (subScope.id) {
      this.setState({
        subScope: this.state.subScope.map((x, i) => ({
          ...x,
          toDelete: index === i ? true : x.toDelete
        }))
      })
    } else {
      this.setState({
        subScope: this.state.subScope.filter((x, i) => index !== i)
      })
    }
  }

  handleChange (field, index, ev) {
    ev.preventDefault()
    this.setState({
      subScope: this.state.subScope.map((x, i) => {
        if (i === index) {
          return {
            ...x,
            [field]: ev.target.value
          }
        }
        return x
      })
    })
  }

  render () {
    return (
      <div className='scope-list'>
        {
          reorderWithChildren(this.state.scopes).map(x => {
            if (x.editing) {
              return (
                <div className='well animated fadeIn' key={x.id}>
                  <div className='row'>
                    <div className='col-sm-10'>
                      <div className='row'>
                        <div className='col-sm-1'>
                          <div className='form-group'>
                            <label htmlFor=''>Number</label>
                            <input className='form-control text-sm' type='text' defaultValue={x.detail && x.detail.number} ref='updateNumber' />
                          </div>
                        </div>
                        <div className='col-sm-5'>
                          <div className='form-group'>
                            <label htmlFor=''>Name</label>
                            <input className='form-control text-sm' type='text' defaultValue={x.name} ref='updateName' />
                          </div>
                        </div>
                        <div className='col-sm-6'>
                          <div className='form-group'>
                            <label htmlFor=''>Description</label>
                            <input className='form-control text-sm' type='text' defaultValue={x.detail && x.detail.description} ref='updateDescription' />
                          </div>
                        </div>
                        <div className='col-sm-3'>
                          <div className='form-group'>
                            <label htmlFor=''>OBS Allocation</label>
                            <select className='form-control' ref='updateObsAllocation' defaultValue={x.detail && x.detail.obs_allocation}>
                              <option value={'OBS Food'}>OBS Food</option>
                              <option value={'OBS Syscert'}>OBS Syscert</option>
                            </select>
                          </div>
                        </div>
                        <div className='col-sm-3'>
                          <div className='form-group'>
                            <label htmlFor=''>Accreditation</label>
                            <select className='form-control' ref='updateAccreditation' defaultValue={x.detail && x.detail.accreditation}>
                              <option value={'accredited'}>ACCREDITED</option>
                              <option value={'unaccredited'}>UNACCREDITED</option>
                            </select>
                          </div>
                        </div>
                        <div className='col-sm-3'>
                          <div className='form-group'>
                            <label htmlFor=''>Active</label>
                            <select className='form-control' ref='updateActive' defaultValue={x.detail && x.detail.active}>
                              <option value={'active'}>ACTIVE</option>
                              <option value={'inactive'}>INACTIVE</option>
                            </select>
                          </div>
                        </div>
                        <div className='col-sm-3'>
                          <div className='form-group'>
                            <label htmlFor=''>Risk level</label>
                            <select className='form-control' ref='updateLevel' defaultValue={x.detail && x.detail.level}>
                              <option value='default'>No Level</option>
                              <option value='low'>LOW</option>
                              <option value='med'>MEDIUM</option>
                              <option value='high'>HIGH</option>
                              <option value='lim'>LIMITED</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='text-right col-sm-2'>
                      <p><button disabled={this.state.requesting} onClick={this.cancel.bind(this)}className='btn btn-block btn-default'>Cancel <i className='fa fa-back' /></button></p>
                      <p><button disabled={this.state.requesting} onClick={this.deleteItem.bind(this)}className='btn btn-block btn-danger'>Delete <i className='fa fa-remove' /></button></p>
                      <p><button disabled={this.state.requesting} onClick={this.saveUpdate.bind(this)} className='btn btn-block btn-primary'>Save <i className='fa fa-check' /></button></p>
                    </div>
                    <div className='col-sm-10'>
                      <legend>Sub scope</legend>
                      { this.state.subScope.map((xx, i) => {
                        return (
                          <div className='row' key={i}>
                            <div className='col-sm-1'>
                              <div className='form-group'>
                                <label htmlFor=''>Number</label>
                                <input className='form-control text-sm' type='text' defaultValue={xx.number} onChange={this.handleChange.bind(this, 'number', i)} />
                              </div>
                            </div>
                            <div className='col-sm-5'>
                              <div className='form-group'>
                                <label htmlFor=''>Name</label>
                                <input className='form-control text-sm' type='text' defaultValue={xx.name} onChange={this.handleChange.bind(this, 'name', i)} />
                              </div>
                            </div>
                            <div className='col-sm-4'>
                              <div className='form-group'>
                                <label htmlFor=''>Risk level</label>
                                <select className='form-control' defaultValue={xx.level} onChange={this.handleChange.bind(this, 'level', i)}>
                                  <option value='default'>No Level</option>
                                  <option value='low'>LOW</option>
                                  <option value='med'>MEDIUM</option>
                                  <option value='high'>HIGH</option>
                                  <option value='lim'>LIMITED</option>
                                </select>
                              </div>
                            </div>
                            <div className='col-sm-2'>
                              { xx.toDelete
                                ? <p>Will be deleted on save</p>
                                : <button className='btn btn-sm btn-default' onClick={this.removeSubscope.bind(this, i)}>Remove</button>
                              }
                            </div>
                          </div>
                        )
                      })}
                      { this.state.subScope.length === 0 && <p>No sub scope</p>}
                      <button className='btn btn-sm btn-default' onClick={this.addSubscope.bind(this)}>Add sub scope</button>
                    </div>
                  </div>
                </div>
              )
            } else {
              return (
                <div key={x.id} className='row'>
                  <div className='col-sm-5'>
                    <p><strong>{x.detail.number || '---'}</strong> - <strong>{x.name || 'Unnamed scope'}</strong></p>
                    <p>{x.detail && x.detail.description ? x.detail.description : 'No description'}</p>
                    <p>{x.sub.length} sub scope(s)</p>
                  </div>
                  <div className='col-sm-2'>
                    <p>{x.detail && x.detail.obs_allocation}</p>
                  </div>
                  <div className='col-sm-2'>
                    <p> {x.detail && x.detail.accreditation && startCase(x.detail.accreditation)} -  {x.detail && x.detail.active && startCase(x.detail.active)}</p>
                  </div>
                  <div className='col-sm-2'>
                    <p> <strong>Level : </strong>{x.detail && x.detail.level ? x.detail.level : 'Default' }</p>
                  </div>
                  <div className='col-sm-1 text-right'>
                    <button disabled={this.state.requesting} onClick={this.toggleEdit.bind(this, x.id)} className='btn btn-default'><i className='fa fa-gear' /></button>
                  </div>
                  <div className='col-sm-12'><hr /></div>
                </div>
              )
            }
          })
        }
        {this.state.openNew
          ?
          <div className='well animated fadeIn'>
            <div className='row'>
              <div className='col-sm-10'>
                <div className='row'>
                  <div className='col-sm-1'>
                    <div className='form-group'>
                      <label htmlFor=''>Number</label>
                      <input className='form-control text-sm' type='text' defaultValue='' ref='newNumber' />
                    </div>
                  </div>
                  <div className='col-sm-5'>
                    <div className='form-group'>
                      <label htmlFor=''>Name</label>
                      <input className='form-control text-sm' type='text' defaultValue='' ref='newName' />
                    </div>
                  </div>
                  <div className='col-sm-6'>
                    <div className='form-group'>
                      <label htmlFor=''>Description</label>
                      <input className='form-control text-sm' type='text' defaultValue='' ref='newDescription' />
                    </div>
                  </div>
                  <div className='col-sm-3'>
                    <div className='form-group'>
                      <label htmlFor=''>OBS Allocation</label>
                      <select className='form-control' ref='newObsAllocation' defaultValue='OBS Food'>
                        <option value={'OBS Food'}>OBS Food</option>
                        <option value={'OBS Syscert'}>OBS Syscert</option>
                      </select>
                    </div>
                  </div>
                  <div className='col-sm-3'>
                    <div className='form-group'>
                      <label htmlFor=''>Accreditation</label>
                      <select className='form-control' ref='newAccreditation' defaultValue='accredited'>
                        <option value={'accredited'}>ACCREDITED</option>
                        <option value={'unaccredited'}>UNACCREDITED</option>
                      </select>
                    </div>
                  </div>
                  <div className='col-sm-3'>
                    <div className='form-group'>
                      <label htmlFor=''>Active</label>
                      <select className='form-control' ref='newActive' defaultValue='active'>
                        <option value={'active'}>ACTIVE</option>
                        <option value={'inactive'}>INACTIVE</option>
                      </select>
                    </div>
                  </div>
                  <div className='col-sm-3'>
                    <div className='form-group'>
                      <label htmlFor=''>Risk level</label>
                      <select className='form-control' ref='newLevel' defaultValue='default'>
                        <option value='default'>No Level</option>
                        <option value='low'>LOW</option>
                        <option value='med'>MEDIUM</option>
                        <option value='high'>HIGH</option>
                        <option value='lim'>LIMITED</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className='text-right col-sm-2'>
                <p><button disabled={this.state.requesting} onClick={this.cancel.bind(this)}className='btn btn-block btn-default'>Cancel <i className='fa fa-back' /></button></p>
                <p><button disabled={this.state.requesting} onClick={this.addNew.bind(this)} className='btn btn-block btn-primary'>Save<i className='fa fa-check' /></button></p>
              </div>
              <div className='col-sm-10'>
                <legend>Sub scope</legend>
                { this.state.subScope.map((xx, i) => {
                  return (
                    <div className='row' key={i}>
                      <div className='col-sm-6'>
                        <div className='form-group'>
                          <label htmlFor=''>Name</label>
                          <input className='form-control text-sm' type='text' defaultValue={xx.name} onChange={this.handleChange.bind(this, 'name', i)} />
                        </div>
                      </div>
                      <div className='col-sm-4'>
                        <div className='form-group'>
                          <label htmlFor=''>Risk level</label>
                          <select className='form-control' defaultValue={xx.level} onChange={this.handleChange.bind(this, 'level', i)}>
                            <option value='default'>No Level</option>
                            <option value='low'>LOW</option>
                            <option value='med'>MEDIUM</option>
                            <option value='high'>HIGH</option>
                            <option value='lim'>LIMITED</option>
                          </select>
                        </div>
                      </div>
                      <div className='col-sm-2'>
                        <button className='btn btn-sm btn-default' onClick={this.removeSubscope.bind(this, i)}>Remove</button>
                      </div>
                    </div>
                  )
                })}
                { this.state.subScope.length === 0 && <p>No sub scope</p>}
                <button className='btn btn-sm btn-default' onClick={this.addSubscope.bind(this)}>Add sub scope</button>
              </div>
            </div>
          </div>
          : !this.state.openNew && this.state.onEdit === null
            ? <div>
              <button disabled={this.state.requesting} onClick={this.toggleNew.bind(this)} className='btn btn-block btn-primary'>Add Scope</button>
            </div>
            : <div className=' bg-grey' />
        }
      </div>
    )
  }
}
