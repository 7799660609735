import React from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css'

import Required from './Required'

export default class DateField extends React.Component {
  constructor (props) {
    super(props)
    let value = moment()
    if (this.props.input.value && typeof this.props.input.value === 'number') {
      value = moment.unix(this.props.input.value)
    }
    if (this.props.input.value && typeof this.props.input.value === 'string') {
      value = moment(this.props.input.value)
    }
    this.state = {
      startDate: value
    }
  }

  onChange (value) {
    this.setState({
      startDate: value
    })
    this.props.input.onChange(value.format('YYYY-MM-DD'))
  }

  render () {
    const { label, required, meta: {touched, error, warning} } = this.props
    return (

      <div className={`form-group ${this.props.input.name.split('.').join('_')}`}>
        <label htmlFor={label} className='col-sm-3 control-label'>{label} {required && <Required />}</label>
        <div className='col-sm-9'>
          <DatePicker
            dateFormat='YYYY-MM-DD'
            style={{fontSize: '1rem'}}
            selected={this.state.startDate}
            onChange={this.onChange.bind(this)}
            className='form-control'
          />
          {touched && ((error && <div className='alert alert-danger'>{error}</div>) || (warning && <div className='alert alert-warning'>{warning}</div>))}
        </div>
      </div>
    )
  }
}
