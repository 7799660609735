import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

import {
  fetchOption,
  deleteOption,
  updateOption
} from '../../actions/options'

import DeleteForm from '../../components/common/DeleteForm'
import { OptionUpdateForm as OptionForm } from '../../components/Options/OptionForm'

function mapStateToProps (state) {
  return {
    option: state.options.option.data,
    accessToken: state.auth.user.access.access_token
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchOption: (id, key) => dispatch(fetchOption(id, key)),
    updateOption: (data, key) => dispatch(updateOption(data, key)),
    deleteOption: (id, key) => dispatch(deleteOption(id, key))
  }
}

export class OptionUpdate extends React.Component {
  componentWillMount () {
    this.props.fetchOption(this.props.match.params.id, this.props.accessToken)
  }

  handleDelete (e) {
    return this.props.deleteOption(this.props.option.id, this.props.accessToken)
  }

  handleSubmit (e) {
    return this.props.updateOption({ ...e }, this.props.accessToken)
  }

  render () {
    return (
      <div className='content'>
        <div className='main-header'>
          <h2>Update Option {this.props.option && this.props.option.name}</h2>
          <em><Link to='/options'>back to option list</Link></em>
        </div>
        <div className='main-content'>
          <div className='row'>
            <div className='col-md-8'>
              {this.props.option
                ? <div>
                  <OptionForm
                    onSubmit={this.handleSubmit.bind(this)}
                    initialValues={this.props.option}
                  />
                  <DeleteForm onClick={this.handleDelete.bind(this)} />
                </div>
                : <div>loading</div>
              }
            </div>
            <div className='col-md-4' />
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(OptionUpdate))
