export const initialState = {
  eventList: {
    data: null,
    pagination: null
  },
  event: {
    data: null
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CREATE_EVENT_FULFILLED':
      return {
        ...state,
        eventList: {
          ...state.eventList,
          data: [action.payload, ...state.eventList.data]
        }
      }
    case 'FETCH_EVENTS_FULFILLED':
      return { ...state,
        eventList: {
          data: action.payload,
          pagination: action.pagination
        }
      }
    case 'FETCH_EVENT_FULFILLED' :
      return { ...state, event: { data: action.payload } }
    case 'UPDATE_EVENT_FULFILLED' :
      return { ...state, event: { data: action.payload } }
    case 'DELETE_EVENT_FULFILLED' :
      return { ...state, event: { data: null } }
    case 'LOGOUT_FULFILLED':
      return { ...initialState }
    default:
      return state
  }
}
