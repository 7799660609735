import React from 'react'
import { Link } from 'react-router-dom'

import { isAfter } from '../../selectors/order'

export default class OrderDocuments extends React.Component {
  render () {
    const { order, accessToken } = this.props
    const afterProtocolReleased = isAfter(order.status, 'Protocol Released')
    const afterApprovedVeto = isAfter(order.status, 'Approved by Veto')
    return (
      <div className='well well-sm'>
        <div className=''>
          <div className='row'>
            <div className='col-md-3'>
              <ul className='list-unstyled'>
                <li><Link className='btn btn-link btn-xs' to={`/quotations/${order.quotation.id}`}><i className='fa fa-arrow-left' /> Quotation</Link></li>
                <li><Link className='btn btn-link btn-xs' to={`/questionnaires/${order.questionnaire.id}`}><i className='fa fa-arrow-left' /> Questionnaire</Link></li>
              </ul>
            </div>
            <div className='col-md-3'>
              <ul className='list-unstyled'>
                { afterApprovedVeto &&
                  <li><a href={`${process.env.REACT_APP_BASE_API}/pdf/order?template=veto-review&id=${order.id}&token=${accessToken}`} className='btn btn-link btn-xs'><i className='fa fa-download' /> Download Veto and Reviewer Feedback</a></li>
                }
                {
                  afterProtocolReleased &&
                  <li><a href={`${process.env.REACT_APP_BASE_API}/pdf/order?template=release-protocol&id=${order.id}&token=${accessToken}`} className='btn btn-link btn-xs'><i className='fa fa-download' /> Download Release Protocol</a></li>
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
