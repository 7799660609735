import React, { Fragment } from 'react'
import { Field } from 'redux-form'

import Required from '../common/Required'
import { AutoCompleteField, TextField, OptionsField, TextareaField } from '../common/FormFields'

const LocationsField = ({ openNew, openNewContact, label, fields, contactAPI, locationAPI, locationsValue, required, meta: { error, touched, warning } }) => {
  const xFields = fields || []

  return (
    <div className='form-group locations'>
      <label htmlFor={label} className='col-sm-3 control-label'>{label} {required && <Required />}</label>
      <div className='col-sm-9'>
        <div className='locations-array'>
          {xFields.map((location, index) => (
            <div key={index} className='location'>
              <Field
                name={`${location}.location`}
                component={AutoCompleteField}
                label={index === 0 ? 'Main Location for Audit' : 'Additional Location'}
                api={locationAPI}
                required
              />

              <Field
                name={`${location}.detail.contact_person`}
                component={AutoCompleteField}
                label='Contact Person'
                api={contactAPI}
                required
                openNew={() => openNewContact(`${location}.detail.contact_person`)}
              />
              <Field name={`${location}.detail.scope_of_business`} type='text' component={TextareaField} label='Scope Wording' required />
              <Field name={`${location}.detail.number_of_employees_fulltime`} type='number' component={TextField} label='Number of employees (in terms of full-time)' required />
              <Field name={`${location}.detail.number_of_employees_temporary`} type='number' component={TextField} label='Number of employees (temporary)' required />
              { locationsValue && locationsValue[index].detail &&
                <div className='form-group locations[0]_detail_number_of_shift '>
                  <label for='Number of Shift' className='col-sm-3 control-label'>Number of employees (total) </label>
                  <div className='col-sm-9'>
                    <input value={parseInt(locationsValue[index].detail.number_of_employees_fulltime || 0, 10) + parseInt(locationsValue[index].detail.number_of_employees_temporary || 0, 10)} className='form-control' disabled />
                  </div>
                </div>
              }
              {
                // <Field name={`${location}.detail.number_of_employees`} type='number' component={TextField} label='Number of employees (total)' disabled />
                // <Field name={`${location}.detail.number_of_employees_shift`} type='number' component={TextField} label='Number of employees (in shift working)' />
              }
              <Field name={`${location}.detail.number_of_shift`} type='number' component={TextField} label='Number of Shift' />
              <Field name={`${location}.detail.outsourced_process`} type='select' component={OptionsField} options={['', 'Yes', 'No']} label='Any outsourced process in this location?' />
              { locationsValue && locationsValue[index].detail && locationsValue[index].detail.outsourced_process === 'Yes' &&
                <Field name={`${location}.detail.outsourced_process_description`} type='text' component={TextareaField} label='Please specify what the process is/are?' />
              }
              <Field name={`${location}.detail.temporary_location`} type='select' component={OptionsField} options={['', 'Yes', 'No']} label='Is the location temporary?' />
              { locationsValue && locationsValue[index].detail && locationsValue[index].detail.temporary_location === 'Yes' &&
                <Field name={`${location}.detail.temporary_location_activity`} type='text' component={TextareaField} label='Please specify type of activities (eg. Construction Site, Project, etc)?' />
              }
              <Field name={`${location}.detail.distance_from_airport`} type='text' component={TextField} label='Distance from airport' />
              { openNew && (xFields.length > 1) &&
                <div className='row'>
                  <div className='col-md-8 col-md-push-4'>
                    <button
                      className='btn btn-sm pull-right'
                      type='button'
                      onClick={() => xFields.remove(index)}
                    >
                      Remove Location
                    </button>
                  </div>
                </div>
              }
              <hr />
            </div>
          ))}
          { openNew &&
            <Fragment>
              <p>Select more than one location for multi location.</p>
              <button className='btn btn-sm btn-default btn-add' type='button' onClick={() => fields.push({})}>Add registered location</button> &nbsp;
              <button className='btn btn-sm btn-default btn-add-new' type='button' onClick={() => openNew()}>Add new location</button>
            </Fragment>
          }
          <br />
          {((error && <div className='alert alert-danger'>{error}</div>) || (warning && <div className='alert alert-warning'>{warning}</div>))}
        </div>
      </div>
    </div>
  )
}
export default LocationsField
