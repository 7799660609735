import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { getServices } from '../../const/services'
import { getQuotationLabel } from '../../const/quotations'

export default class QuotationTable extends React.Component {
  getLabel (status) {
    let label
    switch (status) {
      case 'Draft':
        label = 'warning'
        break
      case 'Created':
        label = 'info'
        break
      case 'Reviewed by SM':
        label = 'primary'
        break
      case 'Reviewed by BOD':
        label = 'primary'
        break
      case 'Manual Approval':
        label = 'primary'
        break
      case 'Approved by Customer':
        label = 'success'
        break
      default:
        // statements_def
        break
    }
    return (
      <span className={`label label-${label}`}>{status.toUpperCase()}</span>
    )
  }

  render () {
    return (
      <div className='table-responsive quotation-table'>
        <table className='table colored-header datatable project-list'>
          <thead>
            <tr>
              <th>Code</th>
              <th>Questionnaire</th>
              <th>Service</th>
              <th>Customer</th>
              <th>Status</th>
              <th>Sales</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {
              this.props.quotations.map((x, i) => {
                return (
                  <tr className={`quotation-${x.id}`} key={x.id}>
                    <td><Link to={`/quotations/${x.id}`}>{x.id}</Link></td>
                    <td><Link to={`/questionnaires/${x.questionnaire.id}`}>{x.questionnaire.id}</Link></td>
                    <td>{getServices(x.questionnaire.services)}</td>
                    <td>{x.customer.name}</td>
                    <td>{getQuotationLabel(x.status)}</td>
                    <td>{x.sales && x.sales.name}</td>
                    <td>{moment.unix(x.created_at).format('D MMMM YYYY')}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    )
  }
}
