import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'

class BaseDaFilter extends Component {
  render () {
    const { handleSubmit, submitting } = this.props
    return (
      <div>
        <form className='form-inline' onSubmit={handleSubmit}>
          <div className='form-group'>
            <label className='sr-only' htmlFor='exampleInputEmail2'>Name</label>
            <Field
              name='q'
              component='input'
              type='text'
              placeholder='Search for ...'
              className='form-control'
            />
          </div>
          &nbsp;
          <button disabled={submitting} type='submit' className='btn btn-default'>Filter</button>
          <Link to='/das' className='btn btn-link pull-right'>Reset</Link>

        </form>
        <hr />
      </div>
    )
  }
}

let DaFilter = reduxForm({
  form: 'daFilter' //, validate, warn
})(BaseDaFilter)

export { BaseDaFilter }
export default DaFilter
