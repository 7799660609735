import React from 'react'
import { Link } from 'react-router-dom'

export default class LocationTable extends React.Component {
  render () {
    const locations = this.props.locations || []

    return (
      <table className='table table-condensed'>
        <thead>
          <tr>
            <th>Address Name</th>
            <th>Street Address</th>
            <th>City</th>
            <th>Phone</th>
            <th>Note</th>
            <th className='text-center' width={80}>Actions</th>
          </tr>
        </thead>
        {this.props.customer &&
          <tbody>
            {locations.map((x, i) => {
              return (
                <tr key={i}>
                  <td><Link to={`/customers/${this.props.customer.id}/locations/${x.id}`}>{x.name}</Link></td>
                  <td>{x.address}</td>
                  <td>{x.city}</td>
                  <td>{x.phone}</td>
                  <td>
                    {x.billing && <span>Billing </span>}
                    {x.default && <span>Default </span>}
                    {x.delivery && <span>Delivery </span>}
                  </td>
                  <td className='text-right'><Link to={`/customers/${this.props.customer.id}/locations/${x.id}`} className='btn btn-xs btn-default'><i className='fa fa-gear' /></Link></td>
                </tr>
              )
            })}
            <tr className=' bg-grey'>
              <td className='customer-scope-container' colSpan={6}>
                <Link className='btn btn-block btn-default' to={`/customers/${this.props.customer.id}/locations/new`}>Add Address</Link>
              </td>
            </tr>
          </tbody>
        }
      </table>
    )
  }
}
