import React from 'react'
import { Link } from 'react-router-dom'

export default class OrderUsers extends React.Component {
  render () {
    const { order } = this.props

    const users = order.users.reduce((sum, x, i) => {
      if (!sum.find(xx => xx.user.id === x.user.id)) {
        return [...sum, x]
      }
      return sum
    }, [])

    return (
      <div className='widget'>
        <div className='widget-header'>
          <h3><i className='fa fa-tasks' /> Participant</h3>
        </div>
        <div className=''>
          <table className='table' style={{ marginBottom: 0 }}>
            <thead>
              <tr>
                <th>User</th>
                <th>Role</th>
              </tr>
            </thead>
            <tbody>
              { users.length === 0 &&
                <tr>
                  <td colSpan={2}>
                    <p>Not available</p>
                  </td>
                </tr>
              }
              { users.map((x, y) => {
                return (
                  <tr key={y}>
                    <td><Link to={`/users/${x.user.id}`}>{x.user.data ? x.user.data.display_name : x.user.username}</Link></td>
                    <td>{x.role} {x.status === 'Draft' && <small>(draft)</small>}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}
