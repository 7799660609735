import { createSelector } from 'reselect'
import { getFormValues } from 'redux-form'

const fees = state => {
  const formValue = getFormValues('daUpdate')(state)
  if (formValue && formValue.detail && formValue.detail.fees) {
    return formValue.detail.fees
  } else {
    return []
  }
}

const totalAllowanceUpdateSelector = createSelector(
  fees,
  (fees) => {
    let total = {usd: 0, idr: 0}
    for (let key in fees) {
      for (let item of fees[key]) {
        let itemTotal = item.quantity * item.rate
        if (item.currency === 'IDR') {
          total.idr += itemTotal
        } else {
          total.usd += itemTotal
        }
      }
    }
    return total
  }
)

export {
  totalAllowanceUpdateSelector
}
