export const initialState = {
  invoiceList: { data: null, pagination: null, error: null, loading: false },
  globalInvoiceList: { data: null, pagination: null, error: null, loading: false },
  invoice: { data: null, error: null, loading: false }
}
export default (state = initialState, action) => {
  switch (action.type) {
    case 'CREATE_INVOICE_PENDING' :
      return { ...state, invoice: { data: null, error: null, loading: true } }
    case 'CREATE_INVOICE_REJECTED':
      return { ...state, invoice: { data: null, error: action.payload, loading: false } }
    case 'CREATE_INVOICE_FULFILLED' :
      return { ...state, invoice: { data: { ...action.payload }, error: null, loading: false } }

    case 'FETCH_INVOICES_PENDING':
      return { ...state, invoiceList: { data: null, error: null, loading: true } }
    case 'FETCH_INVOICES_REJECTED':
      return { ...state, invoiceList: { data: null, error: action.payload, loading: false } }
    case 'FETCH_INVOICES_FULFILLED':
      return { ...state,
        invoiceList: {
          data: action.payload.data,
          pagination: action.payload.pagination,
          error: null,
          loading: false
        }
      }

    case 'FETCH_GLOBAL_INVOICES_PENDING':
      return { ...state, globalInvoiceList: { data: null, error: null, loading: true } }
    case 'FETCH_GLOBAL_INVOICES_REJECTED':
      return { ...state, globalInvoiceList: { data: null, error: action.payload, loading: false } }
    case 'FETCH_GLOBAL_INVOICES_FULFILLED':
      return { ...state,
        globalInvoiceList: {
          data: action.payload.data,
          pagination: action.payload.pagination,
          error: null,
          loading: false
        }
      }

    case 'FETCH_INVOICE_PENDING' :
      return { ...state, invoice: { data: null, error: null, loading: true } }
    case 'FETCH_INVOICE_REJECTED':
      return { ...state, invoice: { data: null, error: action.payload, loading: false } }
    case 'FETCH_INVOICE_FULFILLED' :
      return { ...state, invoice: { data: { ...action.payload }, error: null, loading: false } }

    case 'UPDATE_INVOICE_PENDING' :
      return { ...state, invoice: { ...state.invoice, error: null, loading: true } }
    case 'UPDATE_INVOICE_REJECTED':
      return { ...state, invoice: { ...state.invoice, error: action.payload, loading: false } }
    case 'UPDATE_INVOICE_FULFILLED' :
      return { ...state, invoice: { data: { ...action.payload }, error: null, loading: false } }

    case 'DELETE_INVOICE_PENDING' :
      return { ...state, invoice: { ...state.invoice, error: null, loading: true } }
    case 'DELETE_INVOICE_REJECTED':
      return { ...state, invoice: { ...state.invoice, error: action.payload, loading: false } }
    case 'DELETE_INVOICE_FULFILLED' :
      return { ...state, invoice: { data: null, error: null, loading: false } }

    case 'LOGOUT_FULFILLED':
      return { ...initialState }

    default:
      return state
  }
}
