export function reorderWithChildren (full) {
  const parents = full
    .sort((a, b) => a.name.localeCompare(b.name))
    .sort((a, b) => parseInt(a.detail.number, 10) - parseInt(b.detail.number, 10))
    .filter(x => !x.detail.parent || x.detail.parent === '0')
  const parentIds = parents.map(x => x.id + '')
  const children = full
    .sort((a, b) => a.name.localeCompare(b.name))
    .filter(x => x.detail.parent && x.detail.parent !== '0')
    .reverse()

  for (let child of children) {
    const parentId = child.detail.parent
    const parentIndex = parentIds.indexOf(parentId)
    parents.splice(parentIndex + 1, 0, child)
  }
  return parents
}

export function groupByStandard (sum, x, i) {
  if (!sum.find((xx, ii) => xx.id === x.standard.id)) {
    sum.push({
      ...x.standard,
      scopes: [{
        ...x.scope
      }]
    })
  } else {
    const index = sum.map(xx => xx.name).indexOf(x.standard.name)
    sum[index].scopes = [
      ...sum[index].scopes,
      { ...x.scope }
    ]
  }
  return sum
}
