import React from 'react'
import { Link } from 'react-router-dom'

export default class OptionTable extends React.Component {
  render () {
    const { options } = this.props
    return (
      <div className='table-responsive'>
        <table className='table'>
          <thead>
            <tr>
              <th>#</th>
              <th>Key</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {options.map((option, id) => (
              <tr key={id}>
                <td><Link to={`/options/${option.id}`}>{option.id}</Link></td>
                <td><Link to={`/options/${option.id}`}>{option.key}</Link></td>
                <td>{option.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}
