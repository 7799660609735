import React from 'react'
import get from 'lodash/get'
import { connect } from 'react-redux'
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form'

import StandardsField from './StandardsField'

import ConfirmButton from '../common/ConfirmButton'
import TableField from '../common/TableField'
import { TextField, TextareaField, OptionsField } from '../common/FormFields'

function validateStandards (standards) {
  if (!standards || standards.length === 0) {
    return { _error: 'Required' }
  }
  if (standards.find(x => !x.scope)) {
    return { _error: 'Plese select standard and scope' }
  }
}

function validateExistingCertificationAuditItem (items) {
  if (!items) { return null }

  const errorFormat = items.reduce((sum, x, i) => {
    if (sum) {
      return sum
    }
    // if (!x[0] || !x[1] || !x[2]) {
    //   return { _error: 'Please fill value' }
    // }
    if (x[3] && !/[0-3][0-9]-[0-1][0-9]-[0-9]{4}/.test(x[3])) {
      return { _error: 'Please use dd-mm-yyyy format for date value' }
    }
    if (x[4] && !/[0-3][0-9]-[0-1][0-9]-[0-9]{4}/.test(x[4])) {
      return { _error: 'Please use dd-mm-yyyy format for date value' }
    }
    return sum
  }, null)

  if (errorFormat) { return errorFormat }
}

function validate (values) {
  if (!values.advance) {
    return {}
  }
  const errors = { detail: {} }
  const standardValidation = validateStandards(values.standards)
  const existingCertificationItemValidation = validateExistingCertificationAuditItem(get(values, 'detail.existing_certification'))

  if (standardValidation) {
    errors.standards = standardValidation
  }
  if (existingCertificationItemValidation) {
    errors.detail.existing_certification = existingCertificationItemValidation
  }
  // if (!get(values, 'detail.scope')) {
  //   errors.detail.scope = 'Required.'
  // }
  return errors
}

export function QuestionnaireCertificationInformationForm (props) {
  const { handleSubmit, submitting, changeForm, existingIms, existingIntegrated, existingCombined, consultant, associationMember } = props

  return (
    <div>
      <form className='form-horizontal questionnaire-certification-information-form' onSubmit={handleSubmit}>

        
        <FieldArray
          name='standards'
          component={StandardsField}
          label='Standard and Scope'
          standards={[ { value: 0, label: '...' }, ...props.standards ]}
          onFetchScope={props.onFetchScope}
          required
        />

        
        <Field name='detail.scope' type='text' component={TextareaField} label='Scope wording of operation to be certified?' required />
        
        {
        //  <Field
        //   name='detail.multi_location'
        //   component={OptionsField}
        //   label='Is this a multi location audit?'
        //   type='checkbox'
        // />
        }

        <Field name='detail.integrated' type='select' options={['', 'Yes', 'No']} component={OptionsField} label='Is an integrated certification audit (IMS) required' />
        { existingIntegrated === 'Yes' &&
          <Field name='detail.integrated_detail' type='text' component={TextareaField} label='If yes, according to what standards?' />
        }

        <Field name='detail.combined' type='select' options={['', 'Yes', 'No']} component={OptionsField} label='Is combined certification audit required' />
        { existingCombined === 'Yes' &&
          <Field name='detail.combined_detail' type='text' component={TextareaField} label='If yes, according to what standards?' />
        }

        <FieldArray
          name='detail.transfer_audit'
          component={TableField}
          label='Is this a transfer certification audit? (transfer audit)'
          heads={['Certificate no.', 'Standard', 'Certification body', 'Transfer in which audit cycle (SA1/SA2/RC']}
        />

        <FieldArray
          name='detail.existing_certification'
          component={TableField}
          label='Is your company already certified? (existing certifications?)'
          heads={['Certificate no.', 'Standard / directive etc.', 'Certification company', 'Date of certification audit', 'Certificate valid untill']}
        />

        <Field name='detail.existing_ims' type='select' options={['', 'Yes', 'No']} component={OptionsField} label='Does integrated management system (IMS) implemented for existing certification?' />
        { existingIms === 'Yes' &&
          <Field name='detail.existing_ims_detail' type='text' component={TextareaField} label='If yes, according to what standards?' />
        }

        <Field name='detail.consultant' type='select' options={['', 'Yes', 'No']} component={OptionsField} label='Were you supported by a consultant?' />
        { consultant === 'Yes' &&
          <Field name='detail.consultant_detail' type='text' component={TextareaField} label='Please state!' />
        }

        <Field name='detail.association_member' type='select' options={['', 'Yes', 'No']} component={OptionsField} label='Are you a member of an industry, profesiional or trade association/federation?' />
        { associationMember === 'Yes' &&
          <Field name='detail.association_member_detail' type='text' component={TextareaField} label='Please state!' />
        }

        <Field name='detail.other_information' type='text' component={TextareaField} label='Any further information do you think important for us?' />

        <Field
          name='detail.influencing_factor'
          component={OptionsField}
          label='Other factors which can influence to the audit time and the cost'
          type='multiCheckbox'
          options={[
            'No in-house development',
            'Low process risk',
            'Mature management system',
            'Family-owned company or single processes',
            'High-degreee of regulation (e.g. aerospace, refinery and chemical industry, fishin vessels, mining, food drugs, etc)',
            'High process risk',
            'Large location with small number of employees',
            'Small location with small number of employees',
            'Requiring interpreter(s)',
            'Client preparedness for OH&SMS certification (e.g. already subject to periodically audits by the National Authority for a mandatory governmental OH&SMS schme)',
            'Prior knowledge of the client organisation\'s management system (e.g. already certified in another voluntary OH&SMS scheme by same CAB)'
          ]}
        />

        <Field hidden name='advance' component={TextField} label='Yes please' />

        <div className='form-group'>
          <div className='col-sm-offset-3 col-sm-6'>
            <button disabled={submitting} type='submit' className='btn btn-default btn-block btn-submit' onClick={ev => {
              ev.preventDefault()
              changeForm('advance', false)
              setTimeout(handleSubmit, 100)
            }} >Save Draft</button>
          </div>
          <div className='col-sm-3'>
            <ConfirmButton
              disabled={submitting}
              text={'Save Questionnaire'}
            >
              <button disabled={submitting} type='submit' className='btn-advance btn btn-primary btn-block btn-submit' onClick={ev => {
                ev.preventDefault()
                changeForm('advance', true)
                setTimeout(handleSubmit, 100)
              }} >Continue</button>
            </ConfirmButton>
          </div>
        </div>
      </form>
    </div>
  )
}

const selector = formValueSelector('QuestionnaireCertificationInformationForm')

QuestionnaireCertificationInformationForm = reduxForm({ // eslint-disable-line 
  form: 'QuestionnaireCertificationInformationForm',
  enableReinitialize: true,
  validate: validate
})(QuestionnaireCertificationInformationForm)

QuestionnaireCertificationInformationForm = connect(state => { // eslint-disable-line
  return {
    existingIms: selector(state, 'detail.existing_ims'),
    existingIntegrated: selector(state, 'detail.integrated'),
    existingCombined: selector(state, 'detail.combined'),
    consultant: selector(state, 'detail.consultant'),
    associationMember: selector(state, 'detail.association_member')
  }
})(QuestionnaireCertificationInformationForm)

export default QuestionnaireCertificationInformationForm
