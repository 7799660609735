import axios from 'axios'
import { push } from 'react-router-redux'
import { createFlash, createError } from './flasher'

const baseAPI = process.env.REACT_APP_BASE_API

export const fetchOrders = (params = '', accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_ORDERS_PENDING'
    })
    return axios({
      method: 'get',
      headers: {'Authorization': `Bearer ${accessToken}`},
      url: `${baseAPI}/orders${params}`
    }).then((x) => {
      dispatch({
        type: 'FETCH_ORDERS_FULFILLED',
        payload: {
          data: x.data,
          pagination: {
            currentPage: x.headers['x-pagination-current-page'],
            pageCount: x.headers['x-pagination-page-count'],
            perPage: x.headers['x-pagination-per-page'],
            totalCount: x.headers['x-pagination-total-count']
          }
        }
      })
    }).catch((e) => {
      console.error(e)
      // dispatch(createError(e.response.data))
      dispatch({
        type: 'FETCH_ORDERS_REJECTED',
        payload: e
      })
    })
  }
}

export const fetchOrder = (id, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_ORDER_PENDING'
    })
    return axios({
      method: 'get',
      headers: {'Authorization': `Bearer ${accessToken}`},
      url: `${baseAPI}/orders/${id}?expand=questionnaire,questionnaire_item,schedules`
    }).then((x) => {
      dispatch({
        type: 'FETCH_ORDER_FULFILLED',
        payload: x.data
      })
    }).catch((e) => {
      console.error(e)
      // dispatch(createError(e.response.data))
      dispatch({
        type: 'FETCH_ORDER_REJECTED',
        payload: e
      })
    })
  }
}

export const updateOrder = (data, accessToken, target) => {
  return (dispatch) => {
    dispatch({
      type: 'UPDATE_ORDER_PENDING'
    })
    return axios({
      data,
      method: 'put',
      headers: {'Authorization': `Bearer ${accessToken}`},
      url: `${baseAPI}/orders/${data.id}?expand=questionnaire,questionnaire_item,schedules`
    }).then((x) => {
      dispatch({
        type: 'UPDATE_ORDER_FULFILLED',
        payload: x.data
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Order with the id ${x.data.id} updated`
      }))
      if (target) {
        dispatch(push(target))
      }
    }).catch((e) => {
      console.error(e)
      dispatch(createError(e.message))
      dispatch({
        type: 'UPDATE_ORDER_REJECTED',
        payload: e
      })
    })
  }
}
