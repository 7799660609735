import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
// import { Link , Switch, Route  } from 'react-router-dom'
// import orderBy from 'lodash/fp/orderBy'
import { change } from 'redux-form'
// import { Helmet } from 'react-helmet'

import { OrderPrerequisiteForm } from '../../components/Customers/OrderPrerequisiteForm'
// import QuestionnaireReviewBasicForm from '../../components/Questionnaires/QuestionnaireReviewBasicForm'

import { fetchOrder, updateOrder } from '../../actions/orders'

export function OrderPrerequisiteView ({ order, match, accessToken, changeForm, ...props }) {
  const { fetchOrder } = props
  useEffect(() => {
    fetchOrder(match.params.orderId, accessToken)
  }, [match.params.orderId, accessToken, fetchOrder])

  function handleSubmit (e) {
    let target
    const request = {
      id: order.id,
      detail: {
        ...e.detail
      }
    }
    if (e.advance) {
      request.detail.prerequisite_complete = true
      request.status = 'Questionnaire Completed'
      target = `/orders/${order.id}`
    }
    return props.updateOrder(request, accessToken, target).then((x) => {
      fetchOrder(order.id, accessToken)
    })
  }

  return (
    <div>
      <div className='main-header'>
        <h2>Order Prerequisite</h2>
        { order &&
          <em>For Order <Link to={`/orders/${order.id}`}>{order.id}</Link></em>
        }
      </div>
      <div className='main-content'>
        <div className='row'>
          <div className='col-md-8'>
            <OrderPrerequisiteForm
              changeForm={changeForm}
              initialValues={order}
              onSubmit={handleSubmit.bind(this)}
              fileAPI={{
                url: process.env.REACT_APP_BASE_API,
                accessToken: accessToken
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps (state) {
  return {
    order: state.orders.order.data,
    accessToken: state.auth.user.access.access_token,
    user: state.auth.user
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchOrder: (id, key) => dispatch(fetchOrder(id, key)),
    updateOrder: (data, key, target) => dispatch(updateOrder(data, key, target)),
    changeForm: (field, value) => dispatch(change('OrderPrerequisiteForm', field, value))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
// Implement map dispatch to props
)(OrderPrerequisiteView)
