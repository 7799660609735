import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import Inside from './Inside'
import AuditReportList from './AuditReports/AuditReportList'
// import AuditReportUpdate from './AuditReports/AuditReportUpdate'

export class AuditReports extends React.Component {
  render () {
    return (
      <Inside>
        <Switch>
          {
          // <Route path='/audit-reports/:id' component={AuditReportUpdate} />
          }
          <Route exact path='/audit-reports' component={AuditReportList} />
        </Switch>
      </Inside>
    )
  }
}

function mapStateToProps (state) {
  return {
  }
}

export default connect(
  mapStateToProps
// Implement map dispatch to props
)(AuditReports)
