import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import Inside from './Inside'
import StandardList from './Standards/StandardList'
import StandardUpdate from './Standards/StandardUpdate'

function mapStateToProps (state) {
  return {

  }
}

export class Standards extends React.Component {
  render () {
    return (
      <Inside>
        <Switch>
          <Route path='/standards/:id' component={StandardUpdate} />
          <Route exact path='/standards' component={StandardList} />
        </Switch>
      </Inside>
    )
  }
}

export default connect(
  mapStateToProps
// Implement map dispatch to props
)(Standards)
