export const phases = [
  'Certification Audit Stage 1',
  'Certification Audit Stage 2',
  'Certification Audit Follow-up',
  'Surveillance Audit 1',
  'Surveillance Audit 2',
  'Surveillance Audit 3',
  'Surveillance Audit 4',
  'Surveillance Audit 5'
]

export const caPhases = [
  'Certification Audit Stage 1',
  'Certification Audit Stage 2',
  'Certification Audit Follow-up'
]

export const saPhases = [
  'Surveillance Audit 1',
  'Surveillance Audit 2',
  'Surveillance Audit 3',
  'Surveillance Audit 4',
  'Surveillance Audit 5'
]

export default phases
