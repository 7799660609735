import React from 'react'
// import { Link } from 'react-router-dom'

export default class WidgetSales extends React.Component {
  render () {
    return (
      <div className='new-card'>
        <div className='new-card-header'>
          {
            // <div className='action'>
            //   <div className='btn-group'>
            //     <button type='button' className='btn btn-primary btn-xs rounded-1 dropdown-toggle' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
            //      Action <span className='caret' />
            //     </button>
            //     <ul className='dropdown-menu'>
            //       <li><a href='/'>This month</a></li>
            //       <li><a href='/'>This year</a></li>
            //     </ul>
            //   </div>
            // </div>
          }
          <h4>Sales</h4>
        </div>
        <div className='new-card-body'>
          <div className='sales-widget-list'>
            <ul>
              <li className='left-green'>
                <p> Mandays <span>  {this.props.mandaysExecution.reduce((sum, x) => { return sum + x.executed }, 0).toLocaleString()}  </span> </p>
              </li>
              <li className='left-purple'>
                <p> Order <span>{this.props.invoices.reduce((sum, x) => { return sum + x.total }, 0).toLocaleString()}</span></p>
              </li>
              <li className='left-blue'>
                <p> Invoice <span>{this.props.invoices.reduce((sum, x) => { return sum + x.paid }, 0).toLocaleString()}</span></p>
              </li>
            </ul>
          </div>
        </div>

      </div>

    )
  }
}
