export const initialState = {
  reportList: { data: null, pagination: null, error: null, loading: false },
  globalReportList: { data: null, pagination: null, error: null, loading: false },
  report: { data: null, error: null, loading: false }
}
export default (state = initialState, action) => {
  switch (action.type) {
    case 'CREATE_REPORT_PENDING' :
      return { ...state, report: { ...state.report, error: null, loading: true } }
    case 'CREATE_REPORT_REJECTED':
      return { ...state, report: { ...state.report, error: action.payload, loading: false } }
    case 'CREATE_REPORT_FULFILLED' :
      return { ...state, report: { data: { ...action.payload }, error: null, loading: false } }

    case 'FETCH_REPORTS_PENDING':
      return { ...state, reportList: { data: null, error: null, loading: true } }
    case 'FETCH_REPORTS_REJECTED':
      return { ...state, reportList: { data: null, error: action.payload, loading: false } }
    case 'FETCH_REPORTS_FULFILLED':
      return { ...state,
        reportList: {
          data: action.payload.data,
          pagination: action.payload.pagination,
          error: null,
          loading: false
        }
      }

    case 'FETCH_GLOBAL_REPORTS_PENDING':
      return { ...state, globalReportList: { data: null, error: null, loading: true } }
    case 'FETCH_GLOBAL_REPORTS_REJECTED':
      return { ...state, globalReportList: { data: null, error: action.payload, loading: false } }
    case 'FETCH_GLOBAL_REPORTS_FULFILLED':
      return { ...state,
        globalReportList: {
          data: action.payload.data,
          pagination: action.payload.pagination,
          error: null,
          loading: false
        }
      }

    case 'FETCH_REPORT_PENDING' :
      return { ...state, report: { data: null, error: null, loading: true } }
    case 'FETCH_REPORT_REJECTED':
      return { ...state, report: { data: null, error: action.payload, loading: false } }
    case 'FETCH_REPORT_FULFILLED' :
      return { ...state, report: { data: { ...action.payload }, error: null, loading: false } }

    case 'UPDATE_REPORT_PENDING' :
      return { ...state, report: { ...state.report, error: null, loading: true } }
    case 'UPDATE_REPORT_REJECTED':
      return { ...state, report: { ...state.report, error: action.payload, loading: false } }
    case 'UPDATE_REPORT_FULFILLED' :
      return { ...state, report: { data: { ...action.payload }, error: null, loading: false } }

    case 'DELETE_REPORT_PENDING' :
      return { ...state, report: { ...state.report, error: null, loading: true } }
    case 'DELETE_REPORT_REJECTED':
      return { ...state, report: { ...state.report, error: action.payload, loading: false } }
    case 'DELETE_REPORT_FULFILLED' :
      return { ...state, report: { data: null, error: null, loading: false } }

    case 'LOGOUT_FULFILLED':
      return { ...initialState }

    default:
      return state
  }
}
