import React, { Fragment } from 'react'

export default function DownloadLink (props) {
  return (
    <Fragment>
      {props.file && props.file.map(x => (
        <a key={x.id} href={`${process.env.REACT_APP_BASE_API}/download/${x.id}?token=${props.accessToken}`}>
          <i className='fa fa-download' /> {props.children || 'Download'}
        </a>
      ))}
    </Fragment>
  )
}
