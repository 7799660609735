import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import { Helmet } from 'react-helmet'
import get from 'lodash/fp/get'
import moment from 'moment'

import {
  fetchDa,
  updateDa
} from '../../actions/das'

import DeleteForm from '../../components/common/DeleteForm'
import Affix from '../../components/common/Affix'
import { DaUpdateForm as DaForm } from '../../components/Das/DaForm'

import { totalAllowanceUpdateSelector } from '../../selectors/das'

export class DaUpdate extends React.Component {
  componentWillMount () {
    this.props.fetchDa(this.props.match.params.id, this.props.accessToken)
    // this.props.fetchDaScopes(this.props.match.params.id, this.props.accessToken)
  }

  handleDelete (e) {
    return this.props.deleteDa(this.props.da.id, this.props.accessToken)
  }

  handleSubmit (e) {
    return this.props.updateDa({...this.props.da, detail: {...e.detail}}, this.props.accessToken)
  }

  getRate (fee) {
    if (fee.location.country !== 'Indonesia') {
      return 50
    }
    const inTown = [
      'Kabupaten Kepulauan Seribu',
      'Kota Jakarta Selatan',
      'Kota Jakarta Timur',
      'Kota Jakarta Pusat',
      'Kota Jakarta Barat',
      'Kota Jakarta Utara',
      'Kabupaten Bogor',
      'Kota Bogor',
      'Kota Depok',
      'Kabupaten Tangerang',
      'Kota Tangerang',
      'Kota Tangerang Selatan',
      'Kabupaten Bekasi',
      'Kota Bekasi',
      'Kabupaten Serang',
      'Kota Serang',
      'Kabupaten Karawang'
    ]

    const rateGroup = inTown.indexOf(fee.location.city) >= 0 ? 'A' : 'B'
    const rateA = get(`order.scopes.standard.detail.allowance_a`)(fee) || 200000
    const rateB = get(`order.scopes.standard.detail.allowance_a`)(fee) || 225000

    if (rateGroup === 'A' && fee.role !== 'Trainee') {
      return rateA
    } else if (rateGroup === 'B' && fee.role !== 'Trainee') {
      return rateB
    } else if (rateGroup === 'A' && fee.role === 'Trainee') {
      return rateA / 2
    } else if (rateGroup === 'B' && fee.role === 'Trainee') {
      return rateB / 2
    }

    return 200001
  }

  getWeek (start, duration) {
    let weekday = 0
    let weekEnd = 0
    let day = ''
    const startDay = moment(start)

    for (var i = 0; i < duration; i++) {
      startDay.add(1, 'days')
      day = startDay.format('dd')
      if (day === 'Su' || day === 'Sa') {
        weekEnd += 1
      } else {
        weekday += 1
      }
    }

    return [ weekday, weekEnd ]
  }

  formatData (da) {
    const fees = {}

    if (!da.detail || !da.detail.fees) {
      for (let fee of da.orderSchedules) {
        fees[`fees-${fee.id}`] = [{
          name: 'Audit(weekday)',
          include: this.getWeek(fee.start_date, fee.duration)[0] > 0,
          currency: fee.location.country === 'Indonesia' ? 'IDR' : 'USD',
          quantity: this.getWeek(fee.start_date, fee.duration)[0],
          rate: this.getWeek(fee.start_date, fee.duration)[0] > 0 ? this.getRate(fee) : 0,
          note: ''
        }, {
          name: 'Audit(weekend)',
          include: this.getWeek(fee.start_date, fee.duration)[1] > 0,
          currency: fee.location.country === 'Indonesia' ? 'IDR' : 'USD',
          quantity: this.getWeek(fee.start_date, fee.duration)[1],
          rate: this.getWeek(fee.start_date, fee.duration)[1] > 0 ? this.getRate(fee) * 2 : 0,
          note: ''
        }]
      }
    }

    const newDa = {
      auditor: da.user.name,
      date: moment(da.date).format('MMM - YYYY'),
      detail: {
        fees: (!da.detail || !da.detail.fees) ? {...fees} : {...da.detail.fees}
      }
    }

    return newDa
  }

  render () {
    const { da, getAllowance } = this.props
    return (
      <div>
        <Helmet>
          <title>{da ? `Daily allowance ${da.id}` : `Loading`}</title>
        </Helmet>
        <div className='content'>
          <div className='main-header'>
            <h2>Daily allowance {da && ` : ${da.user.name} ${moment(da.date).format('MMM YYYY')}`}</h2>
            <em>-</em>
          </div>
          <div className='main-content'>
            <div className='row'>
              <div className='col-md-8'>
                { da
                  ? <div>
                    <DaForm
                      onSubmit={this.handleSubmit.bind(this)}
                      initialValues={this.formatData(da)}
                      schedules={da.orderSchedules}
                    />
                    <DeleteForm onClick={this.handleDelete.bind(this)} />
                  </div>
                  : <div>loading</div>
                }
              </div>
              <div className='col-md-4'>
                <Affix>
                  <div className='well'>
                    <dl>
                      <dt><strong>Total (IDR)</strong></dt>
                      <dd>
                        <NumberFormat
                          displayType='text'
                          value={getAllowance.idr}
                          thousandSeparator
                        />
                      </dd>
                      <dt><strong>Total (USD)</strong></dt>
                      <dd>
                        <NumberFormat
                          displayType='text'
                          value={getAllowance.usd}
                          thousandSeparator
                        />
                      </dd>
                    </dl>
                  </div>
                </Affix>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    da: state.das.da.data,
    accessToken: state.auth.user.access.access_token,
    getAllowance: totalAllowanceUpdateSelector(state)
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchDa: (id, key) => dispatch(fetchDa(id, key)),
    updateDa: (data, key) => dispatch(updateDa(data, key))
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(DaUpdate))
