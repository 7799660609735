import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import { Helmet } from 'react-helmet'

import ItemLog from '../../components/common/ItemLog'
import OrderAction from '../../components/Orders/OrderAction'
// import OrderData from '../../components/Orders/OrderData'
import OrderDocuments from '../../components/Orders/OrderDocuments'
// import OrderMilestones from '../../components/Orders/OrderMilestones'
import OrderInvoice from '../../components/Orders/OrderInvoice'
import OrderNc from '../../components/Orders/OrderNc'
import OrderCertificate from '../../components/Orders/OrderCertificate'
import OrderChange from '../../components/Orders/OrderChange'
import OrderReport from '../../components/Orders/OrderReport'
import OrderSchedule from '../../components/Orders/OrderSchedule'
import OrderUsers from '../../components/Orders/OrderUsers'
import OrderPlan from '../../components/Orders/OrderPlan'
import VetoForm from '../../components/Orders/VetoForm'
import DetailWidget from '../../components/Orders/DetailWidget'

import BasicModal from '../../components/common/BasicModal'

import { fetchOrder, updateOrder } from '../../actions/orders'
import { fetchInvoices } from '../../actions/invoices'
import { fetchPlans } from '../../actions/plans'
import { fetchReports } from '../../actions/reports'
import { fetchNcs } from '../../actions/ncs'
import { fetchCertificates } from '../../actions/certificates'
import { fetchChanges } from '../../actions/changes'
import { fetchSchedules } from '../../actions/schedules'

import orderStatuses from '../../const/orderStatuses'
import { isAfter } from '../../selectors/order'

export class OrderView extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      openVeto: false,
      openModal: false,
      submitting: false
    }
  }

  componentWillMount () {
    this.props.fetchOrder(this.props.match.params.id, this.props.accessToken)
    this.props.fetchInvoices(this.props.match.params.id, this.props.accessToken)
    this.props.fetchPlans(this.props.match.params.id, this.props.accessToken)
    this.props.fetchReports(this.props.match.params.id, this.props.accessToken)
    this.props.fetchNcs(this.props.match.params.id, this.props.accessToken)
    this.props.fetchCertificates(this.props.match.params.id, this.props.accessToken)
    this.props.fetchChanges(this.props.match.params.id, this.props.accessToken)
    this.props.fetchSchedules(this.props.match.params.id, this.props.accessToken)
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.order && this.props.order && nextProps.order.status !== this.props.order.status) {
      this.props.fetchInvoices(this.props.match.params.id, this.props.accessToken)
    }
  }

  toggleOpenModal (auditForm) {
    this.setState({
      openModal: !this.state.openModal,
      auditForm
    })
  }

  handleSubmitOrder (status) {
    this.setState({
      submitting: true
    })
    return this.props.updateOrder({
      id: this.props.order.id,
      status
    }, this.props.accessToken).then(() => {
      this.setState({
        submitting: false
      })
      this.toggleOpenModal()
    })
  }

  toggleOpenVeto () {
    this.setState({
      openVeto: !this.state.openVeto
    })
  }

  handleSubmitVeto (e) {
    this.setState({
      submitting: true
    })
    return this.props.updateOrder({
      id: this.props.order.id,
      veto: e.veto.map((x) => x.user_id.value.id),
      status: e.advance ? 'Veto Assigned' : this.props.order.status
    }, this.props.accessToken).then(() => {
      this.toggleOpenVeto()
      this.setState({
        submitting: false
      })
    })
  }

  getReportLink () {
    const { push, order, reports } = this.props
    let reportType
    switch (order.status) {
      case 'Plan Approved by Lead Auditor':
        reportType = 'Stage 1'
        break
      case 'Plan Approved by HCB':
        reportType = 'Stage 1'
        break
      case 'Stage 1 - On Audit':
        reportType = 'Stage 1'
        break
      case 'Stage 1 - Audited':
        reportType = 'Stage 1'
        break
      case 'Stage 2 - On Audit':
        reportType = 'Stage 2'
        break
      case 'Stage 2 - Audited':
        reportType = 'Stage 2'
        break
      case 'Veto Assigned':
        reportType = 'Veto'
        break
      case 'Veto Approval Pending':
        reportType = 'Veto'
        break
      default:
        reportType = 'Stage 1'
        break
    }
    const currentReport = reports.filter(x => x.type === reportType && x.status === 'Draft')
    const url = currentReport.length > 0
      ? `/orders/${order.id}/reports/${currentReport[0].id}`
      : `/orders/${order.id}/reports/new`

    return push.bind(this, url)
  }

  render () {
    const {
      order,
      invoices,
      reports,
      plans,
      ncs,
      certificates,
      changes,
      schedules,
      user,
      accessToken
    } = this.props

    const { submitting } = this.state
    let veto
    let vetoFormValue
    let orderUser = []

    let afterQuestionnaireReviewed = null
    let afterPlanApproved = null
    let afterApprovalPending = null

    if (order) {
      veto = order.users.filter((x) => x.role === 'Veto Person')

      vetoFormValue = veto.length > 0
        ? veto.map((x) => {
          return ({
            user_id: {
              label: x.user.data.display_name,
              value: x.user
            }
          })
        })
        : null

      orderUser = order.users
      afterQuestionnaireReviewed = isAfter(order.status, 'Questionnaire Reviewed')
      afterPlanApproved = isAfter(order.status, 'Plan Approved by Lead Auditor')
      afterApprovalPending = isAfter(order.status, 'HCB Approval Pending')
    }

    return (
      <div>
        <Helmet>
          <title>{order ? `Order ${order.id}` : `Loading`}</title>
        </Helmet>
        <div className='content'>
          <div>
            <div className='main-header'>
              { order &&
                <h2>{order.phase} - {order && order.id} </h2>
              }
              { order &&
                <em>Part of Quotation <Link to={`/quotations/${order.quotation.id}`}>{order.quotation.id}</Link></em>
              }
            </div>
            <div className='row'>
              <div className='col-md-8'>
                { order && plans && reports && invoices
                  ? <div>
                    <OrderAction
                      order={order} user={user}
                      onCompleteQuestionnaire={this.props.push.bind(this, `/orders/${order.id}/prerequisite/${order.questionnaire.id}`)}
                      // onCompleteQuestionnaire={this.props.push.bind(this, `/questionnaires/${order.questionnaire.id}/review?order_id=${order.id}`)}

                      onReviewQuestionnaire={this.toggleOpenModal.bind(this, 'questionnaire-review')}

                      onChangeOrder={this.toggleOpenModal.bind(this, 'change-order')}

                      onManageSchedule={this.props.push.bind(this, `/orders/${order.id}/schedules/manage`)}
                      onReviewSchedule={this.props.push.bind(this, `/orders/${order.id}/schedules/review`)}

                      onCreatePlan={this.props.push.bind(this, `/orders/${order.id}/plans/new`)}
                      onUpdatePlan={plans[0] ? this.props.push.bind(this, `/orders/${order.id}/plans/${plans[0].id}`) : null}

                      onEndStage1={this.toggleOpenModal.bind(this, 'end-stage-1')}

                      onCreateReport={this.getReportLink()}

                      onCreateNc={this.props.push.bind(this, `/orders/${order.id}/ncs/new`)}
                      onUpdateReport={reports[0] ? this.props.push.bind(this, `/orders/${order.id}/reports/${reports[0].id}`) : null}

                      preAssignVeto={order.nc_status === 'Closed'}
                      onToggleVeto={this.toggleOpenVeto.bind(this)}

                      preAuditStart={invoices.filter((x) => ['Draft', 'Created'].indexOf(x.status) >= 0).length === 0}
                      onAuditCancel={this.toggleOpenModal.bind(this, 'cancel')}

                      onConfirmAmendment={this.toggleOpenModal.bind(this, 'confirm-amendment')}

                      onToggleVerification={this.toggleOpenModal.bind(this, 'toggle-verification')}

                      onAudit1Start={this.toggleOpenModal.bind(this, 'stage-1-start')}
                      onAudit1End={this.toggleOpenModal.bind(this, 'end-stage-1')}
                      onAudit2Start={this.toggleOpenModal.bind(this, 'start-2')}
                      onAudit2End={this.toggleOpenModal.bind(this, 'end-stage-2')}
                      onAudit3Start={this.toggleOpenModal.bind(this, 'start-3')}
                      onAudit3End={this.toggleOpenModal.bind(this, 'end-stage-3')}
                      onHCBApproval={this.toggleOpenModal.bind(this, 'hcb-approval')}
                      onEndCertification={this.toggleOpenModal.bind(this, 'end-certification')}

                      preReleaseCertificate={invoices.filter((x) => x.status !== 'Paid').length === 0}
                      onReleaseCertificate={this.props.push.bind(this, `/orders/${order.id}/certificates/new`)}

                      userAuditor={orderUser.filter((x) => x.role === 'Auditor' || x.role === 'Lead Auditor')}
                      userLeadAuditor={orderUser.filter((x) => x.role === 'Lead Auditor')}
                      userCustomer={orderUser.filter((x) => x.role === 'Customer')}
                      userVeto={orderUser.filter((x) => x.role === 'Veto Person')}

                      submitting={this.state.submitting}
                    />
                    <OrderDocuments
                      order={order}
                      accessToken={accessToken}
                    />
                  </div>
                  : <div>loading order data</div>
                }
                { afterQuestionnaireReviewed &&
                  <Fragment>
                    { plans && order && orderStatuses.find
                      ? <OrderPlan
                        order={order}
                        plans={plans}
                        user={user}
                      />
                      : <div>loading plan</div>
                    }
                  </Fragment>
                }
                { afterPlanApproved &&
                  <Fragment>
                    { reports && order
                      ? <OrderReport
                        order={order}
                        reports={reports}
                        user={user}
                      />
                      : <div>loading reports</div>
                    }
                    { ncs && order
                      ? <OrderNc
                        order={order}
                        ncs={ncs}
                        user={user}
                      />
                      : <div>loading nc</div>
                    }
                  </Fragment>
                }
                { invoices && order
                  ? <OrderInvoice
                    order={order}
                    invoices={invoices}
                    user={user}
                  />
                  : <div>loading invoice</div>
                }
                { afterApprovalPending &&
                  <Fragment>
                    { certificates && order
                      ? <OrderCertificate
                        order={order}
                        certificates={certificates}
                        user={user}
                      />
                      : <div>loading certificate</div>
                    }
                  </Fragment>
                }
                { changes && order
                  ? <OrderChange
                    order={order}
                    changes={changes}
                    user={user}
                  />
                  : <div>loading change</div>
                }
                { order
                  ? <DetailWidget order={order} accessToken={this.props.accessToken} />
                  : <div>loading detail</div>
                }
              </div>
              <div className='col-md-4'>
                { order
                  ? <OrderUsers
                    order={order}
                  />
                  : <div>loading order</div>
                }
                { afterQuestionnaireReviewed &&
                  <Fragment>
                    { order && schedules
                      ? <OrderSchedule
                        order={order}
                        schedules={schedules}
                        user={user}
                      />
                      : <div>loading schedules</div>
                    }
                  </Fragment>
                }
                { order
                  ? <ItemLog log={order.log} entity='Order' />
                  : <div>loading</div>
                }
                {
                  // order
                  // ? <OrderMilestones order={order} />
                  // : <div>loading order</div>
                }
              </div>
            </div>
          </div>
        </div>
        { order &&
          <BasicModal open={this.state.openModal} onClose={this.toggleOpenModal.bind(this)}>
            { this.state.auditForm === 'questionnaire-review' &&
              <div>
                <div className='alert alert-info'> Please confirm the questionnaire is completed.</div>
                <button disabled={submitting} className='btn btn-block btn-danger' onClick={this.handleSubmitOrder.bind(this, 'Created')}>Set order as : questionnaire incomplete</button>
                <button disabled={submitting} className='btn btn-block btn-primary' onClick={this.handleSubmitOrder.bind(this, 'Questionnaire Reviewed')}>Set order as : questionnaire completed</button>
              </div>
            }
            { this.state.auditForm === 'stage-1-start' &&
              <div>
                <div className='alert alert-info'> Please make sure that audit schedules are confirmed and audit requirements are completed.</div>
                <button disabled={submitting} className='btn btn-block btn-primary' onClick={this.handleSubmitOrder.bind(this, 'Stage 1 - On Audit')}>Start audit</button>
              </div>
            }
            { this.state.auditForm === 'confirm-amendment' &&
              <div>
                <div className='alert alert-info'> Please confirm the amendment before initiating audit.</div>
                <button disabled={submitting} className='btn btn-block btn-primary' onClick={this.handleSubmitOrder.bind(this, 'Reschedule')}>Reschedule Stage 2</button>
              </div>
            }
            { this.state.auditForm === 'toggle-verification' &&
              <div>
                <div className='alert alert-info'> Please confirm the verification before initiating audit.</div>
                <button disabled={submitting} className='btn btn-block btn-primary' onClick={this.handleSubmitOrder.bind(this, 'Stage 2 - Report Published')}>Set stage 2 as audited</button>
              </div>
            }
            { this.state.auditForm === 'end-stage-1' &&
              <div>
                <div className='alert alert-info'> Please confirm the report before advancing to next stage.</div>
                <button disabled={submitting} className='btn btn-block btn-danger' onClick={this.handleSubmitOrder.bind(this, 'Amandment Quotation')}>Amandment quotation</button>
                <button disabled={submitting} className='btn btn-block btn-danger' onClick={this.handleSubmitOrder.bind(this, 'Reschedule')}>Reschedule stage 2</button>
                <button disabled={submitting} className='btn btn-block btn-primary' onClick={this.handleSubmitOrder.bind(this, 'Report Published - Stage 1')}>Report published</button>
              </div>
            }
            { this.state.auditForm === 'start-2' &&
              <div>
                <div className='alert alert-info'> Please make sure that audit schedules are confirmed and audit requirements are completed.</div>
                <button disabled={submitting} className='btn btn-block btn-primary' onClick={this.handleSubmitOrder.bind(this, 'Stage 2 - On Audit')}>Start audit stage 2</button>
              </div>
            }
            { this.state.auditForm === 'end-stage-2' &&
              <div>
                <div className='alert alert-info'> Please confirm the report before advancing to next stage.</div>
                <button disabled={submitting} className='btn btn-block btn-danger' onClick={this.handleSubmitOrder.bind(this, 'Verification by Submmission of Document')}>Verification by submmission of document</button>
                <button disabled={submitting} className='btn btn-block btn-danger' onClick={this.handleSubmitOrder.bind(this, 'Follow up')}>Reschedule follow up audit</button>
                <button disabled={submitting} className='btn btn-block btn-primary' onClick={this.handleSubmitOrder.bind(this, 'Report Published - Stage 2')}>Report published</button>
              </div>
            }
            { this.state.auditForm === 'start-3' &&
              <div>
                <div className='alert alert-info'> Please make sure that audit schedules are confirmed and audit requirements are completed.</div>
                <button disabled={submitting} className='btn btn-block btn-primary' onClick={this.handleSubmitOrder.bind(this, 'Follow up - On Audit')}>Start follow up audit</button>
              </div>
            }
            { this.state.auditForm === 'end-stage-3' &&
              <div>
                <div className='alert alert-info'> Please confirm the report before advancing to next stage.</div>
                <button disabled={submitting} className='btn btn-block btn-danger' onClick={this.handleSubmitOrder.bind(this, 'Verification by Submmission of Document')}>Verification by submmission of document</button>
                <button disabled={submitting} className='btn btn-block btn-danger' onClick={this.handleSubmitOrder.bind(this, 'Follow up')}>Reschedule follow up audit</button>
                <button disabled={submitting} className='btn btn-block btn-primary' onClick={this.handleSubmitOrder.bind(this, 'Report Published - Stage 2')}>Report published</button>
              </div>
            }
            { this.state.auditForm === 'questionnaire-complete' &&
              <div>
                <div className='alert alert-info'> Please confirm the questionnaire is completed.</div>
                <button disabled={submitting} className='btn btn-block btn-primary' onClick={this.handleSubmitOrder.bind(this, 'Questionnaire Completed')}>Set order questionnaire as complete</button>
              </div>
            }
            { this.state.auditForm === 'hcb-approval' &&
              <div>
                <div className='alert alert-info'> Please confirm the order is completed.</div>
                <button disabled={submitting} className='btn btn-block btn-danger' onClick={this.handleSubmitOrder.bind(this, 'HCB Approval Pending')}>Set order as : head of certification bureau approval pending</button>
                <button disabled={submitting} className='btn btn-block btn-primary' onClick={this.handleSubmitOrder.bind(this, 'Approved by HCB')}>Set order as approved by head of certification bureau</button>
              </div>
            }
            { this.state.auditForm === 'cancel' &&
              <div>
                <div className='alert alert-info'> Please confirm before canceling audit.</div>
                <button disabled={submitting} className='btn btn-block btn-danger' onClick={this.handleSubmitOrder.bind(this, 'Canceled')}>Cancel audit</button>
              </div>
            }
            { this.state.auditForm === 'end-certification' &&
              <div>
                <div className='alert alert-info'> Please confirm before canceling audit.</div>
                <button disabled={submitting} className='btn btn-block btn-danger' onClick={this.handleSubmitOrder.bind(this, 'Order Finished')}>Set order as finished</button>
              </div>
            }
            { this.state.auditForm === 'change-order' &&
              <div>
                <div className='alert alert-info'> Change order here.</div>
                <button disabled={submitting} className='btn btn-block btn-danger' onClick={this.handleSubmitOrder.bind(this, 'Order Finished')}>Set order as finished</button>
              </div>
            }
          </BasicModal>
        }
        { order &&
          <BasicModal open={this.state.openVeto} onClose={this.toggleOpenVeto.bind(this)} title='Assign Veto Person'>
            <VetoForm
              userAPI={{
                url: process.env.REACT_APP_BASE_API + '/users?auditors&per-page=0',
                label: 'data.display_name',
                accessToken: this.props.accessToken
              }}
              onSubmit={this.handleSubmitVeto.bind(this)}
              initialValues={{ veto: vetoFormValue }}
            />
          </BasicModal>
        }
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    order: state.orders.order.data,
    invoices: state.invoices.invoiceList.data,
    plans: state.plans.planList.data,
    ncs: state.ncs.ncList.data,
    certificates: state.certificates.certificateList.data,
    changes: state.changes.changeList.data,
    reports: state.reports.reportList.data,
    schedules: state.schedules.scheduleList.data,
    accessToken: state.auth.user.access.access_token,
    user: state.auth.user
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchOrder: (id, key) => dispatch(fetchOrder(id, key)),
    updateOrder: (data, key, target) => dispatch(updateOrder(data, key, target)),
    fetchInvoices: (id, key) => dispatch(fetchInvoices(id, key)),
    fetchPlans: (id, key) => dispatch(fetchPlans(id, key)),
    fetchReports: (id, key) => dispatch(fetchReports(id, key)),
    fetchNcs: (id, key) => dispatch(fetchNcs(id, key)),
    fetchCertificates: (id, key) => dispatch(fetchCertificates(id, key)),
    fetchChanges: (id, key) => dispatch(fetchChanges(id, key)),
    fetchSchedules: (id, key) => dispatch(fetchSchedules(id, key)),
    push: (route) => dispatch(push(route))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
// Implement map dispatch to props
)(OrderView)
