import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import Inside from './Inside'
import CertificateList from './Certificates/CertificateList'
// import CertificateUpdate from './Certificates/CertificateUpdate'

function mapStateToProps (state) {
  return {

  }
}

export class Certificates extends React.Component {
  render () {
    return (
      <Inside>
        <Switch>
          {
          // <Route path='/certificates/:id' component={CertificateUpdate} />
          }
          <Route exact path='/certificates' component={CertificateList} />
        </Switch>
      </Inside>
    )
  }
}

export default connect(
  mapStateToProps
// Implement map dispatch to props
)(Certificates)
