export const initialState = {
  planList: { data: null, pagination: null, error: null, loading: false },
  globalPlanList: { data: null, pagination: null, error: null, loading: false },
  plan: { data: null, error: null, loading: false }
}
export default (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_PLANS_PENDING':
      return { ...state, planList: {data: null, error: null, loading: true} }
    case 'FETCH_PLANS_REJECTED':
      return { ...state, planList: {data: null, error: action.payload, loading: false} }
    case 'FETCH_PLANS_FULFILLED':
      return { ...state,
        planList: {
          data: action.payload.data,
          pagination: action.payload.pagination,
          error: null,
          loading: false
        }
      }

    case 'FETCH_GLOBAL_PLANS_PENDING':
      return { ...state, globalPlanList: {data: null, error: null, loading: true} }
    case 'FETCH_GLOBAL_PLANS_REJECTED':
      return { ...state, globalPlanList: {data: null, error: action.payload, loading: false} }
    case 'FETCH_GLOBAL_PLANS_FULFILLED':
      return { ...state,
        globalPlanList: {
          data: action.payload.data,
          pagination: action.payload.pagination,
          error: null,
          loading: false
        }
      }

    case 'FETCH_PLAN_PENDING' :
      return { ...state, plan: {data: null, error: null, loading: true} }
    case 'FETCH_PLAN_REJECTED':
      return { ...state, plan: {data: null, error: action.payload, loading: false} }
    case 'FETCH_PLAN_FULFILLED' :
      return { ...state, plan: { data: { ...action.payload }, error: null, loading: false } }

    case 'CREATE_PLAN_PENDING' :
      return { ...state, plan: { ...state.plan, error: null, loading: true } }
    case 'CREATE_PLAN_REJECTED':
      return { ...state, plan: {...state.plan, error: action.payload, loading: false} }
    case 'CREATE_PLAN_FULFILLED' :
      return { ...state, plan: { data: { ...action.payload }, error: null, loading: false } }

    case 'UPDATE_PLAN_PENDING' :
      return { ...state, plan: { ...state.plan, error: null, loading: true } }
    case 'UPDATE_PLAN_REJECTED':
      return { ...state, plan: {...state.plan, error: action.payload, loading: false} }
    case 'UPDATE_PLAN_FULFILLED' :
      return { ...state, plan: { data: { ...action.payload }, error: null, loading: false } }

    case 'DELETE_PLAN_PENDING' :
      return { ...state, plan: {...state.plan, error: null, loading: true} }
    case 'DELETE_PLAN_REJECTED':
      return { ...state, plan: {...state.plan, error: action.payload, loading: false} }
    case 'DELETE_PLAN_FULFILLED' :
      return { ...state, plan: { data: null, error: null, loading: false } }

    case 'LOGOUT_FULFILLED':
      return { ...initialState }

    default:
      return state
  }
}
