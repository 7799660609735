import React from 'react'
import { Manager, Reference, Popper } from 'react-popper'
import { Link } from 'react-router-dom'

import SingleScheduleForm from './SingleScheduleForm'

export default class ScheduleRow extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedDate: null,
      nextDate: null
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.openSchedule && this.props.openSchedule !== nextProps.openSchedule) {
      const date = nextProps.openSchedule.split('-')
      const selectedDate = date.reduce((previous, current, i) => {
        return i === 1 ? current : i > 1 ? previous + '-' + current : previous
      }, null)

      this.setState((state, props) => ({
        selectedDate,
        nextDate: selectedDate
      }))
    } else {
      this.setState((state, props) => ({
        selectedDate: null,
        nextDate: null
      }))
    }
  }

  handleOpenSchedule (user, schedule, event) {
    event.preventDefault()
    this.props.onOpenSchedule(user, schedule)
    this.setState((state, props) => ({
      selectedDate: null
    }))
  }

  handleNew (user, date, event) {
    event.preventDefault()
    if (this.state.selectedDate === null) { // open new
      this.props.onOpenSchedule(user, `${user}-${date}`) // send off user id and temporary id
    } else if (this.isInRange(date, this.state.selectedDate, this.state.nextDate)) { // remove selected
      this.props.onOpenSchedule(user, null)
      this.setState((state, props) => ({
        selectedDate: null,
        nextDate: null
      }))
    } else {
      this.setState((state, props) => ({ // set next date
        nextDate: date
      }))
    }
  }

  handleSubmit (data) {
    const { dates, schedule, ...userBase } = this.props.schedule
    const { selectedDate, nextDate } = this.state
    const startDate = nextDate < selectedDate ? nextDate : selectedDate
    const newData = {
      ...data,
      xid: `${userBase.id}-${startDate}`,
      user: userBase,
      start_date: startDate,
      end_date: nextDate > selectedDate ? nextDate : selectedDate
    }

    this.props.onSubmit(newData)
    this.props.onOpenSchedule(this.props.schedule.id, null)
    this.setState((state, props) => ({
      selectedDate: null,
      nextDate: null
    }))
  }

  handleRemove (user, date) {
    this.props.onOpenSchedule(user, null)
    this.props.onRemove(user, date)
  }

  getOrderInitial (order) {
    let phase
    switch (order.phase) {
      case 'Gap Analysis / Pre Audit':
        phase = 'GA'
        break
      case 'Training':
        phase = 'TR'
        break
      case 'Certification Audit':
        phase = 'CA'
        break
      default:
        if (order.phase.indexOf('Surveillance Audit') > -1) {
          phase = order.phase.replace('Surveillance Audit', 'SA')
        } else {
          phase = 'X'
        }
        break
    }
    return `${phase}`
  }

  isInRange (date, start, end) {
    let xStart = start
    let xEnd = end
    if (start > end) {
      xStart = end
      xEnd = start
    }
    return date <= xEnd && date >= xStart
  }

  isActive (x, y, z) {
    return (
      z.user.id === x.id &&
      (
        z.start_date === y.fullDate ||
        z.end_date === y.fullDate ||
        (y.fullDate > z.start_date && y.fullDate < z.end_date)
      )
    )
  }

  render () {
    const { openSchedule, locations, order, orderSchedules } = this.props
    const { selectedDate, nextDate } = this.state
    const x = this.props.schedule

    return (
      <tr>
        <th className='fixed-left'>
          <Link to={`/users/${x.id}`}>{x.data.display_name}</Link>
        </th>
        {x.dates.map((y, j) => {
          let background = ''
          let activeSchedule = null
          // show weekends
          if (['Sunday', 'Saturday'].indexOf(y.day) > -1) {
            background = 'bg-info'
          }

          // show global schedule
          if (y.schedule) {
            background = y.schedule.status === 'Draft' ? 'bg-warning' : 'bg-danger'
          }

          // inactive selected
          if (orderSchedules && orderSchedules.find((z) => this.isActive(x, y, z))) {
            background = 'selected'
            activeSchedule = orderSchedules.find((z) => this.isActive(x, y, z))
          }

          // active selection
          if (this.isInRange(y.fullDate, selectedDate, nextDate)) {
            background = 'selected'
          }
          return (
            <td
              colSpan={y.schedule ? y.schedule.duration : 1}
              key={j}
              className={`${background} ${y.schedule ? 'occupied' : 'empty-date'}`}
            >
              { y.schedule
                ? <Manager>
                  <div style={{width: y.schedule ? y.schedule.duration * 25 : 25}}>
                    <Reference>
                      {({ ref }) => (
                        <button className='btn btn-link btn-xs' href='/' ref={ref} onClick={this.handleOpenSchedule.bind(this, x.id, y.schedule.id)}>
                          {this.getOrderInitial(y.schedule.order)} - {y.schedule.order.id}
                        </button>
                      )}

                    </Reference>
                    {openSchedule === y.schedule.id && (
                      <Popper placement='left'>
                        {({ ref, style, placement, arrowProps }) => (
                          <div
                            ref={ref}
                            style={{...style, display: 'block', width: 300}}
                            data-placement={placement}
                            className={`popover fade ${placement} in`}
                            role='tooltip'
                          >
                            <div className='arrow' />
                            <h3 className='popover-title'>
                              {y.schedule.order.phase} - {y.schedule.order.id}
                              <a href='/' onClick={this.handleOpenSchedule.bind(this, null, null)} className='pull-right'><i className='fa-close fa' /></a>
                            </h3>
                            <div className='popover-content'><strong>{y.schedule.customer.name}</strong> <br /> {y.schedule.order.status} <br /> <Link to={`/orders/${y.schedule.order.id}`}>See order</Link></div>
                          </div>
                        )}
                      </Popper>
                    )}
                  </div>
                </Manager>
                : activeSchedule
                  ? <Manager>
                    <div>
                      <Reference>
                        {({ ref }) => (
                          <button className='reference btn btn-link btn-xs' ref={ref} onClick={this.handleNew.bind(this, x.id, activeSchedule.start_date)}>{activeSchedule.type === 'Stage 1' ? 'S1' : activeSchedule.type === 'Stage 2' ? 'S2' : 'F'}</button>
                        )}
                      </Reference>
                      {openSchedule === `${x.id}-${y.fullDate}` && (
                        <Popper placement='left'>
                          {({ ref, style, placement, arrowProps }) => (
                            <div
                              ref={ref}
                              style={{...style, display: 'block', width: 300}}
                              data-placement={placement}
                              className={`popover fade ${placement} in new`}
                              role='tooltip'
                            >
                              <div className='arrow' />
                              <h3 className='popover-title'>
                                <span>{y.fullDate} - {x.data.display_name}</span>
                                <a href='/' onClick={this.handleNew.bind(this, x.id, activeSchedule.start_date)} className='pull-right'><i className='fa-close fa' /></a>
                              </h3>
                              <div className='popover-content'>
                                <SingleScheduleForm
                                  locations={locations}
                                  order={order}
                                  initialValues={activeSchedule}
                                  onSubmit={this.handleSubmit.bind(this)}
                                  onRemove={this.handleRemove.bind(this, x.id, activeSchedule.start_date)}
                                />
                              </div>
                            </div>
                          )}
                        </Popper>
                      )}
                    </div>
                  </Manager>
                  : <Manager>
                    <div>
                      <Reference>
                        {({ ref }) => (
                          <button className='reference btn btn-link btn-xs' href='/' ref={ref} onClick={this.handleNew.bind(this, x.id, y.fullDate)}>{y.date}</button>
                        )}
                      </Reference>
                      {openSchedule === `${x.id}-${y.fullDate}` && (
                        <Popper placement='left'>
                          {({ ref, style, placement, arrowProps }) => (
                            <div
                              ref={ref}
                              style={{...style, display: 'block', width: 300}}
                              data-placement={placement}
                              className={`popover fade ${placement} in new`}
                              role='tooltip'
                            >
                              <div className='arrow' />
                              <h3 className='popover-title'>
                                <span>{y.fullDate} - {x.data.display_name}</span>
                                <a href='/' onClick={this.handleNew.bind(this, x.id, y.fullDate)} className='pull-right'><i className='fa-close fa' /></a>
                              </h3>
                              <div className='popover-content'>
                                <SingleScheduleForm
                                  locations={locations}
                                  order={order}
                                  onSubmit={this.handleSubmit.bind(this)}
                                  onRemove={this.handleRemove.bind(this, x.id, y.fullDate)}
                                />
                              </div>
                            </div>
                          )}
                        </Popper>
                      )}
                    </div>
                  </Manager>
              }
            </td>
          )
        })}

      </tr>
    )
  }
}
