import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'react-router-redux'
import thunk from 'redux-thunk'
import { createBrowserHistory } from 'history'
import throttle from 'lodash/throttle'

// import { logger } from 'redux-logger'

import { loadState, saveState } from './localStorage'
import rootReducer from '../reducers'

export const history = createBrowserHistory()

// const initialState = {}
const enhancers = []
const middleware = [
  thunk,
  routerMiddleware(history)
]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  // middleware.push(logger)

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
)

const persistedState = loadState()

const store = createStore(
  rootReducer,
  persistedState,
  composedEnhancers
)

store.subscribe(throttle(() => {
  saveState(store.getState())
}, 1000))

export default store

// import { applyMiddleware, createStore, compose } from 'redux'
// import thunk from 'redux-thunk'
// import { logger } from 'redux-logger'
// import { routerMiddleware } from 'react-router-redux'
// import { browserHistory } from 'react-router'

// import rootReducer from '../reducers'

// let middleware = applyMiddleware(thunk, routerMiddleware(browserHistory), logger)

// if (process.env.NODE_ENV === 'development') {
//   middleware = applyMiddleware(thunk, routerMiddleware(browserHistory), logger)
// } else if (process.env.NODE_ENV === 'production') {
//   middleware = applyMiddleware(thunk, routerMiddleware(browserHistory))
// }

// export default() => {
//   return createStore(
//     rootReducer,
//     compose(
//       middleware
//     )
//   )
// }
