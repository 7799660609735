import React from 'react'
import { Link } from 'react-router-dom'

export default class WidgetStats extends React.Component {
  render () {
    if (!this.props.customers && !this.props.invoices) {
      return ''
    }
    return (
      <div>
        <div className='col-md-6'>
          <div className='new-card p-1'>
            <i className='fa fa-bar-chart-o fa-2x' />
            <p className='stats-title'> Total Revenue </p>
            <h5> <Link to='/invoices'>IDR {this.props.invoices.reduce((sum, x) => { return sum + x.total }, 0).toLocaleString()}</Link></h5>
            {
              // <p className='text-danger'>- 25% </p>
            }
          </div>
        </div>

        <div className='col-md-6'>
          <div className='new-card p-1'>
            <i className='fa fa-money fa-2x' />
            <p className='stats-title'> Outstanding </p>
            <h5> <Link to='/invoices'>IDR {this.props.invoices.reduce((sum, x) => { return sum + x.outstanding }, 0).toLocaleString()}</Link></h5>
            {
              // <p className='text-warning'> -10% </p>
            }
          </div>
        </div>

        <div className='col-md-6'>
          <div className='new-card p-1'>
            <i className='fa fa-user-circle-o fa-2x' />
            <p className='stats-title'> Customer </p>
            <h5><Link to='/customers'>{this.props.customers.totalCount}</Link></h5>
            {
              // <p className='text-success'>+5%</p>
            }
          </div>
        </div>

        <div className='col-md-6'>
          <div className='new-card p-1'>
            <i className='fa fa-sort-amount-desc fa-2x' />
            <p className='stats-title'> EBIT </p>
            <h5>IDR {this.props.invoices.reduce((sum, x) => { return sum + x.paid }, 0).toLocaleString()}</h5>
            {
              // <p className='text-warning'> -10% </p>
            }
          </div>
        </div>
      </div>
    )
  }
}
