import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import Inside from './Inside'
import OrderList from './Orders/OrderList'
import OrderView from './Orders/OrderView'
import OrderInvoiceView from './Orders/OrderInvoiceView'
import OrderScheduleView from './Orders/OrderScheduleView'
import OrderReportView from './Orders/OrderReportView'
import OrderNcView from './Orders/OrderNcView'
import OrderCertificateView from './Orders/OrderCertificateView'
import OrderChangeView from './Orders/OrderChangeView'
import OrderPlanView from './Orders/OrderPlanView'
import OrderPrerequisiteView from './Orders/OrderPrerequisiteView'

function mapStateToProps (state) {
  return {

  }
}

export class Orders extends React.Component {
  render () {
    return (
      <Inside>
        <Switch>
          <Route path='/orders/:orderId/prerequisite/:questionnaireId?' component={OrderPrerequisiteView} />
          <Route path='/orders/:orderId/invoices/:id?' component={OrderInvoiceView} />
          <Route path='/orders/:orderId/schedules/:id?' component={OrderScheduleView} />
          <Route path='/orders/:orderId/reports/:id?' component={OrderReportView} />
          <Route path='/orders/:orderId/ncs/:id?' component={OrderNcView} />
          <Route path='/orders/:orderId/certificates/:id?' component={OrderCertificateView} />
          <Route path='/orders/:orderId/changes/:id?' component={OrderChangeView} />
          <Route path='/orders/:orderId/plans/:id?' component={OrderPlanView} />
          <Route path='/orders/:id' component={OrderView} />
          <Route exact path='/orders' component={OrderList} />
        </Switch>
      </Inside>
    )
  }
}

export default connect(
  mapStateToProps
// Implement map dispatch to props
)(Orders)
