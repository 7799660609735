import React from 'react'

import Required from './Required'

export default class TimeField extends React.Component {
  constructor (props) {
    super(props)
    let timeArray = null
    if (props.input.value) {
      timeArray = props.input.value.split(':')
    }
    this.state = {
      hour: timeArray ? timeArray[0] : '07',
      minute: timeArray ? timeArray[1] : '00'
    }
  }
  onChange (unit, e) {
    const newState = []
    newState['hour'] = this.state.hour
    newState['minute'] = this.state.minute
    newState[unit] = e.target.value
    this.setState(newState)
    this.props.input.onChange(newState['hour'] + ':' + newState['minute'])
  }
  getSelect (unit) {
    let options
    if (unit === 'hour') {
      options = ['07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24']
    } else {
      options = ['00', '15', '30', '45']
    }

    return (
      <select
        className='form-control'
        value={unit === 'hour' ? this.state.hour : this.state.minute}
        style={{display: 'inline-block', width: 'auto', paddingRight: 20}}
        onChange={this.onChange.bind(this, unit)}
      >
        { options.map((x, y) => <option value={x} key={y} >{x}</option>)}
      </select>
    )
  }

  render () {
    return (
      <div className={`form-group ${this.props.label}`}>
        <label htmlFor={this.props.label} className='col-sm-3 control-label'>{this.props.label} {this.props.required && <Required />}</label>
        <div className='col-sm-9'>
          { this.getSelect('hour') }
          { this.getSelect('minute') }
        </div>
      </div>
    )
  }
}
