import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

import {
  TextField,
  OptionsField,
  TextareaField
} from '../common/FormFields'
import ConfirmButton from '../common/ConfirmButton'
import DateField from '../common/DateField'
import FileField from '../common/FileField'

const ncKind = ['NC A', 'NC B', 'PI']

class NcForm extends Component {
  render () {
    const { handleSubmit, submitting } = this.props
    return (

      <div>
        <form className='form-horizontal nc-form' onSubmit={handleSubmit}>
          <Field name='description' type='text' component={TextareaField} label='Description' />
          <Field name='clausul' type='text' component={TextareaField} label='Related Clause' />
          <Field name='stage' type='text' component={TextField} label='Stage' disabled />
          <Field name='kind' type='select' component={OptionsField} options={ncKind} label='Category' />
          <Field name='due_date' component={DateField} label='Due Date' />
          <Field name='detail.attachment' component={FileField} label='Attachment' api={this.props.fileAPI} />
          <Field name='detail.require_reaudit' type='checkbox' component={OptionsField} label='Re-Audit necessary' />
          <Field name='detail.require_evidence' type='checkbox' component={OptionsField} label='Submit new documents / records / evidence' />
          <Field name='detail.close_before_stage_2' type='checkbox' component={OptionsField} label='Need to closed before Stage 2 Audit' />
          { this.props.simple
            ? <div className='form-group'>
              <div className='col-sm-offset-3 col-sm-9'>
                <button disabled={submitting} type='submit' className='btn btn-default btn-block'>Save</button>
              </div>
            </div>
            : <div className='form-group'>
              <div className='col-sm-offset-3 col-sm-6'>
                <button disabled={submitting} type='submit' className='btn btn-default btn-block'>Save</button>
              </div>
              <div className='col-sm-3'>
                <ConfirmButton disabled={submitting} text={'Save NC'}>
                  <button disabled={submitting} type='submit' className='btn btn-primary btn-block' onClick={handleSubmit(values => this.props.onSubmit({...values, advance: true}))} >Continue</button>
                </ConfirmButton>
              </div>
            </div>
          }
        </form>
      </div>
    )
  }
}

export default NcForm

const BaseNcCreateForm = props => <NcForm {...props} mode='create' />

export const NcCreateForm = reduxForm({
  form: 'ncCreate',
  enableReinitialize: true
})(BaseNcCreateForm)

const BaseNcUpdateForm = props => <NcForm {...props} mode='create' />

export const NcUpdateForm = reduxForm({
  form: 'ncUpdate',
  enableReinitialize: true
})(BaseNcUpdateForm)
