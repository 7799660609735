import React from 'react'

export function getOrderLabel (status) {
  let label
  switch (status) {
    case 'Created':
      label = 'warning'
      break
    case 'Schedule Draft':
      label = 'warning'
      break
    case 'Questionnaire Completed':
      label = 'warning'
      break
    case 'Schedule Published':
      label = 'warning'
      break
    case 'Schedule Approved by HCB':
      label = 'warning'
      break
    case 'Schedule Approved by Customer':
      label = 'warning'
      break
    case 'Plan Draft':
      label = 'warning'
      break
    case 'Plan Published':
      label = 'warning'
      break
    case 'Plan Approved by Lead Auditor':
      label = 'warning'
      break
    case 'On Audit':
      label = 'info'
      break
    case 'Audited':
      label = 'info'
      break
    case 'Report Draft':
      label = 'info'
      break
    case 'Report Published':
      label = 'info'
      break
    case 'Report Approved':
      label = 'info'
      break
    case 'Veto Assigned':
      label = 'primary'
      break
    case 'Approved by Veto':
      label = 'primary'
      break
    case 'Approved by HCB':
      label = 'primary'
      break
    case 'Fulfilled':
      label = 'success'
      break
    default:
      label = 'warning'
      break
  }
  return (
    <span className={`label label-${label}`}>{status.toUpperCase()}</span>
  )
}

export function getPlanLabel (status) {
  let label
  switch (status) {
    case 'Draft':
      label = 'info'
      break
    case 'Published':
      label = 'primary'
      break
    case 'Approved by Lead Auditor':
      label = 'success'
      break
    case 'Approved by HCB':
      label = 'success'
      break
    default:
      label = 'warning'
      break
  }
  return (
    <span className={`label label-${label}`}>{status.toUpperCase()}</span>
  )
}

export function getReportLabel (status) {
  let label
  switch (status) {
    case 'Draft':
      label = 'info'
      break
    case 'Published':
      label = 'primary'
      break
    case 'Approved by Veto':
      label = 'warning'
      break
    case 'Approved by HCB':
      label = 'warning'
      break
    case 'Veto Assigned':
      label = 'warning'
      break
    default:
      label = 'warning'
      break
  }
  return (
    <span className={`label label-${label}`}>{status.toUpperCase()}</span>
  )
}

export function getNcLabel (status) {
  let label
  switch (status) {
    case 'Draft':
      label = 'warning'
      break
    case 'Open':
      label = 'info'
      break
    case 'Pending':
      label = 'primary'
      break
    case 'Closed':
      label = 'success'
      break
    default:
      label = 'warning'
      break
  }
  return (
    <span className={`label label-${label}`}>{status.toUpperCase()}</span>
  )
}

export function getCertificateLabel (status) {
  if (!status) {
    return ''
  }
  let label
  switch (status) {
    case 'Draft':
      label = 'info'
      break
    case 'Published':
      label = 'success'
      break
    case 'Expired':
      label = 'warning'
      break
    case 'Canceled':
      label = 'warning'
      break
    default:
      label = 'warning'
      break
  }
  return (
    <span className={`label label-${label}`}>{status.toUpperCase()}</span>
  )
}

export function getInvoiceLabel (status) {
  let label
  switch (status) {
    case 'Draft':
      label = 'warning'
      break
    case 'Created':
      label = 'warning'
      break
    case 'Paid':
      label = 'success'
      break
    case 'Scheduled':
      label = 'info'
      break
    default:
      label = 'warning'
      break
  }
  return (
    <span className={`label label-${label}`}>{status.toUpperCase()}</span>
  )
}
