import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

import phases from '../../const/phases'

import { OptionsField } from '../common/FormFields'
import ConfirmButton from '../common/ConfirmButton'
import DateField from '../common/DateField'

// const statuses = ['Pending', 'Open', 'Closed']

export class NcClosureForm extends Component {
  render () {
    const { handleSubmit, submitting } = this.props
    return (

      <div>
        <form className='form-horizontal nc-form' onSubmit={handleSubmit}>

          <Field name='detail.implementation_verification_phase' type='select' component={OptionsField} options={phases} label='Implementation will be verified in:' />
          <Field name='detail.verified_by_documents' type='checkbox' component={OptionsField} label='Implementation verified by submitted documents' />
          <Field name='detail.verified_in_reaudit' type='checkbox' component={OptionsField} label='Implementation verified in a re-audit' />
          <Field name='detail.effectiveness' type='select' component={OptionsField} options={['Effective', 'Not effective']} label='Result' />
          <Field name='due_date' component={DateField} label='Due Date' />

          <div className='form-group'>
            <div className='col-sm-offset-3 col-sm-9'>
              <p>
                <button disabled={submitting} type='submit' className='btn btn-default btn-block'>Save</button>
              </p>
              <ConfirmButton disabled={submitting} text={'Save NC'}>
                <button disabled={submitting} type='submit' className='btn btn-danger btn-block' onClick={handleSubmit(values => this.props.onSubmit({...values, advance: true, status: 'Open'}))} >Set as open</button>
                <button disabled={submitting} type='submit' className='btn btn-primary btn-block' onClick={handleSubmit(values => this.props.onSubmit({...values, advance: true, status: 'Closed'}))} >Set as closed</button>
              </ConfirmButton>
            </div>
          </div>

        </form>
      </div>
    )
  }
}

NcClosureForm = reduxForm({ // eslint-disable-line 
  form: 'ncClosureForm'
})(NcClosureForm)

export default NcClosureForm
