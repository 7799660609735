const template = [
  {
    day: 'Monday, 24-02-2020',
    time: '07:00',
    site: 'Meeting Room',
    process: 'Opening Meeting',
    focus: 'Introduction and Briefing',
    dateTime: 'Monday, 24-02-2020',
    auditor: [],
    type: 'auditEvent'
  },
  {
    day: 'Monday, 24-02-2020',
    time: '07:00',
    site: 'Top management and Quality Management Representative (QMR)',
    process: 'Context of the organization \n Leadership (Commitment, Policy, Responsibility and authority) \nPlanning (Risk & Oppurtunity) \nObjectives,Planning of Change) \nSupport (Resources,Competence,Awareness,Communication) \nMonitoring,measurement,analysis and evaluation \nManagement system ; Policy,Responsibility & Authority, Communication  \nInternal audit) \n(Nonconformities, and Corrective action) \nManagement review  \n',
    focus: 'QMS: 4.1-4.4, 5.1-5.3, 6.1-6.3, 7.1-7.36',
    dateTime: 'Monday, 24-02-2020',
    auditor: [],
    type: 'auditEvent'
  },
  {
    day: 'Monday, 24-02-2020',
    time: '07:00',
    site: 'HRD',
    process: 'Resources \nCompetence  \nAwareness Communication \n',
    focus: 'QMS: 7.1-7.4',
    dateTime: 'Monday, 24-02-2020',
    auditor: [],
    type: 'auditEvent'
  },
  {
    day: 'Monday, 24-02-2020',
    time: '07:00',
    site: 'Production',
    process: 'Context of the organization \nRisks and Oppurtunities \nResources\nOperational planning and control \nProduction and services provision\nRelease of products and services\nControl of NC outputs\nMonitoring, measurement, analysis and evaluation\nContinual Improvement',
    focus: 'QMS: 4.1-4.2, 6.1-6.3, 7.1-8.1, 8.5-9.1.1, 9.1.2, 10.3',
    dateTime: 'Monday, 24-02-2020',
    auditor: [],
    type: 'auditEvent'
  },
  {
    day: 'Monday, 24-02-2020',
    time: '07:00',
    site: 'Meeting Room',
    process: 'Audior review \n',
    dateTime: 'Monday, 24-02-2020',
    auditor: [],
    type: 'auditEvent'
  },
  {
    day: 'Monday, 24-02-2020',
    time: '07:00',
    site: 'QC',
    process: 'Context of the organization \nLeadership (Commitment, Policy, Responsibility and authority) \nPlanning (Risk & Oppurtunity) \nObjectives,Planning of Change) \nSupport (Resources,Competence,Awareness,Communication) \nMonitoring,measurement,analysis and evaluation \nManagement system ; Policy,Responsibility & Authority, Communication  \nInternal audit) \n(Nonconformities, and Corrective action) \nManagement review  \n',
    focus: 'QMS: 4.1-4.2, 6.1-6.3, 7.1-5,8.5, 9.1.1, 9.1.3, 10.3',
    dateTime: 'Monday, 24-02-2020',
    auditor: [],
    type: 'auditEvent'
  },
  {
    day: 'Monday, 24-02-2020',
    time: '07:00',
    site: 'Purchasing',
    process: 'Control of externally provided processes,products and services \n',
    focus: 'QMS: 4.1-4.2, 6.1-6.3, 8.4',
    dateTime: 'Monday, 24-02-2020',
    auditor: [],
    type: 'auditEvent'
  },
  {
    day: 'Monday, 24-02-2020',
    time: '07:00',
    site: 'Maintenance & Engineering',
    process: 'Context of organization \nRisks and Oppurtunities \nResources \nProduction and services provision \nRelease of products and services \nControl of NC outputs \nMonitoring,measurement,analysis and evaluation \nContinual Improvement \n',
    focus: 'QMS: 4.1-4.2, 6.1-6.3, 7.1-5,8.5, 9.1.1, 9.1.3, 10.3',
    dateTime: 'Monday, 24-02-2020',
    auditor: [],
    type: 'auditEvent'
  },
  {
    day: 'Monday, 24-02-2020',
    time: '07:00',
    site: 'Meeting Room',
    process: 'Audior review \n',
    dateTime: 'Monday, 24-02-2020',
    auditor: [],
    type: 'auditEvent'
  }
]

export default template
