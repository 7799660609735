import React from 'react'
import { connect } from 'react-redux'
import { Link, Switch, Route } from 'react-router-dom'
import { getFormValues, arrayPush, change } from 'redux-form'
import moment from 'moment'
import { extendMoment } from 'moment-range'
import groupBy from 'lodash/groupBy'
import forOwn from 'lodash/forOwn'
import { Helmet } from 'react-helmet'

import ReviewForm from '../../components/common/ReviewForm'
import Affix from '../../components/common/Affix'
import AuditorScheduleTable from '../../components/Orders/AuditorScheduleTable'
import ScheduleDetail from '../../components/Orders/ScheduleDetail'
import ScheduleGroupForm from '../../components/Orders/ScheduleGroupForm'

import { fetchOrder, updateOrder } from '../../actions/orders'
import { fetchSchedules, fetchGlobalSchedules } from '../../actions/schedules'
import { fetchAuditors } from '../../actions/users'

import { getDateArrayStartEnd, otherAuditorsScheduleSelector } from '../../selectors/schedules'

const xmoment = extendMoment(moment)

export class OrderScheduleView extends React.Component {
  constructor (props) {
    super(props)
    const day1 = moment()
    const start = day1.format('YYYY-MM-DD')
    const end = day1.add(59, 'days').endOf('month').format('YYYY-MM-DD')
    this.state = {
      start,
      end,
      selectedAuditors: []
    }
  }

  componentWillMount () {
    this.props.fetchOrder(this.props.match.params.orderId, this.props.accessToken)
    this.props.fetchSchedules(this.props.match.params.orderId, this.props.accessToken, {
      expand: 'user'
    })

    if (this.props.match.params.id === 'manage') {
      this.props.fetchAuditors(this.props.accessToken)
      this.props.fetchGlobalSchedules(this.props.accessToken, {
        start: this.state.start,
        end: this.state.end,
        expand: 'user'
      })
    }
  }

  handleUpdateFilter (e) {
    const x = moment(e + '-01')
    const start = x.format('YYYY-MM-DD')
    x.add(59, 'days').endOf('month')
    const end = x.format('YYYY-MM-DD')
    if (start !== this.state.start) {
      this.setState({ start, end })
      this.props.fetchSchedules(this.props.match.params.orderId, this.props.accessToken, { start, end })
    }
  }

  handleSubmitSchedule (e) {
    let newStatus = [
      'Schedule Draft',
      'Schedule Published'
    ]

    if (this.props.order.status.indexOf('Reschedule') >= 0) {
      newStatus = [
        'Reschedule - Schedule Draft',
        'Reschedule - Schedule Published'
      ]
    }

    if (this.props.order.status.indexOf('Follow up') >= 0) {
      newStatus = [
        'Follow up - Schedule Draft',
        'Follow up - Schedule Published'
      ]
    }

    const to = e.advance ? `/orders/${this.props.order.id}` : null
    const data = {
      id: this.props.order.id,
      schedules: e.auditors.map(x => {
        return {
          ...x,
          user_id: x.user.id,
          location_id: x.location_id || x.location.id,
          status: e.advance ? 'Published' : 'Draft'
        }
      }),
      status: e.advance ? newStatus[1] : newStatus[0]
    }
    return this.props.updateOrder(data, this.props.accessToken, to)
  }

  handleSubmitReview (e) {
    let orderStatus
    let scheduleStatus
    switch (this.props.order.status) {
      case 'Schedule Published':
        orderStatus = ['Schedule Draft', 'Schedule Approved by HCB']
        scheduleStatus = ['Draft', 'Approved by HCB']
        break
      case 'Schedule Approved by HCB':
        orderStatus = ['Schedule Draft', 'Schedule Approved by Customer']
        scheduleStatus = ['Draft', 'Approved by Customer']
        break
      case 'Reschedule - Schedule Published':
        orderStatus = ['Reschedule - Schedule Draft', 'Reschedule - Schedule Approved by HCB']
        scheduleStatus = ['Draft', 'Approved by HCB']
        break
      case 'Reschedule - Schedule Approved by HCB':
        orderStatus = ['Reschedule - Schedule Draft', 'Reschedule - Schedule Approved by Customer']
        scheduleStatus = ['Draft', 'Approved by Customer']
        break
      case 'Follow up - Schedule Published':
        orderStatus = ['Follow up - Schedule Draft', 'Follow up - Schedule Approved by HCB']
        scheduleStatus = ['Draft', 'Approved by HCB']
        break
      case 'Follow up - Schedule Approved by HCB':
        orderStatus = ['Follow up - Schedule Draft', 'Follow up - Schedule Approved by Customer']
        scheduleStatus = ['Draft', 'Approved by Customer']
        break
      default :
        break
    }
    // const newOrderStatus =
    //   this.props.order.status === 'Schedule Published' ? 'Schedule Approved by HCB'
    //     : this.props.order.status === 'Schedule Approved by HCB' ? 'Schedule Approved by Customer'
    //       : 'Schedule Draft'

    // const newScheduleStatus =
    //   this.props.order.status === 'Published' ? 'Approved by HCB'
    //     : this.props.order.status === 'Approved by HCB' ? 'Approved by Customer'
    //       : 'Draft'
    let request = {
      id: this.props.order.id,
      schedules: this.props.order.schedules.map(x => {
        return {
          ...x,
          user_id: x.user.id,
          location_id: x.location_id || x.location.id,
          status: (e.result === 'Not Approved' ? scheduleStatus[0] : scheduleStatus[1])
        }
      }),
      status: (e.result === 'Not Approved' ? orderStatus[0] : orderStatus[1]),
      log: e.log ? e.log.detail : ''
    }
    return this.props.updateOrder(request, this.props.accessToken, `/orders/${this.props.order.id}`)
  }

  handleUpdateSchedule (schedules) {
    this.props.changeSchedule(schedules)
  }

  getDaysByStage (data) {
    if (data) {
      const x = data.reduce((sum, x, i) => {
        const duration = moment(x.end_date).diff(moment(x.start_date), 'days') + 1
        const type = x.type ? x.type : 'Stage 1'
        if (x.detail && x.detail.includeManday) {
          if (sum[type]) {
            sum[type] += duration
          } else {
            sum[type] = duration
          }
        }
        return sum
      }, {})
      return x
    } else {
      return []
    }
  }

  getOverLap (data) {
    const bingo = groupBy(data, 'type')
    if (Object.keys(bingo).length <= 1) {
      return false
    } else {
      const stageRange = {}

      forOwn(bingo, (x, i) => {
        const keyDate = getDateArrayStartEnd(x)
        const range = xmoment.range(moment(keyDate.start, 'YYYY-MM-DD'), moment(keyDate.end, 'YYYY-MM-DD').add(1, 'd'))
        stageRange[i] = range
      })

      if (stageRange['Stage 1']) {
        // check intersecting
        const intersect = stageRange['Stage 1'].intersect(stageRange['Stage 2'])
        if (intersect) {
          return {
            message: 'Please fix intersecting date between stage',
            data: intersect
          }
        }

        // check adjacent
        const adjacent = stageRange['Stage 1'].adjacent(stageRange['Stage 2'])
        if (adjacent) {
          return {
            message: 'Please fix adjacent date between stage',
            data: adjacent
          }
        }
        return false
      }
      return false
    }
  }

  render () {
    const { order, auditorsSchedule, formValue, match } = this.props
    const auditors = order && order.schedules ? order.schedules : []
    const serviceDetail = order ? order.questionnaire : null
    const auditCycle = serviceDetail ? serviceDetail.detail.audit_cycle : []
    const additionalService = serviceDetail ? serviceDetail.detail.additional_service : []
    const allPhase = auditCycle.concat(additionalService)
    let dayCounter = {}
    let overlap = null

    if (formValue && formValue.auditors) {
      dayCounter = this.getDaysByStage(formValue.auditors)
      overlap = this.getOverLap(formValue.auditors)
    }

    return (
      <div>
        <div className='content'>
          <Switch>
            <Route path='/orders/:orderId/schedules/manage'>
              <div>
                <Helmet>
                  <title>{order ? `Order ${order.id} - Manage schedule` : `Loading`}</title>
                </Helmet>
                <div className='main-header'>
                  <h2>Create Audit Schedules</h2>
                  <em>For Order { order && <Link to={`/orders/${order.id}`}>{order.id}</Link> }</em>
                </div>
                <div className='row'>
                  <div className='col-md-8'>
                    { order && allPhase.length > 0 && auditorsSchedule &&
                      <div>
                        { order.phase === 'Certification Audit'
                          ? <div> {/* TODO : make this a component */}
                            <p>
                              Certification Audit Stage 1 allowed mandays : {allPhase.find((x) => x.phase === 'Certification Audit Stage 1').day} day(s)
                              {' '}
                              { dayCounter !== {} && <span> - currently {dayCounter['Stage 1'] || 0 } days</span> }
                            </p>
                            <p>
                              Certification Audit Stage 2 allowed mandays : {allPhase.find((x) => x.phase === 'Certification Audit Stage 2').day} day(s)
                              {' '}
                              { dayCounter !== {} && <span> - currently {dayCounter['Stage 2'] || 0 } days</span> }
                            </p>
                            { overlap &&
                              <div className='alert alert-danger'>{overlap.message}</div>
                            }
                          </div>
                          : <div>
                            <p>
                              {order.phase} allowed mandays : {allPhase.find((x) => x.phase === order.phase).day}
                              {' '}
                              { dayCounter !== {} && <span> - currently {dayCounter['Stage 1'] || 0 } days</span> }
                            </p>
                          </div>
                        }
                      </div>
                    }
                    { order && auditorsSchedule
                      ? <AuditorScheduleTable
                        start={this.state.start}
                        end={this.state.end}
                        onUpdateSchedule={this.handleUpdateSchedule.bind(this)}
                        onUpdateFilter={this.handleUpdateFilter.bind(this)}
                        auditorsSchedule={auditorsSchedule}
                        serviceDetail={serviceDetail}
                        initialValues={formValue ? formValue.auditors : []}
                        order={order}
                      />
                      : <div>loading</div>
                    }
                  </div>
                  <div className='col-md-4'>
                    <div className='widget'>
                      <div className='widget-header'>
                        <h3>Schedules</h3>
                      </div>
                      <div className='widget-content'>
                        { order
                          ? <ScheduleGroupForm
                            order={order}
                            onSubmit={this.handleSubmitSchedule.bind(this)}
                            initialValues={{
                              auditors
                            }}
                          />
                          : <div>loading order</div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Route>
            <Route path='/orders/:orderId/schedules/review'>
              <div>
                <Helmet>
                  <title>{order ? `Order ${order.id} - Review schedule` : `Loading`}</title>
                </Helmet>
                <div className='main-header'>
                  <h2>Review Audit Schedules</h2>
                  <em>For Order { order && <Link to={`/orders/${order.id}`}>{order.id}</Link> }</em>
                </div>
                <div className='row'>
                  <div className='col-md-8'>
                    { order
                      ? <ScheduleDetail order={order} />
                      : <div>loading order</div>
                    }
                  </div>
                  { order && [
                    'Schedule Published',
                    'Schedule Approved by HCB',
                    'Reschedule - Schedule Published',
                    'Reschedule - Schedule Approved by HCB',
                    'Follow up - Schedule Published',
                    'Follow up - Schedule Approved by HCB'
                  ].indexOf(order.status) >= 0 &&
                    <div className='col-md-4'>
                      <Affix>
                        <div className='widget'>
                          <div className='widget-header'>
                            <h3><i className='fa fa-tasks' /> Review</h3>
                          </div>
                          <div className='widget-content'>
                            <ReviewForm
                              onSubmit={this.handleSubmitReview.bind(this)}
                              fileAPI={{
                                url: process.env.REACT_APP_BASE_API,
                                accessToken: this.props.accessToken
                              }}
                            />
                          </div>
                        </div>
                      </Affix>
                    </div>
                  }
                </div>
              </div>
            </Route>
            <Route path='/orders/:orderId/schedules/:id'>
              <div>
                <div className='main-header'>
                  <h2>Review Audit Schedule</h2>
                  <em>For Order { order && <Link to={`/orders/${order.id}`}>{order.id}</Link> }</em>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    { order
                      ? <ScheduleDetail order={order} userId={match.params.id} />
                      : <div>loading order</div>
                    }
                  </div>
                </div>
              </div>
            </Route>
          </Switch>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    formValue: getFormValues('ScheduleGroupForm')(state),
    order: state.orders.order.data,
    schedules: state.schedules.scheduleList.data,
    auditors: state.users.auditorList.data,
    auditorsSchedule: otherAuditorsScheduleSelector(state),
    accessToken: state.auth.user.access.access_token
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchOrder: (id, key) => dispatch(fetchOrder(id, key)),
    updateOrder: (data, key, target) => dispatch(updateOrder(data, key, target)),
    fetchSchedules: (id, key, params) => dispatch(fetchSchedules(id, key, params)),
    fetchGlobalSchedules: (key, params) => dispatch(fetchGlobalSchedules(key, params)),
    fetchAuditors: (key) => dispatch(fetchAuditors(key)),
    addAuditors: (data) => dispatch(arrayPush('ScheduleGroupForm', 'auditors', data)),
    changeSchedule: (data) => dispatch(change('ScheduleGroupForm', 'auditors', data))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
// Implement map dispatch to props
)(OrderScheduleView)
