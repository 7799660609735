import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import Inside from './Inside'
import InvoiceList from './Invoices/InvoiceList'
// import InvoiceUpdate from './Invoices/InvoiceUpdate'

function mapStateToProps (state) {
  return {

  }
}

export class Invoices extends React.Component {
  render () {
    return (
      <Inside>
        <Switch>
          {
          // <Route path='/certificates/:id' component={InvoiceUpdate} />
          }
          <Route exact path='/invoices' component={InvoiceList} />
        </Switch>
      </Inside>
    )
  }
}

export default connect(
  mapStateToProps
// Implement map dispatch to props
)(Invoices)
