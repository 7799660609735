import React from 'react'
import { connect } from 'react-redux'
// import { Link } from 'react-router-dom'

import {
  fetchCustomerContact,
  fetchCustomerLocations,
  updateCustomerContact,
  deleteCustomerContact
} from '../../actions/customers'

import { CustomerContactUpdateForm as CustomerContactForm } from '../../components/Customers/CustomerContactForm'
import DeleteForm from '../../components/common/DeleteForm'

export class CustomerContactUpdate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sending: false
    }
  }

  componentDidMount () {
    this.props.fetchCustomerContact(this.props.match.params.cust_id, this.props.match.params.id, this.props.accessToken)
    this.props.fetchCustomerLocations(this.props.match.params.cust_id, this.props.accessToken)
  }

  handleDelete (e) {
    return this.props.deleteCustomerContact(
      this.props.match.params.cust_id,
      this.props.contact.id,
      this.props.accessToken
    )
  }

  handleSubmit (e) {
    if (!e.default) {
      e.default = 0
    }
    return this.props.updateCustomerContact(this.props.match.params.cust_id, {
      ...e,
      customer_id: this.props.match.params.cust_id
    }, this.props.accessToken)
  }

  handleInvite () {
    this.setState({ sending: true })
    return this.props.updateCustomerContact(this.props.match.params.cust_id, {
      id: this.props.contact.id,
      customer_id: this.props.match.params.cust_id,
      create_user: 1
    }, this.props.accessToken)
      .then((x) => {
        this.setState({ sending: false })
      })
  }

  render () {
    const { contact, locations } = this.props

    return (
      <div className='content'>
        <div className='main-header'>
          <h2>Update Customer Contact</h2>
          {/* <em>normal sidebar with minified mode by default</em> */}
        </div>
        <div className='main-content'>
          <div className='row'>
            <div className='col-md-8'>
              { contact && this.props.locations
                ? <div>
                  <CustomerContactForm
                    onSubmit={this.handleSubmit.bind(this)}
                    initialValues={{
                      ...contact,
                      customer_location_id: contact.location && contact.location.id
                    }}
                    locations={locations}
                  />
                  <DeleteForm onClick={this.handleDelete.bind(this)} />
                </div>
                : <div> loading</div>
              }
            </div>
            <div className='col-md-4'>
              { contact &&
                <div className='widget'>
                  {
                  // <div className='widget-header'>
                  //   <h3><i className='fa fa-edit' /> Widget</h3>
                  // </div>
                  }
                  <div className='widget-content'>
                    { !contact.user &&
                      <button onClick={this.handleInvite.bind(this)} className='btn btn-primary btn-block' disabled={this.state.sending}>Create User</button>
                    }
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    contact: state.customers.customerContact.data,
    locations: state.customers.customerLocationList.data,
    accessToken: state.auth.user.access.access_token
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchCustomerContact: (custId, id, key) => dispatch(fetchCustomerContact(custId, id, key)),
    fetchCustomerLocations: (id, key) => dispatch(fetchCustomerLocations(id, key)),
    updateCustomerContact: (custId, data, key) => dispatch(updateCustomerContact(custId, data, key)),
    deleteCustomerContact: (custId, id, key) => dispatch(deleteCustomerContact(custId, id, key))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerContactUpdate)
