import { createSelector } from 'reselect'
import moment from 'moment'
import { forEach } from 'lodash'
const orderSelector = state => state.orders.order ? state.orders.order.data : null

const auditDaysSelector = createSelector(
  orderSelector,
  (order) => {
    if (order) {
      // console.log(order.schedules);
      const dates = []

      forEach(order.schedules, (x) => {
        const start = moment(x.start_date)
        const end = moment(x.end_date)

        if (dates.find((x) => x.key === start.format('YYYY-MM-DD')) === undefined) {
          dates.push({
            label: start.format('dddd, DD-MM-YYYY'),
            key: start.format('YYYY-MM-DD')
          })
        }

        let dayCount = start.diff(end, 'days')
        while (dayCount < 0) {
          dayCount++
          start.add(1, 'days')
          const key = start.format('YYYY-MM-DD')

          if (dates.find((x) => x.key === key) === undefined) {
            dates.push({
              label: start.format('dddd, DD-MM-YYYY'),
              key
            })
          }
        }
      })

      return dates
    }
  }
)

export { auditDaysSelector }
