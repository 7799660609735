import React from 'react'
// import NumberFormat from 'react-number-format'

export default class InvoiceDetail extends React.Component {
  render () {
    const i = this.props.invoice
    const { accessToken } = this.props

    return (
      <div>
        <h1>Invoice Detail</h1>
        <hr />
        <div className='row'>
          <div className='col-md-3 key-val'>
            <p>Invoice status</p>
            <p className='h3'>{i.status}</p>
          </div>
          <div className='col-md-3 key-val'>
            <p>Invoice number</p>
            <p className='h3'>{i.invoice_no}</p>
          </div>
          <div className='col-md-3 key-val'>
            <p>Sub Total</p>
            <p className='h3'>Rp {i.sub_total.toLocaleString()}</p>
          </div>
          <div className='col-md-3 key-val'>
            <p>Total</p>
            <p className='h3'>Rp {i.total.toLocaleString()}</p>
            <p>({i.detail.terms})</p>
          </div>
          {
            // <div className='col-md-4 key-val'>
            //   <p>Payment Schedule</p>
            //   <p className='h3'>{i.detail.payment_schedule.split('-').join(' ')}</p>
            // </div>
            // <div className='col-md-4'>
            //   <p><a href={`${process.env.REACT_APP_BASE_API}/pdf/questionnaire?template=invoice&id=${i.id}&token=${accessToken}`} className='btn btn-link btn-xs'><i className='fa fa-download' /> Download Full Invoice</a></p>
            // </div>
          }
        </div>
        <hr />
        <div className='row'>
          <div className='col-md-6 key-val'>
            <p>Remarks</p>
            <p>{i.remarks}</p>
          </div>
          <div className='col-md-3 key-val'>
            <ul className='list-unstyled'>
              <li><a href={`${process.env.REACT_APP_BASE_API}/pdf/questionnaire?template=invoice&id=${i.id}&token=${accessToken}`} className='btn btn-link btn-xs'><i className='fa fa-download' /> Download Invoice</a></li>
            </ul>
          </div>
        </div>
        <hr />
        <div className='row'>
          <div className='col-md-6 key-val'>
            <p>Faktur Pajak</p>
            { i.detail.faktur_pajak
              ? <p>
                { i.detail.faktur_pajak.map((x, i) => (
                  <span key={i}>
                    <strong>{x.detail.name} - <a href={`${process.env.REACT_APP_BASE_API}/download/${x.id}?token=${accessToken}`} className='btn btn-link btn-xs'><i className='fa fa-download' /> Download</a></strong><br />
                  </span>
                ))}
              </p>
              : <p>n/a</p>
            }
          </div>
          <div className='col-md-6 key-val'>
            <p>Bukti Pembayaran</p>
            { i.detail.payment_detail
              ? <p>
                { i.detail.payment_detail.map((x, i) => (
                  <span key={i}>
                    <strong>{x.detail.name} - <a href={`${process.env.REACT_APP_BASE_API}/download/${x.id}?token=${accessToken}`} className='btn btn-link btn-xs'><i className='fa fa-download' /> Download</a></strong><br />
                  </span>
                ))}
              </p>
              : <p>n/a</p>
            }
          </div>
        </div>
        {
        // <div>
        //   <p>Audit Cycle</p>
        //   <table className='table'>
        //     <thead>
        //       <tr>
        //         <td>Phase</td>
        //         <td>Days</td>
        //         <td>Rate</td>
        //         <td>Sub total</td>
        //         <td></td>
        //       </tr>
        //     </thead>
        //     <tbody>
        //       {i.detail.audit_cycle.map((x, y) => (
        //         <tr key={y}>
        //           <td>{x.phase}</td>
        //           <td>{x.day} days</td>
        //           <td>
        //             <span>Rp </span>
        //             <NumberFormat
        //               displayType='text'
        //               value={x.rate}
        //               thousandSeparator
        //             />
        //           </td>
        //           <td>
        //             <span>Rp </span>
        //             <NumberFormat
        //               displayType='text'
        //               value={x.rate * x.day}
        //               thousandSeparator
        //             />
        //           </td>
        //           <td>
        //             <a href={`${process.env.REACT_APP_BASE_API}/pdf/questionnaire?template=invoice&id=${i.id}&token=${accessToken}`} className='btn btn-link btn-xs'><i className='fa fa-download' /> Download Sub Invoice</a>
        //           </td>

        //         </tr>
        //       ))}
        //     </tbody>
        //   </table>

        //   <p><strong>Total : Rp. {i.detail.audit_cycle.reduce((sum, x, i) => { return (sum + (x.rate * x.day)) }, 0).toLocaleString()}</strong></p>
        // </div>
        // { i.detail.additional_service &&
        //   <div>
        //     <hr />
        //     <div>
        //       <p>Additional Services</p>
        //       <table className='table'>
        //         <thead>
        //           <tr>
        //             <td>Service</td>
        //             <td>Days</td>
        //             <td>Rate</td>
        //             <td>Sub total</td>
        //           </tr>
        //         </thead>
        //         <tbody>
        //           {i.detail.additional_service.map((x, y) => (
        //             <tr key={y}>
        //               <td>{x.phase}</td>
        //               <td>{x.day} days</td>
        //               <td>
        //                 <span>Rp </span>
        //                 <NumberFormat
        //                   displayType='text'
        //                   value={x.rate}
        //                   thousandSeparator
        //                 />
        //               </td>
        //               <td>
        //                 <span>Rp </span>
        //                 <NumberFormat
        //                   displayType='text'
        //                   value={x.rate * x.day}
        //                   thousandSeparator
        //                 />
        //               </td>

        //             </tr>
        //           ))}
        //         </tbody>
        //       </table>
        //       <p><strong>Total : Rp. {i.detail.additional_service.reduce((sum, x, i) => { return (sum + (x.rate * x.day)) }, 0).toLocaleString()}</strong></p>
        //     </div>
        //   </div>
        // }
        // <hr />
        // <div>
        //   <p>Fees</p>
        //   <table className='table'>
        //     <thead>
        //       <tr>
        //         <td>Name</td>
        //         <td>Quantity</td>
        //         <td>Rate</td>
        //         <td>Note</td>
        //         <td>Sub total</td>
        //       </tr>
        //     </thead>
        //     <tbody>
        //       {i.detail.fees.filter((x) => x.include).map((x, y) => (
        //         <tr key={y}>
        //           <td>{x.name}</td>
        //           <td>{x.quantity}</td>
        //           <td>
        //             <span>Rp </span>
        //             <NumberFormat
        //               displayType='text'
        //               value={x.rate}
        //               thousandSeparator
        //             />
        //           </td>
        //           <td>{x.note}</td>
        //           <td>
        //             <span>Rp </span>
        //             <NumberFormat
        //               displayType='text'
        //               value={x.rate * x.quantity}
        //               thousandSeparator
        //             />
        //           </td>
        //         </tr>
        //       ))}
        //     </tbody>
        //   </table>
        //   <p><strong>Total : Rp. {i.detail.fees.reduce((sum, x, i) => { return (sum + (x.rate * x.quantity)) }, 0).toLocaleString()}</strong></p>
        // </div>
        }
      </div>
    )
  }
}
