import React, { Fragment } from 'react'
import ReactQuill from 'react-quill'

import 'react-quill/dist/quill.snow.css'

const quillFormat = ['bold', 'italic', 'underline', 'strike', 'blockquote']

const quillModules = {
  toolbar: [
    // [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote']
    // [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
    // ['link', 'image'],
    // ['clean']
  ]
}

export default class AnnexList extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      annex: (this.props.standard.annex || []),
      openNew: false,
      onEdit: null,
      requesting: false,
      newFile: false,
      newExplanation: false,
      newPerSite: false
    }
  }

  toggleNew () {
    this.setState({
      openNew: !this.state.openNew,
      annex: this.state.annex.map(x => {
        delete x.editing
        return x
      }),
      onEdit: null
    })
  }

  addNew () {
    this.setState({
      requesting: true
    })
    const annex = this.state.annex.map(x => {
      delete x.editing
      return x
    })

    annex.push({
      name: this.state.newName,
      description: this.state.newDescription,
      nameAlt: this.state.newNameAlt,
      descriptionAlt: this.state.newDescriptionAlt,
      type: this.refs.newType.value,
      options: this.refs.newOptions.value,
      file: this.refs.newFile.checked,
      explanation: this.refs.newExplanation.checked,
      per_site: this.refs.newPerSite.checked
    })

    // submit data
    this.props.onSubmit({
      id: this.props.standard.id,
      annex
    }).then(() => {
      this.setState({
        annex,
        requesting: false,
        onEdit: null,
        openNew: false,
        newFile: false,
        newExplanation: false,
        newPerSite: false
      })
    })
  }

  toggleEdit (n) {
    this.setState({
      openNew: false,
      onEdit: n,
      annex: this.state.annex.map(x => {
        if (x.name === n) {
          x.editing = true
        } else {
          delete x.editing
        }
        return x
      })
    })
  }

  cancel () {
    this.setState({
      openNew: false,
      onEdit: null,
      annex: this.state.annex.map(x => {
        delete x.editing
        return x
      })
    })
  }

  handleQuill (key, value) {
    this.setState({
      [key]: value
    })
  }

  saveItem () {
    this.setState({
      requesting: true
    })

    // save update
    const annex = this.state.annex.map(x => {
      if (x.editing) {
        x = { ...x,
          name: this.state.updateName,
          description: this.state.updateDescription,
          nameAlt: this.state.updateNameAlt,
          descriptionAlt: this.state.updateDescriptionAlt,
          type: this.refs.updateType.value,
          options: this.refs.updateOptions.value,
          file: this.refs.updateFile.checked,
          explanation: this.refs.updateExplanation.checked
        }
        delete x.editing
      }
      return x
    })

    // submit data
    this.props.onSubmit({
      id: this.props.standard.id,
      annex
    }).then(() => {
      this.setState({
        annex,
        requesting: false,
        onEdit: null,
        openNew: false
      })
    })
  }

  deleteItem () {
    this.setState({
      requesting: true
    })

    const annex = this.state.annex.filter(x => {
      return !x.editing
    })

    // submit data
    this.props.onSubmit({
      id: this.props.standard.id,
      annex
    }).then(() => {
      this.setState({
        annex,
        requesting: false,
        onEdit: null,
        openNew: false
      })
    })
  }

  toggleFile (i) {
    const annex = this.state.annex
    if (i !== 'new') {
      annex[i].file = !annex[i].file
      this.setState({ annex })
    } else {
      this.setState({ 'newFile': !this.state.newFile })
    }
  }

  toggleExplanation (i) {
    const annex = this.state.annex
    if (i !== 'new') {
      annex[i].explanation = !annex[i].explanation
      this.setState({ annex })
    } else {
      this.setState({ 'newExplanation': !this.state.newExplanation })
    }
  }

  togglePerSite (i) {
    const annex = this.state.annex
    if (i !== 'new') {
      annex[i].per_site = !annex[i].per_site
      this.setState({ annex })
    } else {
      this.setState({ 'newPerSite': !this.state.newPerSite })
    }
  }

  render () {
    return (

      <div>
        {this.state.annex.map((x, i) => {
          if (x.editing) {
            return (
              <div className='well' key={i}>
                <div key={x.name} className='row'>
                  <div className='col-sm-4'>
                    <div className='form-group'>
                      <select className='form-control input-sm' ref='updateType' defaultValue={x.type} >
                        <option value='Textarea'>Textarea</option>
                        <option value='Boolean'>Boolean</option>
                        <option value='Radio'>Radio</option>
                        <option value='Checkbox'>Checkbox</option>
                      </select>
                    </div>
                  </div>
                  <div className='col-sm-8 text-right'>
                    <div className='form-group'>
                      <button disabled={this.state.requesting} onClick={this.cancel.bind(this)}className='btn btn-xs btn-default'>Cancel <i className='fa fa-back' /></button>{' '}
                      <button disabled={this.state.requesting} onClick={this.deleteItem.bind(this)}className='btn btn-xs btn-danger'>Delete <i className='fa fa-remove' /></button>{' '}
                      <button disabled={this.state.requesting} onClick={this.saveItem.bind(this)} className='btn btn-xs btn-primary'>Save <i className='fa fa-check' /></button>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-4'>
                    <div className='form-group'>
                      <label>Name</label>
                      <ReactQuill onChange={this.handleQuill.bind(this, 'updateName')} modules={quillModules} formats={quillFormat} rows={3} name='updateName' defaultValue={x.name} />
                    </div>
                    <div className='form-group'>
                      <label>Name (secondary)</label>
                      <ReactQuill onChange={this.handleQuill.bind(this, 'updateNameAlt')} modules={quillModules} formats={quillFormat} rows={3} name='updateNameAlt' defaultValue={x.nameAlt} />
                    </div>
                  </div>
                  <div className='col-sm-4'>
                    <div className='form-group'>
                      <label>Description</label>
                      <ReactQuill onChange={this.handleQuill.bind(this, 'updateDescription')} modules={quillModules} formats={quillFormat} rows={3} name='updateDescription' defaultValue={x.description} />
                    </div>
                    <div className='form-group'>
                      <label>Description (secondary)</label>
                      <ReactQuill onChange={this.handleQuill.bind(this, 'updateDescriptionAlt')} modules={quillModules} formats={quillFormat} rows={3} name='updateDescriptionAlt' defaultValue={x.descriptionAlt} />
                    </div>
                  </div>
                  <div className='col-sm-4'>
                    <div className='form-group'>
                      <label>Options</label>
                      <textarea className='form-control input-sm' rows={3} ref='updateOptions' defaultValue={x.options} />
                    </div>
                    <div className='form-group'>
                      <label htmlFor='update-file'>
                        <span>Require file </span>
                        <input name='update-file' type='checkbox' value checked={x.file} ref='updateFile' onChange={this.toggleFile.bind(this, i)} />
                      </label><br />
                      <label htmlFor='update-explanation'>
                        <span>Require explanation </span>
                        <input name='update-explanation' type='checkbox' value checked={x.explanation} ref='updateExplanation' onChange={this.toggleExplanation.bind(this, i)} />
                      </label><br />
                      <label htmlFor='update-per_site'>
                        <span>Require per site information</span>
                        <input name='update-per_site' type='checkbox' value checked={x.per_site} ref='updatePerSite' onChange={this.togglePerSite.bind(this, i)} />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            )
          } else {
            return (

              <Fragment key={x.name}>
                <div className='row'>
                  <div className='col-sm-4'>
                    <p><strong>Information name</strong></p>
                    <div dangerouslySetInnerHTML={{ __html: x.name }} />
                    <div dangerouslySetInnerHTML={{ __html: x.nameAlt }} />
                  </div>
                  <div className='col-sm-4'>
                    <p><strong>Description</strong></p>
                    <div dangerouslySetInnerHTML={{ __html: x.description }} />
                    <div dangerouslySetInnerHTML={{ __html: x.descriptionAlt }} />
                  </div>
                  <div className='col-sm-3'>
                    <p>
                      <strong>Type : {x.type} </strong>
                      { x.file &&
                        <strong>- require file </strong>
                      }
                      { x.explanation &&
                        <strong>- require explanation </strong>
                      }
                      { x.explanation &&
                        <strong>- require per site answer </strong>
                      }
                    </p>
                    { x.options &&
                      <p><strong>Options: </strong> {x.options}</p>
                    }
                  </div>
                  <div className='col-sm-1 text-right'>
                    <button disabled={this.state.requesting} onClick={this.toggleEdit.bind(this, x.name)} className='btn btn-xs btn-default'><i className='fa fa-gear' /></button>
                  </div>
                </div>
                <hr />
              </Fragment>
            )
          }
        })}
        {this.state.openNew
          ? <div className=' bg-grey' key='newForm'>
            <div className='well'>
              <div className='row'>
                <div className='col-sm-4'>
                  <div className='form-group'>
                    <label>Type</label>
                    <select className='form-control input-sm' ref='newType' >
                      <option value='Textarea'>Textarea</option>
                      <option value='Boolean'>Boolean</option>
                      <option value='Radio'>Radio</option>
                      <option value='Checkbox'>Checkbox</option>
                    </select>
                  </div>
                </div>
                <div className='col-sm-8 text-right'>
                  <div className='form-group'>
                    <button disabled={this.state.requesting} onClick={this.addNew.bind(this)} className='btn btn-xs btn-primary'>Save <i className='fa fa-check' /></button>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-4'>
                  <div className='form-group'>
                    <label>Name</label>
                    <ReactQuill onChange={this.handleQuill.bind(this, 'newName')} modules={quillModules} formats={quillFormat} rows={3} name='newName' />
                  </div>
                  <div className='form-group'>
                    <label>Name (secondary)</label>
                    <ReactQuill onChange={this.handleQuill.bind(this, 'newNameAlt')} modules={quillModules} formats={quillFormat} rows={3} name='newNameAlt' />
                  </div>
                </div>
                <div className='col-sm-4'>
                  <div className='form-group'>
                    <label>Description</label>
                    <ReactQuill onChange={this.handleQuill.bind(this, 'newDescription')} modules={quillModules} formats={quillFormat} rows={3} name='newDescription' />
                  </div>
                  <div className='form-group'>
                    <label>Description (secondary)</label>
                    <ReactQuill onChange={this.handleQuill.bind(this, 'newDescriptionAlt')} modules={quillModules} formats={quillFormat} rows={3} name='newDescriptionAlt' />
                  </div>
                </div>
                <div className='col-sm-4'>
                  <div className='form-group'>
                    <label>Options</label>
                    <textarea className='form-control input-sm' rows={3} ref='newOptions' />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='new-file'>
                      <span>Require file </span>
                      <input name='new-file' type='checkbox' value ref='newFile' onChange={this.toggleFile.bind(this, 'new')} />
                    </label><br />
                    <label htmlFor='new-explanation'>
                      <span>Require explanation </span>
                      <input name='new-explanation' type='checkbox' value ref='newExplanation' onChange={this.toggleExplanation.bind(this, 'new')} />
                    </label><br />
                    <label htmlFor='new-per_site'>
                      <span>Require per site information</span>
                      <input name='new-per_site' type='checkbox' value ref='newPerSite' onChange={this.togglePerSite.bind(this, 'new')} />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          : !this.state.openNew && this.state.onEdit === null
            ? <div className=' bg-grey' key='newToggle'>
              <button disabled={this.state.requesting} onClick={this.toggleNew.bind(this)} className='btn btn-block btn-primary'>Add Annex Question</button>
            </div>
            : <div className=' bg-grey' />
        }
      </div>
    )
  }
}
