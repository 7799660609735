import axios from 'axios'
import { createError } from './flasher'

const baseAPI = process.env.REACT_APP_BASE_API

export const fetchGlobalCounts = (accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'GLOBAL_COUNTS_PENDING'
    })
    return axios({
      method: 'get',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/dashboard/global-counts`
    }).then((x) => {
      dispatch({
        type: 'GLOBAL_COUNTS_FULFILLED',
        payload: x.data
      })
    }).catch((e) => {
      console.error(e)
      dispatch(createError(e.message))
      dispatch({
        type: 'GLOBAL_COUNTS_REJECTED',
        payload: e
      })
    })
  }
}

export const fetchAuditCounts = (accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'AUDIT_COUNTS_PENDING'
    })
    return axios({
      method: 'get',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/dashboard/audit-counts`
    }).then((x) => {
      dispatch({
        type: 'AUDIT_COUNTS_FULFILLED',
        payload: x.data
      })
    }).catch((e) => {
      dispatch(createError(e.message))
      dispatch({
        type: 'AUDIT_COUNTS_REJECTED',
        payload: e
      })
    })
  }
}

export const fetchTopCustomer = (accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'TOP_CUSTOMER_PENDING'
    })
    return axios({
      method: 'get',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/dashboard/top?entity=customer`
    }).then((x) => {
      dispatch({
        type: 'TOP_CUSTOMER_FULFILLED',
        payload: x.data
      })
    }).catch((e) => {
      dispatch(createError(e.message))
      dispatch({
        type: 'TOP_CUSTOMER_REJECTED',
        payload: e
      })
    })
  }
}

export const fetchTopSales = (accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'TOP_SALES_PENDING'
    })
    return axios({
      method: 'get',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/dashboard/top?entity=sales`
    }).then((x) => {
      dispatch({
        type: 'TOP_SALES_FULFILLED',
        payload: x.data
      })
    }).catch((e) => {
      dispatch(createError(e.message))
      dispatch({
        type: 'TOP_SALES_REJECTED',
        payload: e
      })
    })
  }
}

export const fetchTopStandard = (accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'TOP_STANDARD_PENDING'
    })
    return axios({
      method: 'get',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/dashboard/top?entity=standard`
    }).then((x) => {
      dispatch({
        type: 'TOP_STANDARD_FULFILLED',
        payload: x.data
      })
    }).catch((e) => {
      console.error(e)
      dispatch(createError(e.message))
      dispatch({
        type: 'TOP_STANDARD_REJECTED',
        payload: e
      })
    })
  }
}

export const fetchTopScope = (accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'TOP_SCOPE_PENDING'
    })
    return axios({
      method: 'get',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/dashboard/top?entity=scope`
    }).then((x) => {
      dispatch({
        type: 'TOP_SCOPE_FULFILLED',
        payload: x.data
      })
    }).catch((e) => {
      dispatch(createError(e.message))
      dispatch({
        type: 'TOP_SCOPE_REJECTED',
        payload: e
      })
    })
  }
}

export const fetchMandaysExecution = (accessToken) => {
  return (dispatch) => {
    return axios({
      method: 'get',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/dashboard/mandays`
    }).then((x) => {
      dispatch({
        type: 'MANDAYS_EXECUTION_RECAP_FULFILLED',
        payload: x.data
      })
    }).catch((e) => {
      dispatch(createError(e.message))
    })
  }
}

export const fetchInvoices = (accessToken) => {
  return (dispatch) => {
    return axios({
      method: 'get',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/dashboard/invoices`
    }).then((x) => {
      dispatch({
        type: 'INVOICES_RECAP_FULFILLED',
        payload: x.data
      })
    }).catch((e) => {
      dispatch(createError(e.message))
    })
  }
}
