import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'

import {
  OptionsField,
  TextareaField,
  MultiCheckboxField
} from '../common/FormFields'
import TimeField from '../common/TimeField'

const selector = formValueSelector('AuditEventForm')

const validate = (values) => {
  const errors = {}
  if (!values.username) {
    errors.username = 'Required'
  }
  if (!values.password) {
    errors.password = 'Required'
  }
  return errors
}

const warn = (values) => {
  const warnings = {}
  if (values.username === 'test') {
    warnings.username = 'No test please'
  }
  return warnings
}

// const auditors = ['One', 'Two', 'Three']

export class AuditEventForm extends Component {
  render () {
    const { handleSubmit, submitting, dayOptions, schedules, template } = this.props
    const auditors = schedules.reduce((sum, x, i) => {
      if (!sum.find(y => y.value === x.user.id)) {
        sum.push({
          label: x.user.data.display_name,
          value: x.user.id
        })
        return sum
      } else {
        return sum
      }
    }, [])
    return (

      <div>
        <form className='form-horizontal' onSubmit={handleSubmit}>

          <Field name='day' type='select' component={OptionsField} label='Day' options={dayOptions} />

          <Field name='time' component={TimeField} label='Time' />

          <div className='form-group'>
            <label htmlFor='select-template' className='col-sm-3 control-label'>Select Template</label>
            <div className='col-sm-9'>
              <select className='form-control' onChange={this.props.useTemplate}>
                <option>Select template</option>
                { template.map((x, i) => <option key={i} value={x.key}>{x.site}</option>)}
              </select>
            </div>
          </div>

          <Field name='site' type='text' component={TextareaField} label='Site / Shift / Business Unit' />

          <Field name='process' type='text' component={TextareaField} label='Process' />

          <Field name='auditor' component={MultiCheckboxField} label='Auditor' options={auditors} />

          <Field name='contact' type='text' component={TextareaField} label='Contact' />

          <Field name='focus' type='text' component={TextareaField} label='Focus / Standard Requirement / Chapter / Clause' />

          <div className='form-group'>
            <div className='col-sm-offset-3 col-sm-9'>
              <button disabled={submitting} type='submit' className='btn btn-default btn-block'>Save</button>
              { this.props.deletable &&
                <button onClick={this.props.onDelete} disabled={submitting} type='button' className='btn btn-link btn-block'>Delete</button>
              }
            </div>
          </div>

        </form>
      </div>
    )
  }
}

AuditEventForm = reduxForm({ // eslint-disable-line 
  form: 'AuditEventForm',
  enableReinitialize: true,
  validate,
  warn
})(AuditEventForm)

AuditEventForm = connect(state => { // eslint-disable-line
  const currentStandards = selector(state, 'standards')
  return {
    currentStandards
  }
})(AuditEventForm)

export default AuditEventForm
