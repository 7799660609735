import React from 'react'

export default function AdditionalInformationWidget ({ questionnaire: q, props }) {
  return (
    <div className='widget'>
      <div className='widget-header'>
        <h3><i className='fa fa-newspaper-o' /> Additional Information</h3>
      </div>
      <div className='widget-content'>
        <table className='table table-hover table-striped'>
          <tbody>
            <tr>
              <th> Document availability </th>
              <td>
                {q.detail.requested_information && q.detail.requested_information.length > 0
                  ? q.detail.requested_information.map((x, i) => (
                    <p key={i}>{x}</p>
                  ))
                  : <span>n/a</span>
                }
              </td>
            </tr>

            <tr>
              <th> Ongoing project </th>
              <td> 
                {q.detail.ongoing_project
                  ? q.detail.ongoing_project.map((x, i) => (
                    <p key={i}>{`Project name: ${x[0]} - Location: ${x[1]}`}</p>
                  ))
                  : <span>n/a</span>
                }
              </td>
            </tr>

            <tr>
              <th> Project done within last 1 year </th>
              <td>
                {q.detail.ongoing_project
                  ? q.detail.ongoing_project.map((x, i) => (
                    <p key={i}>{`Project name: ${x[0]} - Location: ${x[1]}`}</p>
                  ))
                  : <span>n/a</span>
                }
              </td>
            </tr>

          </tbody>
        </table>

      </div>
    </div>
  )
}
