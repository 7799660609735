import React from 'react'
import { connect } from 'react-redux'

import { createCustomerLocation } from '../../actions/customers'

import { CustomerLocationCreateForm as CustomerLocationForm } from '../../components/Customers/CustomerLocationForm'

function mapStateToProps (state) {
  return {
    accessToken: state.auth.user.access.access_token

  }
}

function mapDispatchToProps (dispatch) {
  return {
    createCustomerLocation: (id, data, key) => dispatch(createCustomerLocation(id, data, key))
  }
}

export class CustomerNew extends React.Component {
  handleSubmit (e) {
    return this.props.createCustomerLocation(
      {...e, customer_id: this.props.match.params.cust_id},
      this.props.accessToken
    )
  }

  render () {
    return (
      <div className='content'>
        <div className='main-header'>
          <h2>Create New Address</h2>
          {/* <em>normal sidebar with minified mode by default</em> */}
        </div>
        <div className='main-content'>
          <div className='row'>
            <div className='col-md-8'>
              <CustomerLocationForm
                onSubmit={this.handleSubmit.bind(this)}
                initialValues={{
                  country: 'Indonesia',
                  state: '',
                  city: ''
                }}
              />
            </div>
            <div className='col-md-4' />
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerNew)
