import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'

import {
  OptionsField,
  TextareaField
} from '../common/FormFields'
import TimeField from '../common/TimeField'

const selector = formValueSelector('EventForm')

const validate = (values) => {
  const errors = {}
  if (!values.username) {
    errors.username = 'Required'
  }
  if (!values.password) {
    errors.password = 'Required'
  }
  return errors
}

const warn = (values) => {
  const warnings = {}
  if (values.username === 'test') {
    warnings.username = 'No test please'
  }
  return warnings
}

export class EventForm extends Component {
  render () {
    const { handleSubmit, submitting, dayOptions } = this.props
    return (

      <div>
        <form className='form-horizontal' onSubmit={handleSubmit}>

          <Field name='day' type='select' component={OptionsField} label='Day' options={dayOptions} />

          <Field name='time' component={TimeField} label='Time' />

          <Field name='description' type='text' component={TextareaField} label='Description' />

          <div className='form-group'>
            <div className='col-sm-offset-3 col-sm-9'>
              <button disabled={submitting} type='submit' className='btn btn-default btn-block'>Save</button>
              { this.props.deletable &&
                <button onClick={this.props.onDelete} disabled={submitting} type='button' className='btn btn-link btn-block'>Delete</button>
              }
            </div>
          </div>

        </form>
      </div>
    )
  }
}

EventForm = reduxForm({ // eslint-disable-line 
  form: 'EventForm',
  validate,
  warn
})(EventForm)

EventForm = connect(state => { // eslint-disable-line
  const currentStandards = selector(state, 'standards')
  return {
    currentStandards
  }
})(EventForm)

export default EventForm
