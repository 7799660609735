import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import { reducer as formReducer } from 'redux-form'

// import counter from './counter'
import flasher from './flasher'
import layout from './layout'
import users from './users'
import events from './events'
import options from './options'
import standards from './standards'
import customers from './customers'
import questionnaires from './questionnaires'
import quotations from './quotations'
import orders from './orders'
import invoices from './invoices'
import plans from './plans'
import reports from './reports'
import ncs from './ncs'
import certificates from './certificates'
import changes from './changes'
import schedules from './schedules'
import das from './das'
// import quotes from './quotes'
import auth from './auth'
import dashboard from './dashboard'

const appReducer = combineReducers({
  router: routerReducer,
  layout,
  users,
  standards,
  events,
  options,
  customers,
  questionnaires,
  quotations,
  orders,
  invoices,
  plans,
  reports,
  ncs,
  certificates,
  changes,
  schedules,
  flasher,
  auth,
  das,
  dashboard,
  form: formReducer
})

const rootReducer = (state, action) => {
  const now = Date.now() / 1000
  if (state && state.auth && state.auth.user && state.auth.user.access && now > state.auth.user.access.access_token_expire) {
    state = undefined
  }
  if (action.type === 'LOGOUT_FULFILLED') {
    state = undefined
  }

  if (action.type.indexOf('_REJECTED') > -1) {
    if (action.payload && action.payload.response && action.payload.response.status === 401) {
      window.location.href = '/login'
      state = undefined
    }
  }

  return appReducer(state, action)
}

export default rootReducer
