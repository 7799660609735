import React from 'react'
import { Link } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import moment from 'moment'

import { getInvoiceLabel } from '../../const/orders'

export default class OrderInvoice extends React.Component {
  getLabel (status) {
    let label
    switch (status) {
      case 'Draft':
        label = 'warning'
        break
      case 'Not Paid':
        label = 'info'
        break
      case 'Paid':
        label = 'primary'
        break
      default:
        // statements_def
        break
    }
    return (
      <span className={`label label-${label}`}>{status.toUpperCase()}</span>
    )
  }

  render () {
    const {order, invoices, user} = this.props
    return (
      <div className='widget order-invoice'>
        <div className='widget-header'>
          <h3><i className='fa fa-tasks' /> Invoices</h3>
          {
          // <div className='widget-header-toolbar'>
          //   <Link to={`/orders/${order.id}/invoices/new`} className='btn btn-link btn-xs'><i className='fa fa-plus' />New</Link>
          // </div>
          }
        </div>
        <div className=''>
          <table className='table' style={{marginBottom: 0}}>
            <thead>
              <tr>
                <th>Invoice No.</th>
                <th>Amount</th>
                <th>Date &amp; time</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              { invoices.length === 0 &&
                <tr>
                  <td colSpan={4}>
                    { ['Finance', 'Super Admin'].indexOf(user.data.role) >= 0
                      ? <Link to={`/orders/${order.id}/invoices/new`} className='btn btn-default'><i className='fa fa-plus' />Create New Invoice</Link>
                      : <p>Not available</p>
                    }
                  </td>
                </tr>
              }
              { invoices.map((x, y) => {
                return (
                  <tr key={y}>
                    <td><Link to={`/orders/${x.order.id}/invoices/${x.id}`}>{x.id}</Link></td>
                    <td>RP <NumberFormat value={x.total} displayType='text' thousandSeparator /></td>
                    <td>{moment.unix(x.created_at).format('D MMMM YYYY')}</td>
                    <td>{getInvoiceLabel(x.status)}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}
