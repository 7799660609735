import React from 'react'
import { connect } from 'react-redux'
import { getFormValues, change } from 'redux-form'

import Affix from '../../components/common/Affix'
import QuestionnaireMandaysCalculationForm from '../../components/Questionnaires/QuestionnaireMandaysCalculationForm'

import { mandDayCreateSelector, getMandays } from '../../selectors/questionnaires'

import phases from '../../const/phases'
import allocateMandays from '../../const/allocateMandays'
import { roundBy } from '../../const/helper'

export function QuestionnaireMandaysCalculation (props) {
  const {
    questionnaire,
    accessToken,
    formValue,
    manDayAdjustment,
    user
  } = props

  const calculatedMandays = getMandays(questionnaire)
  const ateamMandays = (formValue && formValue.mandays_by_a_team) || 0
  const roundedMandays = roundBy(((ateamMandays || calculatedMandays) * (((manDayAdjustment || 0) / 100) + 1)), 0.2)

  function handleSubmit (e) {
    let target
    const finalAdjustment =
      manDayAdjustment < -30 ? -30
        : manDayAdjustment > 30 ? 30
          : manDayAdjustment
    let request = {
      id: questionnaire.id,
      detail: {
        a_team: e.a_team,
        mandays: calculatedMandays,
        mandays_by_a_team: e.mandays_by_a_team,
        mandays_roundup: roundedMandays,
        mandays_adjustment: finalAdjustment,
        reduction_factor: e.reduction_factor,
        enhancement_factor: e.enhancement_factor,
        addition_factor: e.addition_factor,
        additional_service: e.additional_service ? e.additional_service.map((x) => { return { ...x, type: 'additional' } }) : [],
        audit_cycle: e.audit_cycle.map((x) => { return { ...x, type: 'certification' } }),
        surveillance_interval: e.surveillance_interval,
        include_recertification: e.include_recertification,
        mandays_justification: e.mandays_justification,
        adjustment: null
      }
    }

    if (e.advance) {
      request.status = 'Created'
      request.detail.mandays_calculation_complete = true
      target = `/questionnaires/${questionnaire.id}`
    }

    return props.updateQuestionnaire(request, accessToken, target).then((x) => {
      props.fetchQuestionnaire(questionnaire.id, accessToken)
    })
  }

  return (
    <div className='tab-pane active'>
      <h1>Mandays Calculation &  Adjustment</h1>
      <div className='row'>
        <div className='col-md-8'>
          { user && user.data.role === 'Customer'
            ? <div>
              <p>Dear Customer, please wait for Sales personnel adjustment for this quotation prior publish.</p>
            </div>
            : <div>
              <p>Dear Sales Personnel, please fill in the customer detail form.</p>
              { }
              <QuestionnaireMandaysCalculationForm
                onSubmit={handleSubmit.bind(this)}
                change={props.changeForm.bind(this)}
                integration={questionnaire.scopes.length > 1}
                initialValues={{
                  a_team: questionnaire.detail.a_team,
                  mandays_by_a_team: questionnaire.detail.mandays_by_a_team,
                  reduction_factor: questionnaire.detail.reduction_factor,
                  enhancement_factor: questionnaire.detail.enhancement_factor,
                  addition_factor: questionnaire.detail.addition_factor,
                  additional_service: questionnaire.detail.additional_service,
                  audit_cycle: questionnaire.detail.audit_cycle,
                  surveillance_interval: questionnaire.detail.surveillance_interval || 6,
                  include_recertification: questionnaire.detail.include_recertification || 'Yes',
                  mandays_justification: questionnaire.detail.mandays_justification,
                  mandays: calculatedMandays,
                  advance: questionnaire.detail.mandays_calculation_complete
                }}
                mandaysAllocation={allocateMandays(phases, roundedMandays, questionnaire.scopes.length)}
                interval={formValue && formValue.surveillance_interval ? formValue.surveillance_interval : 6}
                manDayAdjustment={manDayAdjustment}
                phases={phases}
                services={questionnaire.services}
                fileAPI={{
                  url: process.env.REACT_APP_BASE_API,
                  accessToken: accessToken
                }}
              />
            </div>
          }
        </div>
        <div className='col-md-4'>
          { user && user.data.role !== 'Customer' &&
          <div>
            <Affix offset={250}>
              <div className='widget'>
                <div className='widget-header'>
                  <h3><i className='fa fa-tasks' /> Basic Data</h3> <em>- Summary of Tasks</em>
                </div>
                <div className='widget-content'>
                  <dl>
                    <dt>Man Days Calculation by Formula</dt>
                    <dd>{ calculatedMandays } mandays</dd>
                    { formValue && formValue.mandays_by_a_team &&
                    <span>
                      <dt>Man Days Calculation by A Team</dt>
                      <dd>{ formValue.mandays_by_a_team } mandays</dd>
                    </span>
                    }
                    <dt>Man Day Adjustment Percentage</dt>
                    <dd>{ manDayAdjustment } %</dd>
                    <dt>Man Day Round Up</dt>
                    { questionnaire &&
                    <dd>{ roundedMandays } mandays</dd>
                    }
                  </dl>
                </div>
              </div>
            </Affix>
          </div>
          }
        </div>
      </div>
    </div>
  )
}

function mapStateToProps (state) {
  return {
    formValue: getFormValues('questionnaireAdjustmentForm')(state),
    manDayAdjustment: mandDayCreateSelector(state)
  }
}

function mapDispatchToProps (dispatch) {
  return {
    changeForm: (form, field, value) => dispatch(change(form, field, value))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionnaireMandaysCalculation)
