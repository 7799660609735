import React, { Component } from 'react'
import { Field, FieldArray, reduxForm } from 'redux-form'

import {
  AutoCompleteField
  // OptionsField,
  // FileField
} from '../common/FormFields'

const RenderVetos = ({ userAPI, label, fields, meta: { error, submitFailed } }) => {
  return (
    <div className='form-group veto'>
      <label htmlFor={label} className='col-sm-3 control-label'>{label}</label>
      <div className='col-sm-9'>

        {fields.map((veto, index) => (
          <div key={index}>
            <Field
              name={`${veto}.user_id`}
              component={AutoCompleteField}
              label='User'
              api={userAPI}
            />

            <div className='row'>
              <div className='col-md-8 col-md-push-4'>
                <button
                  className='btn btn-sm pull-right'
                  type='button'
                  onClick={() => fields.remove(index)}
                >
                  Remove
                </button>
              </div>
            </div>
            <hr />
          </div>
        ))}
        <button className='btn btn-default btn-sm' type='button' onClick={() => fields.push({})}>Add User</button>
        {submitFailed && error && <span className='text-danger'>{error}</span>}
      </div>
    </div>
  )
}

export class VetoForm extends Component {
  render () {
    const { handleSubmit, submitting } = this.props
    return (

      <div>
        <form className='form-horizontal veto-form' onSubmit={handleSubmit}>

          <FieldArray
            name='veto'
            component={RenderVetos}
            label='Veto Person'
            userAPI={this.props.userAPI}
          />

          <div className='form-group'>
            <div className='col-sm-offset-3 col-sm-6'>
              <button disabled={submitting} type='submit' className='btn btn-default btn-block'>Save</button>
            </div>
            <div className='col-sm-3'>
              <button disabled={submitting} type='submit' className='btn btn-primary btn-block' onClick={handleSubmit(values => this.props.onSubmit({...values, advance: true}))} >Submit</button>
            </div>
          </div>

        </form>
      </div>
    )
  }
}

VetoForm = reduxForm({ // eslint-disable-line 
  form: 'vetoForm'
})(VetoForm)

export default VetoForm
