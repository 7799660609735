import axios from 'axios'
import { push } from 'react-router-redux'
import { change } from 'redux-form'
import reduce from 'lodash/reduce'
import { createFlash, createError } from './flasher'

const baseAPI = process.env.REACT_APP_BASE_API

export const fetchChanges = (id, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_CHANGES_PENDING'
    })
    return axios({
      method: 'get',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/orders/${id}/changes`
    }).then((x) => {
      dispatch({
        type: 'FETCH_CHANGES_FULFILLED',
        payload: {
          data: x.data,
          pagination: {
            currentPage: x.headers['x-pagination-current-page'],
            pageCount: x.headers['x-pagination-page-count'],
            perPage: x.headers['x-pagination-per-page'],
            totalCount: x.headers['x-pagination-total-count']
          }
        }
      })
    }).catch((e) => {
      dispatch({
        type: 'FETCH_CHANGES_REJECTED',
        payload: e
      })
    })
  }
}

export const fetchGlobalChanges = (query = '', accessToken) => {
  const queryString = typeof query === 'object'
    ? reduce(query, (x, val, key) => (
      x + key + '=' + val + '&'
    ), '')
    : query
  return (dispatch) => {
    dispatch({
      type: 'FETCH_GLOBAL_CHANGES_PENDING'
    })
    return axios({
      method: 'get',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/changes?${queryString}`
    }).then((x) => {
      dispatch({
        type: 'FETCH_GLOBAL_CHANGES_FULFILLED',
        payload: {
          data: x.data,
          pagination: {
            currentPage: x.headers['x-pagination-current-page'],
            pageCount: x.headers['x-pagination-page-count'],
            perPage: x.headers['x-pagination-per-page'],
            totalCount: x.headers['x-pagination-total-count']
          }
        }
      })
    }).catch((e) => {
      console.error(e)
      dispatch(createError(e.response.data))
      dispatch({
        type: 'FETCH_GLOBAL_CHANGES_REJECTED',
        payload: e
      })
    })
  }
}

export const fetchChange = (orderId, id, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_CHANGE_PENDING'
    })
    return axios({
      method: 'get',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/orders/${orderId}/changes/${id}`
    }).then((x) => {
      dispatch({
        type: 'FETCH_CHANGE_FULFILLED',
        payload: x.data
      })
    }).catch((e) => {
      dispatch({
        type: 'FETCH_CHANGE_REJECTED',
        payload: e
      })
    })
  }
}

export const createChange = (data, accessToken, target) => {
  return (dispatch) => {
    dispatch({
      type: 'CREATE_CHANGE_PENDING'
    })
    return axios({
      data,
      method: 'post',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/orders/${data.order_id}/changes`
    }).then((x) => {
      dispatch({
        type: 'CREATE_CHANGE_FULFILLED',
        payload: x.data
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Change created with the id ${x.data.id}`
      }))
      if (target) {
        dispatch(change(target.form, target.field, {
          value: x.data.id,
          label: x.data.name
        }))
      } else {
        dispatch(push(`/orders/${x.data.order.id}/changes/${x.data.id}`))
      }
    }).catch((e) => {
      dispatch({
        type: 'CREATE_CHANGE_REJECTED',
        payload: e
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'error',
        message: `Update error`
      }))
    })
  }
}

export const updateChange = (data, accessToken, target) => {
  return (dispatch) => {
    dispatch({
      type: 'UPDATE_CHANGE_PENDING'
    })
    return axios({
      data,
      method: 'put',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/orders/${data.order_id}/changes/${data.id}`
    }).then((x) => {
      dispatch({
        type: 'UPDATE_CHANGE_FULFILLED',
        payload: x.data
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Change with the id ${x.data.id} updated`
      }))
      if (target) {
        dispatch(push(target))
      }
    }).catch((e) => {
      dispatch({
        type: 'UPDATE_CHANGE_REJECTED',
        payload: e
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'error',
        message: `Update error`
      }))
    })
  }
}
