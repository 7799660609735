import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import Inside from './Inside'
import EventList from './Events/EventList'
import EventUpdate from './Events/EventUpdate'

function mapStateToProps (state) {
  return {

  }
}

export class Events extends React.Component {
  render () {
    return (
      <Inside>
        <Switch>
          <Route path='/events/:id' component={EventUpdate} />
          <Route exact path='/events' component={EventList} />
        </Switch>
      </Inside>
    )
  }
}

export default connect(
  mapStateToProps
// Implement map dispatch to props
)(Events)
