import React from 'react'
import { connect } from 'react-redux'

function mapStateToProps (state) {
  return {
  }
};

export class NoMatch extends React.Component {
  render () {
    return (
      <div className='wrapper full-page-wrapper page-error text-center'>
        <div className='inner-page'>
          <h1>
            <span className='clearfix title'>
              <span className='number'>404</span> <span className='text'>Oops! <br />Page Not Found</span>
            </span>
          </h1>
          <p>The page you were looking for could not be found, please contact us to report this issue.</p>
          <p>You can also use search form below to find the page you are looking for.</p>
          <div>
            <button className='btn btn-custom-primary'><i className='fa fa-arrow-left' /> Go Back</button>
            <button className='btn btn-primary'><i className='fa fa-home' /> Home</button>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps
// Implement map dispatch to props
)(NoMatch)
