import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

export default class DaTable extends React.Component {
  render () {
    const { das } = this.props
    return (
      <div className='table-responsive'>
        <table className='table'>
          <thead>
            <tr>
              <th>#</th>
              <th>Auditor</th>
              <th>Date</th>
              <th># of schedules</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {das.map((da, id) => (
              <tr key={id}>
                <td><Link to={`/daily-allowances/${da.id}`}>{da.id}</Link></td>
                <td><Link to={`/daily-allowances/${da.id}`}>{da.user.name}</Link></td>
                <td>{moment(da.date).format('MMMM - YYYY')}</td>
                <td>{da.orderSchedules.length} schedule(s)</td>
                <td>{da.total}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}
