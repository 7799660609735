import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { getOrderLabel } from '../../const/orders'
import { groupByStandard } from '../../selectors/standards'

export default class QuotationTable extends React.Component {
  render () {
    return (
      <div>
        <div className='table-responsive order-table'>
          <table className='table colored-header datatable project-list'>
            <thead>
              <tr>
                <th>Order No.</th>
                <th>Order Date</th>
                <th>Quotation No.</th>
                <th>Standards</th>
                <th>Customer</th>
                <th>Audit Phase</th>
                <th>Order Status</th>
                <th>Payment Status</th>
              </tr>
            </thead>
            <tbody>
              {
                this.props.orders.map((x, i) => {
                  return (
                    <tr className={`order-${x.id}`} key={x.id}>
                      <td><Link to={`/orders/${x.id}`}>{x.id}</Link></td>
                      <td>{moment.unix(x.created_at).format('D MMMM YYYY')}</td>
                      <td><Link to={`/quotations/${x.quotation.id}`} className='link-quotation'>{x.quotation.id}</Link></td>
                      <td>{x.questionnaire.scopes.reduce(groupByStandard, []).map(x => x.name).join(' - ')}</td>
                      <td>{x.customer.name}</td>
                      <td>{x.phase}</td>
                      <td>{getOrderLabel(x.status)}</td>
                      <td>{x.payment}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}
