import { createSelector } from 'reselect'

const globalSchedulesSelector = state => state.schedules.scheduleList ? state.schedules.globalScheduleList.data : []
const auditorsSelector = state => state.users.auditorList ? state.users.auditorList.data : []
const orderSelector = state => state.orders.order ? state.orders.order.data : {}

const auditorsScheduleSelector = createSelector(
  globalSchedulesSelector,
  auditorsSelector,
  (schedules, auditors) => {
    if (schedules && auditors) {
      return auditors.map(auditor => {
        return {
          ...auditor,
          schedule: schedules.filter(x => x.user.id === auditor.id)
        }
      })
    }
  }
)

const otherAuditorsScheduleSelector = createSelector(
  globalSchedulesSelector,
  auditorsSelector,
  orderSelector,
  (schedules, auditors, order) => {
    if (schedules && auditors && order) {
      return auditors.map(auditor => {
        return {
          ...auditor,
          schedule: schedules.filter(x => x.order.id !== order.id && x.user.id === auditor.id)
        }
      })
    }
  }
)

export { auditorsScheduleSelector, otherAuditorsScheduleSelector }

export function getDateArrayStartEnd (data) {
  return data.reduce((sum, item) => {
    return {
      start: sum.start === 0 ? item.start_date : item.start_date < sum.start ? item.start_date : sum.start,
      end: sum.end === 0 ? item.end_date : item.end_date > sum.end ? item.end_date : sum.end
    }
  }, {
    start: 0,
    end: 0
  })
}
