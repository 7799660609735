import React from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import Pagination from '../../components/common/Pagination'
import OptionTable from '../../components/Options/OptionTable'
import OptionFilter from '../../components/Options/OptionFilter'
import { OptionCreateForm as OptionForm } from '../../components/Options/OptionForm'

import { createOption, fetchOptions } from '../../actions/options'
import { asQueryObject, asQueryString } from '../../const/helper'

function mapStateToProps (state) {
  return {
    options: state.options.optionList.data,
    pagination: state.options.optionList.pagination,
    accessToken: state.auth.user.access.access_token
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchOptions: (params, key) => dispatch(fetchOptions(params, key)),
    createOption: (data, key) => dispatch(createOption(data, key)),
    push: (to) => dispatch(push(to))
  }
}

class OptionList extends React.Component {
  componentWillReceiveProps (nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.props.fetchOptions(nextProps.location.search, this.props.accessToken)
    }
  }

  componentWillMount () {
    this.props.fetchOptions(this.props.location.search, this.props.accessToken)
  }

  handleOption (e) {
    return this.props.createOption(e, this.props.accessToken)
  }

  handleFilter (filter) {
    delete filter.page
    return this.props.push(`/options?${asQueryString(filter)}`)
  }

  render () {
    const { options, pagination, location } = this.props

    return (
      <div className='content'>
        <div className='main-header'>
          <h2>Options </h2>
          <em className='hide'>normal sidebar with minified mode by default</em>
        </div>
        <div className='main-content'>
          <div className='row'>
            <div className='col-md-8'>
              { options
                ? <div>
                  <OptionFilter
                    onSubmit={this.handleFilter.bind(this)}
                    initialValues={asQueryObject(location.search)}
                  />
                  <OptionTable options={options} />
                  
                </div>
                : <div>loading</div>
              }
              <Pagination pagination={pagination} base='/options' params={asQueryObject(location.search)} />
            </div>
            <div className='col-md-4'>
              <div className='widget'>
                <div className='widget-header'>
                  <h3><i className='fa fa-book' /> Create Options</h3>
                </div>
                <div className='widget-content'>
                  <OptionForm onSubmit={this.handleOption.bind(this)} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OptionList)
