import set from 'lodash/fp/set'

export const initialState = {
  standardList: {
    data: null,
    pagination: null,
    error: null,
    loading: false
  },
  standard: {
    data: null,
    error: null,
    loading: false
  },
  scopeList: {
    data: [],
    error: null,
    loading: false
  },
  scopesByStandard: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_STANDARDS_PENDING':
      return { ...state, standardList: { data: null, error: null, loading: true } }
    case 'FETCH_STANDARDS_REJECTED':
      return { ...state, standardList: { data: null, error: action.payload, loading: false } }
    case 'FETCH_STANDARDS_FULFILLED':
      return { ...state,
        standardList: {
          data: action.payload.map((x) => (x)),
          pagination: action.pagination,
          error: null,
          loading: false
        }
      }

    case 'FETCH_STANDARD_PENDING' :
      return { ...state, standard: { data: null, error: null, loading: true } }
    case 'FETCH_STANDARD_REJECTED':
      return { ...state, standard: { data: null, error: action.payload, loading: false } }
    case 'FETCH_STANDARD_FULFILLED' :
      return { ...state, standard: { data: action.payload, error: null, loading: false } }

    case 'UPDATE_STANDARD_PENDING' :
      return { ...state, standard: { ...state.standard, error: null, loading: true } }
    case 'UPDATE_STANDARD_REJECTED':
      return { ...state, standard: { ...state.standard, error: action.payload, loading: false } }
    case 'UPDATE_STANDARD_FULFILLED' :
      return { ...state, standard: { data: action.payload, error: null, loading: false } }

    case 'DELETE_STANDARD_PENDING' :
      return { ...state, standard: { ...state.standard, error: null, loading: true } }
    case 'DELETE_STANDARD_REJECTED':
      return { ...state, standard: { ...state.standard, error: action.payload, loading: false } }
    case 'DELETE_STANDARD_FULFILLED' :
      return { ...state, standard: { data: null, error: null, loading: false } }

    // scopes
    case 'FETCH_SCOPES_PENDING':
      return { ...state, scopeList: { data: [], error: null, loading: true } }
    case 'FETCH_SCOPES_REJECTED':
      return { ...state, scopeList: { data: [], error: action.payload, loading: false } }
    case 'FETCH_SCOPES_FULFILLED':
      const newScopeByStandard = Object.assign(state.scopesByStandard)
      if (action.payload.length > 0) {
        newScopeByStandard[action.payload[0].standard_id] = action.payload
      }
      return { ...state,
        scopeList: {
          data: action.payload,
          error: null,
          loading: false
        },
        scopesByStandard: newScopeByStandard
      }

    case 'CREATE_SCOPE_PENDING':
      return set(`scopeList.loading`, true)(state)
    case 'CREATE_SCOPE_REJECTED':
      return { ...state,
        scopeList: {
          data: [...state.scopeList.data],
          error: action.payload,
          loading: false
        }
      }
    case 'CREATE_SCOPE_FULFILLED':
      return { ...state,
        scopeList: {
          data: [...state.scopeList.data, action.payload],
          error: null,
          loading: false
        }
      }

    case 'UPDATE_SCOPE_PENDING':
      return set(`scopeList.loading`, true)(state)
    case 'UPDATE_SCOPE_REJECTED':
      return { ...state,
        scopeList: {
          data: [...state.scopeList.data],
          error: action.payload,
          loading: false
        }
      }
    case 'UPDATE_SCOPE_FULFILLED':
      return { ...state,
        scopeList: {
          data: state.scopeList.data.map(x => {
            if (x.id === action.payload.id) {
              return action.payload
            } else {
              return x
            }
          }),
          error: null,
          loading: false
        }
      }

    case 'DELETE_SCOPE_PENDING':
      return set(`scopeList.loading`, true)(state)
    case 'DELETE_SCOPE_REJECTED':
      return { ...state,
        scopeList: {
          data: [...state.scopeList.data],
          error: action.payload,
          loading: false
        }
      }
    case 'DELETE_SCOPE_FULFILLED':
      return { ...state,
        scopeList: {
          data: state.scopeList.data.filter(x => {
            return x.id !== action.payload.id
          }),
          error: null,
          loading: false
        }
      }

    case 'LOGOUT_FULFILLED':
      return { ...initialState }

    default:
      return state
  }
}
