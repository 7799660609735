import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

import { TextField, TextareaField } from '../common/FormFields'
import DateField from '../common/DateField'

const validate = (values) => {
  const errors = {}
  if (!values.name) {
    errors.name = 'Required'
  }
  if (!values.date) {
    errors.date = 'Required'
  }
  return errors
}

export class EventForm extends Component {
  render () {
    const { handleSubmit, submitting } = this.props
    return (
      <div>
        <form className='form-horizontal' onSubmit={handleSubmit}>
          <Field name='name' type='text' component={TextField} label='Event Name' />
          <Field name='detail.description' type='text' component={TextareaField} label='Description' />
          <Field name='date' component={DateField} label='Date' />
          <div className='form-group'>
            <div className='col-sm-offset-3 col-sm-9'>
              <button disabled={submitting} type='submit' className='btn btn-primary btn-block'>Submit</button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default EventForm

const BaseEventCreateForm = props => <EventForm {...props} mode='create' />

export const EventCreateForm = reduxForm({
  form: 'eventCreate',
  validate, // validate, warn
})(BaseEventCreateForm)

const BaseEventUpdateForm = props => <EventForm {...props} mode='update' />

export const EventUpdateForm = reduxForm({
  form: 'eventUpdate' // validate, warn
})(BaseEventUpdateForm)
