import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

export default class ScheduleTable extends React.Component {
  handleInitiate (id) {
    this.props.onInitiate(id)
  }

  render () {
    const orders = this.props.orders || []
    const schedules = this.props.schedules.map((x, i) => {
      const xorder = orders.find((y) => {
        return y.phase === x.phase
      })
      if (xorder) {
        x.order = xorder
      }
      return x
    })
    return (
      <div>
        <div className='table-responsive order-table'>
          <table className='table colored-header datatable project-list'>
            <thead>
              <tr>
                <th>Phase</th>
                <th>Order</th>
                <th>Scheduled at</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                schedules.map((x, i) => {
                  return (
                    <tr key={i}>
                      <td>{x.phase}</td>
                      <th>
                        { x.order
                          ? <Link to={`/orders/${x.order.id}`}>{x.order.id}</Link>
                          : '-'
                        }
                      </th>
                      <td>{moment.unix(x.date).format('D MMMM YYYY')}</td>
                      <td>
                        { !x.order && ['Super Admin', 'Scheduler'].indexOf(this.props.role) >= 0 &&
                          <button onClick={this.handleInitiate.bind(this, x.id)} className='btn btn-xs btn-primary'>Create Order</button>
                        }
                        { x.order &&
                          <Link to={`/orders/${x.order.id}`} className='btn btn-xs btn-info'>View Order</Link>
                        }
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}
