import React from 'react'

class Event extends React.Component {
  render () {
    const {editable, handleClick, event} = this.props
    return (
      <tr>
        <td>{event.time}</td>
        <td colSpan={5}>{event.description}</td>
        <td className='text-right'>
          { editable &&
            <button onClick={handleClick} className='btn btn-xs btn-default'><i className='fa fa-gear' /></button>
          }
        </td>
      </tr>
    )
  }
}

class AuditEvent extends React.Component {
  render () {
    const {editable, handleClick, event} = this.props
    const auditor = event.auditor || []
    return (
      <tr>
        <td>{event.time}</td>
        <td>{event.site}</td>
        <td>{event.process}</td>
        <td>{auditor.map((x, i) => x.data.display_name).join(', ')}</td>
        <td>{event.contact}</td>
        <td>{event.focus}</td>
        <td className='text-right'>
          { editable &&
            <button onClick={handleClick} className='btn btn-xs btn-default'><i className='fa fa-gear' /></button>
          }
        </td>
      </tr>
    )
  }
}

export default class PlanTable extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      openEvent: false,
      openAuditEvent: false
    }
  }

  toggleOpenEvent (x) {
    this.props.onOpenEvent(x)
  }

  toggleOpenAuditEvent (x) {
    this.props.onOpenAuditEvent(x)
  }

  render () {
    const { events, editable } = this.props
    console.log(events);
    return (
      <table className='table'>
        <thead>
          <tr>
            <th>Date/Time</th>
            <th>Site / Shift / Business Unit</th>
            <th>Processes</th>
            <th>Auditors</th>
            <th>Contact</th>
            <th colSpan={2}>Focus / Standard Requirement / Chapter / Clause</th>
          </tr>
        </thead>
        { events.map((day, j) => (
          <tbody key={j}>
            <tr>
              <th colSpan={8}> {day.title}</th>
            </tr>
            { day.event.map((x, i) => {
              return x.type === 'event'
                ? <Event key={i} event={x} editable={editable} handleClick={this.toggleOpenEvent.bind(this, x.key)} />
                : <AuditEvent key={i} event={x} editable={editable} handleClick={this.toggleOpenAuditEvent.bind(this, x.key)} />
            })}
          </tbody>
        ))}
      </table>
    )
  }
}
