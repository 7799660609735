import React from 'react'
import { connect } from 'react-redux'

import { createUser } from '../../actions/users'

import UserForm from '../../components/Users/UserForm'

function mapStateToProps (state) {
  return {

  }
}

function mapDispatchToProps (dispatch) {
  return {
    submitUser: (data, key) => dispatch(createUser(data, key))
  }
}

export class UserNew extends React.Component {
  submitUser (e) {
    return this.props.submitUser({ ...e }, 'key')
  }

  render () {
    const testValue = {
      username: 'test',
      password: 'test',
      role: 'public',
      profile_picture: {
        name: 'old-file.jpg',
        preview: 'http://placehold.it/100x100/?text=old+file'
      }
    }

    return (
      <div className='content'>
        <div className='main-header'>
          <h2>Create New User</h2>{/* TODO : change title if updating user */}
          {/* <em>normal sidebar with minified mode by default</em> */}
        </div>
        <div className='main-content'>
          <div className='row'>
            <div className='col-md-8'>
              <UserForm
                onSubmit={this.submitUser.bind(this)}
                initialValues={testValue}
              />
            </div>
            <div className='col-md-4'>
              <div className='widget hide'>
                <div className='widget-header'>
                  <h3><i className='fa fa-edit' /> Basic Input</h3>
                </div>
                <div className='widget-content'>
                  <p>The Gardens are a tremendous big place, with millions and hundreds of trees; and first you come to the Figs, but you scorn to loiter there, for the Figs is the resort of superior little persons, who are forbidden to mix with the commonalty, and is so named, according to legend, because they dress in full fig. These dainty ones are themselves contemptuously called Figs by David and other heroes, and you have a key to the manners and customs of this dandiacal section of the Gardens when I tell you that cricket is called crickets here. Occasionally a rebel Fig climbs over the fence into the world, and such a one was Miss Mabel Grey, of whom I shall tell you when we come to Miss Mabel Grey's gate. She was the only really celebrated Fig.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserNew)
