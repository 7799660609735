const orderStatuses = [
  {
    label: 'Created',
    color: 'info'
  },
  {
    label: 'Questionnaire Completed',
    color: 'info'
  },
  {
    label: 'Questionnaire Reviewed',
    color: 'info'
  },
  {
    label: 'Schedule Draft',
    color: 'info'
  },
  {
    label: 'Schedule Published',
    color: 'info'
  },
  {
    label: 'Schedule Approved by HCB',
    color: 'info'
  },
  {
    label: 'Schedule Approved by Customer',
    color: 'info'
  },
  {
    label: 'Plan Draft',
    color: 'info'
  },
  {
    label: 'Plan Published',
    color: 'info'
  },
  {
    label: 'Plan Approved by Lead Auditor',
    color: 'info'
  },
  {
    label: 'Stage 1 - On Audit',
    color: 'info'
  },
  {
    label: 'Stage 1 - Audited',
    color: 'info'
  },
  {
    label: 'Amendment Quotation',
    color: 'info'
  },
  {
    label: 'Reschedule',
    color: 'info'
  },
  {
    label: 'Reschedule - Schedule Draft',
    color: 'info'
  },
  {
    label: 'Reschedule - Schedule Published',
    color: 'info'
  },
  {
    label: 'Reschedule - Schedule Approved by HCB',
    color: 'info'
  },
  {
    label: 'Reschedule - Schedule Approved by Customer',
    color: 'info'
  },
  {
    label: 'Reschedule - Plan Draft',
    color: 'info'
  },
  {
    label: 'Reschedule - Plan Published',
    color: 'info'
  },
  {
    label: 'Reschedule - Plan Approved by Lead Auditor',
    color: 'info'
  },
  {
    label: 'Stage 1 - Report Published',
    color: 'info'
  },
  {
    label: 'Stage 2 - On Audit',
    color: 'info'
  },
  {
    label: 'Stage 2 - Audited',
    color: 'info'
  },
  {
    label: 'Follow up',
    color: 'info'
  },
  {
    label: 'Follow up - Schedule Draft',
    color: 'info'
  },
  {
    label: 'Follow up - Schedule Published',
    color: 'info'
  },
  {
    label: 'Follow up - Schedule Approved by HCB',
    color: 'info'
  },
  {
    label: 'Follow up - Schedule Approved by Customer',
    color: 'info'
  },
  {
    label: 'Follow up - Plan Draft',
    color: 'info'
  },
  {
    label: 'Follow up - Plan Published',
    color: 'info'
  },
  {
    label: 'Follow up - Plan Approved by Lead Auditor',
    color: 'info'
  },
  {
    label: 'Follow up - On Audit',
    color: 'info'
  },
  {
    label: 'Follow up - Audited',
    color: 'info'
  },
  {
    label: 'Verification by Submmission of Document',
    color: 'info'
  },
  {
    label: 'Stage 2 - Report Published',
    color: 'info'
  },
  {
    label: 'Veto Assigned',
    color: 'info'
  },
  {
    label: 'Veto Approval Pending',
    color: 'info'
  },
  {
    label: 'Approved by Veto',
    color: 'info'
  },
  {
    label: 'Protocol Released',
    color: 'info'
  },
  {
    label: 'HCB Approval Pending',
    color: 'info'
  },
  {
    label: 'Approved by HCB',
    color: 'info'
  },
  {
    label: 'Certificate Published',
    color: 'info'
  },
  {
    label: 'Certificate Approved by HCB',
    color: 'info'
  },
  {
    label: 'Order Finished',
    color: 'info'
  },
  {
    label: 'Canceled',
    color: 'info'
  },
  {
    label: 'Terminated',
    color: 'info'
  }
]

export default orderStatuses
