import React from 'react'
import { Link } from 'react-router-dom'

export default class ReportTable extends React.Component {
  render () {
    return (
      <div>
        <div className='table-responsive'>
          <table className='table colored-header datatable project-list'>
            <thead>
              <tr>
                <th>Schedule No.</th>
                <th>User</th>
                <th>Role</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><Link to='/orders/1/invoices/12'>ORD201502004</Link></td>
                <td>Damar Jati</td>
                <td>Lead Auditor</td>
                <td><span className='label label-info'>DRAFT</span></td>
              </tr>
              <tr>
                <td><Link to='/orders/1/invoices/12'>ORD201502004</Link></td>
                <td>RP 93.000.000</td>
                <td>04/03/2017 17:23</td>
                <td><span className='label label-danger'>NOT PAID</span></td>
              </tr>
              <tr>
                <td><Link to='/orders/1/invoices/12'>ORD201502004</Link></td>
                <td>RP 93.000.000</td>
                <td>04/03/2017 17:23</td>
                <td><span className='label label-success'>PAID</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}
