import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

import {
  fetchStandard,
  deleteStandard,
  updateStandard,
  // scope
  fetchStandardScopes,
  createStandardScope,
  updateScope,
  deleteScope,
  _deleteScope
} from '../../actions/standards'

import DeleteForm from '../../components/common/DeleteForm'
import { StandardUpdateForm as StandardForm } from '../../components/Standards/StandardForm'
import ScopeList from '../../components/Standards/ScopeList'
import AnnexList from '../../components/Standards/AnnexList'

export class StandardUpdate extends React.Component {
  componentDidMount () {
    this.props.fetchStandard(this.props.match.params.id, this.props.accessToken)
    this.props.fetchStandardScopes(this.props.match.params.id, this.props.accessToken)
  }

  handleDelete (e) {
    return this.props.deleteStandard(this.props.standard.id, this.props.accessToken)
  }

  handleSubmit (e) {
    return this.props.updateStandard({ ...e }, this.props.accessToken)
  }

  createStandardScope (e) {
    return this.props.createStandardScope(this.props.standard.id, e, this.props.accessToken)
  }

  updateScope (e) {
    return this.props.updateScope(e, this.props.accessToken)
  }

  deleteScope (id) {
    return this.props.deleteScope(id, this.props.accessToken)
  }

  _deleteScope (id) {
    return this.props._deleteScope(id, this.props.accessToken)
  }

  render () {
    return (
      <div className='content'>
        <div className='main-header'>
          <h2>Update Standard {this.props.standard && this.props.standard.name}</h2>
          <em><Link to='/standards'>back to standards</Link></em>
        </div>
        <div className='main-content'>
          <div className='row'>
            <div className='col-md-8'>
              { this.props.standard
                ? <div>
                  <StandardForm
                    onSubmit={this.handleSubmit.bind(this)}
                    initialValues={this.props.standard}
                    fileAPI={{
                      url: process.env.REACT_APP_BASE_API,
                      accessToken: this.props.accessToken
                    }}
                  />
                  <DeleteForm onClick={this.handleDelete.bind(this)} />
                </div>
                : <div>loading</div>
              }
            </div>
            <div className='col-md-4' />
          </div>
        </div>
        <div className='main-header'>
          <h2>Scope</h2>
          <em>For {this.props.standard && this.props.standard.name}</em>
        </div>
        <div className='main-content'>
          <div className='row'>
            <div className='col-md-12'>
              { this.props.scopes
                ? <Fragment>
                  <ScopeList
                    standard={this.props.standard}
                    scopes={this.props.scopes}
                    createStandardScope={this.createStandardScope.bind(this)}
                    updateScope={this.updateScope.bind(this)}
                    deleteScope={this.deleteScope.bind(this)}
                    _deleteScope={this._deleteScope.bind(this)}
                  />
                </Fragment>
                : <div>loading</div>
              }
            </div>
          </div>
        </div>
        <div className='main-header'>
          <h2>Standard Annex Question</h2>
          <em>For {this.props.standard && this.props.standard.name}</em>
        </div>
        <div className='main-content'>
          <div className='row'>
            <div className='col-md-12'>
              { this.props.standard
                ? <AnnexList
                  onSubmit={this.handleSubmit.bind(this)}
                  standard={this.props.standard}
                />
                : <div>loading</div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    standard: state.standards.standard.data,
    scopes: state.standards.scopeList.data,
    accessToken: state.auth.user.access.access_token
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchStandard: (id, key) => dispatch(fetchStandard(id, key)),
    updateStandard: (data, key) => dispatch(updateStandard(data, key)),
    deleteStandard: (id, key) => dispatch(deleteStandard(id, key)),
    fetchStandardScopes: (id, key) => dispatch(fetchStandardScopes(id, key)),
    createStandardScope: (id, data, key) => dispatch(createStandardScope(id, data, key)),
    updateScope: (data, key) => dispatch(updateScope(data, key)),
    deleteScope: (id, key) => dispatch(deleteScope(id, key)),
    _deleteScope: (id, key) => dispatch(_deleteScope(id, key))
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(StandardUpdate))
