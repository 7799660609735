import React from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { Helmet } from 'react-helmet'

import Pagination from '../../components/common/Pagination'
import OrderTable from '../../components/Orders/OrderTable'
import OrderFilter from '../../components/Orders/OrderFilter'

import { fetchOrders } from '../../actions/orders'
import { asQueryObject, asQueryString } from '../../const/helper'

export class OrderList extends React.Component {
  componentWillReceiveProps (nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      return this.props.fetchOrders(nextProps.location.search, this.props.accessToken)
    }
  }

  componentWillMount () {
    return this.props.fetchOrders(this.props.location.search, this.props.accessToken)
  }

  handleFilter (filter) {
    if (filter.standard === null) {
      delete filter.standard
    }
    if (filter.standard) {
      filter.standard = filter.standard.value.id
    }
    delete filter.page
    return this.props.push(`/orders?${asQueryString(filter)}`)
  }

  render () {
    const { orders, pagination, location } = this.props

    return (
      <div className='content'>
        <Helmet>
          <title>Order List</title>
        </Helmet>
        <div className='main-header'>
          <h2>Order List</h2>
          {/* <em>normal sidebar with minified mode by default</em> */}
        </div>
        <div className='main-content'>
          { orders
            ? <div>
              <OrderFilter
                onSubmit={this.handleFilter.bind(this)}
                initialValues={asQueryObject(location.search)}
                standardAPI={{
                  url: process.env.REACT_APP_BASE_API + '/standards',
                  accessToken: this.props.accessToken,
                  label: 'name'
                }}
              />
              <OrderTable orders={orders} />
            </div>
            : <div>loading</div>
          }
          <Pagination pagination={pagination} base='/orders' params={asQueryObject(location.search)} />
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    orders: state.orders.orderList.data,
    pagination: state.orders.orderList.pagination,
    accessToken: state.auth.user.access.access_token
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  return {
    fetchOrders: (params, key) => dispatch(fetchOrders(params, key)),
    push: (to) => dispatch(push(to))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderList)
