import axios from 'axios'
import { push } from 'react-router-redux'
import reduce from 'lodash/reduce'
import { createFlash, createError } from './flasher'

const baseAPI = process.env.REACT_APP_BASE_API

export const fetchInvoices = (id, accessToken, query = '') => {
  const queryString = typeof query === 'object'
    ? reduce(query, (x, val, key) => (
      x + key + '=' + val + '&'
    ), '')
    : query
  return (dispatch) => {
    dispatch({
      type: 'FETCH_INVOICES_PENDING'
    })
    return axios({
      method: 'get',
      headers: {'Authorization': `Bearer ${accessToken}`},
      url: `${baseAPI}/orders/${id}/invoices${queryString}`
    }).then((x) => {
      dispatch({
        type: 'FETCH_INVOICES_FULFILLED',
        payload: {
          data: x.data,
          pagination: {
            currentPage: x.headers['x-pagination-current-page'],
            pageCount: x.headers['x-pagination-page-count'],
            perPage: x.headers['x-pagination-per-page'],
            totalCount: x.headers['x-pagination-total-count']
          }
        }
      })
    }).catch((e) => {
      console.error(e);
      dispatch(createError(e.response.data))
      dispatch({
        type: 'FETCH_INVOICES_REJECTED',
        payload: e
      })
    })
  }
}

export const fetchGlobalInvoices = (query = '', accessToken) => {
  const queryString = typeof query === 'object'
    ? reduce(query, (x, val, key) => (
      x + key + '=' + val + '&'
    ), '')
    : query
  return (dispatch) => {
    dispatch({
      type: 'FETCH_GLOBAL_INVOICES_PENDING'
    })
    return axios({
      method: 'get',
      headers: {'Authorization': `Bearer ${accessToken}`},
      url: `${baseAPI}/invoices?${queryString}`
    }).then((x) => {
      dispatch({
        type: 'FETCH_GLOBAL_INVOICES_FULFILLED',
        payload: {
          data: x.data,
          pagination: {
            currentPage: x.headers['x-pagination-current-page'],
            pageCount: x.headers['x-pagination-page-count'],
            perPage: x.headers['x-pagination-per-page'],
            totalCount: x.headers['x-pagination-total-count']
          }
        }
      })
    }).catch((e) => {
      console.error(e)
      dispatch(createError(e.response.data))
      dispatch({
        type: 'FETCH_GLOBAL_INVOICES_REJECTED',
        payload: e
      })
    })
  }
}

export const fetchInvoice = (orderId, id, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_INVOICE_PENDING'
    })
    return axios({
      method: 'get',
      headers: {'Authorization': `Bearer ${accessToken}`},
      url: `${baseAPI}/orders/${orderId}/invoices/${id}`
    }).then((x) => {
      dispatch({
        type: 'FETCH_INVOICE_FULFILLED',
        payload: x.data
      })
    }).catch((e) => {
      dispatch({
        type: 'FETCH_INVOICE_REJECTED',
        payload: e
      })
    })
  }
}

export const createInvoice = (data, accessToken, target) => {
  return (dispatch) => {
    dispatch({
      type: 'CREATE_INVOICE_PENDING'
    })
    return axios({
      data,
      method: 'post',
      headers: {'Authorization': `Bearer ${accessToken}`},
      url: `${baseAPI}/orders/${data.order_id}/invoices`
    }).then((x) => {
      dispatch({
        type: 'CREATE_INVOICE_FULFILLED',
        payload: x.data
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Invoice created with the id ${x.data.id}`
      }))
      dispatch(push(`/orders/${x.data.order.id}/invoices/${x.data.id}`))
    }).catch((e) => {
      dispatch({
        type: 'CREATE_INVOICE_REJECTED',
        payload: e
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'error',
        message: `Update error`
      }))
    })
  }
}

export const updateInvoice = (data, accessToken, target) => {
  return (dispatch) => {
    dispatch({
      type: 'UPDATE_INVOICE_PENDING'
    })
    return axios({
      data,
      method: 'put',
      headers: {'Authorization': `Bearer ${accessToken}`},
      url: `${baseAPI}/orders/${data.order_id}/invoices/${data.id}`
    }).then((x) => {
      dispatch({
        type: 'UPDATE_INVOICE_FULFILLED',
        payload: x.data
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Invoice with the id ${x.data.id} updated`
      }))
      if (target) {
        dispatch(push(target))
      }
    }).catch((e) => {
      dispatch({
        type: 'UPDATE_INVOICE_REJECTED',
        payload: e
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'error',
        message: `Update error`
      }))
    })
  }
}
