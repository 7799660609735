import axios from 'axios'
import { push } from 'react-router-redux'
import { createFlash, createError } from './flasher'

const baseAPI = process.env.REACT_APP_BASE_API

export const fetchQuestionnaires = (params = '', accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_QUESTIONNAIRES_PENDING'
    })
    return axios({
      method: 'get',
      headers: {'Authorization': `Bearer ${accessToken}`},
      url: `${baseAPI}/questionnaires${params}`
    }).then((x) => {
      dispatch({
        type: 'FETCH_QUESTIONNAIRES_FULFILLED',
        payload: {
          data: x.data,
          pagination: {
            currentPage: x.headers['x-pagination-current-page'],
            pageCount: x.headers['x-pagination-page-count'],
            perPage: x.headers['x-pagination-per-page'],
            totalCount: x.headers['x-pagination-total-count']
          }
        }
      })
    }).catch((e) => {
      console.error(e)
      dispatch(createError(e.response ? e.response.data : 'error 5324B'))
      dispatch({
        type: 'FETCH_QUESTIONNAIRES_REJECTED',
        payload: e
      })
    })
  }
}

export const fetchQuestionnaire = (id, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_QUESTIONNAIRE_PENDING'
    })
    return axios({
      method: 'get',
      headers: {'Authorization': `Bearer ${accessToken}`},
      url: `${baseAPI}/questionnaires/${id}?expand=log,certification`
    }).then((x) => {
      dispatch({
        type: 'FETCH_QUESTIONNAIRE_FULFILLED',
        payload: x.data
      })
    }).catch((e) => {
      console.error(e)
      dispatch(createError(e.response ? e.response.data : 'error 5324'))
      dispatch({
        type: 'FETCH_QUESTIONNAIRE_REJECTED',
        payload: e
      })
    })
  }
}

export const createQuestionnaire = (data, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'CREATE_QUESTIONNAIRE_PENDING'
    })
    return axios({
      data,
      method: 'post',
      headers: {'Authorization': `Bearer ${accessToken}`},
      url: `${baseAPI}/questionnaires`
    }).then((x) => {
      dispatch({
        type: 'CREATE_QUESTIONNAIRE_FULFILLED',
        payload: x.data
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Questionnaire created with the id ${x.data.id}`
      }))
      dispatch(push(`/questionnaires/${x.data.id}`))
    }).catch((e) => {
      dispatch({
        type: 'CREATE_QUESTIONNAIRE_REJECTED',
        payload: e
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'error',
        message: `Update error`
      }))
    })
  }
}

export const updateQuestionnaire = (data, accessToken, target) => {
  return (dispatch) => {
    dispatch({
      type: 'UPDATE_QUESTIONNAIRE_PENDING'
    })
    return axios({
      data,
      method: 'put',
      headers: {'Authorization': `Bearer ${accessToken}`},
      url: `${baseAPI}/questionnaires/${data.id}?expand=log,certification`
    }).then((x) => {
      dispatch({
        type: 'UPDATE_QUESTIONNAIRE_FULFILLED',
        payload: x.data
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Questionnaire with the id ${x.data.id} updated`
      }))
      if (target) {
        dispatch(push(target))
      }
    }).catch((e) => {
      console.error(e)
      dispatch({
        type: 'UPDATE_QUESTIONNAIRE_REJECTED',
        payload: e
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'error',
        message: `Update error`
      }))
    })
  }
}

export const deleteQuestionnaire = (id, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'DELETE_QUESTIONNAIRE_PENDING'
    })
    return axios({
      method: 'delete',
      headers: {'Authorization': `Bearer ${accessToken}`},
      url: `${baseAPI}/questionnaires/${id}`
    }).then((x) => {
      dispatch({
        type: 'DELETE_QUESTIONNAIRE_FULFILLED',
        payload: x
      })
      dispatch(push('/questionnaires'))
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Questionnaire ${id} deleted`
      }))
    }).catch((e) => {
      dispatch({
        type: 'DELETE_QUESTIONNAIRE_REJECTED',
        payload: e
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'error',
        message: `delete error`
      }))
    })
  }
}

export const addNewCustomer = () => {
  return (dispatch) => {
    dispatch({
      type: 'ADD_NEW_CUSTOMER_TO_QUESTIONNAIRE_START'
    })
  }
}

export const addNewCustomerContact = () => {
  return (dispatch) => {
    dispatch({
      type: 'ADD_NEW_CUSTOMER_CONTACT_TO_QUESTIONNAIRE_START'
    })
  }
}

export const addNewCustomerLocation = () => {
  return (dispatch) => {
    dispatch({
      type: 'ADD_NEW_CUSTOMER_LOCATION_TO_QUESTIONNAIRE_START'
    })
  }
}

export const addNewQuestionnaireLocation = () => {
  return (dispatch) => {
    dispatch({
      type: 'ADD_NEW_LOCATION_TO_QUESTIONNAIRE_START'
    })
  }
}

export const updateQuestionnaireItem = (data, accessToken, target) => {
  return (dispatch) => {
    dispatch({
      type: 'UPDATE_QUESTIONNAIRE_ITEM_PENDING'
    })
    return axios({
      data,
      method: 'put',
      headers: {'Authorization': `Bearer ${accessToken}`},
      url: `${baseAPI}/questionnaires/${data.questionnaire_id}/items/${data.id}`
    }).then((x) => {
      dispatch({
        type: 'UPDATE_QUESTIONNAIRE_ITEM_FULFILLED',
        payload: x.data
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Questionnaire Item with the id ${x.data.id} updated`
      }))
      if (target) {
        dispatch(push(target))
      }
    }).catch((e) => {
      dispatch({
        type: 'UPDATE_QUESTIONNAIRE_ITEM_REJECTED',
        payload: e
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'error',
        message: `Update error`
      }))
    })
  }
}

export const deleteQuestionnaireItem = (id, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'DELETE_QUESTIONNAIRE_ITEM_PENDING'
    })
    return axios({
      method: 'delete',
      headers: {'Authorization': `Bearer ${accessToken}`},
      url: `${baseAPI}/questionnaire-items/${id}`
    }).then((x) => {
      dispatch({
        type: 'DELETE_QUESTIONNAIRE_ITEM_FULFILLED',
        payload: x
      })
      dispatch(push('/questionnaires'))
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Questionnaire ${id} deleted`
      }))
    }).catch((e) => {
      dispatch({
        type: 'DELETE_QUESTIONNAIRE_ITEM_REJECTED',
        payload: e
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'error',
        message: `delete error`
      }))
    })
  }
}
