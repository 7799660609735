import React from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { reset } from 'redux-form'
import moment from 'moment'

import Pagination from '../../components/common/Pagination'
import EventTable from '../../components/Events/EventTable'
import EventFilter from '../../components/Events/EventFilter'
import { EventCreateForm as EventForm } from '../../components/Events/EventForm'

import { createEvent, fetchEvents } from '../../actions/events'
import { asQueryObject, asQueryString } from '../../const/helper'

function mapStateToProps (state) {
  return {
    events: state.events.eventList.data,
    pagination: state.events.eventList.pagination,
    accessToken: state.auth.user.access.access_token
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchEvents: (params, key) => dispatch(fetchEvents(params, key)),
    createEvent: (data, key) => dispatch(createEvent(data, key)),
    push: to => dispatch(push(to)),
    reset: form => dispatch(reset(form))
  }
}

class EventList extends React.Component {
  componentWillReceiveProps (nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.props.fetchEvents(nextProps.location.search, this.props.accessToken)
    }
  }

  componentWillMount () {
    this.props.fetchEvents(this.props.location.search, this.props.accessToken)
  }

  handleEvent (e) {
    return this.props.createEvent(e, this.props.accessToken).then(data => {
      this.props.reset('eventCreate')
    })
  }

  handleFilter (filter) {
    delete filter.page
    return this.props.push(`/events?${asQueryString(filter)}`)
  }

  render () {
    const { events, pagination, location } = this.props

    return (
      <div className='content'>
        <div className='main-header'>
          <h2>Events </h2>
          <em className='hide'>normal sidebar with minified mode by default</em>
        </div>
        <div className='main-content'>
          <div className='row'>
            <div className='col-md-8'>
              { events
                ? <div>
                  <EventFilter
                    onSubmit={this.handleFilter.bind(this)}
                    initialValues={asQueryObject(location.search)}
                  />
                  <EventTable events={events} />
                </div>
                : <div>loading</div>
              }
              <Pagination pagination={pagination} base='/events' params={asQueryObject(location.search)} />
            </div>
            <div className='col-md-4'>
              <div className='widget'>
                <div className='widget-header'>
                  <h3><i className='fa fa-book' /> Create Events</h3>
                </div>
                <div className='widget-content'>
                  <EventForm
                    onSubmit={this.handleEvent.bind(this)}
                    initialValues={{
                      date: moment().format('YYYY-MM-DD')
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventList)
