import React from 'react'
import { Link } from 'react-router-dom'

export default class EventTable extends React.Component {
  render () {
    const { events } = this.props
    return (
      <div className='table-responsive'>
        <table className='table'>
          <thead>
            <tr>
              <th>#</th>
              <th>Event Name</th>
              <th>Date</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {events.map((event, id) => (
              <tr key={id}>
                <td><Link to={`/events/${event.id}`}>{event.id}</Link></td>
                <td><Link to={`/events/${event.id}`}>{event.name}</Link></td>
                <td>{event.date}</td>
                <td>{event.detail && event.detail.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}
