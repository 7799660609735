import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import Inside from './Inside'
import DaList from './Das/DaList'
import DaUpdate from './Das/DaUpdate'

export class Das extends React.Component {
  render () {
    return (
      <Inside>
        <Switch>
          <Route path='/daily-allowances/:id' component={DaUpdate} />
          <Route exact path='/daily-allowances' component={DaList} />
        </Switch>
      </Inside>
    )
  }
}

function mapStateToProps (state) {
  return {
  }
}

export default connect(
  mapStateToProps
// Implement map dispatch to props
)(Das)
