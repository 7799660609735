import axios from 'axios'
import { push } from 'react-router-redux'
import { createFlash } from './flasher'

const baseAPI = process.env.REACT_APP_BASE_API

export const fetchQuotations = (params = '', accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_QUOTATIONS_PENDING'
    })
    return axios({
      method: 'get',
      headers: {'Authorization': `Bearer ${accessToken}`},
      url: `${baseAPI}/quotations${params}`
    }).then((x) => {
      dispatch({
        type: 'FETCH_QUOTATIONS_FULFILLED',
        payload: {
          data: x.data,
          pagination: {
            currentPage: x.headers['x-pagination-current-page'],
            pageCount: x.headers['x-pagination-page-count'],
            perPage: x.headers['x-pagination-per-page'],
            totalCount: x.headers['x-pagination-total-count']
          }
        }
      })
    }).catch((e) => {
      dispatch({
        type: 'FETCH_QUOTATIONS_REJECTED',
        payload: e
      })
    })
  }
}

export const fetchQuotationSchedules = (params = '', accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_QUOTATION_SCHEDULES_PENDING'
    })
    return axios({
      method: 'get',
      headers: {'Authorization': `Bearer ${accessToken}`},
      url: `${baseAPI}/quotation-schedules${params}`
    }).then((x) => {
      dispatch({
        type: 'FETCH_QUOTATION_SCHEDULES_FULFILLED',
        payload: {
          data: x.data,
          pagination: {
            currentPage: x.headers['x-pagination-current-page'],
            pageCount: x.headers['x-pagination-page-count'],
            perPage: x.headers['x-pagination-per-page'],
            totalCount: x.headers['x-pagination-total-count']
          }
        }
      })
    }).catch((e) => {
      dispatch({
        type: 'FETCH_QUOTATION_SCHEDULES_REJECTED',
        payload: e
      })
    })
  }
}

export const fetchQuotation = (id, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_QUOTATION_PENDING'
    })
    return axios({
      method: 'get',
      headers: {'Authorization': `Bearer ${accessToken}`},
      url: `${baseAPI}/quotations/${id}?expand=questionnaire,certification,log, detail`
    }).then((x) => {
      dispatch({
        type: 'FETCH_QUOTATION_FULFILLED',
        payload: x.data
      })
    }).catch((e) => {
      console.error(e)
      dispatch({
        type: 'FETCH_QUOTATION_REJECTED',
        payload: e
      })
    })
  }
}

export const createQuotation = (data, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'CREATE_QUOTATION_PENDING'
    })
    return axios({
      data,
      method: 'post',
      headers: {'Authorization': `Bearer ${accessToken}`},
      url: `${baseAPI}/quotations`
    }).then((x) => {
      dispatch({
        type: 'CREATE_QUOTATION_FULFILLED',
        payload: x.data
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Quotation created with the id ${x.data.id}`
      }))
      dispatch(push(`/quotations/${x.data.id}`))
    }).catch((e) => {
      dispatch({
        type: 'CREATE_QUOTATION_REJECTED',
        payload: e
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'error',
        message: `Update error`
      }))
    })
  }
}

export const updateQuotation = (data, accessToken, target) => {
  return (dispatch) => {
    dispatch({
      type: 'UPDATE_QUOTATION_PENDING'
    })
    return axios({
      data,
      method: 'put',
      headers: {'Authorization': `Bearer ${accessToken}`},
      url: `${baseAPI}/quotations/${data.id}?expand=questionnaire,certification,log, detail`
    }).then((x) => {
      dispatch({
        type: 'UPDATE_QUOTATION_FULFILLED',
        payload: x.data
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Quotation with the id ${x.data.id} updated`
      }))
      if (target) {
        dispatch(push(target))
      }
    }).catch((e) => {
      dispatch({
        type: 'UPDATE_QUOTATION_REJECTED',
        payload: e
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'error',
        message: `Update error`
      }))
    })
  }
}
