import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

export default class OrderNcTable extends React.Component {
  getLabel (status) {
    let label
    switch (status) {
      case 'Draft':
        label = 'warning'
        break
      case 'Open':
        label = 'info'
        break
      case 'Pending':
        label = 'primary'
        break
      case 'Closed':
        label = 'success'
        break
      default:
        // statements_def
        break
    }
    return (
      <span className={`label label-${label}`}>{status.toUpperCase()}</span>
    )
  }

  render () {
    const { order, ncs } = this.props
    return (
      <div>
        <div className='table-responsive'>
          <table className='table colored-header datatable project-list'>
            <thead>
              <tr>
                <th>NC No</th>
                <th>Clausul</th>
                <th>Due Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              { ncs.map((x, y) => {
                return (
                  <tr key={y}>
                    <td><Link to={`/orders/${order.id}/ncs/${x.id}`}>{x.id}</Link></td>
                    <td>{x.description}</td>
                    <td>{x.due_date && moment.unix(x.due_date).format('D MMMM YYYY')}</td>
                    <td>{this.getLabel(x.status)}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}
