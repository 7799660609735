import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

import { TextField, AnnexOptionsField, AnnexTextareaField, AnnexMultiCheckboxField } from '../common/FormFields'
import AnnexFileField from '../common/AnnexFileField'
import ConfirmButton from '../common/ConfirmButton'

export class QuestionnaireAnnexForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openTab: 0
    }
  }

  changeTab (target, ev) {
    ev.preventDefault()
    this.setState({
      openTab: target
    })
  }

  render () {
    const { handleSubmit, submitting, questionnaire } = this.props

    const scopes = questionnaire.scopes.reduce((sum, x) => {
      if (sum.filter(y => y.standard.id === x.standard.id).length === 0) {
        sum.push(x)
      }
      return sum
    }, [])
    let perSite = []

    return (
      <form className='form-horizontal questionnaire-annex-form' onSubmit={handleSubmit}>
        {scopes.length > 1 &&
          <div>
            <ul className='nav nav-tabs nav-tabs-custom-colored tabs-iconized'>
              {scopes.map((x, i) => (
                <li key={i} className={this.state.openTab === i ? 'active' : ''} >
                  <a href='/' onClick={this.changeTab.bind(this, i)} >{x.standard.name}</a>
                </li>
              ))}
            </ul>
            <br />
          </div>
        }

        <Field hidden name='advance' component={TextField} label='Yes please' />

        <div className='tab-content profile-page'>
          { scopes.map((x, i) => {
            // perSite = []
            return (
              <div key={i} className={this.state.openTab === i ? 'tab-pane profile active' : 'tab-pane profile'}>
                <fieldset key={i}>
                  <legend>Annex for <strong>{x.standard.name}</strong></legend>
                  {!x.standard.annex &&
                    <p>Annex not available. Please continue.</p>
                  }
                  {x.standard.annex && x.standard.annex.map((y, j) => {
                    // if (!y.per_site) {
                    return (
                      <div key={y.name}>
                        { y.type.toLowerCase() === 'textarea' &&
                          <Field name={`scopes[${i}].detail.annex[${j}].answer`} component={AnnexTextareaField} label={y.name} help={y.description} labelAlt={y.nameAlt} helpAlt={y.descriptionAlt} />
                        }
                        { y.type.toLowerCase() === 'select' &&
                          <Field name={`scopes[${i}].detail.annex[${j}].answer`} type='select' component={AnnexOptionsField} label={y.name} options={y.options.split(';')} help={y.description} />
                        }
                        { y.type.toLowerCase() === 'boolean' &&
                          <Field name={`scopes[${i}].detail.annex[${j}].answer`} type='select' component={AnnexOptionsField} label={y.name} options={['-', 'Yes', 'No']} help={y.description} />
                        }
                        { y.type.toLowerCase() === 'checkbox' &&
                          <Field name={`scopes[${i}].detail.annex[${j}].answer`} component={AnnexMultiCheckboxField} label={y.name} options={y.options.split(';')} help={y.description} labelAlt={y.nameAlt} helpAlt={y.descriptionAlt} />
                        }
                        { y.file &&
                          <Field name={`scopes[${i}].detail.annex[${j}].file`} component={AnnexFileField} api={this.props.fileAPI} label='Attachment' />
                        }
                        { y.explanation &&
                          <Field name={`scopes[${i}].detail.annex[${j}].explanation`} component={AnnexTextareaField} api={this.props.fileAPI} label='Explanation' />
                        }
                        <hr />
                      </div>
                    )
                    // } else {
                    //   questionnaire.locations.map((z, k) => {
                    //     // return (
                    //     //   <div key={y.name}>
                    //     //     { y.type === 'textarea' &&
                    //     //       <Field name={`scopes[${i}].detail.annex[${j}].answer[${k}]`} component={TextareaField} label={`${y.name} sitex ${z.name}`} help={y.description} />
                    //     //     }
                    //     //     { y.type === 'select' &&
                    //     //       <Field name={`scopes[${i}].detail.annex[${j}].answer[${k}]`} type='select' component={OptionsField} label={`${y.name} sitex ${z.name}`} options={y.options.split(';')} help={y.description} />
                    //     //     }
                    //     //     { y.type === 'boolean' &&
                    //     //       <Field name={`scopes[${i}].detail.annex[${j}].answer[${k}]`} type='select' component={OptionsField} label={`${y.name} sitex ${z.name}`} options={['Yes', 'No']} help={y.description} />
                    //     //     }
                    //     //     { y.type === 'checkbox' &&
                    //     //       <Field name={`scopes[${i}].detail.annex[${j}].answer[${k}]`} type='multiCheckbox' component={OptionsField} label={`${y.name} sitex ${z.name}`} options={y.options.split(';')} help={y.description} />
                    //     //     }
                    //     //     { y.file &&
                    //     //       <Field name={`scopes[${i}].detail.annex[${j}].file[${k}]`} component={FileField} api={this.props.fileAPI} label='Attacment'/>
                    //     //     }
                    //     //     { y.explanation &&
                    //     //       <Field name={`scopes[${i}].detail.annex[${j}].explanation[${k}]`} component={TextareaField} api={this.props.fileAPI} label='Explanation'/>
                    //     //     }
                    //     //   </div>
                    //     // )
                    //   })
                    // }
                  })}
                  { perSite.length > 0 &&
                    questionnaire.locations.map((z, k) => {
                      return null
                      // return(
                      //   <div key={k}>
                      //     <legend>Annex for <strong>{x.standard.name}</strong>, site <strong>{z.location.name}</strong></legend>
                      //     {perSite.map((a, b) => {
                      //       return (
                      //         <div key={a.name}>
                      //           { a.type === 'textarea' &&
                      //             <Field name={`scopes[${i}].detail.annex[${b}][${k}].answer`} component={TextareaField} label={a.name} help={a.description} />
                      //           }
                      //           { a.type === 'select' &&
                      //             <Field name={`scopes[${i}].detail.annex[${b}][${k}].answer`} type='select' component={OptionsField} label={a.name} options={a.options.split(';')} help={a.description} />
                      //           }
                      //           { a.type === 'boolean' &&
                      //             <Field name={`scopes[${i}].detail.annex[${b}][${k}].answer`} type='select' component={OptionsField} label={a.name} options={['Yes', 'No']} help={a.description} />
                      //           }
                      //           { a.type === 'checkbox' &&
                      //             <Field name={`scopes[${i}].detail.annex[${b}][${k}].answer`} type='multiCheckbox' component={OptionsField} label={a.name} options={a.options.split(';')} help={a.description} />
                      //           }
                      //           { a.file &&
                      //             <Field name={`scopes[${i}].detail.annex[${b}][${k}].file`} component={FileField} api={this.props.fileAPI} label='Attacment'/>
                      //           }
                      //           { a.explanation &&
                      //             <Field name={`scopes[${i}].detail.annex[${b}][${k}].explanation`} component={TextareaField} api={this.props.fileAPI} label='Explanation'/>
                      //           }
                      //         </div>
                      //       )
                      //     })}
                      //   </div>
                      // )
                    })
                  }
                  { this.state.openTab === scopes.length - 1 &&
                    <div className='form-group'>
                      <div className='col-sm-offset-3 col-sm-6'>
                        <button disabled={submitting} type='submit' className='btn btn-default btn-block btn-submit' onClick={ev => {
                          ev.preventDefault()
                          this.props.changeForm('advance', false)
                          setTimeout(handleSubmit, 100)
                        }} >Save Draft</button>
                      </div>
                      <div className='col-sm-3'>
                        <ConfirmButton
                          disabled={submitting}
                          text={'Save Annex'}
                        >
                          <button disabled={submitting} type='submit' className='btn-advance btn btn-primary btn-block btn-submit' onClick={ev => {
                            ev.preventDefault()
                            this.props.changeForm('advance', true)
                            setTimeout(handleSubmit, 100)
                          }} >Continue</button>
                        </ConfirmButton>
                      </div>
                    </div>
                  }
                  { this.state.openTab !== scopes.length - 1 &&
                    <div>
                      <button onClick={this.changeTab.bind(this, (i + 1))} className='btn btn-primary btn-block' type='button'>Next Page</button>
                    </div>
                  }
                </fieldset>
              </div>
            )
          })}
        </div>
      </form>
    )
  }
}

QuestionnaireAnnexForm = reduxForm({ // eslint-disable-line 
  form: 'QuestionnaireAnnexForm',
  enableReinitialize: true
})(QuestionnaireAnnexForm)

export default QuestionnaireAnnexForm
