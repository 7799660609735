export function loadState () {
  try {
    const serializedState = window.localStorage.getItem('certState')
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

export function saveState (state) {
  try {
    const serializedState = JSON.stringify(state)
    window.localStorage.setItem('certState', serializedState)
  } catch (e) {
    console.error(e)
  }
}
