const businessType = [
  'AGRICULTURE, FORESTRY and FISHERY',
  'MINING and EXCAVATION',
  'PROCESSING INDUSTRY',
  'PROCUREMENT OF ELECTRICITY, GAS, VAPOR/HOT WATER, and COLD AIR',
  'CONSTRUCTION',
  'RETAIL and BIG TRADING, OTOMOTIVE REPARATION & MAINTENANCE',
  'FREIGHT and WAREHOUSING',
  'FOOD, BEVERAGE & ACCOMODATION SUPPLIER',
  'INFORMATION and COMMUNICATION',
  'REAL ESTATE',
  'PROFESSIONAL, SCIENTIFIC and TECHNICAL ACTIVITIES',
  'RENTAL, LABOUR SUPPLIER, TRAVEL AGENT and OTHER BUSINESS SUPPORTS',
  'GOVERNMENT ADMINISTRATION, DEFENSE, SOCIAL GUARANTEE',
  'EDUCATION',
  'HUMAN HEALTH & SOCIAL ACTIVITIES',
  'ART, ENTERTAINMENT and RECREATION',
  'HOME INDUSTRY ',
  'INTERNATIONAL AGENCY',
  'OTHER SERVICES '
]

export default businessType
