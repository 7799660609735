import React from 'react'
import { Line } from 'react-chartjs-2'

const data = {
  // labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  datasets: [
    {
      label: 'Executed',
      fill: true,
      lineTension: 0.4,
      backgroundColor: 'rgba(158,155,242,0.4)',
      borderColor: 'rgba(158,155,242,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(158,100,242,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 10,
      // data: [60, 29, 90, 85, 77, 65, 86]
    },
    {
      label: 'Capacity',
      fill: false,
      lineTension: 0.1,
      borderColor: 'red',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'red',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 0.2,
      pointHoverRadius: 1,
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 1,
      pointRadius: 1,
      pointHitRadius: 10,
      // data: [100, 100, 100, 100, 100, 100, 100]
    }
  ]
}

const options = {
  legend: {
    display: true,
    position: 'bottom',
    align: 'start',
    fullWidth: true
  }
}

export default class ChartsMandays extends React.Component {
  render () {
    data.labels = this.props.data.map(x => x.month)
    data.datasets[0].data = this.props.data.map(x => x.executed)
    data.datasets[1].data = this.props.data.map(x => x.capacity)
    return (
      <div className='new-card'>
        <div className='new-card-header'>
          {
            // <div className='action'>
            //   <div className='btn-group'>
            //     <button type='button' className='btn btn-default btn-xs rounded-1 dropdown-toggle' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
            //                       Action <span className='caret' />
            //     </button>
            //     <ul className='dropdown-menu'>
            //       <li><a href='/'>This month</a></li>
            //       <li><a href='/'>This year</a></li>
            //     </ul>
            //   </div>
            // </div>
          }
          <h4>Mandays Quantity</h4>
        </div>
        <div className='new-card-body'>
          <div>
            <Line
              data={data}
              options={options}
              height={100}
            />
          </div>
        </div>
      </div>
    )
  }
}
