import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import Inside from './Inside'
import NonComplyList from './NonComplies/NonComplyList'
// import NonComplyUpdate from './NonComplies/NonComplyUpdate'

function mapStateToProps (state) {
  return {

  }
}

export class NonComplies extends React.Component {
  render () {
    return (
      <Inside>
        <Switch>
          {
          // <Route path='/ncs/:id' component={NonComplyUpdate} />
          }
          <Route exact path='/ncs' component={NonComplyList} />
        </Switch>
      </Inside>
    )
  }
}

export default connect(
  mapStateToProps
// Implement map dispatch to props
)(NonComplies)
