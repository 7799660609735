import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Inside from './Inside'
import QuotationList from './Quotations/QuotationList'
import QuotationView from './Quotations/QuotationView'
import QuotationNew from './Quotations/QuotationNew'

export default function Quotations (props) {
  return (
    <Inside>
      <Switch>
        <Route path='/quotations/new/:questionnaire_id' component={QuotationNew} />
        <Route path='/quotations/:id/:stage?' component={QuotationView} />
        <Route exact path='/quotations' component={QuotationList} />
      </Switch>
    </Inside>
  )
}
