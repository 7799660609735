import React from 'react'

export default class DeleteForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      confirmed: false
    }
  }

  confirm () {
    this.setState({
      confirmed: true
    })
  }

  delete () {
    this.props.onClick()
  }
  render () {
    return (
      <div>
        <div className='form-horizontal' >
          <div className='form-group'>
            <div className='col-sm-offset-3 col-sm-9'>
              { this.state.confirmed === false &&
                <button onClick={this.confirm.bind(this)} className='btn btn-xs btn-link btn-block'>Delete</button>
              }
              { this.state.confirmed === true &&
                <button onClick={this.delete.bind(this)} className='btn btn-xs btn-danger btn-block'>Are You Sure</button>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
