export const initialState = {
  certificateList: { data: null, pagination: null, error: null, loading: false },
  globalCertificateList: { data: null, pagination: null, error: null, loading: false },
  certificate: { data: null, error: null, loading: false }
}
export default (state = initialState, action) => {
  switch (action.type) {
    case 'CREATE_CERTIFICATE_PENDING' :
      return { ...state, certificate: { data: null, error: null, loading: true } }
    case 'CREATE_CERTIFICATE_REJECTED':
      return { ...state, certificate: { data: null, error: action.payload, loading: false } }
    case 'CREATE_CERTIFICATE_FULFILLED' :
      return { ...state, certificate: { data: { ...action.payload }, error: null, loading: false } }

    case 'FETCH_CERTIFICATES_PENDING':
      return { ...state, certificateList: { data: null, error: null, loading: true } }
    case 'FETCH_CERTIFICATES_REJECTED':
      return { ...state, certificateList: { data: null, error: action.payload, loading: false } }
    case 'FETCH_CERTIFICATES_FULFILLED':
      return { ...state,
        certificateList: {
          data: action.payload.data,
          pagination: action.payload.pagination,
          error: null,
          loading: false
        }
      }

    case 'FETCH_GLOBAL_CERTIFICATES_PENDING':
      return { ...state, globalCertificateList: { data: null, error: null, loading: true } }
    case 'FETCH_GLOBAL_CERTIFICATES_REJECTED':
      return { ...state, globalCertificateList: { data: null, error: action.payload, loading: false } }
    case 'FETCH_GLOBAL_CERTIFICATES_FULFILLED':
      return { ...state,
        globalCertificateList: {
          data: action.payload.data,
          pagination: action.payload.pagination,
          error: null,
          loading: false
        }
      }

    case 'FETCH_CERTIFICATE_PENDING' :
      return { ...state, certificate: { data: null, error: null, loading: true } }
    case 'FETCH_CERTIFICATE_REJECTED':
      return { ...state, certificate: { data: null, error: action.payload, loading: false } }
    case 'FETCH_CERTIFICATE_FULFILLED' :
      return { ...state, certificate: { data: { ...action.payload }, error: null, loading: false } }

    case 'UPDATE_CERTIFICATE_PENDING' :
      return { ...state, certificate: { ...state.certificate, error: null, loading: true } }
    case 'UPDATE_CERTIFICATE_REJECTED':
      return { ...state, certificate: { ...state.certificate, error: action.payload, loading: false } }
    case 'UPDATE_CERTIFICATE_FULFILLED' :
      return { ...state, certificate: { data: { ...action.payload }, error: null, loading: false } }

    case 'DELETE_CERTIFICATE_PENDING' :
      return { ...state, certificate: { ...state.certificate, error: null, loading: true } }
    case 'DELETE_CERTIFICATE_REJECTED':
      return { ...state, certificate: { ...state.certificate, error: action.payload, loading: false } }
    case 'DELETE_CERTIFICATE_FULFILLED' :
      return { ...state, certificate: { data: null, error: null, loading: false } }

    case 'LOGOUT_FULFILLED':
      return { ...initialState }

    default:
      return state
  }
}
