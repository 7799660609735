import React, { useState } from 'react'

import Required from './Required'

export default function SelectOrInput (props) {
  const { options, input, label, required, placeholder, help, meta, disabled } = props
  let defaultSelect = true
  let defaultValue = input.value || options[0].value

  if (input.value && (options.indexOf(input.value) < 0)) {
    defaultSelect = false
    defaultValue = input.value
  }

  const [isSelect, setSelect] = useState(defaultSelect)
  const [value, setValue] = useState(defaultValue)

  return (
    <div className={`form-group ${input.name.split('.').join('_')}`}>
      <label htmlFor={label} className='col-sm-3 control-label'>
        {label}
        {required && <Required />}
      </label>
      <div className='col-sm-9'>
        { isSelect
          ? <select disabled={disabled} {...input} className='form-control' value={value} onChange={ev => {
            setValue(ev.target.value === 'other' ? null : ev.target.value)
            if (ev.target.value === 'other') {
              setSelect(false)
            }
            input.onChange(ev.target.value === 'other' ? null : ev.target.value)
          }} >
            { options.map(x => {
              let option
              if (typeof x === 'object') {
                option = <option value={x.value} key={x.value} >{x.label}</option>
              } else {
                option = <option value={x} key={x} >{x}</option>
              }
              return option
            })}
            <option value='other'>Other</option>
          </select>
          : <div>
            <div className='input-group'>
              <input disabled={disabled} {...input} type='text' className='form-control' id={label} placeholder={placeholder || label} />
              <span className='input-group-btn'>
                <button onClick={() => {
                  setSelect(true)
                  input.onChange(options[0].value)
                }}className='btn btn-default' type='button'><i className='fa fa-bars' /></button>
              </span>
            </div>
          </div>
        }
        { help && <p className='help-text'>{help}</p> }
        { meta.touched && ((meta.error && <div className='alert alert-danger'>{meta.error}</div>)) }
      </div>
    </div>
  )
}
