import React from 'react'
import Dropzone from 'react-dropzone'
import axios from 'axios'

import Required from './Required'

async function uploadFile (files, api, accessToken) {
  const url = `${api}/files`
  let data = new window.FormData()
  for (var i = 0; i < files.length; i++) {
    let file = files[i]
    data.append('files[]', file, file.name)
  }
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
      'Authorization': `Bearer ${accessToken}`
    }
  }
  const result = await axios.post(url, data, config)
  return result.data
}

async function removeFile (id, api, accessToken) {
  const url = `${api}/files/${id}`
  const config = {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  }
  const result = await axios.delete(url, config)
  return result
}

export default function FileField ({api, input, label, required, meta: {touched, error, warning}}) {
  let files = input.value
  if (typeof files === 'string' && files !== '') {
    files = JSON.parse(files)
  }
  return (
    <div className={`form-group ${input.name.split('.').join('_')}`}>
      <label htmlFor={label} className='col-sm-12 control-label'>{label} {required && <Required />}</label>
      <div className='col-sm-12'>
        <Dropzone
          name={label}
          style={{marginBottom: 0}}
          className='well'
          onDrop={(filesToUpload, e) => {
            uploadFile(filesToUpload, api.url, api.accessToken)
              .then(r => {
                input.onChange(r)
              })
              .catch((err) => {
                console.error(err)
              })
          }}
        >
          <div>Try dropping some files here, or click to select files to upload.</div>
        </Dropzone>
        {touched && ((error && <div className='alert alert-danger'>{error}</div>) || (warning && <div className='alert alert-warning'>{warning}</div>))}

        { files &&
          files.map(x => (
            <div key={x.id}>
              <span>{x.detail.name}</span>{' '}
              <a href={`${api.url}/download/${x.id}?token=${api.accessToken}`} className='btn btn-link btn-xs'><i className='fa fa-download' /></a>
              <button
                className='btn btn-link btn-xs'
                type='button'
                onClick={(ev) => {
                  removeFile(x.id, api.url, api.accessToken)
                    .then(r => {
                      input.onChange(files.filter(y => y.id !== x.id))
                    })
                    .catch((err) => {
                      console.error(err)
                    })
                }}><i className='fa fa-close' /></button>
            </div>
          ))
        }
      </div>
    </div>
  )
}
