function makeTrainingAction (props) {
  const actions = [
    {
      status: 'Created',
      todo: 'Create Audit Schedule',
      primary: {
        role: ['Super Admin', 'Scheduler'],
        text: 'Create Audit Schedule',
        action: props.onManageSchedule,
        alternative: 'Please wait for Audit Schedule Draft by Scheduler'
      }
    },
    {
      status: 'Schedule Draft',
      todo: 'Publish Audit Schedule',
      primary: {
        role: ['Super Admin', 'Scheduler'],
        text: 'Open Audit Schedule Draft',
        action: props.onManageSchedule
      }
    },
    {
      status: 'Schedule Published',
      todo: 'Review Audit Schedule',
      primary: {
        role: ['Super Admin', 'Head of CB'],
        text: 'Open Audit Schedule',
        action: props.onReviewSchedule,
        alternative: 'Please wait for Audit Schedule Review by Customer'
      }
    },
    {
      status: 'Schedule Approved by HCB',
      todo: 'Review Audit Plan by Customer',
      primary: {
        role: ['Customer', 'Super Admin'],
        text: 'Open Audit Schedule',
        action: props.onReviewSchedule,
        alternative: 'Please wait for Audit Schedule Review by Customer'
      }
    },
    {
      status: 'Schedule Approved by Customer',
      todo: 'Create Audit Plan',
      primary: {
        role: ['Audit Plan Operator', 'Super Admin'],
        text: 'Create Audit Plan',
        action: props.onCreatePlan,
        alternative: 'Please wait for Audit Plan Draft by Audit Plan Operator'
      }
    },
    {
      status: 'Plan Draft',
      todo: 'Publish Audit Plan',
      primary: {
        role: ['Audit Plan Operator', 'Super Admin'],
        text: 'Open Audit Plan',
        action: props.onUpdatePlan,
        alternative: 'Please wait for Published Audit Plan by Audit Plan Operator'
      }
    },
    {
      status: 'Plan Published',
      todo: 'Review Audit Plan',
      primary: {
        role: ['Super Admin'],
        users: props.userReviewPlan,
        text: 'Open Audit Plan',
        action: props.onUpdatePlan,
        alternative: 'Please wait for Audit Plan Review by Lead Auditor'
      }
    },
    {
      status: 'Plan Approved by Lead Auditor',
      todo: 'Waiting for Audit Day',
      primary: {
        pre: props.preAuditStart,
        role: ['Super Admin'],
        users: props.userAuditor,
        text: 'Start Audit',
        action: props.onAuditStart,
        alternative: 'Please recheck invoice payment status'
      }
    },
    {
      status: 'On Audit',
      todo: 'Audit in Progress',
      primary: {
        role: ['Super Admin'],
        users: props.userAuditor,
        text: 'End Audit',
        action: props.onAuditEnd,
        alternative: 'Please wait for End Audit'
      },
      secondary: [
        {
          role: ['Super Admin'],
          users: props.userAuditor,
          text: 'Create Non Conformity',
          action: props.onCreateNc
        }, {
          role: ['Super Admin'],
          users: props.userAuditor,
          text: 'Create Audit Report',
          action: props.onCreateReport
        }
      ]
    },
    {
      status: 'Audited',
      todo: 'Create Audit Report',
      primary: {
        role: ['Super Admin'],
        users: props.userAuditor,
        text: 'Create Audit Report',
        action: props.onCreateReport,
        alternative: 'Please wait for Audit Plan Review by Head of Certification Body'
      },
      secondary: [
        {
          role: ['Super Admin'],
          users: props.userAuditor,
          text: 'Create Non Conformity',
          action: props.onCreateNc
        }
      ]
    },
    {
      status: 'Report Draft',
      todo: 'Publish Audit Report',
      primary: {
        role: ['Super Admin'],
        text: 'Publish Audit Report',
        action: props.onUpdateReport,
        alternative: 'Please wait for Audit Plan Review by Head of Certification Body'
      }
    },
    {
      status: 'Report Published',
      todo: 'Assign Veto Person',
      primary: {
        pre: props.preAssignVeto,
        role: ['Super Admin'],
        text: 'Assign Veto Person',
        action: props.onToggleVeto,
        alternative: 'Please wait for NC closing and Veto Person Assignment by Super Admin'
      }
    }
  ]

  return actions
}

export default makeTrainingAction
