export const initialState = {
  changeList: { data: null, pagination: null, error: null, loading: false },
  globalChangeList: { data: null, pagination: null, error: null, loading: false },
  change: { data: null, error: null, loading: false }
}
export default (state = initialState, action) => {
  switch (action.type) {
    case 'CREATE_CHANGE_PENDING' :
      return { ...state, change: { data: null, error: null, loading: true } }
    case 'CREATE_CHANGE_REJECTED':
      return { ...state, change: { data: null, error: action.payload, loading: false } }
    case 'CREATE_CHANGE_FULFILLED' :
      return { ...state, change: { data: { ...action.payload }, error: null, loading: false } }

    case 'FETCH_CHANGES_PENDING':
      return { ...state, changeList: { data: null, error: null, loading: true } }
    case 'FETCH_CHANGES_REJECTED':
      return { ...state, changeList: { data: null, error: action.payload, loading: false } }
    case 'FETCH_CHANGES_FULFILLED':
      return { ...state,
        changeList: {
          data: action.payload.data,
          pagination: action.payload.pagination,
          error: null,
          loading: false
        }
      }

    case 'FETCH_GLOBAL_CHANGES_PENDING':
      return { ...state, globalChangeList: { data: null, error: null, loading: true } }
    case 'FETCH_GLOBAL_CHANGES_REJECTED':
      return { ...state, globalChangeList: { data: null, error: action.payload, loading: false } }
    case 'FETCH_GLOBAL_CHANGES_FULFILLED':
      return { ...state,
        globalChangeList: {
          data: action.payload.data,
          pagination: action.payload.pagination,
          error: null,
          loading: false
        }
      }

    case 'FETCH_CHANGE_PENDING' :
      return { ...state, change: { data: null, error: null, loading: true } }
    case 'FETCH_CHANGE_REJECTED':
      return { ...state, change: { data: null, error: action.payload, loading: false } }
    case 'FETCH_CHANGE_FULFILLED' :
      return { ...state, change: { data: { ...action.payload }, error: null, loading: false } }

    case 'UPDATE_CHANGE_PENDING' :
      return { ...state, change: { ...state.certificate, error: null, loading: true } }
    case 'UPDATE_CHANGE_REJECTED':
      return { ...state, certificate: { ...state.certificate, error: action.payload, loading: false } }
    case 'UPDATE_CHANGE_FULFILLED' :
      return { ...state, certificate: { data: { ...action.payload }, error: null, loading: false } }

    case 'DELETE_CHANGE_PENDING' :
      return { ...state, certificate: { ...state.certificate, error: null, loading: true } }
    case 'DELETE_CHANGE_REJECTED':
      return { ...state, certificate: { ...state.certificate, error: action.payload, loading: false } }
    case 'DELETE_CHANGE_FULFILLED' :
      return { ...state, certificate: { data: null, error: null, loading: false } }

    case 'LOGOUT_FULFILLED':
      return { ...initialState }

    default:
      return state
  }
}
