function makeCertificationAction (props) {
  // console.log(1000, props)
  const actions = [
    {
      status: 'Created',
      todo: 'Complete order prerequisite',
      primary: {
        role: ['Sales'],
        users: props.userCustomer,
        text: 'Complete order prerequisite',
        action: props.onCompleteQuestionnaire,
        alternative: 'Please wait for order prerequisite completion by customer'
      },
      secondary: [
        {
          role: ['Super Admin'],
          users: props.userAuditor,
          text: 'Change order',
          action: props.onChangeOrder
        }
      ]
    },
    {
      status: 'Questionnaire Completed',
      todo: 'Review order prerequisite',
      primary: {
        role: ['Application Reviewer Manager'],
        text: 'Review order prerequisite',
        action: props.onReviewQuestionnaire,
        alternative: 'Please wait for order prerequisite review by Application Reviewer Manager'
      }
    },
    {
      status: 'Questionnaire Reviewed',
      todo: 'Create audit schedule',
      primary: {
        role: ['Super Admin', 'Scheduler'],
        text: 'Create audit schedule',
        action: props.onManageSchedule,
        alternative: 'Please wait for audit schedule draft by scheduler'
      }
    },
    {
      status: 'Schedule Draft',
      todo: 'Publish audit schedule',
      primary: {
        role: ['Super Admin', 'Scheduler'],
        text: 'Open audit schedule draft',
        action: props.onManageSchedule
      }
    },
    {
      status: 'Schedule Published',
      todo: 'Review audit schedule',
      primary: {
        role: ['Super Admin', 'Head of CB'],
        text: 'Open audit schedule',
        action: props.onReviewSchedule,
        alternative: 'Please wait for audit schedule review by head of certification bureau'
      }
    },
    {
      status: 'Schedule Approved by HCB',
      todo: 'Review audit schedule by customer',
      primary: {
        users: props.userCustomer,
        role: ['Super Admin'],
        text: 'Open audit schedule',
        action: props.onReviewSchedule,
        alternative: 'Please wait for audit schedule review by customer'
      }
    },
    {
      status: 'Schedule Approved by Customer',
      todo: 'Create audit plan',
      primary: {
        role: ['Audit Plan Operator', 'Super Admin'],
        text: 'Create audit plan',
        action: props.onCreatePlan,
        alternative: 'Please wait for audit plan draft by audit plan operator'
      }
    },
    {
      status: 'Plan Draft',
      todo: 'Publish Audit Plan',
      primary: {
        role: ['Audit Plan Operator', 'Super Admin'],
        text: 'Open Audit Plan',
        action: props.onUpdatePlan,
        alternative: 'Please wait for Published Audit Plan by Audit Plan Operator'
      }
    },
    {
      status: 'Plan Published',
      todo: 'Review audit plan',
      primary: {
        role: ['Super Admin'],
        users: props.userLeadAuditor,
        text: 'Open audit plan',
        action: props.onUpdatePlan,
        alternative: 'Please wait for audit plan review by lead auditor'
      }
    },
    {
      status: 'Plan Approved by Lead Auditor',
      todo: 'Waiting for audit stage 1',
      primary: {
        pre: props.preAuditStart,
        role: ['Super Admin'],
        users: props.userLeadAuditor,
        text: 'Start audit stage 1',
        action: props.onAudit1Start,
        alternative: 'Please recheck invoice payment status and wait for audit start'
      },
      secondary: [
        {
          role: ['Super Admin'],
          users: props.userAuditor,
          text: 'Create non conformity',
          action: props.onCreateNc
        // }, {
        //   role: ['Super Admin'],
        //   users: props.userAuditor,
        //   text: 'Audit report',
        //   action: props.onCreateReport
        }
      ]
    },
    {
      status: 'Stage 1 - On Audit',
      todo: 'Audit Stage 1 in Progress',
      primary: {
        role: ['Super Admin'],
        users: props.userAuditor,
        text: 'Create non conformity',
        action: props.onCreateNc,
        alternative: 'Audit Stage 1 in Progress'
      },
      secondary: [
        {
          role: ['Super Admin'],
          users: props.userAuditor,
          text: 'Publish audit stage 1 report',
          action: props.onCreateReport
        }
      ]
    },
    {
      status: 'Stage 1 - Audited',
      todo: 'Create audit stage 1 report',
      primary: {
        role: ['Super Admin'],
        users: props.userAuditor,
        text: 'Publish audit stage 1 report',
        action: props.onCreateReport,
        alternative: 'Please wait for audit stage 1 report by Auditor'
      },
      secondary: [
        {
          role: ['Super Admin'],
          users: props.userAuditor,
          text: 'Create non conformity',
          action: props.onCreateNc
        }
      ]
    },
    {
      status: 'Stage 1 - Report Published',
      todo: 'Waiting for audit stage 2',
      primary: {
        role: ['Super Admin'],
        users: props.userAuditor,
        text: 'Start audit',
        action: props.onAudit2Start,
        alternative: 'Please wait for audit stage 2 start'
      }
    },
    {
      status: 'Amendment Quotation',
      todo: 'Complete Amendment Quotation',
      primary: {
        role: ['Super Admin'],
        // users: props.userAuditor,
        text: 'Confirm Quotation Amendment',
        action: props.onConfirmAmendment,
        alternative: 'Waiting for Amendment Quotation'
      }
    },
    {
      status: 'Reschedule',
      todo: 'Reschedule Stage 2 Audit ',
      primary: {
        role: ['Super Admin', 'Scheduler'],
        text: 'Update audit schedule',
        action: props.onManageSchedule,
        alternative: 'Please wait for audit reschedule draft by scheduler'
      }
    },
    {
      status: 'Reschedule - Schedule Draft',
      todo: 'Reschedule Stage 2 Audit ',
      primary: {
        role: ['Super Admin', 'Scheduler'],
        text: 'Update audit schedule',
        action: props.onManageSchedule,
        alternative: 'Please wait for audit reschedule draft by scheduler'
      }
    },
    {
      status: 'Reschedule - Schedule Published',
      todo: 'Review audit schedule',
      primary: {
        role: ['Super Admin', 'Head of CB'],
        text: 'Open audit schedule',
        action: props.onReviewSchedule,
        alternative: 'Please wait for audit reschedule review by head of certification bureau'
      }
    },
    {
      status: 'Reschedule - Schedule Approved by HCB',
      todo: 'Review audit schedule by customer',
      primary: {
        users: props.userCustomer,
        role: ['Super Admin'],
        text: 'Open audit schedule',
        action: props.onReviewSchedule,
        alternative: 'Please wait for audit schedule review by customer'
      }
    },
    {
      status: 'Reschedule - Schedule Approved by Customer',
      todo: 'Create audit plan for reschedule',
      primary: {
        role: ['Audit Plan Operator', 'Super Admin'],
        text: 'Create audit plan',
        action: props.onCreatePlan,
        alternative: 'Please wait for audit plan draft by audit plan operator'
      }
    },
    {
      status: 'Reschedule - Plan Draft',
      todo: 'Publish Audit Plan',
      primary: {
        role: ['Audit Plan Operator', 'Super Admin'],
        text: 'Open Audit Plan',
        action: props.onUpdatePlan,
        alternative: 'Please wait for Published Audit Plan by Audit Plan Operator'
      }
    },
    {
      status: 'Reschedule - Plan Published',
      todo: 'Review audit plan',
      primary: {
        role: ['Super Admin'],
        users: props.userReviewPlan,
        text: 'Open audit plan',
        action: props.onUpdatePlan,
        alternative: 'Please wait for audit plan review by lead auditor'
      }
    },
    {
      status: 'Reschedule - Plan Approved by Lead Auditor',
      todo: 'Waiting for audit stage 2',
      primary: {
        pre: props.preAuditStart,
        role: ['Super Admin'],
        users: props.userLeadAuditor,
        text: 'Start audit stage 2',
        action: props.onAudit2Start,
        alternative: 'Please recheck invoice payment status and wait for audit start'
      },
      secondary: [
        {
          role: ['Super Admin'],
          users: props.userAuditor,
          text: 'Create non conformity',
          action: props.onCreateNc
        // }, {
        //   role: ['Super Admin'],
        //   users: props.userAuditor,
        //   text: 'Audit report',
        //   action: props.onCreateReport
        }
      ]
    },
    {
      status: 'Follow up',
      todo: 'Reschedule Follow up Audit ',
      primary: {
        role: ['Super Admin', 'Scheduler'],
        text: 'Update audit schedule',
        action: props.onManageSchedule,
        alternative: 'Please wait for audit reschedule draft by scheduler'
      }
    },
    {
      status: 'Follow up - Schedule Draft',
      todo: 'Reschedule Follow up Audit ',
      primary: {
        role: ['Super Admin', 'Scheduler'],
        text: 'Update audit schedule',
        action: props.onManageSchedule,
        alternative: 'Please wait for audit reschedule draft by scheduler'
      }
    },
    {
      status: 'Follow up - Schedule Published',
      todo: 'Review audit schedule',
      primary: {
        role: ['Super Admin', 'Head of CB'],
        text: 'Open audit schedule',
        action: props.onReviewSchedule,
        alternative: 'Please wait for audit reschedule review by head of certification bureau'
      }
    },
    {
      status: 'Follow up - Schedule Approved by HCB',
      todo: 'Review audit schedule by customer',
      primary: {
        users: props.userCustomer,
        role: ['Super Admin'],
        text: 'Open audit schedule',
        action: props.onReviewSchedule,
        alternative: 'Please wait for audit schedule review by customer'
      }
    },
    {
      status: 'Follow up - Schedule Approved by Customer',
      todo: 'Create audit plan for follow up',
      primary: {
        role: ['Audit Plan Operator', 'Super Admin'],
        text: 'Create audit plan',
        action: props.onCreatePlan,
        alternative: 'Please wait for audit plan draft by audit plan operator'
      }
    },
    {
      status: 'Follow up - Plan Draft',
      todo: 'Publish Audit Plan',
      primary: {
        role: ['Audit Plan Operator', 'Super Admin'],
        text: 'Open Audit Plan',
        action: props.onUpdatePlan,
        alternative: 'Please wait for Published Audit Plan by Audit Plan Operator'
      }
    },
    {
      status: 'Follow up - Plan Published',
      todo: 'Review audit plan',
      primary: {
        role: ['Super Admin'],
        users: props.userReviewPlan,
        text: 'Open audit plan',
        action: props.onUpdatePlan,
        alternative: 'Please wait for audit plan review by lead auditor'
      }
    },
    {
      status: 'Follow up - Plan Approved by Lead Auditor',
      todo: 'Waiting for follow up audit',
      primary: {
        pre: props.preAuditStart,
        role: ['Super Admin'],
        users: props.userLeadAuditor,
        text: 'Start follow up audit',
        action: props.onAudit3Start,
        alternative: 'Please recheck invoice payment status and wait for audit start'
      },
      secondary: [
        {
          role: ['Super Admin'],
          users: props.userAuditor,
          text: 'Create non conformity',
          action: props.onCreateNc
        // }, {
        //   role: ['Super Admin'],
        //   users: props.userAuditor,
        //   text: 'Audit report',
        //   action: props.onCreateReport
        }
      ]
    },
    {
      status: 'Stage 2 - On Audit',
      todo: 'Audit stage 2 in progress',
      primary: {
        role: ['Super Admin'],
        users: props.userAuditor,
        text: 'Publish audit stage 2 report',
        action: props.onCreateReport,
        alternative: 'Please wait for Audit Stage 2 End'
      },
      secondary: [
        {
          role: ['Super Admin'],
          users: props.userAuditor,
          text: 'Create non conformity',
          action: props.onCreateNc
        }
      ]
    },
    {
      status: 'Stage 2 - Audited',
      todo: 'Create audit stage 2 report',
      primary: {
        role: ['Super Admin'],
        users: props.userAuditor,
        text: 'Publish audit stage 2 report',
        action: props.onCreateReport,
        alternative: 'Please wait for audit stage 2 report by Auditor'
      },
      secondary: [
        {
          role: ['Super Admin'],
          users: props.userAuditor,
          text: 'Create non conformity',
          action: props.onCreateNc
        }
      ]
    },
    {
      status: 'Follow up - On Audit',
      todo: 'Follow up audit in progress',
      primary: {
        role: ['Super Admin'],
        users: props.userAuditor,
        text: 'Publish follow up audit report',
        action: props.onCreateReport,
        alternative: 'Please wait for Follow up Audit End'
      },
      secondary: [
        {
          role: ['Super Admin'],
          users: props.userAuditor,
          text: 'Create non conformity',
          action: props.onCreateNc
        }
      ]
    },
    {
      status: 'Follow up - Audited',
      todo: 'Create follow up audit report',
      primary: {
        role: ['Super Admin'],
        users: props.userAuditor,
        text: 'Publish follow up audit report',
        action: props.onCreateReport,
        alternative: 'Please wait for follow up audit report by Auditor'
      },
      secondary: [
        {
          role: ['Super Admin'],
          users: props.userAuditor,
          text: 'Create non conformity',
          action: props.onCreateNc
        }
      ]
    },
    {
      status: 'Stage 2 - Report Published',
      todo: 'Assign Veto Person',
      primary: {
        pre: props.preAssignVeto,
        role: ['Super Admin'],
        text: 'Assign Veto Person',
        action: props.onToggleVeto,
        alternative: 'Please wait for NC closing and Veto Person Assignment by Super Admin'
      }
    },
    {
      status: 'Verification by Submmission of Document',
      todo: 'Confirm Submmission of Document',
      primary: {
        pre: props.preAssignVeto,
        role: ['Super Admin'],
        text: 'Confirm Submmission of Document',
        action: props.onToggleVerification,
        alternative: 'Please wait for document confirmation by Super Admin'
      }
    },
    {
      status: 'Veto Assigned',
      todo: 'Veto Review Audit Report',
      primary: {
        role: ['Super Admin'],
        users: props.userVeto,
        text: 'Veto Review Audit Report',
        action: props.onCreateReport,
        alternative: 'Please wait for Audit Report Review by Veto Person'
      }
    },
    {
      status: 'Veto Approval Pending',
      todo: 'Veto Review Audit Report',
      primary: {
        role: ['Super Admin'],
        users: props.userVeto,
        text: 'Veto Review Audit Report',
        action: props.onCreateReport,
        alternative: 'Please wait for Audit Report Review by Veto Person'
      }
    },
    {
      status: 'Approved by Veto',
      todo: 'Release Protocol',
      primary: {
        role: ['Super Admin'],
        text: 'Release Protocol',
        action: props.onUpdateReport,
        alternative: 'Please wait for Release Protocol by Super Admin'
      }
    },
    {
      status: 'Protocol Released',
      todo: 'Approval by Head of Certification Body',
      primary: {
        role: ['Super Admin', 'Head of CB'],
        text: 'Head of CB Approval',
        action: props.onHCBApproval,
        alternative: 'Please wait for Approval by Head of Certification Body'
      }
    },
    {
      status: 'HCB Approval Pending',
      todo: 'Approval by Head of Certification Body',
      primary: {
        role: ['Super Admin', 'Head of CB'],
        text: 'Head of CB Approval',
        action: props.onHCBApproval,
        alternative: 'Please wait for Approval by Head of Certification Body'
      }
    },
    {
      status: 'Approved by HCB',
      todo: 'Publish Certificate',
      primary: {
        pre: props.onEndCertification,
        role: ['Super Admin', 'Head of CB', 'Certificate Admin'],
        text: 'Publish Certificate',
        action: props.onReleaseCertificate,
        alternative: 'Please wait for Release Certificate or Invoice Payment'
      },
      secondary: [
        {
          role: ['Super Admin'],
          users: props.userAuditor,
          text: 'Create Certificate',
          action: props.onReleaseCertificate
        }
      ]
    }
  ]

  return actions
}

export default makeCertificationAction
