import React from 'react'
import moment from 'moment'

export default class ItemLog extends React.Component {
  render () {
    const now = moment()
    const { entity, log } = this.props
    return (
      <div className='widget'>
        <div className='widget-header'>
          <h3><i className='fa fa-fire' /> Log</h3>
        </div>
        <div className='widget-content'>
          <ul className='list-unstyled activity-list'>
            { log.sort((a, b) => b.created_at - a.created_at).map((item) => {
              const createdAt = moment.unix(item.created_at)
              const diff = now.diff(createdAt, 'days')
              const diffHours = now.diff(createdAt, 'hours') % 24
              const diffMinutes = now.diff(createdAt, 'minutes') % (24 * 60)

              const diffMinuteString = diffMinutes <= 0 ? 'just now' : diffMinutes === 1 ? '1 minute ago' : `${diffMinutes} minutes ago`
              const diffHourString = diffHours <= 0 ? diffMinuteString : diffHours === 1 ? '1 hour ago' : `${diffHours} hours ago`
              const diffString = diff <= 0 ? diffHourString : diff === 1 ? '1 day ago' : `${diff} days ago`
              const actionString = `${entity} set to ${item.action.replace('to:', '')} by ${item.user ? item.user.name : 'schedule'}`

              return (
                <li key={item.id}>
                  <i className='fa fa-check activity-icon pull-left icon-success' />
                  <p style={{ marginBottom: 10 }}>
                    <span>{actionString} - {createdAt.format('llll')}</span>
                    <span className='timestamp' title={createdAt.format('D MMMM YYYY')}>{diffString}</span>
                  </p>
                  { item.detail && (item.detail.remarks || item.detail.legal_document) &&
                    <div className='well'>
                      { item.detail.remarks &&
                        <p>{item.detail.remarks}</p>
                      }
                      { item.detail.legal_document &&
                        <div>
                          <hr />
                          <ul className='list-unstyled'>
                            { item.detail.legal_document.map((x, i) => (
                              <li key={i}> <span>{x.detail.name} -</span> <a href={`${process.env.REACT_APP_BASE_API}/download/${x.id}?token=${this.props.accessToken}`} className='btn btn-link btn-xs'><i className='fa fa-download' /> Download</a></li>
                            ))}
                          </ul>
                        </div>
                      }
                    </div>
                  }
                </li>
              )
            })}
          </ul>
          {
          // <button type='button' className='btn btn-clean center-block'><i className='fa fa-refresh' /> Load more</button>
          }
        </div>
      </div>
    )
  }
}
