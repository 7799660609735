import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import Inside from './Inside'
import AuditPlanList from './AuditPlans/AuditPlanList'

function mapStateToProps (state) {
  return {

  }
}

export class AuditPlans extends React.Component {
  render () {
    return (
      <Inside>
        <Switch>
          <Route exact path='/audit-plans' component={AuditPlanList} />
        </Switch>
      </Inside>
    )
  }
}

export default connect(
  mapStateToProps
// Implement map dispatch to props
)(AuditPlans)
