import React from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import Pagination from '../../components/common/Pagination'
import QuotationScheduleTable from '../../components/Quotations/QuotationScheduleTable'
import QuotationFilter from '../../components/Quotations/QuotationFilter'

import { fetchQuotationSchedules } from '../../actions/quotations'
import { asQueryObject, asQueryString } from '../../const/helper'

export class QuotationScheduleList extends React.Component {
  componentWillReceiveProps (nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      return this.props.fetchQuotationSchedules(nextProps.location.search, this.props.accessToken)
    }
  }

  componentWillMount () {
    return this.props.fetchQuotationSchedules(this.props.location.search, this.props.accessToken)
  }

  handleFilter (filter) {
    if (filter.standard === null) {
      delete filter.standard
    }
    if (filter.standard) {
      filter.standard = filter.standard.value.id
    }
    delete filter.page
    return this.props.push(`/quotations-schedule?${asQueryString(filter)}`)
  }

  render () {
    const { quotationSchedules, pagination, location } = this.props

    return (
      <div className='content'>
        <div className='main-header'>
          <h2>Surveillance List</h2>
          {/* <em>normal sidebar with minified mode by default</em> */}
        </div>
        <div className='main-content'>
          { quotationSchedules
            ? <div>
              <QuotationFilter
                onSubmit={this.handleFilter.bind(this)}
                initialValues={asQueryObject(location.search)}
                standardAPI={{
                  url: process.env.REACT_APP_BASE_API + '/standards',
                  accessToken: this.props.accessToken,
                  label: 'name'
                }}
              />
              <QuotationScheduleTable quotationSchedules={quotationSchedules} />
            </div>
            : <div>loading</div>
          }
          <Pagination pagination={pagination} base='/quotation-schedules' params={asQueryObject(location.search)} />
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    quotationSchedules: state.quotations.quotationScheduleList.data,
    pagination: state.quotations.quotationScheduleList.pagination,
    accessToken: state.auth.user.access.access_token
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  return {
    fetchQuotationSchedules: (params, key) => dispatch(fetchQuotationSchedules(params, key)),
    push: (to) => dispatch(push(to))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuotationScheduleList)
