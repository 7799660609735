import React from 'react'
import { Link } from 'react-router-dom'

export default class UserTable extends React.Component {
  render () {
    const { auditors, standards } = this.props
    console.log(auditors)
    return (
      <div className='table-responsive'>
        <table className='table'>
          <thead>
            <tr>
              <th>#</th>
              <th>Username</th>
              <th>Name</th>
              <th>Auditor Code</th>
              <th>Employment</th>
              <th>Email</th>
              {standards.map(x => <th key={x.id}>{x.name}</th>)}
            </tr>
          </thead>
          <tbody>
            {auditors.length === 0 &&
              <tr colSpan={5}>
                <td>User not found</td>
              </tr>
            }
            {auditors.map((user) => (
              <tr key={user.id}>
                <td><Link to={`/users/${user.id}`}>{user.id}</Link></td>
                <td><Link to={`/users/${user.id}`}>{user.username}</Link></td>
                <td><Link to={`/users/${user.id}`}>{user.data && user.data.display_name}</Link></td>
                <td>{user.data.auditor_code}</td>
                <td>{user.data.managed_by_admin && <span>external</span>}</td>
                <td>{user.email}</td>
                {standards.map(x => {
                  const specs = user.specifications.filter((xx) => xx.standard.id === x.id)
                  if (specs.length === 0) {
                    return <td key={x.id} className='text-center'> - </td>
                  }
                  return (
                    <td key={x.id} className='text-center'>
                      {
                        specs
                          .map((xx, ii) => xx.scope ? xx.scope.detail.number : '')
                          .join(',')
                        // specs.length > 0 ? `${specs.length} scopes`
                        //   : specs.length === 1 ? `Available`
                        //     : '-'
                      }
                    </td>
                  )
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}
