import React from 'react'

export default class ReviewWidget extends React.Component {
  render () {
    const q = this.props.questionnaire
    const r = q.review_detail

    if (!r) {
      return ''
    }

    return (
      <div className='widget'>
        <div className='widget-header'>
          <h3><i className='fa fa-tasks' /> Questionnaire Review Detail</h3>
        </div>
        <div className='widget-content'>
          <div className='row'>
            <div className='col-md-3 key-val'>
              <p>Company Detail</p>
              <p className='h4'>{r.company_detail_complete === 'Yes' ? 'Complete' : 'Not complete'}</p>
            </div>
            <div className='col-md-3 key-val'>
              <p>Number of sites</p>
              <p className='h4'>{r.number_of_site}</p>
            </div>
            <div className='col-md-6'>
              <p className=''>{r.company_detail_note || 'Notes not available'}</p>
            </div>
          </div>
          <hr />

          <div className='row'>
            <div className='col-md-6 key-val'>
              <p>Attachment Document</p>
              <p className='h4'>{r.document_complete === 'Yes' ? 'Complete' : 'Not complete'}</p>
            </div>
            <div className='col-md-6'>
              <p className=''>{r.document_note || 'Notes not available'}</p>
            </div>
          </div>
          <hr />

          <div className='row'>
            <div className='col-md-6 key-val'>
              <p>Management system implementation and application </p>
              <p className='h4'>{r.implementation_complete === 'Yes' ? 'Sufficient' : 'Not sufficient'}</p>
            </div>
            <div className='col-md-6'>
              <p className=''>{r.implementation_note || 'Notes not available'}</p>
            </div>
          </div>
          <hr />

          <div className='row'>
            <div className='col-md-6 key-val'>
              <p>Audit mandays for scope</p>
              <p className='h4'>{r.scope_mandays_appropriate === 'Yes' ? 'Appropriate' : 'Not appropriate'}</p>
            </div>
            <div className='col-md-6'>
              <p className=''>{r.scope_mandays_appropriate_note || 'Notes not available'}</p>
            </div>
          </div>
          <hr />

          <div className='row'>
            <div className='col-md-6 key-val'>
              <p>Competence personal</p>
              <p className='h4'>{r.competence_personal === 'Yes' ? 'Available' : 'Not available'}</p>
            </div>
            <div className='col-md-6'>
              <p className=''>{r.competence_personal_note || 'Notes not available'}</p>
            </div>
          </div>

        </div>
      </div>
    )
  }
}
