import React from 'react'
import NumberFormat from 'react-number-format'
import { mergeAuditCycle } from '../../selectors/quotations'

export default class QuotationDetail extends React.Component {
  render () {
    const q = this.props.quotation
    const questionnaire = this.props.questionnaire
    const { accessToken, onEditTemporary } = this.props
    const template = q.status === 'Temporary' ? 'quotation-temporary' : 'quotation-full'

    return (
      <div>
        <h1>Quotation Detail</h1>
        <hr />
        <div className='row'>
          <div className='col-md-4'>
            <div className='blue-bg text-white p-1'>
              <p>Quotation status</p>
              <p className='h5'><i className='fa fa-check-circle' /> {q.status}</p>
            </div>
          </div>
          <div className='col-md-4 key-val'>
            <div className='green-bg text-white p-1'>
              <p>Payment Schedule</p>
              <p className='h5'><i className='fa fa-credit-card' /> {q.detail && q.detail.payment_schedule.split('-').join(' ')}</p>
            </div>
          </div>
          <div className='col-md-4 mt-2'>
            <p><a href={`${process.env.REACT_APP_BASE_API}/pdf/quotation?template=${template}&id=${q.id}&token=${accessToken}`} className='btn btn-block btn-primary'><i className='fa fa-download' /> Download Full Quotation</a></p>
            {q.status === 'Temporary' &&
              <p>
                <button className='btn btn-default' onClick={onEditTemporary}>Edit temporary quotation</button>
              </p>
            }
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6 mt-2'>
            <p>Attachments : </p>

            { questionnaire.scopes.map((x, i) => {
              return (
                <div key={i}>
                  {x.standard.attachment && x.standard.attachment.map((xx, ii) => {
                    return (
                      <p key={ii}><a href={`${process.env.REACT_APP_BASE_API}/download/${xx.id}?token=${this.props.accessToken}`} className='btn btn-xs btn-success'><i className='fa fa-download' />{xx.detail.name}</a></p>
                    )
                  })}
                </div>
              )
            })}

            { questionnaire.detail.consultant === 'Yes'
              ? <p><a href={`${process.env.REACT_APP_BASE_API}/pdf/quotation?template=fmlf-b&id=${q.id}&token=${accessToken}`} className='btn btn-xs btn-success'><i className='fa fa-download' /> FMLF Contract B</a></p>
              : <p><a href={`${process.env.REACT_APP_BASE_API}/pdf/quotation?template=fmlf-a&id=${q.id}&token=${accessToken}`} className='btn btn-xs btn-success'><i className='fa fa-download' /> FMLF Contract A</a></p>
            }
          </div>
          { q.detail.annex && q.detail.annex.length > 0 &&
            <div className='col-md-6'>
              <p>Annex : </p>
              { q.detail.annex.map((x, i) => {
                return (
                  <p key={i}><a href={`${process.env.REACT_APP_BASE_API}/download/${x.id}?token=${this.props.accessToken}`} className='btn btn-xs btn-success'><i className='fa fa-download' />{x.detail.name}</a></p>
                )
              })}
            </div>
          }
        </div>
        <hr />
        <div>
          <p>Audit Cycle</p>
          <table className='table'>
            <thead>
              <tr>
                <td>Phase</td>
                <td>Days</td>
                <td>Rate</td>
                <td>Sub total</td>
                <td />
              </tr>
            </thead>
            <tbody>
              {mergeAuditCycle(q.detail.audit_cycle).map((x, y) => (
                <tr key={y}>
                  <td>{x.phase}</td>
                  <td>{x.day} days</td>
                  <td>
                    <span>Rp </span>
                    <NumberFormat
                      displayType='text'
                      value={x.rate}
                      thousandSeparator
                    />
                  </td>
                  <td>
                    <span>Rp </span>
                    <NumberFormat
                      displayType='text'
                      value={x.rate * x.day}
                      thousandSeparator
                    />
                  </td>
                  <td>
                    { q.status !== 'Temporary' &&
                      <a href={`${process.env.REACT_APP_BASE_API}/pdf/quotation?template=quotation-${x.phase === 'Certification Audit' ? 'ca' : 'sa'}&part=${x.phase}&id=${q.id}&token=${accessToken}`} className='btn btn-link btn-xs'><i className='fa fa-download' /> Download Sub Quotation</a>
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <p><strong>Total : Rp. {q.detail.audit_cycle.reduce((sum, x, i) => { return (sum + (x.rate * x.day)) }, 0).toLocaleString()}</strong></p>
        </div>
        { q.detail.additional_service &&
          <div>
            <hr />
            <div>
              <p>Additional Services</p>
              <table className='table'>
                <thead>
                  <tr>
                    <td>Service</td>
                    <td>Days</td>
                    <td>Rate</td>
                    <td>Sub total</td>
                  </tr>
                </thead>
                <tbody>
                  {q.detail.additional_service.map((x, y) => (
                    <tr key={y}>
                      <td>{x.phase}</td>
                      <td>{x.day} days</td>
                      <td>
                        <span>Rp </span>
                        <NumberFormat
                          displayType='text'
                          value={x.rate}
                          thousandSeparator
                        />
                      </td>
                      <td>
                        <span>Rp </span>
                        <NumberFormat
                          displayType='text'
                          value={x.rate * x.day}
                          thousandSeparator
                        />
                      </td>

                    </tr>
                  ))}
                </tbody>
              </table>
              <p><strong>Total : Rp. {q.detail.additional_service.reduce((sum, x, i) => { return (sum + (x.rate * x.day)) }, 0).toLocaleString()}</strong></p>
            </div>
          </div>
        }
        <hr />
        <div>
          <p>Fees</p>
          <table className='table'>
            <thead>
              <tr>
                <td>Name</td>
                <td>Quantity</td>
                <td>Rate</td>
                <td>Note</td>
                <td>Sub total</td>
              </tr>
            </thead>
            <tbody>
              {q.detail.fees.filter((x) => x.include).map((x, y) => (
                <tr key={y}>
                  <td>{x.name}</td>
                  <td>{x.quantity}</td>
                  <td>
                    <span>Rp </span>
                    <NumberFormat
                      displayType='text'
                      value={x.rate}
                      thousandSeparator
                    />
                  </td>
                  <td>{x.note}</td>
                  <td>
                    <span>Rp </span>
                    <NumberFormat
                      displayType='text'
                      value={x.rate * x.quantity}
                      thousandSeparator
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <p><strong>Total : Rp. {q.detail.fees.reduce((sum, x, i) => { return (sum + (x.rate * x.quantity)) }, 0).toLocaleString()}</strong></p>
        </div>
        <div>
          { q.detail.transport_accomodation && q.detail.transport_accomodation.map((x, i) => {
            return (<p key={i}>{x}</p>)
          })}
        </div>
      </div>
    )
  }
}
