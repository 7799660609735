import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { getOrderLabel } from '../../const/orders'

export default class QuotationTable extends React.Component {
  render () {
    return (
      <div className='table-responsive quotation-table'>
        <table className='table colored-header datatable project-list'>
          <thead>
            <tr>
              <th>Questionnaire</th>
              <th>Customer</th>
              <th>Phase</th>
              <th>Scheduled Date</th>
              <th>Order</th>
              <th>Order Status</th>

            </tr>
          </thead>
          <tbody>
            {
              this.props.quotationSchedules.map((x, i) => {
                return (
                  <tr className={`quotation-schedule-${x.id}`} key={i}>
                    <td><Link to={`/quotations/${x.quotation.id}`}>{x.quotation.id}</Link></td>
                    <td><Link to={`/customers/${x.customer.id}`}>{x.customer.name}</Link></td>
                    <td>{x.phase}</td>
                    <td>{moment.unix(x.date).format('D MMMM YYYY')}</td>
                    <td>{x.order ? <Link to={`/orders/${x.order.id}`}>{x.order.id}</Link> : '-'}</td>
                    <td>{x.order ? getOrderLabel(x.order.status) : 'Scheduled'}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    )
  }
}
