import React from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import Pagination from '../../components/common/Pagination'
import StandardTable from '../../components/Standards/StandardTable'
import StandardFilter from '../../components/Standards/StandardFilter'
import { StandardCreateForm as StandardForm } from '../../components/Standards/StandardForm'

import { createStandard, fetchStandards } from '../../actions/standards'
import { asQueryObject, asQueryString } from '../../const/helper'

class StandardList extends React.Component {
  componentWillReceiveProps (nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.props.fetchStandards(nextProps.location.search, this.props.accessToken)
    }
  }

  componentDidMount () {
    this.props.fetchStandards(this.props.location.search, this.props.accessToken)
  }

  handleStandard (e) {
    return this.props.createStandard(e, this.props.accessToken)
  }

  handleFilter (filter) {
    delete filter.page
    return this.props.push(`/standards?${asQueryString(filter)}`)
  }

  render () {
    const { standards, pagination, location } = this.props

    return (
      <div className='content'>
        <div className='main-header'>
          <h2>Standards </h2>
          <em className='hide'>normal sidebar with minified mode by default</em>
        </div>
        <div className='main-content'>
          <div className='row'>
            <div className='col-md-8'>
              { standards
                ? <div>
                  <StandardFilter
                    onSubmit={this.handleFilter.bind(this)}
                    initialValues={asQueryObject(location.search)}
                  />
                  <StandardTable standards={standards} />
                </div>
                : <div>loading</div>
              }
              <Pagination pagination={pagination} base='/standards' params={asQueryObject(location.search)} />
            </div>
            <div className='col-md-4'>
              <div className='widget'>
                <div className='widget-header'>
                  <h3><i className='fa fa-book' /> Create Standards</h3>
                </div>
                <div className='widget-content'>
                  <StandardForm
                    onSubmit={this.handleStandard.bind(this)}
                    initialValues={{
                      detail: {
                        bod_approval: true
                      }
                    }}
                    fileAPI={{
                      url: process.env.REACT_APP_BASE_API,
                      accessToken: this.props.accessToken
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    standards: state.standards.standardList.data,
    pagination: state.standards.standardList.pagination,
    accessToken: state.auth.user.access.access_token
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  return {
    fetchStandards: (params, key) => dispatch(fetchStandards(params, key)),
    createStandard: (data, key) => dispatch(createStandard(data, key)),
    push: (to) => dispatch(push(to))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StandardList)
