import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import moment from 'moment'

import Inside from './Inside'
// import Counter from '../components/Dashboard/Counter'
// import TopList from '../components/Dashboard/TopList'
// import Numbers from '../components/Dashboard/Numbers'
// import WidgetList from '../components/Dashboard/WidgetList'

// new dashboard
import WidgetSales from '../components/Dashboard/WidgetSales'
import WidgetStats from '../components/Dashboard/WidgetStats'
import ChartsOrder from '../components/Dashboard/ChartsOrder'
import ChartsMandays from '../components/Dashboard/ChartsMandays'
import ChartsTop from '../components/Dashboard/ChartsTop'
// import SalesbyAudit from '../components/Dashboard/SalesbyAudit'
// import SalesbySales from '../components/Dashboard/SalesbySales'
import '../components/Dashboard/new-dashboard.scss'

import {
  fetchGlobalCounts,
  fetchAuditCounts,
  fetchTopCustomer,
  fetchTopSales,
  fetchTopStandard,
  fetchTopScope,
  fetchMandaysExecution,
  fetchInvoices
} from '../actions/dashboard'

import { fetchCustomers } from '../actions/customers'
import { fetchGlobalSchedules } from '../actions/schedules'

import {
  fetchSpecificationByUser
} from '../actions/users'

export class Dashboard extends React.Component {
  componentDidMount () {
    const today = moment()
    this.props.fetchGlobalCounts(this.props.accessToken)
    this.props.fetchAuditCounts(this.props.accessToken)
    this.props.fetchTopCustomer(this.props.accessToken)
    this.props.fetchTopSales(this.props.accessToken)
    this.props.fetchTopStandard(this.props.accessToken)
    this.props.fetchTopScope(this.props.accessToken)
    this.props.fetchMandaysExecution(this.props.accessToken)
    this.props.fetchInvoices(this.props.accessToken)
    this.props.fetchCustomers(this.props.location.search, this.props.accessToken)

    this.props.fetchGlobalSchedules(this.props.accessToken, {
      start: today.format('YYYY-MM-DD'),
      end: today.add(2, 'months').format('YYYY-MM-DD'),
      user_id: this.props.user.id
    })
    this.props.fetchSpecificationByUser(this.props.user.id, this.props.accessToken)
  }

  render () {
    const {
      user,
      // globalCounts,
      // auditCounts,
      topCustomer,
      topSales,
      topStandard,
      topScope,
      // globalScheduleList,
      // specifications,
      mandaysExecution,
      invoices,
      customers
    } = this.props

    return (
      <Inside>
        <Helmet>
          <title>Dashboard</title>
        </Helmet>
        <div className='content'>
          <div className='main-header'>
            <h2>Dashboard</h2>
            {
            // <em>normal sidebar with minified mode by default</em>
            }
          </div>
          <div className='main-content'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='row'>
                  <div className='col-md-4'>
                    {
                    // <Counter
                    //   background='green' icon='check-circle'
                    //   title='Total Invoice'
                    //   count={35}
                    // />
                    }
                  </div>
                </div>
              </div>

              { user.data.role !== 'Customer' &&
                <div className='col-md-4'>
                  <div className='row'>
                    {invoices && customers && <WidgetStats invoices={invoices} customers={customers} />}
                    <div className='col-md-12'>
                      {invoices && mandaysExecution && <WidgetSales invoices={invoices} mandaysExecution={mandaysExecution} />}
                    </div>
                  </div>
                </div>
              }

              { user.data.role !== 'Customer' &&
                <div className='col-md-8'>
                  {invoices && <ChartsOrder data={invoices} />}
                  {mandaysExecution && <ChartsMandays data={mandaysExecution} />}
                </div>
              }
            </div>

            { user.data.role !== 'Customer' &&
              <Fragment>
                { topCustomer && topSales && topStandard && topScope &&
                  <ChartsTop
                    topCustomer={topCustomer}
                    topStandard={topStandard}
                    topScope={topScope}
                  />
                }
              </Fragment>
            }
            {
            // <div className='row'>
            //   <div className='col-md-6'>
            //     {SalesbyAudit && <SalesbyAudit data={SalesbyAudit} />}
            //   </div>
            //   <div className='col-md-6'>
            //     {SalesbySales && <SalesbySales data={SalesbySales} />}
            //   </div>
            // </div>
            // <div className='row'>
            //   { user.data.role !== 'Customer' &&
            //   <div className='col-md-6'>
            //     { globalCounts && <Numbers title='Dashboard Count' numbers={globalCounts} /> }
            //     { auditCounts && specifications.length > 0 && <Numbers title='Auditor Dashboard' numbers={auditCounts} /> }
            //     { globalScheduleList && specifications.length > 0 && <WidgetList title='Audit Schedule' schedules={globalScheduleList} /> }
            //   </div>
            //   }
            // </div>
            }

          </div>
        </div>
      </Inside>
    )
  }
}

function mapStateToProps (state) {
  return {
    accessToken: state.auth.user.access.access_token,
    globalCounts: state.dashboard.globalCounts.data,
    auditCounts: state.dashboard.auditCounts.data,
    topCustomer: state.dashboard.topCustomer.data,
    topSales: state.dashboard.topSales.data,
    topStandard: state.dashboard.topStandard.data,
    topScope: state.dashboard.topScope.data,
    mandaysExecution: state.dashboard.mandaysExecution.data,
    invoices: state.dashboard.invoices.data,
    customers: state.customers.customerList.pagination,

    globalScheduleList: state.schedules.globalScheduleList.data,
    specifications: state.users.specificationList.data || [],

    user: state.auth.user
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchGlobalCounts: (token) => dispatch(fetchGlobalCounts(token)),
    fetchAuditCounts: (token) => dispatch(fetchAuditCounts(token)),
    fetchTopCustomer: (token) => dispatch(fetchTopCustomer(token)),
    fetchTopSales: (token) => dispatch(fetchTopSales(token)),
    fetchTopStandard: (token) => dispatch(fetchTopStandard(token)),
    fetchTopScope: (token) => dispatch(fetchTopScope(token)),
    fetchMandaysExecution: (token) => dispatch(fetchMandaysExecution(token)),
    fetchInvoices: (token) => dispatch(fetchInvoices(token)),
    fetchCustomers: (params, key) => dispatch(fetchCustomers(params, key)),

    fetchGlobalSchedules: (key, params) => dispatch(fetchGlobalSchedules(key, params)),
    fetchSpecificationByUser: (id, key) => dispatch(fetchSpecificationByUser(id, key))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)
