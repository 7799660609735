const roles = [
  {
    value: 'Public',
    label: 'Public'
  },
  {
    value: 'Super Admin',
    label: 'Super Admin'
  },
  {
    value: 'Customer',
    label: 'Customer'
  },
  {
    value: 'Sales',
    label: 'Sales'
  },
  {
    value: 'Sales Manager',
    label: 'Sales Manager'
  },
  {
    value: 'Board of Director',
    label: 'Board of Director'
  },
  {
    value: 'Application Reviewer',
    label: 'Application Reviewer'
  },
  {
    value: 'Application Reviewer Manager',
    label: 'Application Reviewer Manager'
  },
  {
    value: 'Order Operator',
    label: 'Order Operator'
  },
  {
    value: 'Finance',
    label: 'Finance'
  },
  {
    value: 'Scheduler',
    label: 'Scheduler'
  },
  {
    value: 'Auditor',
    label: 'Auditor'
  },
  {
    value: 'Audit Plan Operator',
    label: 'Audit Plan Operator'
  },
  {
    value: 'Head of CB',
    label: 'Head of CB'
  },
  {
    value: 'Certificate Admin',
    label: 'Certificate Admin'
  },
  {
    value: 'Operational Manager',
    label: 'Operational Manager'
  }
]

export default roles
