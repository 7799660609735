import React from 'react'
import { connect } from 'react-redux'

import { createCustomerContact } from '../../actions/customers'

import { CustomerContactCreateForm as CustomerContactForm } from '../../components/Customers/CustomerContactForm'

function mapStateToProps (state) {
  return {
    locations: state.customers.customerLocationList.data,
    accessToken: state.auth.user.access.access_token
  }
}

function mapDispatchToProps (dispatch) {
  return {
    createCustomerContact: (data, key) => dispatch(createCustomerContact(data, key))
  }
}

export class CustomerNew extends React.Component {
  handleContactCreate (e) {
    return this.props.createCustomerContact({
      ...e,
      customer_id: this.props.match.params.cust_id
    }, this.props.accessToken)
  }

  render () {
    return (
      <div className='content'>
        <div className='main-header'>
          <h2>Create New Contact</h2>
          {/* <em>normal sidebar with minified mode by default</em> */}
        </div>
        <div className='main-content'>
          <div className='row'>
            <div className='col-md-8'>
              <CustomerContactForm
                onSubmit={this.handleContactCreate.bind(this)}
                locations={this.props.locations}
              />
            </div>
            <div className='col-md-4' />
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerNew)
