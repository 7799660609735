import React, { Fragment, useState, useEffect } from 'react'
import get from 'lodash/get'
import NumberFormat from 'react-number-format'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import intersection from 'lodash/intersection'

import ItemLog from '../../components/common/ItemLog'
import { QuotationUpdateForm as QuotationForm } from '../../components/Quotations/QuotationForm'
import QuotationDetail from '../../components/Quotations/QuotationDetail'
import ScheduleTable from '../../components/Quotations/ScheduleTable'

import CertificationInformationWidget from '../../components/Questionnaires/CertificationInformationWidget'
import AdditionalInformationWidget from '../../components/Questionnaires/AdditionalInformationWidget'
import MandaysCalculationWidget from '../../components/Questionnaires/MandaysCalculationWidget'
import AnnexWidget from '../../components/Questionnaires/AnnexWidget'
import ReviewWidget from '../../components/Questionnaires/ReviewWidget'

import CustomerWidget from '../../components/Customers/CustomerWidget'
import LocationWidget from '../../components/Customers/LocationWidget'
import ContactWidget from '../../components/Customers/ContactWidget'

// import Affix from '../../components/common/Affix'
import ReviewForm from '../../components/common/ReviewForm'

import additionalServices from '../../const/additionalServices'

import { fetchQuestionnaire } from '../../actions/questionnaires'
import { fetchQuotation, updateQuotation } from '../../actions/quotations'
import { fetchOrders } from '../../actions/orders'

import { totalFeesUpdateSelector, filterAuditCycle, getFeesByYearUpdate } from '../../selectors/quotations'
import { annexWithValueSelector } from '../../selectors/questionnaires'

export function QuotationView (props) {
  const {
    fetchQuotation,
    fetchQuestionnaire,
    fetchOrders,
    updateQuotation,
    questionnaire,
    quotation,
    user,
    accessToken,
    getFees,
    match,
    orders,
    questionnaireAnnex,
    feesByYear
  } = props
  const { role } = user.data

  const status = get(quotation, 'status')
  const customer = get(questionnaire, 'detail.copy.customer')
  const mainContact = get(questionnaire, 'detail.main_contact')

  const [submitting, setSubmitting] = useState(false)
  const [amend, setAmend] = useState(false)
  const [temporaryUpdate, setTemporaryUpdate] = useState(false)

  useEffect(() => {
    fetchQuotation(match.params.id, accessToken)
  }, [match.params.id])

  useEffect(() => {
    if (quotation) {
      fetchQuestionnaire(quotation.questionnaire.id, accessToken)
      fetchOrders(`?quotation_id=${quotation.id}`, accessToken)
    }
  }, [quotation])

  function handleSubmit (e) {
    setSubmitting(true)
    const data = {
      id: match.params.id,
      sub_total: getFees,
      total: getFees,
      detail: { ...e.detail },
      remarks: e.detail.remarks,
      log: {
        remarks: e.detail.remarks
      }
    }

    if (
      e.advance &&
      ['Approved', 'Quoted', 'Ordered'].indexOf(questionnaire.status) >= 0
    ) {
      data.status = 'Created'
    }

    return updateQuotation(data, accessToken, `/quotations`).then(() => {
      setSubmitting(false)
    })
  }

  function handleSubmitReview (e) {
    setSubmitting(true)
    let request = {
      id: quotation.id,
      log: e.log ? e.log.detail : '',
      status: e.result
    }

    return updateQuotation(request, accessToken, '/quotations').then(() => {
      setSubmitting(false)
    })
  }

  function handleInitiateSchedule (initiate) {
    const data = {
      id: quotation.id,
      initiate
    }
    updateQuotation(data, accessToken, `/orders`)
  }

  function handleAmend () {
    setAmend(!amend)
  }

  function getSide () {
    let result
    let message = false
    let showForm = false
    let requireFile = null

    switch (status) {
      case 'Draft':
        message = `Current status is <strong>Draft</strong>. Please wait for quotation from <strong>Sales Officer</strong>`
        showForm = false
        break
      case 'Created':
        message = `Current status is <strong>Created</strong>. Please wait for review from <strong>Sales Manager</strong>`
        showForm = intersection(['Sales Manager', 'Super Admin'], role).length > 0
        result = ['Reviewed by SM', 'Need to Revise']
        break
      case 'Reviewed by SM':
        message = `Current status is <strong>Reviewed by Sales Manager</strong>. Please wait for review from <strong>Board of Director</strong>`
        showForm = intersection(['Board of Director', 'Super Admin'], role).length > 0
        result = ['Reviewed by BOD', 'Need to Revise']
        break
      case 'Reviewed by BOD':
        message = `Current status is <strong>Reviewed by Board of Director</strong>. Please wait for review from <strong>Customer</strong> or manual approval by <strong>Sales Officer</strong>`
        if (role.includes('Customer')) {
          result = ['Approved by Customer', 'Need to Revise']
          showForm = true
          requireFile = true
        } else if (quotation.sales.id === user.id || role.includes['Super Admin']) {
          result = ['Manual Approval', 'Need to Revise']
          showForm = true
          requireFile = true
        }
        break
      case 'Manual Approval':
        message = `Current status is <strong>Manual Approval</strong>. Please wait for review from <strong>Operational Manager</strong>.`
        result = ['Approved by Customer', 'Need to Revise']
        showForm = intersection(['Operational Manager', 'Super Admin'], role).length > 0
        break

      case 'Amendment Created':
        message = `Current status is <strong>Amendment Created</strong>. Please wait for approval from <strong>Customer</strong>`
        if (role.includes('Customer')) {
          result = ['Amendment Approved by Customer', 'Amendment Need to Revise']
          showForm = true
        } else if (quotation.sales.id === user.id || role === 'Super Admin') {
          result = ['Amendment Manual Approval', 'Amendment Need to Revise']
          showForm = true
        }
        break
      case 'Amendment Manual Approval':
        message = `Current status is <strong>Amendment Manual Approval</strong>. Please wait for review from <strong>Operational Manager</strong>.`
        result = ['Amendment Approved by Customer', 'Amendment Need to Revise']
        showForm = intersection(['Operational Manager', 'Super Admin'], role).length > 0
        break
      default:
        break
    }

    return (
      <div className='form-summary'>
        { message &&
          <div className='alert alert-info' dangerouslySetInnerHTML={{ __html: message }} />
        }
        { showForm &&
          <div className='widget'>
            <div className='widget-header'>
              <h3><i className='fa fa-tasks' /> Review</h3>
            </div>
            <div className='widget-content'>
              {props.quotation &&
                <ReviewForm
                  quotation={props.quotation}
                  onSubmit={handleSubmitReview.bind(this)}
                  fileAPI={{
                    url: process.env.REACT_APP_BASE_API,
                    accessToken: accessToken
                  }}
                  requireFile={requireFile}
                  submitting={submitting}
                  result={result}
                />
              }
            </div>
          </div>
        }
      </div>
    )
  }

  // console.log(quotation)
  // console.log(questionnaire)

  return (
    <div>
      <div className='content'>
        {quotation &&
          <div className='main-header'>
            {['Draft', 'Temporary'].indexOf(quotation.status) >= 0 && quotation.sales.id === user.id &&
              <h2>Create Quotation </h2>
            }
            {['Draft', 'Temporary'].indexOf(quotation.status) < 0 &&
              <h2>Review Quotation <span>{quotation.id}</span></h2>
            }
            {/* <em>normal sidebar with minified mode by default</em> */}
          </div>
        }
        <div className='row'>
          <div className='col-md-8'>
            {quotation && questionnaire
              ? <div>
                { (
                  (quotation.status === 'Draft' && user.data.role.includes('Sales')) ||
                  // (quotation.status === 'Temporary' && user.data.role.includes('Sales')) ||
                  (quotation.status === 'Need to Revise' && user.data.role.includes('Sales')) ||
                  (quotation.status === 'Approved by Customer' && amend) ||
                  (quotation.status === 'Amendment Approved by Customer' && amend) ||
                  temporaryUpdate
                )
                  ? <QuotationForm
                    onSubmit={handleSubmit.bind(this)}
                    initialValues={quotation}
                    questionnaire={questionnaire}
                    amend={amend}
                    additionalServices={additionalServices}
                    mandays={questionnaire.detail.mandays_roundup}
                    fileAPI={{
                      url: process.env.REACT_APP_BASE_API,
                      accessToken: accessToken
                    }}
                    phases={filterAuditCycle(questionnaire.detail.audit_cycle || [])}
                    submitting={submitting}
                  />
                  : <div className='widget'>
                    {
                    // <div className='widget-header'>
                    //   <h3><i className='fa fa-tasks' /> Quotation Detail</h3>
                    // </div>
                    }
                    <div className='widget-content'>
                      <QuotationDetail
                        quotation={quotation}
                        questionnaire={questionnaire}
                        accessToken={accessToken}
                        onEditTemporary={() => setTemporaryUpdate(true)}
                      />
                    </div>
                  </div>
                }
                { orders && quotation.status === 'Approved by Customer' &&
                  <div className='widget'>
                    <div className='widget-header'>
                      <h3><i className='fa fa-tasks' /> Quotation Schedules</h3>
                    </div>
                    <div className='widget-content'>
                      <ScheduleTable
                        orders={orders}
                        schedules={quotation.quotation_schedules}
                        onInitiate={handleInitiateSchedule.bind(this)}
                        role={user.data.role}
                      />
                    </div>
                  </div>
                }
              </div>
              : <div>loading</div>
            }
          </div>
          <div className='col-md-4'>
            { quotation && <div>{getSide()}</div> }

            { quotation && ['Approved by Customer', 'Amendment Approved by Customer'].indexOf(quotation.status) >= 0 && ['Sales', 'Super Admin'].indexOf(user.data.role) > -1 &&
              <p>
                <button className='btn btn-block btn-primary' onClick={handleAmend}>Amend Quotation</button>
              </p>
            }
            {
              (
                (quotation && quotation.status === 'Draft') ||
                (quotation && quotation.status === 'Need to Revise') ||
                (quotation && quotation.status === 'Approved by Customer' && amend)
              ) &&
              <div className='widget'>
                <div className='widget-header'>
                  <h3><i className='fa fa-tasks' /> Basic Data</h3> <em>- Summary of Tasks</em>
                </div>
                { questionnaire &&
                <div className='widget-content'>
                  <dl>
                    <dt><strong>Mandays by Formula</strong></dt>
                    { questionnaire.detail && questionnaire.detail.mandays
                      ? <dd>{questionnaire.detail.mandays} days</dd>
                      : <dd>n/a</dd>
                    }
                    <dt><strong>Mandays by A Team</strong></dt>
                    { questionnaire.detail && questionnaire.detail.mandays_by_a_team
                      ? <dd>{questionnaire.detail.mandays_by_a_team} days</dd>
                      : <dd>n/a</dd>
                    }
                    <dt><strong>Mandays Round Up</strong></dt>
                    { questionnaire.detail && questionnaire.detail.mandays_roundup
                      ? <dd>{questionnaire.detail.mandays_roundup} days ({questionnaire.detail.mandays_adjustment} %)</dd>
                      : <dd>n/a</dd>
                    }
                    { feesByYear.map((x, i) => (
                      <Fragment key={i}>
                        <dt>{x.phase} {x.description && <span>({x.description})</span>}</dt>
                        <dd>
                          <span>Rp</span>&nbsp;
                          <NumberFormat
                            displayType='text'
                            value={x.amount}
                            thousandSeparator
                          />
                        </dd>
                      </Fragment>
                    ))}
                  </dl>
                </div>
                }
              </div>
            }
            { quotation &&
            <div>
              <ItemLog
                log={quotation.log}
                entity='Quotation'
                accessToken={accessToken}
              />
            </div>
            }

          </div>
        </div>
        {
          // <div className='main-header'>
          //   <h2>Questionnaire Data </h2>
          //   <em>normal sidebar with minified mode by default</em>
          // </div>
        }
        <div className='row'>
          <div className='col-md-8'>
            { questionnaire &&
              <Fragment>
                <div className='row'>
                  <div className='col-md-6'>
                    <CustomerWidget customer={customer} accessToken={accessToken} />
                  </div>
                  <div className='col-md-6'>
                    <ContactWidget contact={mainContact} accessToken={accessToken} title='Main Contact' />
                  </div>
                </div>
                <CertificationInformationWidget questionnaire={questionnaire} accessToken={accessToken} />
                {questionnaire.locations.map((x, i) => (
                  <LocationWidget
                    key={i}
                    location={x.location}
                    detail={x.detail}
                    accessToken={accessToken}
                    title={`Audit Location : ${x.location.name}`}
                  />
                ))}
                <AdditionalInformationWidget questionnaire={questionnaire} accessToken={accessToken} />
                <AnnexWidget annex={questionnaireAnnex} questionnaire={questionnaire} accessToken={accessToken} />
                <MandaysCalculationWidget questionnaire={questionnaire} accessToken={accessToken} />
                <ReviewWidget questionnaire={questionnaire} accessToken={accessToken} />
              </Fragment>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps (state) {
  return {
    quotation: state.quotations.quotation.data,
    questionnaire: state.questionnaires.questionnaire.data,
    orders: state.orders.orderList.data,
    // needBOD: needBODSelector(state),
    quotationFormValue: getFormValues('quotationForm')(state),
    getFees: totalFeesUpdateSelector(state),
    feesByYear: getFeesByYearUpdate(state),
    questionnaireAnnex: annexWithValueSelector(state),
    accessToken: state.auth.user.access.access_token,
    user: state.auth.user
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchQuestionnaire: (id, key) => dispatch(fetchQuestionnaire(id, key)),
    fetchQuotation: (id, key) => dispatch(fetchQuotation(id, key)),
    fetchOrders: (params, key) => dispatch(fetchOrders(params, key)),
    updateQuotation: (data, key, target) => dispatch(updateQuotation(data, key, target))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
// Implement map dispatch to props
)(QuotationView)
