import React from 'react'
import { connect } from 'react-redux'
import { Link, Switch, Route } from 'react-router-dom'
import orderBy from 'lodash/fp/orderBy'
import intersection from 'lodash/intersection'
import { change } from 'redux-form'
import { Helmet } from 'react-helmet'
import moment from 'moment'

import ItemLog from '../../components/common/ItemLog'
import BasicModal from '../../components/common/BasicModal'
import ReviewForm from '../../components/common/ReviewForm'
import Affix from '../../components/common/Affix'

import PlanTable from '../../components/Orders/PlanTable'
import AuditEventForm from '../../components/Orders/AuditEventForm'
import EventForm from '../../components/Orders/EventForm'
import { PlanCreateForm, PlanUpdateForm } from '../../components/Orders/PlanForm'

import { fetchOrder } from '../../actions/orders'
import { fetchPlans, fetchPlan, createPlan, updatePlan } from '../../actions/plans'

import { auditDaysSelector } from '../../selectors/plans'
import { eventTemplate } from '../../const/eventTemplate'
import orderPlanTemplate from '../../const/orderPlanTemplate'

export class OrderPlanView extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      openEvent: false,
      openAuditEvent: false,
      activeEvent: null,
      events: [],
      submitting: false,
      mode: 'document'
    }
  }

  componentWillMount () {
    this.props.fetchOrder(this.props.match.params.orderId, this.props.accessToken)

    if (this.props.match.params.id && this.props.match.params.id !== 'new') {
      this.props.fetchPlan(
        this.props.match.params.orderId,
        this.props.match.params.id,
        this.props.accessToken
      )
    }
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.match.params.id !== 'new' && nextProps.plan) {
      // get saved events from state
      this.setState({
        events: nextProps.plan && nextProps.plan.detail && nextProps.plan.detail.events ? nextProps.plan.detail.events : [],
        mode: nextProps.plan.detail.mode
      })
    }
  }

  handleOpenEvent (e) {
    this.setState({
      activeEvent: e,
      openEvent: !this.state.openEvent
    })
  }

  handleOpenAuditEvent (e) {
    this.setState({
      activeEvent: e,
      openAuditEvent: !this.state.openAuditEvent
    })
  }

  getAuditor (x, i) {
    const auditor = this.props.order.schedules.find((y, j) => {
      return y.user.id === parseInt(x, 10)
    })
    return auditor.user
  }

  handleSubmitEvent (e) {
    const { events, openEvent, activeEvent } = this.state
    const newEvent = {
      ...e,
      dateTime: e.day,
      type: 'event'
    }
    const reducedEvents = activeEvent === null
      ? [...events, newEvent]
      : [ ...events.slice(0, activeEvent), newEvent, ...events.slice(activeEvent + 1) ]

    this.setState({
      events: reducedEvents,
      openEvent: !openEvent,
      activeEvent: null
    })
  }

  handleSubmitAuditEvent (e) {
    const { events, openAuditEvent, activeEvent } = this.state
    const auditor = e.auditor || []
    const newEvent = {
      ...e,
      dateTime: e.day,
      auditor: auditor.map(this.getAuditor.bind(this)),
      type: 'auditEvent'
    }
    const reducedEvents = activeEvent === null
      ? [...events, newEvent]
      : [ ...events.slice(0, activeEvent), newEvent, ...events.slice(activeEvent + 1) ]

    this.setState({
      events: reducedEvents,
      openAuditEvent: !openAuditEvent,
      activeEvent: null
    })
  }

  handleSubmitPlan (e) {
    let nextStatus = 'Published'
    let currentStatus = 'Draft'

    this.setState({
      submitting: true
    })

    if (this.props.plan && this.props.plan.status === 'Published') {
      nextStatus = 'Approved by Lead Auditor'
      currentStatus = 'Published'
    }
    const data = {
      status: e.advance ? nextStatus : currentStatus,
      detail: {
        ...e.detail,
        events: [...this.state.events],
        mode: this.state.mode
      },
      remarks: e.remarks,
      order_id: this.props.match.params.orderId
    }
    if (this.props.match.params.id && this.props.match.params.id !== 'new') {
      return this.props.updatePlan({
        ...data,
        id: this.props.match.params.id
      }, this.props.accessToken, e.advance ? `/orders/${data.order_id}` : null
      ).then(() => {
        this.setState({
          submitting: false
        })
      })
    } else if (this.props.match.params.id && this.props.match.params.id === 'new') {
      return this.props.createPlan(data, this.props.accessToken, e.advance ? `/orders/${data.order_id}` : null)
        .then(() => {
          this.setState({
            submitting: false
          })
        })
    }
  }

  formatEvents (events) {
    const { auditDays } = this.props
    const test = events.reduce((sum, x, i) => {
      const dayNumber = auditDays.reduce((sum2, y, j) => x.day === y.label ? j : sum2, null)

      if (sum[dayNumber]) {
        sum[dayNumber].event = orderBy(['time'])(['asc'])([ ...sum[dayNumber].event, { ...x, key: i } ])
      } else {
        sum[dayNumber] = {
          day: dayNumber,
          title: 'Day ' + (parseInt(dayNumber, 10) + 1) + ' : ' + auditDays[dayNumber].label,
          event: [{ ...x, key: i }]
        }
      }
      return sum
    }, [])
    return test
  }

  formatAuditForm (data) {
    const formatted = { ...data }
    if (data.auditor) {
      formatted.auditor = data.auditor.map((x) => (x.id))
    }
    return formatted
  }

  handleDeleteEvent () {
    const { events, activeEvent } = this.state
    const reducedEvents = [ ...events.slice(0, activeEvent), ...events.slice(activeEvent + 1) ]

    this.setState({
      events: reducedEvents,
      activeEvent: null,
      openAuditEvent: false,
      openEvent: false
    })
  }

  handleSubmitReview (e) {
    let request = {
      id: this.props.plan.id,
      order_id: this.props.order.id,
      log: e.log ? e.log.detail : ''
    }
    if (e.result === 'Not Approved') {
      request.status = 'Draft'
    } else {
      switch (this.props.plan.status) {
        case 'Published':
          request.status = 'Approved by Lead Auditor'
          break
        default:
          request.status = 'Draft'
          break
      }
    }
    return this.props.updatePlan(request, this.props.accessToken, `/orders/${this.props.order.id}`)
  }

  applyTemplate (y) {
    const data = eventTemplate.find((x) => x.key === y.target.value)
    this.props.change('AuditEventForm', 'site', data.site)
    this.props.change('AuditEventForm', 'process', data.process)
    this.props.change('AuditEventForm', 'focus', data.focus)
  }

  changeMode (mode) {
    this.setState({ mode })

    if (this.state.events.length === 0 && mode === 'manual') {
      const day1 = this.props.order.schedules
        .sort((a, b) => a.start_date > b.start_date)[0]
        .start_date
      const day1Format = moment(day1).format('dddd, DD-MM-YYYY')
      const defaultTemplate = orderPlanTemplate.map(x => ({
        ...x,
        day: day1Format,
        dateTime: day1Format
      }))

      this.setState({
        events: defaultTemplate
      })
    }
  }

  render () {
    const { events, activeEvent, mode } = this.state
    const { order, plan, auditDays, user, accessToken } = this.props
    const leadAuditor = order ? order.users.filter((x) => x.role === 'Lead Auditor') : []

    return (
      <div>
        <div className='content'>
          <Switch>
            <Route path='/orders/:orderId/plans/new'>
              <div>
                <Helmet>
                  <title>{order ? `Order ${order.id} - Create plan` : `Loading`}</title>
                </Helmet>
                <div className='main-header'>
                  <h2>Create Plan</h2>
                  { order && <em>For Order <Link to={`/orders/${order.id}`}>{order.id}</Link></em> }
                </div>
                { mode === 'document'
                  ? <div className='row'>
                    <div className='col-md-10'>
                      { order &&
                        <PlanCreateForm
                          order={order}
                          onSubmit={this.handleSubmitPlan.bind(this)}
                          fileAPI={{
                            url: process.env.REACT_APP_BASE_API,
                            accessToken: this.props.accessToken
                          }}
                        />
                      }
                    </div>
                    <div className='col-md-2'>
                      <p>
                        <button className='btn btn-block btn-sm btn-default' onClick={this.changeMode.bind(this, 'manual')}>Use manual input</button>
                      </p>
                    </div>
                  </div>
                  : <div className='row'>
                    <div className='col-md-10'>
                      { order && auditDays
                        ? <PlanTable
                          events={this.formatEvents(events)}
                          onOpenAuditEvent={this.handleOpenAuditEvent.bind(this)}
                          onOpenEvent={this.handleOpenEvent.bind(this)}
                          editable
                        />
                        : <div>loading order</div>
                      }
                    </div>
                    <div className='col-md-2'>
                      <Affix>
                        <div className='widget'>
                          <div className='widget-content'>
                            <button disabled={this.state.submitting} className='btn btn-sm btn-block btn-default' onClick={this.changeMode.bind(this, 'document')}>Use document input</button>
                            <hr />
                            { order
                              ? <div>
                                <button disabled={this.state.submitting} onClick={this.handleOpenAuditEvent.bind(this, null)} className='btn btn-sm btn-default btn-block'>Add Audit Event</button>
                                <button disabled={this.state.submitting} onClick={this.handleOpenEvent.bind(this, null)} className='btn btn-sm btn-default btn-block'>Add Non Audit Event</button>
                                <hr />
                                <button disabled={this.state.submitting} onClick={this.handleSubmitPlan.bind(this)} className='btn btn-link btn-block'>Save</button>
                                <button disabled={this.state.submitting} onClick={this.handleSubmitPlan.bind(this, { advance: true })} className='btn btn-primary btn-block'>Submit</button>
                              </div>
                              : <div>loading order</div>
                            }
                          </div>
                        </div>
                      </Affix>
                    </div>
                  </div>
                }
              </div>
            </Route>
            <Route path='/orders/:orderId/plans/:id'>
              <div>
                { (!plan || !order) && <p>loading</p> }
                { plan && order &&
                  <div>
                    <Helmet>
                      <title>{order ? `Order ${order.id} - Review plan` : `Loading`}</title>
                    </Helmet>
                    <div className='main-header'>
                      <h2>Plan {plan.id}</h2>
                      <em>For Order <Link to={`/orders/${order.id}`}>{order.id}</Link></em>
                    </div>
                    { // review by super admin or customer
                      plan.status === 'Approved by Lead Auditor' &&
                      (intersection(['Super Admin', 'Customer'], user.data.role).length > 0) &&
                    (
                      <div className='row'>
                        <div className='col-md-10'>
                          <div className='widget'>
                            <div className='widget-header'>
                              <h3><i className='fa fa-tasks' /> Review</h3>
                            </div>
                            <div className='widget-content'>
                              <ReviewForm
                                onSubmit={this.handleSubmitReview.bind(this)}
                                fileAPI={{
                                  url: process.env.REACT_APP_BASE_API,
                                  accessToken: this.props.accessToken
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {
                      (
                        (plan.status === 'Draft' && intersection(['Audit Plan Operator', 'Super Admin'], user.data.role).length > 0) || // editable draft
                        (plan.status === 'Published' && intersection(['Audit Plan Operator', 'Super Admin'], user.data.role)) || // editable by super admin
                        (plan.status === 'Published' && leadAuditor.filter(x => x.user.id === user.id).length > 0) // editable by lead auditor
                      ) && (
                        <div>
                          { this.state.mode === 'document' &&
                          <div className='row'>
                            <div className='col-md-10'>
                              <PlanUpdateForm
                                onSubmit={this.handleSubmitPlan.bind(this)}
                                initialValues={{ ...plan }}
                                fileAPI={{
                                  url: process.env.REACT_APP_BASE_API,
                                  accessToken: this.props.accessToken
                                }}
                              />
                            </div>
                            <div className='col-md-2'>
                              <p><button className='btn btn-block btn-sm btn-default' onClick={this.changeMode.bind(this, 'manual')}>Use manual input</button></p>
                            </div>
                          </div>
                          }
                          { this.state.mode === 'manual' &&
                          <div className='row'>
                            <div className='col-md-10'>
                              <PlanTable
                                events={this.formatEvents(events)}
                                onOpenAuditEvent={this.handleOpenAuditEvent.bind(this)}
                                onOpenEvent={this.handleOpenEvent.bind(this)}
                                editable
                              />
                            </div>
                            <div className='col-md-2'>
                              <Affix>
                                <div className='widget'>
                                  <div className='widget-content'>
                                    <button disabled={this.state.submitting} className='btn btn-sm btn-block btn-default' onClick={this.changeMode.bind(this, 'document')}>Use document input</button>
                                    <hr />
                                    <button disabled={this.state.submitting} onClick={this.handleOpenAuditEvent.bind(this, null)} className='btn btn-sm btn-default btn-block'>Add Audit Event</button>
                                    <button disabled={this.state.submitting} onClick={this.handleOpenEvent.bind(this, null)} className='btn btn-sm btn-default btn-block'>Add Non Audit Event</button>
                                    <hr />
                                    <button disabled={this.state.submitting} onClick={this.handleSubmitPlan.bind(this)} className='btn btn-link btn-block'>Save</button>
                                    <button disabled={this.state.submitting} onClick={this.handleSubmitPlan.bind(this, { advance: true })} className='btn btn-primary btn-block'>Submit</button>
                                  </div>
                                </div>
                              </Affix>
                            </div>
                          </div>
                          }
                        </div>
                      )
                    }
                    { plan.status === 'Approved by Lead Auditor' &&
                      <div className='row'>
                        { plan.detail.mode === 'manual' &&
                          <div className='col-md-10'>
                            <PlanTable events={this.formatEvents(events)} />
                          </div>
                        }
                        { plan.detail.mode === 'document' && plan.detail.plan_document[0] &&
                          <div className='col-md-10'>
                            <p>Download plan document here.</p>
                            <p>
                              <a className='btn btn-primary' href={`${process.env.REACT_APP_BASE_API}/download/${plan.detail.plan_document[0].id}?token=${accessToken}`}><i className='fa fa-download' /> {plan.detail.plan_document[0].detail.name}</a>
                            </p>
                          </div>
                        }
                      </div>
                    }
                  </div>
                }

                <div className='row'>
                  <div className='col-md-10'>
                    { plan
                      ? <ItemLog log={plan.log} entity='Order Plan' />
                      : <div>loading</div>
                    }
                  </div>
                </div>
              </div>
            </Route>
          </Switch>
        </div>
        { order &&
          <BasicModal open={this.state.openAuditEvent} onClose={this.handleOpenAuditEvent.bind(this, null)} title='Audit Event'>
            <AuditEventForm
              onSubmit={this.handleSubmitAuditEvent.bind(this)}
              onDelete={this.handleDeleteEvent.bind(this)}
              initialValues={activeEvent != null ? this.formatAuditForm(events[activeEvent]) : {
                day: auditDays[0].label,
                time: '07:00'
              }}
              dayOptions={auditDays}
              schedules={order.schedules}
              deletable={activeEvent !== null}
              template={eventTemplate}
              useTemplate={this.applyTemplate.bind(this)}
            />
          </BasicModal>
        }
        { order &&
          <BasicModal open={this.state.openEvent} onClose={this.handleOpenEvent.bind(this, null)} title='Non Audit Event'>
            <EventForm
              onSubmit={this.handleSubmitEvent.bind(this)}
              onDelete={this.handleDeleteEvent.bind(this)}
              initialValues={activeEvent != null ? events[activeEvent] : {
                day: auditDays[0].label,
                time: '07:00'
              }}
              dayOptions={auditDays}
              deletable={activeEvent !== null}
            />
          </BasicModal>
        }
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    order: state.orders.order.data,
    plans: state.plans.planList.data,
    plan: state.plans.plan.data,
    auditDays: auditDaysSelector(state),
    accessToken: state.auth.user.access.access_token,
    user: state.auth.user
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchOrder: (id, key) => dispatch(fetchOrder(id, key)),
    fetchPlans: (id, accessToken) => dispatch(fetchPlans(id, accessToken)),
    fetchPlan: (orderId, id, accessToken) => dispatch(fetchPlan(orderId, id, accessToken)),
    createPlan: (data, accessToken, target) => dispatch(createPlan(data, accessToken, target)),
    updatePlan: (data, accessToken, target) => dispatch(updatePlan(data, accessToken, target)),
    change: (a, b, c) => dispatch(change(a, b, c))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
// Implement map dispatch to props
)(OrderPlanView)
