import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'

import businessType from '../../const/businessType'
class BaseCustomerFilter extends Component {
  render () {
    const { handleSubmit, submitting } = this.props
    return (
      <div>
        <form className='form-inline' onSubmit={handleSubmit}>
          <div className='form-group'>
            <label className='sr-only' htmlFor='exampleInputEmail2'>Name</label>
            <Field
              name='q'
              component='input'
              type='text'
              placeholder='Search for ...'
              className='form-control'
            />
          </div>
          &nbsp;
          <div className='form-group'>
            <Field name='business' component='select' className='form-control'>
              <option value=''>All Business Type &nbsp;</option>
              {businessType.map((x, i) => <option key={x} value={x}>{x} &nbsp;</option>)}
            </Field>
          </div>
          &nbsp;
          <button disabled={submitting} type='submit' className='btn btn-default'>Filter</button>
          <Link to='/customers' className='btn btn-link pull-right'>Reset</Link>

        </form>
        <hr />
      </div>
    )
  }
}

let CustomerFilter = reduxForm({
  form: 'customerFilter' //, validate, warn
})(BaseCustomerFilter)

export { BaseCustomerFilter }
export default CustomerFilter
