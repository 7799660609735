import React from 'react'
import get from 'lodash/get'
import { change } from 'redux-form'
import { connect } from 'react-redux'

import QuestionnaireReviewForm from '../../components/Questionnaires/QuestionnaireReviewForm'
import CustomerWidget from '../../components/Customers/CustomerWidget'
import LocationWidget from '../../components/Customers/LocationWidget'
import ContactWidget from '../../components/Customers/ContactWidget'

import CertificationInformationWidget from '../../components/Questionnaires/CertificationInformationWidget'
import AdditionalInformationWidget from '../../components/Questionnaires/AdditionalInformationWidget'
import MandaysCalculationWidget from '../../components/Questionnaires/MandaysCalculationWidget'
import AnnexWidget from '../../components/Questionnaires/AnnexWidget'
import ReviewWidget from '../../components/Questionnaires/ReviewWidget'

import { annexWithValueSelector } from '../../selectors/questionnaires'

export function QuestionnaireReview2 (props) {
  const {
    accessToken,
    changeForm,
    fetchQuestionnaire,
    questionnaire,
    updateQuestionnaire,
    questionnaireAnnex
  } = props

  function handleSubmit (e) {
    let target
    const request = {
      id: questionnaire.id,
      review_detail: { ...e }
    }
    if (e.advance && e.review_result === 'Approved') {
      request.status = 'Approved'
    } else if (e.advance && e.review_result === 'Need to Revise') {
      request.status = 'Need to Revise'
    } else if (e.advance && e.review_result === 'Not Approved') {
      request.status = 'Not Approved'
    }
    if (e.advance) {
      target = `/questionnaires/${questionnaire.id}`
    }

    return updateQuestionnaire(request, accessToken, target).then((x) => {
      fetchQuestionnaire(questionnaire.id, accessToken)
    })
  }

  const customer = get(questionnaire, 'detail.copy.customer')
  const mainContact = get(questionnaire, 'detail.main_contact')

  return (
    <div className='tab-pane active'>
      <div className='row'>
        <div className='col-md-8'>
          <div className='row'>
            <div className='col-md-6'>
              <CustomerWidget customer={customer} accessToken={accessToken} />
            </div>
            <div className='col-md-6'>
              <ContactWidget contact={mainContact} accessToken={accessToken} title='Main Contact' />
            </div>
          </div>
          <CertificationInformationWidget questionnaire={questionnaire} accessToken={accessToken} />
          {questionnaire.locations.map((x, i) => (
            <LocationWidget
              key={i}
              location={x.location}
              detail={x.detail}
              accessToken={accessToken}
              title={`Audit Location : ${x.location.name}`}
            />
          ))}
          <AdditionalInformationWidget questionnaire={questionnaire} accessToken={accessToken} />
          <AnnexWidget annex={questionnaireAnnex} questionnaire={questionnaire} accessToken={accessToken} />
          <MandaysCalculationWidget questionnaire={questionnaire} accessToken={accessToken} />
          { questionnaire.review_detail &&
            <ReviewWidget questionnaire={questionnaire} accessToken={accessToken} />
          }
        </div>
        <div className='col-md-4'>
          <h1>Review Questionnaire</h1>
          <QuestionnaireReviewForm
            onSubmit={handleSubmit.bind(this)}
            changeForm={changeForm}
            initialValues={{
              number_of_site: questionnaire.locations.length,
              company_detail_complete: '',
              document_complete: '',
              implementation_complete: '',
              scope_mandays_appropriate: '',
              competence_personal: '',
              review_result: '',
              advance: false,
              ...questionnaire.review_detail
            }}
            fileAPI={{
              url: process.env.REACT_APP_BASE_API,
              accessToken: accessToken
            }}
          />
        </div>
      </div>
    </div>
  )
}

function mapStateToProps (state) {
  return {
    questionnaireAnnex: annexWithValueSelector(state)
  }
}

function mapDispatchToProps (dispatch) {
  return {
    changeForm: (field, value) => dispatch(change('QuestionnaireReviewForm', field, value))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionnaireReview2)
