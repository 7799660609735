import React from 'react'
import { Field, FieldArray, reduxForm } from 'redux-form'

import ConfirmButton from '../common/ConfirmButton'
import TableField from '../common/TableField'
import { TextField } from '../common/FormFields'
import FileField from '../common/FileField'

function validate (values) {
  return {}
}

export function QuestionnaireAdditionalInformation (props) {
  const { handleSubmit, submitting, changeForm, fileAPI } = props

  return (
    <div>
      <form className='form-horizontal questionnaire-additional-information-form' onSubmit={handleSubmit}>
        <p>The following documents must be sent to TUV NORD Indonesia after the contract is approved. <br/> If available you can upload the documents by now!</p>

        <Field name='detail.requested_information.quality_managament_manual' component={FileField} label='Quality Management Manual with Revision including scope, details of justification for any exclusions, policy, targets' api={fileAPI} />
        <Field name='detail.requested_information.legal_documents' component={FileField} label='Legal documents' api={fileAPI} />
        <Field name='detail.requested_information.organisation_chart' component={FileField} label='Current organisation chart of the company' api={fileAPI} />
        <Field name='detail.requested_information.business_process_map' component={FileField} label='Business Process Map' api={fileAPI} />
        <Field name='detail.requested_information.internal_audit' component={FileField} label='Results or summary of internal audits within the last 12 months' api={fileAPI} />
        <Field name='detail.requested_information.externak_audit' component={FileField} label='Results of the management review since the last external audit' api={fileAPI} />
        <Field name='detail.requested_information.last_audit_reports' component={FileField} label='Audit report and nonconformity management of the last certification body (additionally for transfer audits only)' api={fileAPI} />
        <Field name='detail.requested_information.last_audit_closing' component={FileField} label='Evidence of the last certification body that nonconformities are “closed” (additionally for transfer audits only)' api={fileAPI} />
        <Field name='detail.requested_information.last_certification' component={FileField} label='Certificates of the last certification body (additionally for transfer audits only)' api={fileAPI} />

        <FieldArray
          name='detail.ongoing_project'
          component={TableField}
          label='Ongoing project'
          heads={['Project name', 'Location']}
        />

        <FieldArray
          name='detail.within_last_year'
          component={TableField}
          label='Project done within last 1 year'
          heads={['Project name', 'Location']}
        />

        <Field hidden name='advance' component={TextField} label='Yes please' />

        <div className='form-group'>
          <div className='col-sm-offset-3 col-sm-6'>
            <button disabled={submitting} type='submit' className='btn btn-default btn-block btn-submit' onClick={ev => {
              ev.preventDefault()
              changeForm('advance', false)
              setTimeout(handleSubmit, 100)
            }} >Save Draft</button>
          </div>
          <div className='col-sm-3'>
            <ConfirmButton
              disabled={submitting}
              text={'Save Questionnaire'}
            >
              <button disabled={submitting} type='submit' className='btn-advance btn btn-primary btn-block btn-submit' onClick={ev => {
                ev.preventDefault()
                changeForm('advance', true)
                setTimeout(handleSubmit, 100)
              }} >Continue</button>
            </ConfirmButton>
          </div>
        </div>
      </form>
    </div>
  )
}

QuestionnaireAdditionalInformation = reduxForm({ // eslint-disable-line 
  form: 'QuestionnaireAdditionalInformationForm',
  enableReinitialize: true,
  validate: validate
})(QuestionnaireAdditionalInformation)

export default QuestionnaireAdditionalInformation
