import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'

// import AutoCompleteField from '../common/AutoCompleteField'
import orderStatuses from '../../const/orderStatuses'

class BaseOrderFilter extends Component {
  render () {
    const { handleSubmit, submitting } = this.props
    return (
      <div>
        <form className='form-inline' onSubmit={handleSubmit}>
          <div className='form-group'>
            <Field name='status' component='select' className='form-control'>
              <option value=''>All Status &nbsp;</option>
              {orderStatuses.map((x, i) => <option key={i} value={x.label}>{x.label} &nbsp;</option>)}
            </Field>
          </div>
          {
          // &nbsp;
          // <div className='form-group' style={{width: 150}}>
          //   <Field
          //     name='standard'
          //     component={AutoCompleteField}
          //     label='Standard'
          //     api={this.props.standardAPI}
          //   />
          // </div>
          }
          &nbsp;
          <div className='form-group'>
            <label className='sr-only' htmlFor='exampleInputEmail2'>Name</label>
            <Field
              name='q'
              component='input'
              type='text'
              placeholder='Search for ...'
              className='form-control'
            />
          </div>
          &nbsp;
          <button disabled={submitting} type='submit' className='btn btn-default'>Filter</button>
          <Link to='/orders' className='btn btn-link pull-right'>Reset</Link>

        </form>
        <hr />
      </div>
    )
  }
}

let OrderFilter = reduxForm({
  form: 'orderFilter' //, validate, warn
})(BaseOrderFilter)

export { BaseOrderFilter }
export default OrderFilter
