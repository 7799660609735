import axios from 'axios'
import { push } from 'react-router-redux'
import { createFlash, createError } from './flasher'

const baseAPI = process.env.REACT_APP_BASE_API

export const fetchDas = (params = '', accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_DAS_PENDING'
    })
    return axios({
      method: 'get',
      headers: {'Authorization': `Bearer ${accessToken}`},
      url: `${baseAPI}/daily-allowances${params}`
    }).then((x) => {
      dispatch({
        type: 'FETCH_DAS_FULFILLED',
        payload: x.data,
        pagination: {
          currentPage: x.headers['x-pagination-current-page'],
          pageCount: x.headers['x-pagination-page-count'],
          perPage: x.headers['x-pagination-per-page'],
          totalCount: x.headers['x-pagination-total-count']
        }
      })
    }).catch((e) => {
      console.error(e);
      dispatch(createError(e.response.data))
      dispatch({
        type: 'FETCH_DAS_REJECTED',
        payload: e
      })
    })
  }
}

export const fetchDa = (id, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_DA_PENDING'
    })
    return axios({
      method: 'get',
      headers: {'Authorization': `Bearer ${accessToken}`},
      url: `${baseAPI}/daily-allowances/${id}?expand=annex,detail`
    }).then((x) => {
      dispatch({
        type: 'FETCH_DA_FULFILLED',
        payload: x.data
      })
    }).catch((e) => {
      dispatch({
        type: 'FETCH_DA_REJECTED',
        payload: e
      })
    })
  }
}

export const createDa = (data, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'CREATE_DA_PENDING'
    })
    return axios({
      data,
      method: 'post',
      headers: {'Authorization': `Bearer ${accessToken}`},
      url: `${baseAPI}/daily-allowances`
    }).then((x) => {
      dispatch({
        type: 'CREATE_DA_FULFILLED',
        payload: x.data
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Standar created`
      }))
      dispatch(push(`/daily-allowances/${x.data.id}`))
    }).catch((e) => {
      dispatch({
        type: 'CREATE_DA_REJECTED',
        payload: e
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'error',
        message: `Update error`
      }))
    })
  }
}

export const updateDa = (data, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'UPDATE_DA_PENDING'
    })
    return axios({
      data,
      method: 'put',
      headers: {'Authorization': `Bearer ${accessToken}`},
      url: `${baseAPI}/daily-allowances/${data.id}?expand=annex,detail`
    }).then((x) => {
      dispatch({
        type: 'UPDATE_DA_FULFILLED',
        payload: x.data
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `standar created with the id ${x.data.id}`
      }))
    }).catch((e) => {
      dispatch({
        type: 'UPDATE_DA_REJECTED',
        payload: e
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'error',
        message: `Update error`
      }))
    })
  }
}

export const deleteDa = (id, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'DELETE_DA_PENDING'
    })
    return axios({
      method: 'delete',
      headers: {'Authorization': `Bearer ${accessToken}`},
      url: `${baseAPI}/daily-allowances/${id}`
    }).then((x) => {
      dispatch({
        type: 'DELETE_DA_FULFILLED',
        payload: x
      })
      dispatch(push('/daily-allowances'))
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `standar ${id} deleted`
      }))
    }).catch((e) => {
      dispatch({
        type: 'DELETE_DA_REJECTED',
        payload: e
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'error',
        message: `delete error`
      }))
    })
  }
}
