export const initialState = {
  customerList: { data: null, pagination: null, error: null, loading: false },
  customer: { data: null, error: null, loading: false },
  customerContactList: { data: [], error: null, loading: false },
  customerLocationList: { data: [], error: null, loading: false },
  customerContact: { data: null, error: null, loading: false },
  customerLocation: { data: null, error: null, loading: false }
}
export default (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_CUSTOMERS_PENDING':
      return { ...state, customerList: { data: null, error: null, loading: true } }
    case 'FETCH_CUSTOMERS_REJECTED':
      return { ...state, customerList: { data: null, error: action.payload, loading: false } }
    case 'FETCH_CUSTOMERS_FULFILLED':
      return { ...state,
        customerList: {
          data: action.payload,
          pagination: action.pagination,
          error: null,
          loading: false
        }
      }

    case 'FETCH_CUSTOMER_PENDING' :
      return { ...state, customer: { data: null, error: null, loading: true } }
    case 'FETCH_CUSTOMER_REJECTED':
      return { ...state, customer: { data: null, error: action.payload, loading: false } }
    case 'FETCH_CUSTOMER_FULFILLED' :
      return { ...state, customer: { data: { ...action.payload }, error: null, loading: false } }

    case 'UPDATE_CUSTOMER_PENDING' :
      return { ...state, customer: { ...state.activeCustomer, error: null, loading: true } }
    case 'UPDATE_CUSTOMER_REJECTED':
      return { ...state, customer: { ...state.activeCustomer, error: action.payload, loading: false } }
    case 'UPDATE_CUSTOMER_FULFILLED' :
      return { ...state, customer: { data: { ...action.payload }, error: null, loading: false } }

    case 'DELETE_CUSTOMER_PENDING' :
      return { ...state, customer: { ...state.activeCustomer, error: null, loading: true } }
    case 'DELETE_CUSTOMER_REJECTED':
      return { ...state, customer: { ...state.activeCustomer, error: action.payload, loading: false } }
    case 'DELETE_CUSTOMER_FULFILLED' :
      return { ...state, customer: { data: null, error: null, loading: false } }

    // customer contact
    case 'FETCH_CUSTOMER_CONTACTS_PENDING':
      return { ...state, customerContactList: { data: [], error: null, loading: true } }
    case 'FETCH_CUSTOMER_CONTACTS_REJECTED':
      return { ...state, customerContactList: { data: [], error: action.payload, loading: false } }
    case 'FETCH_CUSTOMER_CONTACTS_FULFILLED':
      return { ...state,
        customerContactList: {
          data: action.payload,
          error: null,
          loading: false
        }
      }

    case 'CREATE_CUSTOMER_CONTACT_PENDING':
      return { ...state, customerContact: { data: null, error: null, loading: true } }
    case 'CREATE_CUSTOMER_CONTACT_REJECTED':
      return { ...state, customerContact: { data: null, error: action.payload, loading: false } }
    case 'CREATE_CUSTOMER_CONTACT_FULFILLED':
      return { ...state,
        customerContact: {
          data: action.payload,
          error: null,
          loading: false
        }
      }

    case 'FETCH_CUSTOMER_CONTACT_PENDING':
      return { ...state, customerContact: { data: null, error: null, loading: true } }
    case 'FETCH_CUSTOMER_CONTACT_REJECTED':
      return { ...state, customerContact: { data: null, error: action.payload, loading: false } }
    case 'FETCH_CUSTOMER_CONTACT_FULFILLED':
      return { ...state,
        customerContact: {
          data: action.payload,
          error: null,
          loading: false
        }
      }

    // customer location
    case 'FETCH_CUSTOMER_LOCATIONS_PENDING':
      return { ...state, customerLocationList: { data: null, error: null, loading: true } }
    case 'FETCH_CUSTOMER_LOCATIONS_REJECTED':
      return { ...state, customerLocationList: { data: null, error: action.payload, loading: false } }
    case 'FETCH_CUSTOMER_LOCATIONS_FULFILLED':
      return { ...state,
        customerLocationList: {
          data: action.payload,
          error: null,
          loading: false
        }
      }

    // customer location
    case 'FETCH_CUSTOMER_LOCATION_PENDING':
      return { ...state, customerLocation: { data: null, error: null, loading: true } }
    case 'FETCH_CUSTOMER_LOCATION_REJECTED':
      return { ...state, customerLocation: { data: null, error: action.payload, loading: false } }
    case 'FETCH_CUSTOMER_LOCATION_FULFILLED':
      return { ...state,
        customerLocation: {
          data: action.payload,
          error: null,
          loading: false
        }
      }

    // customer location
    case 'UPDATE_CUSTOMER_LOCATION_PENDING':
      return { ...state, customerLocation: { ...state.customerLocation, error: null, loading: true } }
    case 'UPDATE_CUSTOMER_LOCATION_REJECTED':
      return { ...state, customerLocation: { ...state.customerLocation, error: action.payload, loading: false } }
    case 'UPDATE_CUSTOMER_LOCATION_FULFILLED':
      return { ...state,
        customerLocation: {
          data: action.payload,
          error: null,
          loading: false
        }
      }

    // customer contact
    case 'UPDATE_CUSTOMER_CONTACT_PENDING':
      return { ...state, customerContact: { ...state.customerContact, error: null, loading: true } }
    case 'UPDATE_CUSTOMER_CONTACT_REJECTED':
      return { ...state, customerContact: { ...state.customerContact, error: action.payload, loading: false } }
    case 'UPDATE_CUSTOMER_CONTACT_FULFILLED':
      return { ...state,
        customerContact: {
          data: action.payload,
          error: null,
          loading: false
        }
      }

    case 'LOGOUT_FULFILLED':
      return { ...initialState }

    default:
      return state
  }
}
