import React from 'react'
import { connect } from 'react-redux'
// import { Link } from 'react-router-dom'

import { fetchCustomerLocation, deleteCustomerLocation, updateCustomerLocation } from '../../actions/customers'

import { CustomerLocationUpdateForm as CustomerLocationForm } from '../../components/Customers/CustomerLocationForm'
import DeleteForm from '../../components/common/DeleteForm'

export class CustomerLocationUpdate extends React.Component {
  componentWillMount () {
    this.props.fetchCustomerLocation(this.props.match.params.cust_id, this.props.match.params.id, this.props.accessToken)
  }

  handleDelete (e) {
    return this.props.deleteCustomerLocation(
      this.props.match.params.cust_id,
      this.props.location.id,
      this.props.accessToken
    )
  }

  handleSubmit (e) {
    if (!e.default) {
      e.default = 0
    }
    return this.props.updateCustomerLocation({
      ...e,
      customer_id: this.props.match.params.cust_id
    }, this.props.accessToken)
  }

  render () {
    return (
      <div className='content'>
        <div className='main-header'>
          <h2>Update {this.props.location && this.props.location.name}</h2>
          <em className='hide'>normal sidebar with minified mode by default</em>
        </div>
        <div className='main-content'>
          <div className='row'>
            <div className='col-md-8'>
              {
                this.props.location
                  ? <div>
                    <CustomerLocationForm
                      onSubmit={this.handleSubmit.bind(this)}
                      initialValues={this.props.location}
                    />
                    <DeleteForm onClick={this.handleDelete.bind(this)} />
                  </div>
                  : <div>loading</div>
              }
            </div>
            <div className='col-md-4'>
              <div className='widget hide'>
                <div className='widget-header'>
                  <h3><i className='fa fa-edit' /> Widget</h3>
                </div>
                <div className='widget-content'>
                  <p>Widget content</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    location: state.customers.customerLocation.data,
    accessToken: state.auth.user.access.access_token
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchCustomerLocation: (custId, id, key) => dispatch(fetchCustomerLocation(custId, id, key)),
    updateCustomerLocation: (data, key) => dispatch(updateCustomerLocation(data, key)),
    deleteCustomerLocation: (custId, id, key) => dispatch(deleteCustomerLocation(custId, id, key))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerLocationUpdate)
