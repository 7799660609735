import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import Inside from './Inside'
import UserList from './Users/UserList'
import UserNew from './Users/UserNew'
import UserUpdate from './Users/UserUpdate'
import UserUpdateSpec from './Users/UserUpdateSpec'
import AuditorMatrix from './Users/AuditorMatrix'

function mapStateToProps (state) {
  return {

  }
}

export class Users extends React.Component {
  render () {
    return (
      <Inside>
        <Switch>
          <Route path='/users/auditor-experts' component={AuditorMatrix} />
          <Route path='/users/new' component={UserNew} />
          <Route path='/users/:id/spec' component={UserUpdateSpec} />
          <Route path='/users/:id' component={UserUpdate} />
          <Route exact path='/users' component={UserList} />
        </Switch>
      </Inside>
    )
  }
}

export default connect(
  mapStateToProps
// Implement map dispatch to props
)(Users)
