import React from 'react'
import DownloadLink from '../../components/common/DownloadLink'

export default function CustomerWidget ({ customer, accessToken, ...props }) {
  return (
    <div className='widget'>
      <div className='widget-header'>
        <h3><i className='fa fa-user' /> Customer details</h3>
      </div>
      <div className='widget-content'>

        <table className='table table-hover table-striped'>
          <tbody>
            <tr>
              <th>Name</th>
              <td>{customer.name}</td>
            </tr>

            <tr>
              <th> Business </th>
              <td> {customer.business} </td>
            </tr>

            <tr>
              <th> NPWP </th>
              <td> {customer.npwp} </td>
            </tr>

            <tr>
              <th> Legal document </th>
              <td>  
                { customer.legal_document
                  ? <DownloadLink accessToken={accessToken} file={customer.legal_document} />
                  : <span>n/a</span>
                }
              </td>
            </tr>

            <tr>
              <th> Email </th>
              <td> {customer.email} </td>
            </tr>

            <tr>
              <th> Website </th>
              <td> {customer.website} </td>
            </tr>
            </tbody>
          </table>

      </div>
    </div>
  )
}
