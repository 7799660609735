import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
// import { Route, Switch } from 'react-router-dom'

import Inside from './Inside'

import AuditorScheduleTable from '../components/Orders/AuditorScheduleTable'

import { fetchSchedules, fetchGlobalSchedules } from '../actions/schedules'
import { fetchAuditors } from '../actions/users'

import { auditorsScheduleSelector } from '../selectors/schedules'

export class ScheduleMatrix extends React.Component {
  constructor (props) {
    super(props)
    const day1 = moment()
    const start = day1.format('YYYY-MM-01')
    const end = day1.add(59, 'days').endOf('month').format('YYYY-MM-DD')
    this.state = {
      start,
      end
      // selectedAuditors: []
    }
  }

  componentWillMount () {
    this.props.fetchGlobalSchedules(this.props.accessToken, {
      start: this.state.start,
      end: this.state.end
    })
    this.props.fetchAuditors(this.props.accessToken)
    // this.props.fetchOrder(this.props.match.params.orderId, this.props.accessToken)
    // this.props.fetchSchedules(this.props.match.params.orderId, this.props.accessToken)

  //   if (this.props.match.params.id === 'manage') {
  //   }
  }

  handleUpdateFilter (e) {
    const x = moment(e + '-01')
    const start = x.format('YYYY-MM-DD')
    x.add(59, 'days').endOf('month')
    const end = x.format('YYYY-MM-DD')
    if (start !== this.state.start) {
      this.setState({start, end})
      this.props.fetchSchedules(this.props.match.params.orderId, this.props.accessToken, {start, end, expand: 'user'})
    }
  }

  render () {
    const { auditorsSchedule } = this.props
    return (
      <Inside>
        <div className='content'>
          <div>
            <div className='main-header'>
              <h2>Schedule Matrix</h2>
              {
                // <em>For Order { order && <Link to={`/orders/${order.id}`}>{order.id}</Link> }</em>
              }
            </div>
            <div className='row'>
              <div className='col-md-12'>
                { auditorsSchedule
                  ? <AuditorScheduleTable
                    start={this.state.start}
                    end={this.state.end}
                    onUpdateFilter={this.handleUpdateFilter.bind(this)}
                    auditorsSchedule={auditorsSchedule}
                  />
                  : <div>loading</div>
                }
              </div>
            </div>
          </div>
        </div>
      </Inside>
    )
  }
}

function mapStateToProps (state) {
  return {
    // formValue: getFormValues('ScheduleGroupForm')(state),
    // order: state.orders.order.data,
    // schedules: state.schedules.scheduleList.data,
    auditors: state.users.auditorList.data,
    auditorsSchedule: auditorsScheduleSelector(state),
    accessToken: state.auth.user.access.access_token
  }
}

function mapDispatchToProps (dispatch) {
  return {
    // fetchOrder: (id, key) => dispatch(fetchOrder(id, key)),
    // updateOrder: (data, key, target) => dispatch(updateOrder(data, key, target)),
    fetchSchedules: (id, key, params) => dispatch(fetchSchedules(id, key, params)),
    fetchGlobalSchedules: (key, params) => dispatch(fetchGlobalSchedules(key, params)),
    fetchAuditors: (key) => dispatch(fetchAuditors(key))
    // addAuditors: (data) => dispatch(arrayPush('ScheduleGroupForm', 'auditors', data))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
// Implement map dispatch to props
)(ScheduleMatrix)
