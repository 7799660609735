import React from 'react'

export default class BasicModal extends React.Component {
  componentWillMount () {
    this.setState({
      open: this.props.open || false
    })
  }

  render () {
    const { open } = this.props

    if (open) {
      return (
        <div>
          <div className='modal fade in' id='myModal' tabIndex='-1' role='dialog' style={{display: 'block', paddingRight: '15px'}} >
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <button onClick={this.props.onClose} type='button' className='close' data-dismiss='modal' aria-hidden='true'>×</button>
                  <h4 className='modal-title' id='myModalLabel'>{this.props.title || ''}</h4>
                </div>
                <div className='modal-body'>
                  {this.props.children}
                </div>
              </div>
            </div>
          </div>
          <div className='modal-backdrop fade in' onClick={this.props.onClose} />
        </div>
      )
    } else {
      return null
    }
  }
}
