import React from 'react'
import { connect } from 'react-redux'
import { Link, Switch, Route } from 'react-router-dom'
import intersection from 'lodash/intersection'

import ItemLog from '../../components/common/ItemLog'
import Pagination from '../../components/common/Pagination'

import InvoiceTable from '../../components/Orders/InvoiceTable'
import { InvoiceCreateForm, InvoiceUpdateForm } from '../../components/Orders/InvoiceForm'
import InvoiceDetail from '../../components/Orders/InvoiceDetail'

import { fetchOrder } from '../../actions/orders'
import { fetchInvoices, fetchInvoice, createInvoice, updateInvoice } from '../../actions/invoices'

export class OrderInvoiceView extends React.Component {
  componentWillMount () {
    this.props.fetchOrder(this.props.match.params.orderId, this.props.accessToken)

    if (this.props.match.params.id && this.props.match.params.id !== 'new') {
      this.props.fetchInvoice(
        this.props.match.params.orderId,
        this.props.match.params.id,
        this.props.accessToken
      )
    }
    if (!this.props.match.params.id) {
      this.props.fetchInvoices(this.props.match.params.orderId, this.props.accessToken)
    }
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.invoice && nextProps.invoice) {
    }
  }

  handleSubmitNew (e) {
    return this.props.createInvoice(
      { ...e,
        sub_total: e.total,
        order_id: this.props.match.params.orderId
      },
      this.props.accessToken
    )
  }

  handleSubmitUpdate (e) {
    return this.props.updateInvoice(
      { ...e,
        sub_total: e.total,
        order_id: e.order.id
      },
      this.props.accessToken
    )
  }

  render () {
    const { order, invoice, invoices, user, accessToken } = this.props
    return (
      <div>
        <div className='content'>
          <Switch>
            <Route path='/orders/:orderId/invoices/new'>
              <div>
                <div className='main-header'>
                  <h2>New Invoice</h2>
                  { order &&
                    <em>For Order <Link to={`/orders/${order.id}`}>{order.id}</Link></em>
                  }
                </div>
                <div className='row'>
                  <div className='col-md-8'>
                    <InvoiceCreateForm
                      onSubmit={this.handleSubmitNew.bind(this)}
                      fileAPI={{
                        url: process.env.REACT_APP_BASE_API,
                        accessToken: accessToken
                      }}
                      initialValues={{
                        status: 'Draft'
                      }}
                    />
                  </div>
                </div>
              </div>
            </Route>
            <Route path='/orders/:orderId/invoices/:id'>
              <div>
                <div className='main-header'>
                  { invoice &&
                    <h2>Update Invoice {invoice.id}</h2>
                  }
                  { order &&
                    <em>For Order <Link to={`/orders/${order.id}`}>{order.id}</Link></em>
                  }
                </div>
                <div className='row'>
                  <div className='col-md-8'>
                    { order && invoice && user
                      ? <div>
                        { intersection(['Finance', 'Head of CB', 'Super Admin'], user.data.role).length > 0
                          ? <InvoiceUpdateForm
                            onSubmit={this.handleSubmitUpdate.bind(this)}
                            initialValues={invoice}
                            fileAPI={{
                              url: process.env.REACT_APP_BASE_API,
                              accessToken: accessToken
                            }}
                          />
                          : <p>Access denied</p>
                        }
                        { order.users.filter((x) => x.user.id === user.id).length > 0 &&
                          <div className='widget'>
                            <div className='widget-content'>
                              <InvoiceDetail invoice={invoice} />
                            </div>
                          </div>
                        }
                      </div>
                      : <div>loading</div>
                    }
                  </div>
                  <div className='col-md-4'>
                    { invoice
                      ? <div>
                        <p>
                          <a href={`${process.env.REACT_APP_BASE_API}/pdf/invoice?template=invoice&id=${invoice.id}&token=${accessToken}`} className='btn btn-block btn-default'>Download PDF</a>
                        </p>
                        <ItemLog log={invoice.log} entity='Order Invoice' />
                      </div>
                      : <div>loading</div>
                    }
                  </div>
                </div>
              </div>
            </Route>
            <Route path='/orders/:orderId/invoices'>
              <div>
                <div className='main-header'>
                  <h2>Invoices</h2>
                  { order &&
                    <em>For Order <Link to={`/orders/${order.id}`}>{order.id}</Link></em>
                  }
                </div>
                <div className='row'>
                  <div className='col-md-8'>
                    { invoices &&
                      <InvoiceTable invoices={invoices} />
                    }
                    <Pagination />
                  </div>
                </div>
              </div>
            </Route>
          </Switch>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    order: state.orders.order.data,
    invoices: state.invoices.invoiceList.data,
    invoice: state.invoices.invoice.data,
    accessToken: state.auth.user.access.access_token,
    user: state.auth.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOrder: (id, accessToken) => dispatch(fetchOrder(id, accessToken)),
    fetchInvoices: (id, accessToken) => dispatch(fetchInvoices(id, accessToken)),
    fetchInvoice: (orderId, id, accessToken) => dispatch(fetchInvoice(orderId, id, accessToken)),
    createInvoice: (data, accessToken) => dispatch(createInvoice(data, accessToken)),
    updateInvoice: (data, accessToken) => dispatch(updateInvoice(data, accessToken))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
// Implement map dispatch to props
)(OrderInvoiceView)
