import React from 'react'
import { Link } from 'react-router-dom'

export default class OrderSchedule extends React.Component {
  render () {
    const { schedules, order } = this.props
    return (
      <div className='widget'>
        <div className='widget-header'>
          <h3><i className='fa fa-tasks' /> Schedule</h3>
          {schedules.length > 0 && schedules[0].status === 'Approved'
            ? <span>&nbsp;</span>
            : <div className='widget-header-toolbar'>
              {
              // <Link to={`/orders/${order.id}/schedules/manage`} className='btn btn-link btn-xs'><i className='fa fa-gear' />Manage</Link>
              // <Link to={`/orders/${order.id}/schedules/review`} className='btn btn-link btn-xs'><i className='fa fa-gear' />Review</Link>
              }
            </div>
          }
        </div>
        <div className=''>
          <table className='table' style={{ marginBottom: 0 }}>
            <thead>
              <tr>
                <th>Schedule</th>
                <th>User</th>
                <th>Role</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              { schedules.length === 0 &&
                <tr>
                  <td colSpan={3}>
                    <p>Not available</p>
                    {
                      // ['Scheduler', 'Super Admin'].indexOf(user.data.role) > -1
                      // ? <Link to={`/orders/${order.id}/schedules/manage`} className='btn btn-default'><i className='fa fa-plus' />Create Audit Schedule</Link>
                      // : <p>Not available</p>
                    }
                  </td>
                </tr>
              }
              { schedules.map((x, y) => {
                return (
                  <tr key={y}>
                    { <td><Link to={`/orders/${order.id}/schedules/${x.user.id}`}>{x.type}</Link></td> }
                    <td><Link to={`/users/${x.user.id}`}>{x.user.data.display_name}</Link></td>
                    <td>{x.role}</td>
                    <td>{x.start_date} ({x.duration}{x.duration === 1 ? 'day' : 'days'})</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}
