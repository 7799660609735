import axios from 'axios'
import { push } from 'react-router-redux'
import { createFlash, createError } from './flasher'

const baseAPI = process.env.REACT_APP_BASE_API

export const fetchUsers = (params = '', accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_USERS_PENDING'
    })
    return axios({
      method: 'get',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/users${params}`
    }).then((x) => {
      dispatch({
        type: 'FETCH_USERS_FULFILLED',
        payload: x.data,
        pagination: {
          currentPage: x.headers['x-pagination-current-page'],
          pageCount: x.headers['x-pagination-page-count'],
          perPage: x.headers['x-pagination-per-page'],
          totalCount: x.headers['x-pagination-total-count']
        }
      })
    }).catch((e) => {
      console.error(e)
      dispatch(createError(e.message))
      dispatch({
        type: 'FETCH_USERS_REJECTED',
        payload: e
      })
    })
  }
}

export const fetchUser = (id, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_USER_PENDING'
    })

    return axios({
      method: 'get',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/users/${id}`
    }).then((x) => {
      dispatch({
        type: 'FETCH_USER_FULFILLED',
        payload: x.data
      })
    }).catch((e) => {
      // console.error(e)
      // dispatch(createError(e.message))
      dispatch({
        type: 'FETCH_USER_REJECTED',
        payload: e
      })
    })
  }
}

export const createUser = (data, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'CREATE_USER_PENDING'
    })
    return axios({
      data,
      method: 'post',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/users`
    }).then((x) => {
      dispatch({
        type: 'CREATE_USER_FULFILLED',
        payload: x
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `User created with the id ${x.data.id}`
      }))
      return x.data
      // dispatch(push(`/users/${x.data.id}`))
      // dispatch(push(`/users`))
    }).catch((e) => {
      console.error(e)
      dispatch(createError(e.message))
      dispatch({
        type: 'CREATE_USER_REJECTED',
        payload: e
      })
    })
  }
}

export const updateUser = (data, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'UPDATE_USER_PENDING'
    })
    return axios({
      data,
      method: 'put',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/users/${data.id}`
    }).then((x) => {
      dispatch({
        type: 'UPDATE_USER_FULFILLED',
        payload: x.data
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `user created with the id ${x.data.id}`
      }))
    }).catch((e) => {
      console.error(e)
      dispatch(createError(e.message))
      dispatch({
        type: 'UPDATE_USER_REJECTED',
        payload: e
      })
    })
  }
}

export const deleteUser = (id, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'DELETE_USER_PENDING'
    })
    return axios({
      method: 'delete',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/users/${id}`
    }).then((x) => {
      dispatch({
        type: 'DELETE_USER_FULFILLED',
        payload: x
      })
      dispatch(push('/users'))
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `user ${id} deleted`
      }))
    }).catch((e) => {
      console.error(e)
      dispatch(createError(e.message))
      dispatch({
        type: 'DELETE_USER_REJECTED',
        payload: e
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'error',
        message: `delete error`
      }))
    })
  }
}

export const fetchSpecificationByUser = (id, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_SPECIFICATIONS_PENDING'
    })
    return axios({
      method: 'get',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/users/${id}/specifications`
    }).then((x) => {
      dispatch({
        type: 'FETCH_SPECIFICATIONS_FULFILLED',
        payload: x.data
      })
    }).catch((e) => {
      console.error(e)
      dispatch(createError(e.message))
      dispatch({
        type: 'FETCH_SPECIFICATIONS_REJECTED',
        payload: e
      })
    })
  }
}

export const createSpecificationByUser = (id, data, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'CREATE_SPECIFICATION_PENDING'
    })
    return axios({
      data,
      method: 'post',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/users/${id}/specifications`
    }).then((x) => {
      dispatch({
        type: 'CREATE_SPECIFICATION_FULFILLED',
        payload: x.data
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Specification created`
      }))
    }).catch((e) => {
      console.error(e)
      dispatch(createError(e.message))
      dispatch({
        type: 'CREATE_SPECIFICATION_REJECTED',
        payload: e
      })
    })
  }
}

export const updateSpecification = (data, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'UPDATE_SPECIFICATION_PENDING'
    })
    return axios({
      data,
      method: 'put',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/users/${data.user_id}/specifications/${data.id}`
    }).then((x) => {
      dispatch({
        type: 'UPDATE_SPECIFICATION_FULFILLED',
        payload: x.data
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Specification ${x.data.id} updated`
      }))
    }).catch((e) => {
      console.error(e)
      dispatch(createError(e.message))
      dispatch({
        type: 'UPDATE_SPECIFICATION_REJECTED',
        payload: e
      })
    })
  }
}

export const deleteSpecification = (data, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'DELETE_SPECIFICATION_PENDING'
    })
    return axios({
      method: 'delete',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/users/${data.user_id}/specifications/${data.id}`
    }).then((x) => {
      dispatch({
        type: 'DELETE_SPECIFICATION_FULFILLED',
        payload: data
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Specification ${data.id} deleted`
      }))
    }).catch((e) => {
      console.error(e)
      dispatch(createError(e.message))
      dispatch({
        type: 'DELETE_SPECIFICATION_REJECTED',
        payload: e
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'error',
        message: `delete error`
      }))
    })
  }
}

export const fetchAuditors = (accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_AUDITORS_PENDING'
    })
    return axios({
      method: 'get',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/users?auditors&per-page=0&expand=specifications`
    }).then((x) => {
      dispatch({
        type: 'FETCH_AUDITORS_FULFILLED',
        payload: x.data,
        pagination: {
          currentPage: x.headers['x-pagination-current-page'],
          pageCount: x.headers['x-pagination-page-count'],
          perPage: x.headers['x-pagination-per-page'],
          totalCount: x.headers['x-pagination-total-count']
        }
      })
    }).catch((e) => {
      console.error(e)
      dispatch(createError(e.message))
      dispatch({
        type: 'FETCH_AUDITORS_REJECTED',
        payload: e
      })
    })
  }
}
