import React, { Component } from 'react'
import { connect } from 'react-redux'

import { removeFlash } from '../../actions/flasher'
import './Flasher.scss'
/*

<div id="gritter-item-3" className="gritter-item-wrapper " role="alert">
  <div className="gritter-item">
    <div className="gritter-without-image">
      <span className="gritter-title">Sticky Alert!</span>
      <p>You have <a >new support message</a>. Click (x) on the top right to close this message.</p>
    </div>
    <div style={{clear:'both'}}></div>
  </div>
</div>

<div id="gritter-item-3" className="gritter-item-wrapper " role="alert">
  <div className="gritter-item">
    <img className='gritter-image' src="https://api.fnkr.net/testimg/48x48/00CED1/FFF/?text=x" />
    <div className="gritter-with-image">
      <span className="gritter-title">Sticky Alert!</span>
      <p>You have <a >new support message</a>. Click (x) on the top right to close this message.</p>
    </div>
    <div style={{clear:'both'}}></div>
  </div>
</div>
</div>

<div key={x.id}>{`${x.type} : ${x.message}`}</div>

 */

class Flasher extends Component {
  render () {
    const {flasher} = this.props
    // const flasher = [{message: 'my hello world'}]
    return (
      <div id='gritter-notice-wrapper'>
        { flasher.map((x) => {
          return (
            <div key={x.id} className='gritter-item-wrapper ' role='alert' onClick={this.props.removeFlash.bind(this, x.id)}>
              <div className='gritter-item' >
                <div className='gritter-without-image'>
                  {/*<span className='gritter-title'>Hi!</span>*/}
                  <p dangerouslySetInnerHTML={{__html: x.message}} />
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    flasher: state.flasher
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    removeFlash: (id) => dispatch(removeFlash(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Flasher)
