import React, { useEffect, useState, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import {
  fetchCustomer, deleteCustomer, updateCustomer,
  fetchCustomerLocations, fetchCustomerContacts
} from '../../actions/customers'

import { CustomerUpdateForm as CustomerForm } from '../../components/Customers/CustomerForm'
import ContactTable from '../../components/Customers/ContactTable'
import LocationTable from '../../components/Customers/LocationTable'

export function CustomerUpdate ({
  customer,
  contacts,
  locations,
  accessToken,
  fetchCustomer,
  fetchCustomerContacts,
  fetchCustomerLocations,
  match,
  deleteCustomer,
  updateCustomer
}) {
  const [ tab, setTab ] = useState(match.params.tab || 'customer')

  useEffect(() => {
    fetchCustomer(match.params.id, accessToken)
    fetchCustomerContacts(match.params.id, accessToken)
    fetchCustomerLocations(match.params.id, accessToken)
  }, [])

  // function handleDelete (e) {
  //   return deleteCustomer(e, accessToken)
  // }

  function handleSubmit (e) {
    return updateCustomer(e, accessToken)
  }

  if (!customer || !contacts || !locations) {
    return (<div>loading</div>)
  }

  return (
    <div className='content'>
      <div className='main-header'>
        <h2>Update Customer {customer && customer.name}</h2>
        {/* <em>normal sidebar with minified mode by default</em> */}
      </div>

      <div className='main-content'>
        <div className='widget'>
          <div className='widget-content'>
            <div className='wizard' style={{ marginBottom: 20 }}>
              <ul className='steps'>
                <li className={tab === 'customer' ? 'active' : ''}>
                  <span className={!tab ? 'badge badge-info' : 'badge'}>1</span>
                  { tab === 'customer'
                    ? <span>Basic Information</span>
                    : <Link onClick={() => setTab('customer')} to={`/customers/${match.params.id}`}>Basic Information</Link>
                  }
                  <span className='chevron' />
                </li>
                <li className={tab === 'locations' ? 'active' : ''}>
                  <span className={tab === 'locations' ? 'badge badge-info' : 'badge'}>2</span>
                  { tab === 'locations'
                    ? <span>Addresses</span>
                    : <Link onClick={() => setTab('locations')} to={`/customers/${match.params.id}/locations`}>Addresses</Link>
                  }
                  <span className='chevron' />
                </li>
                <li className={tab === 'contacts' ? 'active' : ''}>
                  <span className={tab === 'contacts' ? 'badge badge-info' : 'badge'}>3</span>
                  { tab === 'contacts'
                    ? <span>Contacts</span>
                    : <Link onClick={() => setTab('contacts')} to={`/customers/${match.params.id}/contacts`}>Contacts</Link>
                  }
                  <span className='chevron' />
                </li>
                <li className={tab === 'other' ? 'active last' : ' last'}>
                  <span className={tab === 'other' ? 'badge badge-info' : 'badge'}>4</span>
                  { tab === 'other'
                    ? <span>Other Information</span>
                    : <Link onClick={() => setTab('other')} to={`/customers/${match.params.id}/other`}>Other Information</Link>
                  }
                </li>
              </ul>
            </div>
            <div className='row'>
              <div className='col-md-8'>
                { tab === 'customer' &&
                  <Fragment>
                    {
                      customer !== null
                        ? <CustomerForm
                          onSubmit={handleSubmit.bind(this)}
                          initialValues={customer}
                          fileAPI={{
                            url: process.env.REACT_APP_BASE_API,
                            accessToken: accessToken
                          }}
                          basic
                        />
                        : <div> loading</div>
                    }
                  </Fragment>
                }
                { tab === 'other' &&
                  <Fragment>
                    {
                      customer !== null
                        ? <CustomerForm
                          onSubmit={handleSubmit.bind(this)}
                          initialValues={customer}
                          fileAPI={{
                            url: process.env.REACT_APP_BASE_API,
                            accessToken: accessToken
                          }}
                          other
                        />
                        : <div> loading</div>
                    }
                  </Fragment>
                }
                { tab === 'contacts' &&
                  <Fragment>
                    <ContactTable
                      customer={customer}
                      contacts={contacts}
                    />
                  </Fragment>
                }
                { tab === 'locations' &&
                <Fragment>
                  <LocationTable
                    customer={customer}
                    locations={locations}
                  />
                </Fragment>
                }
              </div>
              <div className='col-md-4'>
                { tab === 'customer' &&
                  <button className='btn btn-link' onClick={() => setTab('locations')}>Next : Addresses</button>
                }
                { tab === 'locations' &&
                  <button className='btn btn-link' onClick={() => setTab('contacts')}>Next : Contacts</button>
                }
                { tab === 'contacts' &&
                  <Fragment>
                    <button className='btn btn-link' onClick={() => setTab('other')}>Next : Other Information</button> <br/>
                    <Link className='btn btn-link' to={`/questionnaires/new?customer=${customer.id}`}>Create Questionniare <i className="fa fa-external-link"/></Link>
                  </Fragment>
                }
                <br/>
                { tab === 'other' &&
                  <Link className='btn btn-link' to={`/questionnaires/new?customer=${customer.id}`}>Create Questionniare <i className="fa fa-external-link"/></Link>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps (state) {
  return {
    customer: state.customers.customer.data,
    contacts: state.customers.customerContactList.data,
    locations: state.customers.customerLocationList.data,
    accessToken: state.auth.user.access.access_token
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchCustomer: (id, key) => dispatch(fetchCustomer(id, key)),
    updateCustomer: (data, key) => dispatch(updateCustomer(data, key)),
    deleteCustomer: (id, key) => dispatch(deleteCustomer(id, key)),
    fetchCustomerContacts: (id, key) => dispatch(fetchCustomerContacts(id, key)),
    fetchCustomerLocations: (id, key) => dispatch(fetchCustomerLocations(id, key))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerUpdate)
