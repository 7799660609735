import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Inside from './Inside'
import QuestionnaireList from './Questionnaires/QuestionnaireList'
import QuestionnaireNew from './Questionnaires/QuestionnaireNew'
import QuestionnaireView2 from './Questionnaires/QuestionnaireView2'

export default function Questionnaires (props) {
  return (
    <Inside>
      <Switch>
        <Route path='/questionnaires/new' component={QuestionnaireNew} />
        <Route path='/questionnaires/:id/:stage?' component={QuestionnaireView2} />
        <Route exact path='/questionnaires' component={QuestionnaireList} />
      </Switch>
    </Inside>
  )
}
