import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { fetchAuditors } from '../../actions/users'
import { fetchStandards } from '../../actions/standards'
import AuditorTable from '../../components/Users/AuditorTable'

export function ScheduleMatrix (props) {
  useEffect(() => {
    props.fetchAuditors(props.accessToken)
    props.fetchStandards('', props.accessToken)
  }, [props])

  return (
    <div className='content'>
      <div>
        <div className='main-header'>
          <h2>Auditor Matrix</h2>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            { props.auditors && props.standards
              ? <AuditorTable auditors={props.auditors} standards={props.standards} />
              : <div>loading</div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps (state) {
  return {
    auditors: state.users.auditorList.data,
    standards: state.standards.standardList.data,
    accessToken: state.auth.user.access.access_token
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchAuditors: (key) => dispatch(fetchAuditors(key)),
    fetchStandards: (params, key) => dispatch(fetchStandards(params, key))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleMatrix)
