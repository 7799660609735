import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'

import { TextField, TextareaField, OptionsField, AutoCompleteField } from '../common/FormFields'
import FileField from '../common/FileField'
import SelectOrInputField from '../common/SelectOrInputField'
import ConfirmButton from '../common/ConfirmButton'

import entityType from '../../const/entityType'
import businessType from '../../const/businessType'
import titles from '../../const/titles'

const selector = formValueSelector('QuestionnaireGeneralInformationForm')
const validate = (values) => {
  const errors = {}
  if (!values.customer) {
    errors.customer = 'Please select customer.'
  }
  if (!values.contact) {
    errors.contact = 'Please select contact person.'
  }
  if (!values.location) {
    errors.location = 'Please select main address.'
  }
  return errors
}

export function QuestionnaireGeneralInformationForm ({
  handleSubmit,
  submitting,
  currentCustomer,
  currentContact,
  currentLocation,
  changeForm,
  push,
  ...props
}) {
  useEffect(() => {
    // set empty to "sub field"
    changeForm('contact', null)
    changeForm('location', null)
    changeForm('copy', null)

    if (currentCustomer) {
      changeForm('copy.customer.name', currentCustomer.value.name)
      changeForm('copy.customer.business', currentCustomer.value.business)
      changeForm('copy.customer.npwp', currentCustomer.value.npwp)
      changeForm('copy.customer.legal_document', currentCustomer.value.legal_document)
      changeForm('copy.customer.email', currentCustomer.value.email)
      changeForm('copy.customer.website', currentCustomer.value.website)
      if (currentCustomer.value.detail) {
        changeForm('copy.customer.detail.type', currentCustomer.value.detail.type)
      }
      if (currentCustomer.value.default_contact) {
        changeForm('contact', {
          label: currentCustomer.value.default_contact.name,
          value: currentCustomer.value.default_contact
        })
      }
      if (currentCustomer.value.default_location) {
        changeForm('location', {
          label: currentCustomer.value.default_location.name,
          value: currentCustomer.value.default_location
        })
      }
    }
  }, [currentCustomer])

  useEffect(() => {
    if (currentContact) {
      changeForm('main_contact.title', currentContact.value.title)
      changeForm('main_contact.first_name', currentContact.value.first_name)
      changeForm('main_contact.middle_name', currentContact.value.middle_name)
      changeForm('main_contact.surname', currentContact.value.surname)
      changeForm('main_contact.job_title', currentContact.value.job_title)
      changeForm('main_contact.department', currentContact.value.department)
      changeForm('main_contact.email', currentContact.value.email)
      changeForm('main_contact.phone', currentContact.value.phone)
      changeForm('main_contact.mobile_phone', currentContact.value.mobile_phone)
      changeForm('main_contact.fax', currentContact.value.fax)
      if (currentContact.value.contact_detail) {
        changeForm('main_contact.contact_detail.additional_email', currentContact.value.contact_detail.additional_email)
      }
    }
  }, [currentContact])

  useEffect(() => {
    if (currentLocation) {
      changeForm('main_location.name', currentLocation.value.name)
      changeForm('main_location.address', currentLocation.value.address)
      changeForm('main_location.phone', currentLocation.value.phone)
      changeForm('main_location.fax', currentLocation.value.fax)
      changeForm('main_location.postal_code', currentLocation.value.postal_code)
      changeForm('main_location.country', currentLocation.value.country)
      changeForm('main_location.state', currentLocation.value.state)
      changeForm('main_location.city', currentLocation.value.city)
    }
  }, [currentLocation])

  return (
    <form className='form-horizontal questionnaire-basic-form' onSubmit={handleSubmit}>
      { !currentCustomer &&
        <fieldset>
          <legend>Customer</legend>
          <Field name='customer' component={AutoCompleteField} label='Customer' api={props.customerAPI} required openNew={() => push('/customers/new')} />
        </fieldset>
      }
      { currentCustomer &&
        <fieldset>
          <legend>Customer</legend>
          <div className='well'>
            <Field disabled name='customer' component={AutoCompleteField} label='Customer' api={props.customerAPI} required />
            <Field disabled name='copy.customer.name' type='text' component={TextField} label='Customer Legal Name' required />
            <Field disabled name='copy.customer.detail.type' component={SelectOrInputField} label='Entity Type' options={entityType} required placeholder='Please insert here!' />
            <Field disabled name='copy.customer.business' type='select' component={OptionsField} label='Business' options={businessType} />
            <Field disabled name='copy.customer.legal_document' component={FileField} label='Legal document' api={props.fileAPI} />
            <Field disabled name='copy.customer.npwp' type='text' component={TextField} label='NPWP' />
            <Field disabled name='copy.customer.email' type='text' component={TextField} label='Email' />
            <Field disabled name='copy.customer.website' type='text' component={TextField} label='Website' />
            <button onClick={() => { changeForm('customer', null) }} className='btn btn-default' type='button'>Change customer</button>
          </div>
        </fieldset>
      }
      { currentCustomer &&
        <Fragment>
          { !currentContact &&
            <fieldset>
              <legend>Contact Person</legend>
              <Field name='contact' component={AutoCompleteField} label='Select Contact Person' api={props.contactAPI} required openNew={() => push(`/customers/${currentCustomer.value.id}/locations`)} />
            </fieldset>
          }
          { currentContact &&
            <fieldset>
              <legend>Contact Person</legend>
              <div className='well'>
                <Field disabled name='contact' component={AutoCompleteField} label='Select Contact Person' api={props.contactAPI} required />
                <Field disabled name='main_contact.title' type='select' component={OptionsField} label='Title' options={titles} required />
                <Field disabled name='main_contact.first_name' type='text' component={TextField} label='First Name' required />
                <Field disabled name='main_contact.middle_name' type='text' component={TextField} label='Middle Name' />
                <Field disabled name='main_contact.surname' type='text' component={TextField} label='Last Name' required />
                <Field disabled name='main_contact.job_title' type='text' component={TextField} label='Job Title' required />
                <Field disabled name='main_contact.department' type='text' component={TextField} label='Department' required />
                <Field disabled name='main_contact.email' type='text' component={TextField} label='Email' required />
                <Field disabled name='main_contact.contact_detail.additional_email' type='text' component={TextField} label='Additional Email' help='Separate multiple email using commas' />
                <Field disabled name='main_contact.phone' type='text' component={TextField} label='Phone' />
                <Field disabled name='main_contact.mobile_phone' type='text' component={TextField} label='Mobile Phone' required />
                <Field disabled name='main_contact.fax' type='text' component={TextField} label='Fax' />
                <button onClick={() => { changeForm('contact', null) }} className='btn btn-default' type='button'>Change contact</button>
              </div>
            </fieldset>
          }
          { !currentLocation &&
            <fieldset>
              <legend>Main Location</legend>
              <Field name='location' component={AutoCompleteField} label='Select Main Location' api={props.locationAPI} required openNew={() => push(`/customers/${currentCustomer.value.id}/locations`)} />
            </fieldset>
          }
          { currentLocation &&
            <fieldset>
              <legend>Main Address</legend>
              <div className='well'>
                <Field disabled name='location' component={AutoCompleteField} label='Select Main Location' api={props.locationAPI} required />
                <Field disabled name='main_location.name' type='text' component={TextField} label='Address Name' required />
                <Field disabled name='main_location.address' type='text' component={TextareaField} label='Address' required />
                <Field disabled name='main_location.phone' type='text' component={TextField} label='Phone' required />
                <Field disabled name='main_location.fax' type='text' component={TextField} label='Fax' />
                <Field disabled name='main_location.postal_code' type='text' component={TextField} label='Postal Code' required />
                <Field disabled name='main_location.country' component={TextField} label='Country' required />
                <Field disabled name='main_location.state' component={TextField} label='State' />
                <Field disabled name='main_location.city' component={TextField} label='City' required />
                <button onClick={() => { changeForm('location', null) }} className='btn btn-default' type='button'>Change location</button>
              </div>
            </fieldset>
          }
        </Fragment>
      }

      <div className='form-group'>
        <div className='col-sm-offset-3 col-sm-9'>
          <ConfirmButton
            disabled={submitting}
            text={'Save Questionnaire'}
          >
            <button disabled={submitting} type='submit' className='btn-advance btn btn-primary btn-block btn-submit'>Save Questionnaire</button>
          </ConfirmButton>
        </div>
      </div>

    </form>
  )
}

QuestionnaireGeneralInformationForm = reduxForm({ // eslint-disable-line 
  form: 'QuestionnaireGeneralInformationForm',
  enableReinitialize: true,
  validate
})(QuestionnaireGeneralInformationForm)

QuestionnaireGeneralInformationForm = connect(state => { // eslint-disable-line
  const currentCustomer = selector(state, 'customer')
  const currentContact = selector(state, 'contact')
  const currentLocation = selector(state, 'location')
  return {
    currentCustomer,
    currentContact,
    currentLocation
  }
})(QuestionnaireGeneralInformationForm)

export default QuestionnaireGeneralInformationForm
