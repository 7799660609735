const services = [
  {
    value: 'certification',
    label: 'System Certification'
  },
  {
    value: 'audit',
    label: 'General Audit'
  },
  {
    value: 'training',
    label: 'Public Training'
  }
]

function getServices (x) {
  const services = []
  // if (x['certification']) { services.push('System Certification') }
  // if (x['audit']) { services.push('General Audit') }
  // if (x['training']) { services.push('Training') }
  return services.join(' - ')
}

export default services

export {
  services,
  getServices
}
