// const authInitialState = {
//   user: {
//     id: 1,
//     email: 'email@test.com',
//     userName: 'username'
//   },
//   token: {
//     value: 'some-key',
//     expire: 1642342341
//   },
//   error: null,
//   loading: false
// }

const authInitialState = {
  user: null,
  error: null,
  loading: false
}

export default (state = authInitialState, action) => {
  switch (action.type) {
    case 'LOGIN_PENDING':
      return {
        user: null,
        error: null,
        loading: true
      }
    case 'LOGIN_FULFILLED':
      return {
        user: { ...action.payload },
        error: null,
        loading: false
      }
    case 'LOGIN_REJECTED':
      return {
        user: null,
        error: { ...action.payload },
        loading: false
      }
    case 'REGISTER_FULFILLED':
      return {
        user: { ...action.payload },
        error: null,
        loading: false
      }
    case 'REGISTER_PENDING':
      return {
        user: null,
        error: null,
        loading: true
      }
    case 'REGISTER_REJECTED':
      return {
        user: null,
        error: { ...action.payload },
        loading: false
      }
    case 'LOGOUT_FULFILLED':
      return { ...authInitialState }
    default:
      return state
  }
}
