import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { getCertificateLabel } from '../../const/orders'

export default class CertificateTable extends React.Component {
  render () {
    const { certificates } = this.props
    return (
      <div className='table-responsive'>
        <table className='table'>
          <thead>
            <tr>
              <th>#</th>
              <th>Certificate Number</th>
              <th>Order Number</th>
              <th>Customer Name</th>
              <th>Standard</th>
              <th>Issued date</th>
              <th>Expired date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {certificates.map((x, i) => (
              <tr key={i}>
                <td><Link to={`/orders/${x.order.id}/certificates/${x.id}`}>{x.id}</Link></td>
                <td><Link to={`/orders/${x.order.id}/certificates/${x.id}`}>{x.id}</Link></td>
                <td><Link to={`/orders/${x.order.id}`}>{x.order.id}</Link></td>
                <td>{x.order.customer.name}</td>
                <td>{x.detail.standard}</td>
                <td>{moment.unix(x.release_date).format('D MMMM YYYY')}</td>
                <td>{moment.unix(x.end_date).format('D MMMM YYYY')}</td>
                <td>{getCertificateLabel(x.status)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}
