import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

import { TextField, OptionsField } from '../common/FormFields'
import titles from '../../const/titles'

const validate = values => {
  const errors = {}

  if (!values.first_name) {
    errors.first_name = 'Required'
  }
  if (!values.surname) {
    errors.surname = 'Required'
  }
  if (!values.job_title) {
    errors.job_title = 'Required'
  }
  if (!values.department) {
    errors.department = 'Required'
  }
  if (!values.email) {
    errors.email = 'Required'
  }
  if (!values.mobile_phone) {
    errors.mobile_phone = 'Required'
  }

  return errors
}

class CustomerContactForm extends Component {
  getLocations () {
    if (this.props.locations) {
      return this.props.locations.map(x => {
        return {
          value: x.id,
          label: `${x.name} - ${x.city}`
        }
      })
    } else {
      return []
    }
  }

  render () {
    const { handleSubmit, submitting } = this.props
    return (

      <div>
        <form className='form-horizontal' onSubmit={handleSubmit}>
          <Field name='title' type='select' component={OptionsField} label='Title' options={titles} required />

          <Field name='first_name' type='text' component={TextField} label='First Name' required />

          <Field name='middle_name' type='text' component={TextField} label='Middle Name' />

          <Field name='surname' type='text' component={TextField} label='Last Name' required />

          <Field name='job_title' type='text' component={TextField} label='Job Title' required />

          <Field name='department' type='text' component={TextField} label='Department' required />

          <Field name='email' type='text' component={TextField} label='Email' required />

          <Field name='contact_detail.additional_email' type='text' component={TextField} label='Additional Email' help='Separate multiple email using commas' />

          <Field name='phone' type='text' component={TextField} label='Phone' />

          <Field name='mobile_phone' type='text' component={TextField} label='Mobile Phone' required />

          <Field name='fax' type='text' component={TextField} label='Fax' />

          <Field name='default' type='checkbox' component={OptionsField} label='Default contact' />

          {!this.props.basic &&
            <Field name='customer_location_id' type='select' component={OptionsField} label='Location' options={this.getLocations()} />
          }

          <div className='form-group'>
            <div className='col-sm-offset-3 col-sm-9'>
              <button disabled={submitting} type='submit' className='btn btn-primary btn-block'>Submit</button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

const BaseCustomerContactCreateForm = props => <CustomerContactForm {...props} mode='create' />

let CustomerContactCreateForm = reduxForm({
  form: 'customerContactCreate',
  validate // validate, warn
})(BaseCustomerContactCreateForm)

const BaseCustomerContactUpdateForm = props => <CustomerContactForm {...props} mode='update' />

let CustomerContactUpdateForm = reduxForm({
  form: 'customerContactUpdate',
  validate // validate, warn
})(BaseCustomerContactUpdateForm)

export default CustomerContactForm
export { CustomerContactCreateForm, CustomerContactUpdateForm }
