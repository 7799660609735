import React from 'react'
import { getFullName } from '../../selectors/contacts'
// import DownloadLink from '../../components/common/DownloadLink'

export default function ContactWidget ({ title, contact: c, accessToken, ...props }) {
  return (
    <div className='widget'>
      <div className='widget-header'>
        <h3><i className='fa fa-envelope' /> {title || getFullName(c)}</h3>
      </div>
      <div className='widget-content'>

        <table className='table table-hover table-striped'>
          <tbody>
            <tr>
              <th> Title </th>
              <td> {c.title} </td>
            </tr>

            <tr>
              <th> First Name </th>
              <td> {c.first_name} </td>
            </tr>

            <tr>
              <th> Middle Name </th>
              <td> {c.middle_name} </td>
            </tr>

            <tr>
              <th> Surname </th>
              <td> {c.surname} </td>
            </tr>

            <tr>
              <th> Email </th>
              <td> {c.email} </td>
            </tr>

            <tr>
              <th> Fax </th>
              <td> {c.fax} </td>
            </tr>

            <tr>
              <th> Mobile Phone </th>
              <td> {c.mobile_phone} </td>
            </tr>

            <tr>
              <th> Phone </th>
              <td> {c.phone} </td>
            </tr>

            <tr>
              <th> Department </th>
              <td> {c.department} </td>
            </tr>

            <tr>
              <th> Job Title </th>
              <td> {c.job_title} </td>
            </tr>

          </tbody>
        </table>

      </div>
    </div>
  )
}
