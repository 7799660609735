import axios from 'axios'
import { push } from 'react-router-redux'
import { createFlash } from './flasher'

const baseAPI = process.env.REACT_APP_BASE_API

export const fetchEvents = (params = '', accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_EVENTS_PENDING'
    })
    return axios({
      method: 'get',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/events${params}`
    }).then((x) => {
      dispatch({
        type: 'FETCH_EVENTS_FULFILLED',
        payload: x.data,
        pagination: {
          currentPage: x.headers['x-pagination-current-page'],
          pageCount: x.headers['x-pagination-page-count'],
          perPage: x.headers['x-pagination-per-page'],
          totalCount: x.headers['x-pagination-total-count']
        }
      })
    }).catch((e) => {
      console.error(e)
      dispatch({
        type: 'FETCH_EVENTS_REJECTED',
        payload: e
      })
    })
  }
}

export const fetchEvent = (id, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_EVENT_PENDING'
    })
    return axios({
      method: 'get',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/events/${id}`
    }).then((x) => {
      dispatch({
        type: 'FETCH_EVENT_FULFILLED',
        payload: x.data
      })
    }).catch((e) => {
      dispatch({
        type: 'FETCH_EVENT_REJECTED',
        payload: e
      })
    })
  }
}

export const createEvent = (data, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'CREATE_EVENT_PENDING'
    })
    return axios({
      data,
      method: 'post',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/events`
    }).then((x) => {
      dispatch({
        type: 'CREATE_EVENT_FULFILLED',
        payload: x.data
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Event created.`
      }))
      // dispatch(push(`/events/${x.data.id}`))
      return x.data
    }).catch((e) => {
      dispatch({
        type: 'CREATE_EVENT_REJECTED',
        payload: e
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'error',
        message: `Update error.`
      }))
      throw new Error(e.message)
    })
  }
}

export const updateEvent = (data, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'UPDATE_EVENT_PENDING'
    })
    return axios({
      data,
      method: 'put',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/events/${data.id}`
    }).then((x) => {
      dispatch({
        type: 'UPDATE_EVENT_FULFILLED',
        payload: x.data
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Standard updated.`
      }))
    }).catch((e) => {
      dispatch({
        type: 'UPDATE_EVENT_REJECTED',
        payload: e
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'error',
        message: `Update error.`
      }))
    })
  }
}

export const deleteEvent = (id, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'DELETE_EVENT_PENDING'
    })
    return axios({
      method: 'delete',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/events/${id}`
    }).then((x) => {
      dispatch({
        type: 'DELETE_EVENT_FULFILLED',
        payload: x
      })
      dispatch(push('/events'))
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Standard deleted.`
      }))
    }).catch((e) => {
      dispatch({
        type: 'DELETE_EVENT_REJECTED',
        payload: e
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'error',
        message: `Delete error.`
      }))
    })
  }
}
