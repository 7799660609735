import React, { useState, useEffect } from 'react'

import Required from './Required'

export default function RepeatTextField ({ input, label, required, meta: { touched, error, warning } }) {
  const [ values, setValues ] = useState(input.value || [])
  useEffect(() => {
    setValues(input.value || [])
  }, [input.value])

  function removeEntry (id) {
    const newValues = values.filter((x, i) => i !== id)
    setValues(newValues)
    input.onChange(newValues)
  }

  return (
    <div className={`form-group ${input.name.split('.').join('_')}`}>
      <label htmlFor={label} className='col-sm-3 control-label'>{label} {required && <Required />}</label>
      <div className='col-sm-9'>

        {values.map((member, index) => (
          <div key={index} className='input-group' style={{ marginBottom: 5 }}>
            <input
              component='input'
              type='text'
              className='form-control'
              value={values[index]}
              onChange={ev => {
                const newValues = values.map((x, i) => i === index ? ev.target.value : x)
                setValues(newValues)
                input.onChange(newValues)
              }}
            />
            <span className='input-group-btn'>
              <button type='button' className='btn btn-default' onClick={removeEntry.bind(this, index)}><i className='fa fa-remove' /></button>
            </span>
          </div>
        ))}
        <button className='btn btn-default btn-sm btn-add-new' type='button' onClick={() => {
          setValues([...values, ''])
          input.onChange([...values, ''])
        }}>Add item</button>
      </div>

      {touched && ((error && <div className='alert alert-danger'>{error}</div>) || (warning && <div className='alert alert-warning'>{warning}</div>))}
    </div>
  )
}
