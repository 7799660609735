import React from 'react'
import { Link } from 'react-router-dom'
import NumberFormat from 'react-number-format'

export default class InvoiceTable extends React.Component {
  getLabel (status) {
    let label
    switch (status) {
      case 'Draft':
        label = 'warning'
        break
      case 'Not Paid':
        label = 'info'
        break
      case 'Paid':
        label = 'primary'
        break
      default:
        // statements_def
        break
    }
    return (
      <span className={`label label-${label}`}>{status.toUpperCase()}</span>
    )
  }

  render () {
    return (
      <div>
        <div className='table-responsive'>
          <table className='table colored-header datatable project-list'>
            <thead>
              <tr>
                <th>Invoice No.</th>
                <th>Amount</th>
                <th>Date &amp; time</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              { this.props.invoices.map((x, y) => {
                return (
                  <tr key={y}>
                    <td><Link to='/orders/1/invoices/12'>INV.201502004</Link></td>
                    <td><span>Rp </span><NumberFormat value={x.total} displayType='text' thousandSeparator /></td>
                    <td>04/03/2017 17:23</td>
                    <td><span className='label label-info'>DRAFT</span></td>
                  </tr>
                )
              })}
              <tr>
                <td><Link to='/orders/1/invoices/12'>ORD201502004</Link></td>
                <td>RP 93.000.000</td>
                <td>04/03/2017 17:23</td>
                <td><span className='label label-info'>DRAFT</span></td>
              </tr>
              <tr>
                <td><Link to='/orders/1/invoices/12'>ORD201502004</Link></td>
                <td>RP 93.000.000</td>
                <td>04/03/2017 17:23</td>
                <td><span className='label label-danger'>NOT PAID</span></td>
              </tr>
              <tr>
                <td><Link to='/orders/1/invoices/12'>ORD201502004</Link></td>
                <td>RP 93.000.000</td>
                <td>04/03/2017 17:23</td>
                <td><span className='label label-success'>PAID</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}
