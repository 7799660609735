import React from 'react'
import { connect } from 'react-redux'
import { Link, Switch, Route } from 'react-router-dom'
import moment from 'moment'
import { Helmet } from 'react-helmet'

import ItemLog from '../../components/common/ItemLog'
import Pagination from '../../components/common/Pagination'

import ChangeTable from '../../components/Orders/ChangeTable'
import { ChangeCreateForm, ChangeUpdateForm } from '../../components/Orders/ChangeForm'

import { fetchOrder } from '../../actions/orders'
import { fetchChanges, fetchChange, createChange, updateChange } from '../../actions/changes'

export class OrderChangeView extends React.Component {
  componentDidMount () {
    this.props.fetchOrder(this.props.match.params.orderId, this.props.accessToken)

    if (this.props.match.params.id && this.props.match.params.id !== 'new') {
      this.props.fetchChange(
        this.props.match.params.orderId,
        this.props.match.params.id,
        this.props.accessToken
      )
    }
    if (!this.props.match.params.id) {
      this.props.fetchChanges(this.props.match.params.orderId, this.props.accessToken)
    }
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.change && nextProps.change) {
    }
  }

  handleSubmitNew (e) {
    return this.props.createChange(
      { ...e,
        release_date: moment(e.release_date).unix(),
        end_date: moment(e.end_date).unix(),
        order_id: this.props.match.params.orderId,
        log: e.log ? e.log.detail : ''
      },
      this.props.accessToken
    )
  }

  handleSubmitUpdate (e) {
    return this.props.updateChange(
      { ...e,
        release_date: moment(e.release_date).unix(),
        end_date: moment(e.end_date).unix(),
        order_id: e.order.id,
        log: e.log ? e.log.detail : ''
      },
      this.props.accessToken
    )
  }

  render () {
    const { order, change, changes } = this.props
    const serviceDetail = order ? order.questionnaire_item : null

    return (
      <div>
        <div className='content'>
          <Switch>
            <Route path='/orders/:orderId/changes/new'>
              <div>
                <Helmet>
                  <title>{order ? `Order ${order.id} - Create change` : `Loading`}</title>
                </Helmet>
                <div className='main-header'>
                  <h2>New Change</h2>
                  { order &&
                    <em>For Order <Link to={`/orders/${order.id}`}>{order.id}</Link></em>
                  }
                </div>
                <div className='row'>
                  <div className='col-md-8'>
                    <ChangeCreateForm
                      serviceDetail={serviceDetail}
                      onSubmit={this.handleSubmitNew.bind(this)}
                      initialValues={{
                        status: 'Draft'
                      }}
                      fileAPI={{
                        url: process.env.REACT_APP_BASE_API,
                        accessToken: this.props.accessToken
                      }}
                    />
                  </div>
                </div>
              </div>
            </Route>
            <Route path='/orders/:orderId/changes/:id'>
              <div>
                <Helmet>
                  <title>{order && change ? `Order ${order.id} - Update change ${change.id}` : `Loading`}</title>
                </Helmet>
                <div className='main-header'>
                  { change &&
                    <h2>Update Change {change.id}</h2>
                  }
                  { order &&
                    <em>For Order <Link to={`/orders/${order.id}`}>{order.id}</Link></em>
                  }
                </div>
                <div className='row'>
                  <div className='col-md-8'>
                    <ChangeUpdateForm
                      serviceDetail={serviceDetail}
                      onSubmit={this.handleSubmitUpdate.bind(this)}
                      initialValues={change}
                      fileAPI={{
                        url: process.env.REACT_APP_BASE_API,
                        accessToken: this.props.accessToken
                      }}
                    />
                  </div>
                  <div className='col-md-4'>
                    { change
                      ? <ItemLog log={change.log} entity='Change' />
                      : <div>loading</div>
                    }
                  </div>
                </div>
              </div>
            </Route>
            <Route path='/orders/:orderId/changes'>
              <div>
                <div className='main-header'>
                  <h2>Change List</h2>
                  { order &&
                    <em>For Order <Link to={`/orders/${order.id}`}>{order.id}</Link></em>
                  }
                </div>
                <div className='row'>
                  <div className='col-md-8'>
                    { changes &&
                      <ChangeTable changes={changes} />
                    }
                  </div>
                  <Pagination />
                </div>
              </div>
            </Route>
          </Switch>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    order: state.orders.order.data,
    changes: state.changes.changeList.data,
    change: state.changes.change.data,
    accessToken: state.auth.user.access.access_token
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOrder: (id, accessToken) => dispatch(fetchOrder(id, accessToken)),
    fetchChanges: (id, accessToken) => dispatch(fetchChanges(id, accessToken)),
    fetchChange: (orderId, id, accessToken) => dispatch(fetchChange(orderId, id, accessToken)),
    createChange: (data, accessToken) => dispatch(createChange(data, accessToken)),
    updateChange: (data, accessToken) => dispatch(updateChange(data, accessToken))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
// Implement map dispatch to props
)(OrderChangeView)
