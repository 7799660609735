import React from 'react'
import { Link } from 'react-router-dom'

import { asQueryString } from '../../const/helper'

export default class Pagination extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      sequence: 3
    }
  }

  getPrevious () {
    if (!this.props.pagination) { return null }
    const { base, pagination, params } = this.props
    delete params.page
    const { currentPage } = pagination
    const previousPage = parseInt(currentPage, 10) - 1
    const previousUrl = (currentPage === '2' ? base : `${base}?${asQueryString({ ...params, page: previousPage })}`)
    return (
      <li className={currentPage === '1' ? 'disabled' : ''}>
        { previousPage > 0
          ? <Link to={previousUrl}><i className='fa fa-angle-left' /></Link>
          : <span><i className='fa fa-angle-left' /></span>
        }
      </li>
    )
  }

  getPreviousBuffer () {

  }

  getNext () {
    if (!this.props.pagination) { return null }
    const { base, pagination, params } = this.props
    delete params.page
    const { currentPage, pageCount } = pagination
    const nextPage = parseInt(currentPage, 10) + 1
    const nextUrl = `${base}?${asQueryString({ ...params, page: nextPage })}`

    return (
      <li className={currentPage === pageCount ? 'disabled' : ''}>
        { nextPage < parseInt(pageCount, 10)
          ? <Link to={nextUrl}><i className='fa fa-angle-right' /></Link>
          : <span><i className='fa fa-angle-right' /></span>
        }
      </li>
    )
  }

  getNextBuffer () {

  }

  getPages () {
    if (!this.props.pagination) { return null }
    const { base, params, pagination } = this.props
    delete params.page
    const { currentPage, pageCount } = pagination
    const pages = []

    // loop for previous
    for (let i = parseInt(currentPage, 10) - this.state.sequence; i < parseInt(currentPage, 10); i++) {
      if (i > 0) {
        const link = (i === 1 ? `${base}?${asQueryString({ ...params })}` : `${base}?${asQueryString({ ...params, page: i })}`)
        pages.push(<li key={i}><Link to={link}>{i}</Link></li>)
      }
    }

    // current page
    pages.push(<li key='this' className='active'><a href='/' onClick={(x) => x.preventDefault()}>{currentPage}</a></li>)

    // loop for nexts
    for (let i = parseInt(currentPage, 10) + 1; i < parseInt(currentPage, 10) + this.state.sequence; i++) {
      if (i <= parseInt(pageCount, 10)) {
        const nextLink = `${base}?${asQueryString({ ...params, page: i })}`
        pages.push(<li key={i}><Link to={nextLink}>{i}</Link></li>)
      }
    }
    return pages
  }

  render () {
    if (this.props.pagination) {
      return (
        <div className='text-center'>
          <ul className='pagination'>
            {this.getPrevious()}
            {this.getPreviousBuffer()}
            {this.getPages()}
            {this.getNextBuffer()}
            {this.getNext()}
          </ul>
        </div>
      )
    } else {
      return null
    }
  }
}
