import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'

import { AutoCompleteField } from '../common/FormFields'
import ConfirmButton from '../common/ConfirmButton'

const selector = formValueSelector('questionnaireAssignmentForm')

const validate = (values) => {
  const errors = {}
  if (
    !values.reviewer ||
    (values.reviewer && !values.reviewer.value)
  ) {
    errors.reviewer = 'Required'
  }
  return errors
}

export class QuestionnaireAssignmentForm extends Component {
  render () {
    const { handleSubmit, submitting } = this.props
    return (

      <div>
        <form className='form-horizontal questionnaire-assignment-form' onSubmit={handleSubmit}>

          <Field
            name='reviewer'
            component={AutoCompleteField}
            label='Select Reviewer'
            api={this.props.userAPI}
            required
          />

          <div className='form-group'>
            <div className='col-sm-offset-3 col-sm-9'>
              <ConfirmButton
                disabled={submitting}
                text={'Assign Reviewer'}
              >
                <button disabled={submitting} type='submit' className='btn-advance btn btn-primary btn-block btn-submit'>Save Questionnaire</button>
              </ConfirmButton>
            </div>
          </div>

        </form>
      </div>
    )
  }
}

QuestionnaireAssignmentForm = reduxForm({ // eslint-disable-line 
  form: 'questionnaireAssignmentForm',
  validate
})(QuestionnaireAssignmentForm)

QuestionnaireAssignmentForm = connect(state => { // eslint-disable-line
  const currentSstandards = selector(state, 'standards')
  return {
    currentSstandards
  }
})(QuestionnaireAssignmentForm)

export default QuestionnaireAssignmentForm
