import React from 'react'
import { Line } from 'react-chartjs-2'

const data = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      label: 'Order',
      fill: true,
      lineTension: 0.4,
      backgroundColor: 'rgba(85,235,253,0.3)',
      borderColor: 'rgba(85,215,253,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(85,178,253,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 1,
      pointRadius: 4,
      pointHitRadius: 10,
      data: [65, 59, 80, 81, 56, 55, 40]
    },
    {
      label: 'Invoice',
      fill: true,
      lineTension: 0.4,
      backgroundColor: 'rgba(158,155,242,0.4)',
      borderColor: 'rgba(158,155,242,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(158,100,242,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 1,
      pointRadius: 4,
      pointHitRadius: 10,
      data: [60, 29, 100, 85, 77, 35, 90]
    }
  ]
}

const options = {
  legend: {
    display: true,
    position: 'bottom',
    align: 'start',
    fullWidth: true
  }
}

export default class ChartsOrder extends React.Component {
  render () {
    data.labels = this.props.data.map(x => x.month)
    data.datasets[0].data = this.props.data.map(x => x.total / 1000000)
    data.datasets[1].data = this.props.data.map(x => x.paid / 1000000)
    return (
      <div className='new-card'>
        <div className='new-card-header'>
          {
          // <div className='action'>
          //   <div className='btn-group'>
          //     <button type='button' className='btn btn-default btn-xs rounded-1 dropdown-toggle' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
          //                       Action <span className='caret' />
          //     </button>

          //     <ul className='dropdown-menu'>
          //       <li><a href='/'>This month</a></li>
          //       <li><a href='/'>This year</a></li>
          //     </ul>
          //   </div>
          // </div>
          }
          <h4>Order and Invoice <small>in millions of rupiah</small></h4>
        </div>
        <div className='new-card-body'>
          <div>
            <Line
              data={data}
              options={options}
              height={100}
            />
          </div>
        </div>
      </div>
    )
  }
}
