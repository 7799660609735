import axios from 'axios'
import { push } from 'react-router-redux'
import { createFlash } from './flasher'

const baseAPI = process.env.REACT_APP_BASE_API

export const fetchOptions = (params = '', accessToken) => {
  return (dispatch) => {
    return axios({
      method: 'get',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/options${params}`
    }).then((x) => {
      dispatch({
        type: 'FETCH_OPTIONS_FULFILLED',
        payload: x.data,
        pagination: {
          currentPage: x.headers['x-pagination-current-page'],
          pageCount: x.headers['x-pagination-page-count'],
          perPage: x.headers['x-pagination-per-page'],
          totalCount: x.headers['x-pagination-total-count']
        }
      })
    })
  }
}

export const fetchOption = (id, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_OPTION_PENDING'
    })
    return axios({
      method: 'get',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/options/${id}`
    }).then((x) => {
      dispatch({
        type: 'FETCH_OPTION_FULFILLED',
        payload: x.data
      })
    }).catch((e) => {
      dispatch({
        type: 'FETCH_OPTION_REJECTED',
        payload: e
      })
    })
  }
}

export const createOption = (data, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'CREATE_OPTION_PENDING'
    })
    return axios({
      data,
      method: 'post',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/options`
    }).then((x) => {
      dispatch({
        type: 'CREATE_OPTION_FULFILLED',
        payload: x.data
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Standar created.`
      }))
      dispatch(push(`/options/${x.data.id}`))
    }).catch((e) => {
      dispatch({
        type: 'CREATE_OPTION_REJECTED',
        payload: e
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'error',
        message: `Update error.`
      }))
    })
  }
}

export const updateOption = (data, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'UPDATE_OPTION_PENDING'
    })
    return axios({
      data,
      method: 'put',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/options/${data.id}`
    }).then((x) => {
      dispatch({
        type: 'UPDATE_OPTION_FULFILLED',
        payload: x.data
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Standard updated.`
      }))
    }).catch((e) => {
      dispatch({
        type: 'UPDATE_OPTION_REJECTED',
        payload: e
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'error',
        message: `Update error.`
      }))
    })
  }
}

export const deleteOption = (id, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'DELETE_OPTION_PENDING'
    })
    return axios({
      method: 'delete',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/options/${id}`
    }).then((x) => {
      dispatch({
        type: 'DELETE_OPTION_FULFILLED',
        payload: x
      })
      dispatch(push('/options'))
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Standard deleted.`
      }))
    }).catch((e) => {
      dispatch({
        type: 'DELETE_OPTION_REJECTED',
        payload: e
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'error',
        message: `Delete error.`
      }))
    })
  }
}
