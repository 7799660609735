import React from 'react'
import {Link} from 'react-router-dom'
import moment from 'moment'

import { getReportLabel } from '../../const/orders'

export default class OrderReport extends React.Component {
  render () {
    const {order, reports, user} = this.props

    return (
      <div className='widget'>
        <div className='widget-header'>
          <h3><i className='fa fa-tasks' /> Audit Reports</h3>
          {
            // <div className='widget-header-toolbar'>
            //   <Link to={`/orders/${order.id}/reports/new`} className='btn btn-link btn-xs'><i className='fa fa-plus' />New</Link>{' '}
            // </div>
          }
        </div>
        <div className=''>
          <table className='table' style={{marginBottom: 0}}>
            <thead>
              <tr>
                <th>Report no.</th>
                <th>Report type</th>
                <th>Decision</th>
                <th>Date &amp; time</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              { reports.length === 0 &&
                <tr>
                  <td colSpan={3}>
                    {
                      order.users && order.users.filter((x) => x.user.id === user.id && (x.role === 'Auditor' || x.role === 'Lead Auditor')).length > 0 &&
                      ['On Audit', 'Audited'].indexOf(order.status) > -1
                        ? <Link to={`/orders/${order.id}/reports/new`} className='btn btn-default'><i className='fa fa-plus' />Create New Report</Link>
                        : <p>Not available</p>
                    }
                  </td>
                </tr>
              }
              { reports.map((x, y) => {
                return (
                  <tr key={y}>
                    <td><Link to={`/orders/${x.order.id}/reports/${x.id}`}>{x.id}</Link></td>
                    <td>{x.type}</td>
                    <td>{x.decision}</td>
                    <td>{moment.unix(x.created_at).format('D MMMM YYYY')}</td>
                    <td>{getReportLabel(x.status)}</td>
                  </tr>
                )
              })}
              <tr />
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}
