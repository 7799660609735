import React from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { Link } from 'react-router-dom'

import Pagination from '../../components/common/Pagination'
import CustomerTable from '../../components/Customers/CustomerTable'
import CustomerFilter from '../../components/Customers/CustomerFilter'

import { fetchCustomers } from '../../actions/customers'
import { asQueryObject, asQueryString } from '../../const/helper'

function mapStateToProps (state) {
  return {
    customers: state.customers.customerList.data,
    pagination: state.customers.customerList.pagination,
    accessToken: state.auth.user.access.access_token
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchCustomers: (params, key) => dispatch(fetchCustomers(params, key)),
    push: (to) => dispatch(push(to))
  }
}

class CustomerList extends React.Component {
  componentWillReceiveProps (nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      return this.props.fetchCustomers(nextProps.location.search, this.props.accessToken)
    }
  }

  componentWillMount () {
    return this.props.fetchCustomers(this.props.location.search, this.props.accessToken)
  }

  handleFilter (filter) {
    delete filter.page
    return this.props.push(`/customers?${asQueryString(filter)}`)
  }

  render () {
    const { customers, pagination, location } = this.props

    return (
      <div className='content'>
        <div className='main-header'>
          <h2>Customers </h2>
          {/* <em>normal sidebar with minified mode by default</em> */}
        </div>
        <div className='main-content'>
          <div className='row'>
            <div className='col-md-8'>

              { customers
                ? <div>
                  <CustomerFilter
                    onSubmit={this.handleFilter.bind(this)}
                    initialValues={asQueryObject(location.search)}
                  />
                  <CustomerTable customers={customers} />
                </div>
                : <div>loading</div>
              }
              <Pagination pagination={pagination} base='/customers' params={asQueryObject(location.search)} />
            </div>
            <div className='col-md-4'>
              <Link className='btn btn-primary btn-block' to='/customers/new'>Create New customer</Link>
              <div className='widget hide'>
                <div className='widget-header'>
                  <h3><i className='fa fa-tasks' /> My Tasks</h3> <em>- Summary of Tasks</em>
                  <div className='btn-group widget-header-toolbar'>
                    <button title='Focus' className='btn-borderless btn-focus'><i className='fa fa-eye' /></button>
                    <button title='Expand/Collapse' className='btn-borderless btn-toggle-expand'><i className='fa fa-chevron-up' /></button>
                    <button title='Remove' className='btn-borderless btn-remove'><i className='fa fa-times' /></button>
                  </div>
                </div>
                <div className='widget-content'>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerList)
