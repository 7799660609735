import React from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import Pagination from '../../components/common/Pagination'
import NcTable from '../../components/Ncs/NcTable'
import NcFilter from '../../components/Ncs/NcFilter'

import { fetchGlobalNcs } from '../../actions/ncs'
import { asQueryObject, asQueryString } from '../../const/helper'

class NonComplyList extends React.Component {
  componentWillReceiveProps (nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.props.fetchGlobalNcs(nextProps.location.search, this.props.accessToken)
    }
  }

  componentWillMount () {
    this.props.fetchGlobalNcs(this.props.location.search, this.props.accessToken)
  }

  handleFilter (filter) {
    delete filter.page
    return this.props.push(`/ncs?${asQueryString(filter)}`)
  }

  render () {
    const { ncs, pagination, location } = this.props

    return (
      <div className='content'>
        <div className='main-header'>
          <h2>Non Conformity List </h2>
          <em className='hide'>normal sidebar with minified mode by default</em>
        </div>
        <div className='main-content'>
          <div className='row'>
            <div className='col-md-8'>
              { ncs
                ? <div>
                  <NcFilter
                    onSubmit={this.handleFilter.bind(this)}
                    initialValues={asQueryObject(location.search)}
                  />
                  <NcTable ncs={ncs} />
                </div>
                : <div>loading</div>
              }
              <Pagination pagination={pagination} base='/ncs' params={asQueryObject(location.search)} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    ncs: state.ncs.globalNcList.data,
    pagination: state.ncs.globalNcList.pagination,
    accessToken: state.auth.user.access.access_token
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  return {
    fetchGlobalNcs: (params, key) => dispatch(fetchGlobalNcs(params, key)),
    push: (to) => dispatch(push(to))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NonComplyList)
