import axios from 'axios'
import { push } from 'react-router-redux'
import { change, reset, arrayPush } from 'redux-form'
import { createFlash } from './flasher'

const baseAPI = process.env.REACT_APP_BASE_API

export const fetchCustomers = (params = '', accessToken) => {
  return (dispatch) => {
    return axios({
      method: 'get',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/customers${params}`
    }).then((x) => {
      dispatch({
        type: 'FETCH_CUSTOMERS_FULFILLED',
        payload: x.data,
        pagination: {
          currentPage: x.headers['x-pagination-current-page'],
          pageCount: x.headers['x-pagination-page-count'],
          perPage: x.headers['x-pagination-per-page'],
          totalCount: x.headers['x-pagination-total-count']
        }
      })
    }).catch((e) => {
      dispatch({
        type: 'FETCH_CUSTOMERS_REJECTED',
        payload: e
      })
    })
  }
}

export const _fetchCustomer = (id, accessToken) => {
  return axios({
    method: 'get',
    headers: { 'Authorization': `Bearer ${accessToken}` },
    url: `${baseAPI}/customers/${id}?expand=detail,legal_document`
  }).then(x => x.data)
}

export const fetchCustomer = (id, accessToken) => {
  return (dispatch) => {
    return _fetchCustomer(id, accessToken)
      .then((x) => {
        dispatch({
          type: 'FETCH_CUSTOMER_FULFILLED',
          payload: x
        })
      }).catch((e) => {
        console.error(e)
        dispatch({
          type: 'FETCH_CUSTOMER_REJECTED',
          payload: e
        })
      })
  }
}

export const createCustomer = (data, accessToken, target) => {
  return (dispatch) => {
    return axios({
      data,
      method: 'post',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/customers`
    }).then((x) => {
      dispatch({
        type: 'CREATE_CUSTOMER_FULFILLED',
        payload: x.data
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Customer created.`
      }))
      if (target) {
        dispatch(change(target.form, target.field, {
          value: x.data,
          label: x.data.name
        }))
        dispatch({
          type: 'ADD_NEW_CUSTOMER_TO_QUESTIONNAIRE_END',
          payload: x.data
        })
        dispatch(reset('customerCreate'))
      } else {
        dispatch(push(`/customers/${x.data.id}/locations`))
      }
    }).catch((e) => {
      dispatch({
        type: 'CREATE_CUSTOMER_REJECTED',
        payload: e
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'error',
        message: `Update error.`
      }))
    })
  }
}

export const updateCustomer = (data, accessToken) => {
  return (dispatch) => {
    return axios({
      data,
      method: 'put',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/customers/${data.id}?expand=detail,legal_document`
    }).then((x) => {
      dispatch({
        type: 'UPDATE_CUSTOMER_FULFILLED',
        payload: x.data
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Customer updated.`
      }))
    }).catch((e) => {
      dispatch({
        type: 'UPDATE_CUSTOMER_REJECTED',
        payload: e
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'error',
        message: `Update error.`
      }))
    })
  }
}

export const deleteCustomer = (id, accessToken) => {
  return (dispatch) => {
    return axios({
      method: 'delete',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/customers/${id}`
    }).then((x) => {
      dispatch({
        type: 'DELETE_CUSTOMER_FULFILLED',
        payload: x
      })
      dispatch(push('/customers'))
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Customer deleted.`
      }))
    }).catch((e) => {
      dispatch({
        type: 'DELETE_CUSTOMER_REJECTED',
        payload: e
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'error',
        message: `Delete error`
      }))
    })
  }
}

export const fetchCustomerContacts = (id, accessToken) => {
  return (dispatch) => {
    return axios({
      method: 'get',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/customers/${id}/contacts`
    }).then((x) => {
      dispatch({
        type: 'FETCH_CUSTOMER_CONTACTS_FULFILLED',
        payload: x.data
      })
    }).catch((e) => {
      dispatch({
        type: 'FETCH_CUSTOMER_CONTACTS_REJECTED',
        payload: e
      })
    })
  }
}

export const fetchCustomerContact = (custId, id, accessToken) => {
  return (dispatch) => {
    return axios({
      method: 'get',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/customers/${custId}/contacts/${id}`
    }).then((x) => {
      dispatch({
        type: 'FETCH_CUSTOMER_CONTACT_FULFILLED',
        payload: x.data
      })
    }).catch((e) => {
      console.error(e)
      dispatch({
        type: 'FETCH_CUSTOMER_CONTACT_REJECTED',
        payload: e
      })
    })
  }
}

export const _createContact = (data, accessToken) => {
  return axios({
    data,
    method: 'post',
    headers: { 'Authorization': `Bearer ${accessToken}` },
    url: `${baseAPI}/customers/${data.customer_id}/contacts`
  })
}

export const createContact = (data, accessToken) => {
  return (dispatch) => {
    return _createContact(data, accessToken).then((x) => {
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Contact created.`
      }))
      dispatch(reset('customerContactCreate'))
      return x.data
    })
  }
}

export const createCustomerContact = (data, accessToken, target) => {
  return (dispatch) => {
    return axios({
      data,
      method: 'post',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/customers/${data.customer_id}/contacts`
    }).then((x) => {
      dispatch({
        type: 'CREATE_CUSTOMER_CONTACT_FULFILLED',
        payload: x.data
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Contact created.`
      }))
      if (target) {
        dispatch(change(target.form, target.field, {
          value: x.data,
          label: x.data.name
        }))
        dispatch({
          type: 'ADD_NEW_CUSTOMER_CONTACT_TO_QUESTIONNAIRE_END',
          payload: x.data
        })
        dispatch(reset('customerContactCreate'))
      } else {
        dispatch(push(`/customers/${x.data.customer.id}/contacts`))
      }
    }).catch((e) => {
      dispatch({
        type: 'CREATE_CUSTOMER_CONTACT_REJECTED',
        payload: e
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'error',
        message: `Update error.`
      }))
    })
  }
}

export const updateCustomerContact = (custId, data, accessToken) => {
  return (dispatch) => {
    return axios({
      data,
      method: 'put',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/customers/${custId}/contacts/${data.id}`
    }).then((x) => {
      dispatch({
        type: 'UPDATE_CUSTOMER_CONTACT_FULFILLED',
        payload: x.data
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Customer created with the id ${x.data.id}`
      }))
    }).catch((e) => {
      dispatch({
        type: 'UPDATE_CUSTOMER_CONTACT_REJECTED',
        payload: e
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'error',
        message: `Update error.`
      }))
    })
  }
}

export const deleteCustomerContact = (custId, id, accessToken) => {
  return (dispatch) => {
    return axios({
      method: 'delete',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/customers/${custId}/contacts/${id}`
    }).then((x) => {
      dispatch({
        type: 'DELETE_CUSTOMER_CONTACT_FULFILLED',
        payload: x
      })
      dispatch(push(`/customers/${custId}/contacts`))
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Contact deleted.`
      }))
    }).catch((e) => {
      dispatch({
        type: 'DELETE_CUSTOMER_CONTACT_REJECTED',
        payload: e
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'error',
        message: `Delete error.`
      }))
    })
  }
}

export const fetchCustomerLocations = (id, accessToken) => {
  return (dispatch) => {
    return axios({
      method: 'get',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/customers/${id}/locations`
    }).then((x) => {
      dispatch({
        type: 'FETCH_CUSTOMER_LOCATIONS_FULFILLED',
        payload: x.data
      })
    })
  }
}

export const _fetchCustomerLocation = (custId, id, accessToken) => {
  return axios({
    method: 'get',
    headers: { 'Authorization': `Bearer ${accessToken}` },
    url: `${baseAPI}/customers/${custId}/locations/${id}`
  })
}

export const fetchCustomerLocation = (custId, id, accessToken) => {
  return (dispatch) => {
    return _fetchCustomerLocation(custId, id, accessToken)
      .then((x) => {
        dispatch({
          type: 'FETCH_CUSTOMER_LOCATION_FULFILLED',
          payload: x.data
        })
      })
  }
}

export const _createLocation = (data, accessToken) => {
  return axios({
    data,
    method: 'post',
    headers: { 'Authorization': `Bearer ${accessToken}` },
    url: `${baseAPI}/customers/${data.customer_id}/locations`
  })
}

export const createLocation = (data, accessToken) => {
  return (dispatch) => {
    return _createLocation(data, accessToken).then((x) => {
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Location created.`
      }))
      dispatch(reset('customerLocationCreate'))
      return x.data
    })
  }
}

export const createCustomerLocation = (data, accessToken, target) => {
  return (dispatch) => {
    return _createLocation(data, accessToken).then((x) => {
      if (target) {
        if (target.append) {
          dispatch(arrayPush(target.form, target.field, {
            value: x.data,
            label: x.data.name
          }))
        } else {
          dispatch(change(target.form, target.field, {
            value: x.data,
            label: x.data.name
          }))
        }
        dispatch({
          type: 'ADD_NEW_CUSTOMER_LOCATION_TO_QUESTIONNAIRE_END',
          payload: x.data
        })
        dispatch({
          type: 'ADD_NEW_LOCATION_TO_QUESTIONNAIRE_END',
          payload: x.data
        })
      } else {
        dispatch(push(`/customers/${data.customer_id}/locations`))
      }
    }).catch((e) => {
      dispatch({
        type: 'CREATE_CUSTOMER_LOCATION_REJECTED',
        payload: e
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'error',
        message: `Update error.`
      }))
    })
  }
}

export const updateCustomerLocation = (data, accessToken) => {
  return (dispatch) => {
    return axios({
      data,
      method: 'put',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/customers/${data.customer_id}/locations/${data.id}`
    }).then((x) => {
      dispatch({
        type: 'UPDATE_CUSTOMER_LOCATION_FULFILLED',
        payload: x.data
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Location updated.`
      }))
    }).catch((e) => {
      dispatch({
        type: 'UPDATE_CUSTOMER_LOCATION_REJECTED',
        payload: e
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'error',
        message: `Update error.`
      }))
    })
  }
}

export const deleteCustomerLocation = (custId, id, accessToken) => {
  return (dispatch) => {
    return axios({
      method: 'delete',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/customers/${custId}/locations/${id}`
    }).then((x) => {
      dispatch({
        type: 'DELETE_CUSTOMER_LOCATION_FULFILLED',
        payload: x
      })
      dispatch(push(`/customers/${custId}/locations`))
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Location deleted.`
      }))
    }).catch((e) => {
      dispatch({
        type: 'DELETE_CUSTOMER_LOCATION_REJECTED',
        payload: e
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'error',
        message: `Delete error.`
      }))
    })
  }
}

export const createCustomerExtended = (data, accessToken) => {
  return dispatch => {
    return axios({
      data: data.customer,
      method: 'post',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/customers`
    })
      .then(customer => ({ customer: customer.data }))
      .then(sum => {
        if (!data.include_location) { return sum }

        return axios({
          data: { ...data.location, customer_id: sum.customer.id },
          method: 'post',
          headers: { 'Authorization': `Bearer ${accessToken}` },
          url: `${baseAPI}/customers/${sum.customer.id}/locations`
        })
          .then(location => ({ ...sum, location: location.data }))
      })
      .then(sum => {
        if (!data.include_contact) {
          dispatch({
            type: 'CREATE_CUSTOMER_FULFILLED',
            payload: sum.customer
          })
          dispatch(push(`/customers/${sum.customer.id}`))

          return sum
        }

        const contactData = { ...data.contact, customer_id: sum.customer.id }

        if (sum.location) {
          contactData.location_id = sum.location.id
        }
        axios({
          data: contactData,
          method: 'post',
          headers: { 'Authorization': `Bearer ${accessToken}` },
          url: `${baseAPI}/customers/${sum.customer.id}/contacts`
        })

        dispatch({
          type: 'CREATE_CUSTOMER_FULFILLED',
          payload: sum.customer
        })
        dispatch(push(`/customers/${sum.customer.id}`))
      })
      .catch((e) => {
        dispatch({
          type: 'CREATE_CUSTOMER_REJECTED',
          payload: e
        })
        dispatch(createFlash({
          id: Date.now(),
          type: 'error',
          message: `Update error.`
        }))
      })
  }
}
