import orderStatuses from '../const/orderStatuses'

export function isVeto (user, order) {
  return order.users.filter((x) => x.role === 'Veto Person' && x.user.id === user.id).length > 0
}

export function isAuditor (user, order) {
  return order.users.filter((x) => x.role === 'Auditor' && x.user.id === user.id).length > 0
}

export function isLeadAuditor (user, order) {
  return order.users.filter((x) => x.role === 'Lead Auditor' && x.user.id === user.id).length > 0
}

export function isCustomer (user, order) {
  return order.users.filter((x) => x.role === 'Customer' && x.user.id === user.id).length > 0
}

export function isAfter (a, b) {
  return orderStatuses.reduce((sum, x, i) => {
    if (sum !== null) { return sum }
    if (x.label === a) { return false }
    if (x.label === b) { return true }
    return sum
  }, null)
}

export function getStage (order) {
  if (
    [
      'Plan Approved by Lead Auditor',
      'Stage 1 - On Audit',
      'Stage 1 - Audited'
    ].indexOf(order.status) >= 0
  ) {
    return 'Stage 1'
  }
  if (
    [
      'Reschedule - Plan Approved by Lead Auditor',
      'Stage 2 - On Audit',
      'Stage 2 - Audited'
    ].indexOf(order.status) >= 0
  ) {
    return 'Stage 2'
  }
  if (
    [
      'Follow up - Plan Approved by Lead Auditor',
      'Follow up - On Audit',
      'Follow up - Audited'
    ].indexOf(order.status) >= 0
  ) {
    return 'Follow up'
  }
  return null
}
