import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, Switch, Route } from 'react-router-dom'
import moment from 'moment'
import { change } from 'redux-form'
import intersection from 'lodash/intersection'

import ItemLog from '../../components/common/ItemLog'
import Pagination from '../../components/common/Pagination'

import CertificateTable from '../../components/Orders/CertificateTable'
import { CertificateCreateForm, CertificateUpdateForm } from '../../components/Orders/CertificateForm'

import { fetchOrder } from '../../actions/orders'
import { fetchCertificates, fetchCertificate, createCertificate, updateCertificate } from '../../actions/certificates'

export function OrderCertificateView (props) {
  const {
    fetchOrder,
    fetchCertificate,
    fetchCertificates,
    createCertificate,
    updateCertificate,
    match,
    accessToken,
    location,
    order,
    certificate,
    certificates,
    changeForm,
    user
  } = props

  const { params } = match

  useEffect(() => {
    fetchOrder(params.orderId, accessToken)
  }, [])

  useEffect(() => {
    if (params.id && params.id !== 'new') {
      fetchCertificate(match.params.orderId, match.params.id, accessToken)
    }
  }, [params])

  useEffect(() => {
    if (!params.id) {
      fetchCertificates(params.orderId, accessToken, location.search)
    }
  }, [location, params])

  function handleSubmitNew (e) {
    return createCertificate(
      { ...e,
        release_date: moment(e.release_date).unix(),
        end_date: moment(e.end_date).unix(),
        order_id: params.orderId,
        log: e.log ? e.log.detail : ''
      },
      accessToken
    )
  }

  function handleSubmitUpdate (e) {
    return updateCertificate(
      { ...e,
        release_date: moment(e.release_date).unix(),
        end_date: moment(e.end_date).unix(),
        order_id: e.order.id,
        log: e.log ? e.log.detail : ''
      },
      accessToken
    )
  }

  return (
    <div>
      <div className='content'>
        <Switch>
          <Route path='/orders/:orderId/certificates/new'>
            <div>
              <div className='main-header'>
                <h2>New Certificate</h2>
                { order &&
                  <em>For Order <Link to={`/orders/${order.id}`}>{order.id}</Link></em>
                }
              </div>
              <div className='row'>
                <div className='col-md-8'>
                  { order && order.questionnaire
                    ? <CertificateCreateForm
                      questionnaire={order.questionnaire}
                      onSubmit={handleSubmitNew.bind(this)}
                      changeForm={changeForm}
                      initialValues={{
                        status: 'Draft',
                        detail: {
                          standard: order.questionnaire.scopes[0].standard.name,
                          location: 0
                        }
                      }}
                      fileAPI={{
                        url: process.env.REACT_APP_BASE_API,
                        accessToken: accessToken
                      }}
                    />
                    : <p>loading</p>
                  }
                </div>
              </div>
            </div>
          </Route>
          <Route path='/orders/:orderId/certificates/:id'>
            <div>
              <div className='main-header'>
                { certificate &&
                  <h2>Update Certificate {certificate.id}</h2>
                }
                { order &&
                  <em>For Order <Link to={`/orders/${order.id}`}>{order.id}</Link></em>
                }
              </div>
              <div className='row'>
                <div className='col-md-8'>
                  { order && order.questionnaire
                    ? <Fragment>
                      { intersection(['Super Admin', 'Certificate Admin'], user.data.role).length > 0
                        ? <CertificateUpdateForm
                          questionnaire={order.questionnaire}
                          onSubmit={handleSubmitUpdate.bind(this)}
                          changeForm={changeForm}
                          initialValues={certificate}
                          fileAPI={{
                            url: process.env.REACT_APP_BASE_API,
                            accessToken: accessToken
                          }}
                        />
                        : <p>Access denied</p>
                      }
                    </Fragment>
                    : <p>loading</p>
                  }
                </div>
                <div className='col-md-4'>
                  { certificate &&

                    <p>
                      <a className='btn btn-primary btn-block' href={`${process.env.REACT_APP_BASE_API}/pdf/certificate?template=certificate-preview&id=${certificate.id}&token=${accessToken}`}><i className='fa fa-download' /> Preview Certificate</a>
                      <a className='btn btn-primary btn-block' href={`${process.env.REACT_APP_BASE_API}/pdf/certificate?template=certificate&id=${certificate.id}&token=${accessToken}`}><i className='fa fa-download' /> Print Certificate</a>
                    </p>
                  }
                  { certificate
                    ? <ItemLog log={certificate.log} entity='Certificate' />
                    : <div>loading</div>
                  }
                </div>
              </div>
            </div>
          </Route>
          <Route path='/orders/:orderId/certificates'>
            <div>
              <div className='main-header'>
                <h2>Certificate List</h2>
                { order &&
                  <em>For Order <Link to={`/orders/${order.id}`}>{order.id}</Link></em>
                }
              </div>
              <div className='row'>
                <div className='col-md-8'>
                  { certificates &&
                    <CertificateTable certificates={certificates} />
                  }
                </div>
                <Pagination />
              </div>
            </div>
          </Route>
        </Switch>
      </div>
    </div>
  )
}

function mapStateToProps (state) {
  return {
    order: state.orders.order.data,
    certificates: state.certificates.certificateList.data,
    certificate: state.certificates.certificate.data,
    accessToken: state.auth.user.access.access_token,
    user: state.auth.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOrder: (id, accessToken) => dispatch(fetchOrder(id, accessToken)),
    fetchCertificates: (id, accessToken) => dispatch(fetchCertificates(id, accessToken)),
    fetchCertificate: (orderId, id, accessToken) => dispatch(fetchCertificate(orderId, id, accessToken)),
    createCertificate: (data, accessToken) => dispatch(createCertificate(data, accessToken)),
    updateCertificate: (data, accessToken) => dispatch(updateCertificate(data, accessToken)),
    changeForm: (form, field, data) => dispatch(change(form, field, data))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderCertificateView)
