import React from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import Pagination from '../../components/common/Pagination'
import DaTable from '../../components/Das/DaTable'
import DaFilter from '../../components/Das/DaFilter'
// import { DaCreateForm as DaForm } from '../../components/Das/DaForm'

import { fetchDas } from '../../actions/das'
import { asQueryObject, asQueryString } from '../../const/helper'

class DaList extends React.Component {
  componentWillReceiveProps (nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.props.fetchDas(nextProps.location.search, this.props.accessToken)
    }
  }

  componentWillMount () {
    this.props.fetchDas(this.props.location.search, this.props.accessToken)
  }

  handleDa (e) {
    // return this.props.createDa(e, this.props.accessToken)
  }

  handleFilter (filter) {
    delete filter.page
    return this.props.push(`/das?${asQueryString(filter)}`)
  }

  render () {
    const { das, pagination, location } = this.props

    return (
      <div className='content'>
        <div className='main-header'>
          <h2>Daily Allowances </h2>
          <em className='hide'>normal sidebar with minified mode by default</em>
        </div>
        <div className='main-content'>
          <div className='row'>
            <div className='col-md-8'>
              { das
                ? <div>
                  <DaFilter
                    onSubmit={this.handleFilter.bind(this)}
                    initialValues={asQueryObject(location.search)}
                  />
                  <DaTable das={das} />
                </div>
                : <div>loading</div>
              }
              <Pagination pagination={pagination} base='/das' params={asQueryObject(location.search)} />
            </div>
            <div className='col-md-4'>
              {
                // <div className='widget'>
                //   <div className='widget-header'>
                //     <h3><i className='fa fa-book' /> Create Das</h3>
                //   </div>
                //   <div className='widget-content'>
                //     <DaForm
                //       onSubmit={this.handleDa.bind(this)}
                //       initialValues={{
                //         detail: {
                //           bod_approval: true
                //         }
                //       }}
                //     />
                //   </div>
                // </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    das: state.das.daList.data,
    pagination: state.das.daList.pagination,
    accessToken: state.auth.user.access.access_token
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchDas: (params, key) => dispatch(fetchDas(params, key)),
    push: (to) => dispatch(push(to))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DaList)
