import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import ConfirmButton from '../common/ConfirmButton'

import {
  // FileField,
  TextareaField
} from '../common/FormFields'

class VetoReviewForm extends Component {
  render () {
    const { handleSubmit, submitting } = this.props
    return (

      <div>

        <form className='form-horizontal veto-review-form' onSubmit={handleSubmit}>
          <Field name='detail.veto.audit_documentation' component={TextareaField} label='Audit Documentation' />
          <Field name='detail.veto.audit_duration' component={TextareaField} label='Audit Duration' />
          <Field name='detail.veto.ea_code_review' component={TextareaField} label='EA Code and Scope wording review' />
          <Field name='detail.veto.technical_competence_in_audit' component={TextareaField} label='Technical competence in audit' />
          <Field name='detail.veto.corrective_action_resulted' component={TextareaField} label='Corrective Action resulted in last audit reviewed' />
          <Field name='detail.veto.about_conformities' component={TextareaField} label='About nonconformities' />
          <Field name='detail.veto.report_content' component={TextareaField} label='Veto Review content' />
          <div className='form-group'>
            <div className='col-sm-offset-3 col-sm-9'>
              <p><button disabled={submitting} type='submit' className='btn btn-default btn-block'>Save</button></p>
              <ConfirmButton disabled={submitting} text={'Publish report'} confirmMessage='Please confirm the report and decision before proceeding.'>
                <button disabled={submitting} type='submit' className='btn btn-danger btn-block' onClick={handleSubmit(values => this.props.onSubmit({...values, decision: 'Veto Approval Pending'}))} >Publish & set as : veto approval pending</button>
                <button disabled={submitting} type='submit' className='btn btn-primary btn-block' onClick={handleSubmit(values => this.props.onSubmit({...values, advance: true, decision: 'Protocol Released'}))} >Publish & set as : protocol released</button>
              </ConfirmButton>
            </div>
          </div>

        </form>
      </div>
    )
  }
}

export default VetoReviewForm

const BaseVetoReviewCreateForm = props => <VetoReviewForm {...props} mode='create' />

export const VetoReviewCreateForm = reduxForm({
  form: 'vetoReviewCreate',
  enableReinitialize: true
})(BaseVetoReviewCreateForm)

const BaseVetoReviewUpdateForm = props => <VetoReviewForm {...props} mode='update' />

export const VetoReviewUpdateForm = reduxForm({
  form: 'vetoReviewUpdate',
  enableReinitialize: true
})(BaseVetoReviewUpdateForm)
