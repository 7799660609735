export const initialState = {
  scheduleList: { data: null, pagination: null, error: null, loading: false },
  globalScheduleList: { data: null, pagination: null, error: null, loading: false },
  schedule: { data: null, error: null, loading: false }
}
export default (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_SCHEDULES_PENDING':
      return { ...state, scheduleList: { data: null, error: null, loading: true } }
    case 'FETCH_SCHEDULES_REJECTED':
      return { ...state, scheduleList: { data: null, error: action.payload, loading: false } }
    case 'FETCH_SCHEDULES_FULFILLED':
      return { ...state,
        scheduleList: {
          data: action.payload.data,
          pagination: action.payload.pagination,
          error: null,
          loading: false
        }
      }

    case 'FETCH_GLOBAL_SCHEDULES_PENDING':
      return { ...state, globalScheduleList: { data: null, error: null, loading: true } }
    case 'FETCH_GLOBAL_SCHEDULES_REJECTED':
      return { ...state, globalScheduleList: { data: null, error: action.payload, loading: false } }
    case 'FETCH_GLOBAL_SCHEDULES_FULFILLED':
      return { ...state,
        globalScheduleList: {
          data: action.payload.data,
          pagination: action.payload.pagination,
          error: null,
          loading: false
        }
      }

    case 'FETCH_SCHEDULE_PENDING' :
      return { ...state, schedule: { data: null, error: null, loading: true } }
    case 'FETCH_SCHEDULE_REJECTED':
      return { ...state, schedule: { data: null, error: action.payload, loading: false } }
    case 'FETCH_SCHEDULE_FULFILLED' :
      return { ...state, schedule: { data: { ...action.payload }, error: null, loading: false } }

    case 'UPDATE_SCHEDULE_PENDING' :
      return { ...state, schedule: { ...state.schedule, error: null, loading: true } }
    case 'UPDATE_SCHEDULE_REJECTED':
      return { ...state, schedule: { ...state.schedule, error: action.payload, loading: false } }
    case 'UPDATE_SCHEDULE_FULFILLED' :
      return { ...state, schedule: { data: { ...action.payload }, error: null, loading: false } }

    case 'DELETE_SCHEDULE_PENDING' :
      return { ...state, schedule: { ...state.schedule, error: null, loading: true } }
    case 'DELETE_SCHEDULE_REJECTED':
      return { ...state, schedule: { ...state.schedule, error: action.payload, loading: false } }
    case 'DELETE_SCHEDULE_FULFILLED' :
      return { ...state, schedule: { data: null, error: null, loading: false } }

    case 'UPDATE_ORDER_FULFILLED' :
      return { ...state, scheduleList: { data: [ ...action.payload.schedules ], error: null, loading: false } }

    case 'LOGOUT_FULFILLED':
      return { ...initialState }

    default:
      return state
  }
}
