import set from 'lodash/set'

export const initialState = {
  questionnaireList: { data: null, paginaton: null, error: null, loading: false },
  questionnaire: { data: null, error: null, loading: false }
}
export default (state = initialState, action) => {
  const questionnaire = { ...state.questionnaire }
  switch (action.type) {
    case 'FETCH_QUESTIONNAIRES_PENDING':
      return { ...state, questionnaireList: { data: null, error: null, loading: true } }
    case 'FETCH_QUESTIONNAIRES_REJECTED':
      return { ...state, questionnaireList: { data: null, error: action.payload, loading: false } }
    case 'FETCH_QUESTIONNAIRES_FULFILLED':
      return { ...state,
        questionnaireList: {
          data: action.payload.data,
          pagination: action.payload.pagination,
          error: null,
          loading: false
        } }

    case 'FETCH_QUESTIONNAIRE_PENDING' :
      return { ...state, questionnaire: { data: null, error: null, loading: true } }
    case 'FETCH_QUESTIONNAIRE_REJECTED':
      return { ...state, questionnaire: { data: null, error: action.payload, loading: false } }
    case 'FETCH_QUESTIONNAIRE_FULFILLED' :
      return { ...state, questionnaire: { data: { ...action.payload }, error: null, loading: false } }

    case 'UPDATE_QUESTIONNAIRE_PENDING' :
      return { ...state, questionnaire: { ...state.questionnaire, error: null, loading: true } }
    case 'UPDATE_QUESTIONNAIRE_REJECTED':
      return { ...state, questionnaire: { ...state.questionnaire, error: action.payload, loading: false } }
    case 'UPDATE_QUESTIONNAIRE_FULFILLED' :
      return { ...state, questionnaire: { data: { ...action.payload }, error: null, loading: false } }

    case 'DELETE_QUESTIONNAIRE_PENDING' :
      return { ...state, questionnaire: { ...state.questionnaire, error: null, loading: true } }
    case 'DELETE_QUESTIONNAIRE_REJECTED':
      return { ...state, questionnaire: { ...state.questionnaire, error: action.payload, loading: false } }
    case 'DELETE_QUESTIONNAIRE_FULFILLED' :
      return { ...state, questionnaire: { data: null, error: null, loading: false } }

    case 'ADD_NEW_CUSTOMER_TO_QUESTIONNAIRE_START' :
      return { ...state, questionnaire: { ...state.questionnaire, addingCustomer: true } }
    case 'ADD_NEW_CUSTOMER_TO_QUESTIONNAIRE_END' :
      delete questionnaire.addingCustomer
      return { ...state, questionnaire }

    case 'ADD_NEW_CUSTOMER_CONTACT_TO_QUESTIONNAIRE_START' :
      return { ...state, questionnaire: { ...state.questionnaire, addingCustomerContact: true } }
    case 'ADD_NEW_CUSTOMER_CONTACT_TO_QUESTIONNAIRE_END' :
      delete questionnaire.addingCustomerContact
      return { ...state, questionnaire: { ...questionnaire } }

    case 'ADD_NEW_CUSTOMER_LOCATION_TO_QUESTIONNAIRE_START' :
      return { ...state, questionnaire: { ...state.questionnaire, addingCustomerLocation: true } }
    case 'ADD_NEW_CUSTOMER_LOCATION_TO_QUESTIONNAIRE_END' :
      delete questionnaire.addingCustomerLocation
      return { ...state, questionnaire: { ...questionnaire } }

    case 'ADD_NEW_LOCATION_TO_QUESTIONNAIRE_START' :
      return { ...state, questionnaire: { ...state.questionnaire, addingQuestionnaireLocation: true } }
    case 'ADD_NEW_LOCATION_TO_QUESTIONNAIRE_END' :
      delete questionnaire.addingQuestionnaireLocation
      return { ...state, questionnaire: { ...questionnaire }
      }

    case 'UPDATE_QUESTIONNAIRE_ITEM_FULFILLED' :
      const itemKey = action.payload.service
      const newState = set(state, `questionnaire.data.${itemKey}`, action.payload)
      return { ...newState }

    case 'LOGOUT_FULFILLED':
      return { ...initialState }

    default:
      return state
  }
}
