import { forEach } from 'lodash'

export const createFlash = (flash = '') => {
  return (dispatch) => {
    dispatch({
      type: 'CREATE_FLASH',
      payload: flash
    })

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve('ok')
      }, 3000)
    }).then((x) => {
      dispatch({
        type: 'DELETE_FLASH',
        payload: flash.id
      })
    })
  }
}

export const createError = (flash) => {
  const id = Date.now()
  let response = ''

  if (typeof flash === 'object') {
    if (flash.message) {
      response += flash.message
    } else {
      forEach(flash, (x) => {
        response += x.join('<br />') + '<br/>'
      })
    }
  // } else if (typeof flash === 'array') {
  //   response = flash.reduce((previous, current) => {
  //     return previous + ' ' + current.message
  //   }, '')
  } else {
    response = flash
  }

  return (dispatch) => {
    dispatch({
      type: 'CREATE_FLASH',
      payload: {
        id: id,
        type: 'danger',
        message: response
      }
    })

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve('ok')
      }, 3000)
    }).then((x) => {
      dispatch({
        type: 'DELETE_FLASH',
        payload: id
      })
    })
  }
}

export const removeFlash = (id) => {
  return (dispatch) => {
    dispatch({
      type: 'DELETE_FLASH',
      payload: id
    })
  }
}
