import React from 'react'
import Flasher from '../components/common/Flasher'

export default class Outside extends React.Component {
  // constructor (props) {
  //   super(props)
  // }

  render () {
    return (
      <div>
        {this.props.children}
        <Flasher />
      </div>
    )
  }
}
