import React from 'react'

export default class SingleScheduleForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      locationId: props.locations[0].key,
      stage: 'Stage 1',
      role: 'Auditor',
      includeManday: true
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.initialValues) {
      this.setState({
        locationId: nextProps.initialValues.location_id,
        stage: nextProps.initialValues.type,
        role: nextProps.initialValues.role,
        includeManday: nextProps.initialValues.detail.includeManday
      })
    }
  }

  handleChange (ev) {
    const newState = {}
    newState[ev.target.name] = ev.target.value
    this.setState(newState)
  }

  handleSubmit (ev) {
    ev.preventDefault()
    const data = {
      location_id: this.state.locationId,
      stage: this.state.stage,
      role: this.state.role,
      includeManday: this.state.includeManday
    }
    this.props.onSubmit(data)
  }

  handleRemove () {
    this.props.onRemove()
  }

  render () {
    const {order, locations} = this.props
    const { locationId, stage, role, includeManday } = this.state

    return (
      <form className='form' onSubmit={this.handleSubmit.bind(this)}>
        <div className='row'>
          <div className='col-xs-12'>
            <div className='form-group'>
              <select id='' className='form-control input-sm' onChange={this.handleChange.bind(this)} value={locationId} name='locationId'>
                { locations
                  .map((x, i) => <option value={x.value} key={i}>{x.label}</option>)
                }
              </select>
            </div>
          </div>
          {order.phase === 'Certification Audit' &&
            <div className='col-xs-12'>
              <div className='form-group'>
                <select id='' className='form-control input-sm' onChange={this.handleChange.bind(this)} value={stage} name='stage'>
                  { ['Stage 1', 'Stage 2', 'Follow-up']
                    .map((x, i) => <option value={x} key={i}>{x}</option>)
                  }
                </select>
              </div>
            </div>
          }
          <div className='col-xs-12'>
            <div className='form-group'>
              <select id='' className='form-control input-sm' onChange={this.handleChange.bind(this)} value={role} name='role'>
                { ['Lead Auditor', 'Auditor', 'Senior Auditor', 'Expert', 'Trainee']
                  .map((x, i) => <option value={x} key={i}>{x}</option>)
                }
              </select>
            </div>
          </div>
          <div className='col-xs-12'>
            <div className='form-group'>
              <select id='' className='form-control input-sm' onChange={this.handleChange.bind(this)} value={includeManday} name='includeManday'>
                <option value >Manday included</option>
                <option value={false} >Manday excluded</option>
              </select>
            </div>
          </div>
        </div>
        <div className='form-group'>
          <button className='btn btn-primary btn-block'>Save</button>
          <button type='button' onClick={this.handleRemove.bind(this)} className='btn btn-default btn-block'>Remove</button>
        </div>
      </form>
    )
  }
}
