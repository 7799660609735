import React from 'react'
import { Field } from 'redux-form'

import Required from './Required'
import './TableField.scss'

export default class TableField extends React.Component {
  render () {
    const { label, fields, heads, required, meta: { error, warning } } = this.props

    return (
      <div className={`form-group table-field ${fields.name.toLowerCase().split('.').join('_')}`}>
        <label htmlFor={label} className='col-sm-3 control-label'>{label} {required && <Required />}</label>
        <div className='col-sm-9'>
          <div className='table-responsive'>
            <table className='table table-bordered'>
              <thead>
                <tr>
                  {heads.map((x, i) => {
                    return (
                      <th key={i}>{x}</th>
                    )
                  })}
                  <th />
                </tr>
              </thead>
              <tbody>
                {fields.map((member, index) => (
                  <tr key={index}>
                    {heads.map((label, i) => (
                      <td style={{ padding: 0 }} key={i}>
                        <Field
                          name={`${member}.${i}`}
                          component='input'
                          type='text'
                          className='form-control'
                          label={label}
                        />
                      </td>
                    ))}
                    <th>
                      <a onClick={() => fields.remove(index)} ><i className='fa fa-remove' /></a>
                    </th>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button className='btn btn-default btn-sm btn-add-new' type='button' onClick={() => fields.push({})}>Add item</button>
        </div>
        <div className='col-sm-9 col-sm-offset-3'>
          {((error && <div className='alert alert-danger'>{error}</div>) || (warning && <div className='alert alert-warning'>{warning}</div>))}
        </div>
      </div>
    )
  }
}
