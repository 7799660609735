const questionnaireStatuses = [
  {
    label: 'Draft',
    color: 'warning'
  },
  {
    label: 'Published',
    color: 'info'
  },
  {
    label: 'Approved by SM',
    color: 'primary'
  },
  {
    label: 'Approved by BOD',
    color: 'primary'
  },
  {
    label: 'Approved by CUST',
    color: 'success'
  }
]

export default questionnaireStatuses
