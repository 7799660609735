import React from 'react'
import { connect } from 'react-redux'
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form'
// import get from 'lodash/get'

import LocationsField from './LocationsField'

import ConfirmButton from '../common/ConfirmButton'
import { TextField } from '../common/FormFields'

function validateLocations (locations) {
  if (!locations || locations.length === 0) {
    return { _error: 'Required' }
  }
  if (locations.find(x => !x.detail || !x.detail.number_of_employees_fulltime)) {
    return { _error: 'Plese complete data' }
  }
  if (locations.find(x => !x.detail || !x.detail.number_of_employees_temporary)) {
    return { _error: 'Plese complete data' }
  }
  if (locations.find(x => !x.detail || !x.detail.scope_of_business)) {
    return { _error: 'Plese complete data' }
  }
}

function validate (values) {
  if (!values.advance) {
    return {}
  }
  const errors = { detail: {} }
  const locationValidation = validateLocations(values.locations)

  if (locationValidation) {
    errors.locations = locationValidation
  }
  return errors
}

export function QuestionnaireAuditLocationFormBase (props) {
  const { handleSubmit, submitting, changeForm, contactAPI, locationAPI, openNewLocation, openNewContact, locations } = props

  return (
    <div>
      <form className='form-horizontal questionnaire-audit-location-form' onSubmit={handleSubmit}>

        <FieldArray
          name='locations'
          component={LocationsField}
          label='Locations'
          locationAPI={locationAPI}
          contactAPI={contactAPI}
          openNew={openNewLocation}
          openNewContact={openNewContact}
          locationsValue={locations}
          required
        />

        <Field hidden name='advance' component={TextField} label='Yes please' />

        <div className='form-group'>
          <div className='col-sm-offset-3 col-sm-6'>
            <button disabled={submitting} type='submit' className='btn btn-default btn-block btn-submit' onClick={ev => {
              ev.preventDefault()
              changeForm('advance', false)
              setTimeout(handleSubmit, 100)
            }} >Save Draft</button>
          </div>
          <div className='col-sm-3'>
            <ConfirmButton
              disabled={submitting}
              text={'Save Questionnaire'}
            >
              <button disabled={submitting} type='submit' className='btn-advance btn btn-primary btn-block btn-submit' onClick={ev => {
                ev.preventDefault()
                changeForm('advance', true)
                setTimeout(handleSubmit, 100)
              }} >Continue</button>
            </ConfirmButton>
          </div>
        </div>
      </form>
    </div>
  )
}

let QuestionnaireAuditLocationForm = reduxForm({ // eslint-disable-line 
  form: 'QuestionnaireAuditLocationForm',
  enableReinitialize: true,
  validate: validate
})(QuestionnaireAuditLocationFormBase)

const selectorCreate = formValueSelector('QuestionnaireAuditLocationForm')

QuestionnaireAuditLocationForm = connect(state => { // eslint-disable-line 
  return {
    locations: selectorCreate(state, 'locations')
  }
})(QuestionnaireAuditLocationForm)

export default QuestionnaireAuditLocationForm
