import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

import { TextField, TextareaField } from '../common/FormFields'

export class OptionForm extends Component {
  render () {
    const { handleSubmit, submitting } = this.props
    return (
      <div>
        <form className='form-horizontal' onSubmit={handleSubmit}>
          <Field name='key' type='text' component={TextField} label='Option Key' />
          <Field name='value' type='text' component={TextareaField} label='Value' />
          <div className='form-group'>
            <div className='col-sm-offset-3 col-sm-9'>
              <button disabled={submitting} type='submit' className='btn btn-primary btn-block'>Submit</button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default OptionForm

const BaseOptionCreateForm = props => <OptionForm {...props} mode='create' />

export const OptionCreateForm = reduxForm({
  form: 'optionCreate' // validate, warn
})(BaseOptionCreateForm)

const BaseOptionUpdateForm = props => <OptionForm {...props} mode='update' />

export const OptionUpdateForm = reduxForm({
  form: 'optionUpdate' // validate, warn
})(BaseOptionUpdateForm)
