import React from 'react'
import moment from 'moment'

export default class NcDetail extends React.Component {
  render () {
    const { nc, accessToken } = this.props
    const dueDate = moment.unix(nc.due_date)
    return (
      <div className='widget'>
        <div className='widget-header'>
          <h3>
            <i className='fa fa-table' /> Nc Detail
          </h3>
        </div>
        <div className='widget-content'>
          { nc &&
            <div>
              <div className='row'>
                <div className='col-md-3 key-val'>
                  <p>NC Number</p>
                  <p className='h4'>{nc.id}</p>
                </div>
                <div className='col-md-3 key-val'>
                  <p>Category</p>
                  <p className='h4'>{nc.kind}</p>
                </div>
                <div className='col-md-3 key-val'>
                  <p>Status</p>
                  <p className='h4'>{nc.status}</p>
                </div>
                <div className='col-md-3 key-val'>
                  <p>Due Date</p>
                  <p>{dueDate.format('llll')}</p>
                </div>
              </div>
              <hr />
              <div className='row'>
                <div className='col-md-3 key-val'>
                  <p>Effectiveness</p>
                  <p className='h4'>{nc.detail && nc.detail.effectiveness}</p>
                </div>
                <div className='col-md-3 key-val'>
                  <p>Implementation Verification</p>
                  <p className='h4'>{nc.detail && nc.detail.implementation_verification_phase}</p>
                </div>
              </div>
              <hr />
              <div className='row'>
                <div className='col-md-12'>
                  <p><strong>Description</strong></p>
                  <p>{nc.description}</p>
                </div>
                <div className='col-md-12'>
                  <p><strong>Related Clause</strong></p>
                  <p>{nc.clausul}</p>
                </div>
              </div>
              <hr />
              <div className='row'>
                <div className='col-md-6 key-val'>
                  <p>Attachment</p>
                  { nc.detail && nc.detail.attachment && nc.detail.attachment.map((x, i) => {
                    return (
                      <span key={i}>
                        <strong>{x.detail.name} - <a href={`${process.env.REACT_APP_BASE_API}/download/${x.id}?token=${accessToken}`} className='btn btn-link btn-xs'><i className='fa fa-download' /> Download</a></strong><br />
                      </span>
                    )
                  })}
                </div>
                <div className='col-md-6 key-val'>
                  <p><strong>Notes: </strong></p>
                  <p>
                    { nc.detail && nc.detail.require_evidence && <span>Require Evidence &nbsp;</span> }
                    { nc.detail && nc.detail.require_reaudit && <span>Require Reaudit &nbsp;</span> }
                    { nc.detail && nc.detail.verified_by_documents && <span>Verified by Documents &nbsp;</span> }
                    { nc.detail && nc.detail.verified_in_reaudit && <span>Verified in Reaudit &nbsp;</span> }
                    { nc.detail && nc.detail.close_before_stage_2 && <span>Need to closed before Stage 2 Audit &nbsp;</span> }
                  </p>
                </div>
              </div>
              <hr />
              <div className='row'>
                <div className='col-md-6 key-val'>
                  <p><strong>Cause</strong></p>
                  {nc.detail && nc.detail.cause}
                </div>
                <div className='col-md-6 key-val'>
                  <p><strong>Correction</strong></p>
                  {nc.detail && nc.detail.correction}
                </div>
              </div>
              <hr />
              <div className='row'>
                <div className='col-md-6 key-val'>
                  <p><strong>Corrective Action</strong></p>
                  {nc.detail && nc.detail.corrective_action}
                </div>
                <div className='col-md-3 key-val'>
                  <p><strong>Correction Date</strong></p>
                  <p>{nc.detail && nc.detail.correction_date}</p>
                </div>
                <div className='col-md-3 key-val'>
                  <p><strong>Implementation Deadline</strong></p>
                  <p>{nc.detail && nc.detail.implementation_deadline}</p>
                </div>
              </div>
              <hr />
              <div className='row'>
                <div className='col-md-6 key-val'>
                  <p>Correction Photo</p>
                  { nc.detail && nc.detail.correction_photo && nc.detail.correction_photo.map((x, i) => {
                    return (
                      <span key={i}>
                        <strong>{x.detail.name} - <a href={`${process.env.REACT_APP_BASE_API}/download/${x.id}?token=${accessToken}`} className='btn btn-link btn-xs'><i className='fa fa-download' /> Download</a></strong><br />
                      </span>
                    )
                  })}
                </div>
              </div>
              <hr />
              <div className='row'>
                <div className='col-md-12'>
                  <ul className='list-unstyled'>
                    <li><a href={`${process.env.REACT_APP_BASE_API}/pdf/nc?template=nc-report&id=${nc.id}&token=${accessToken}`} className='btn btn-link btn-xs'><i className='fa fa-download' /> Download Nonconformity Report</a></li>
                  </ul>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}
