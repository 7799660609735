import React from 'react'
import { Link } from 'react-router-dom'

export default class ChangeTable extends React.Component {
  render () {
    return (
      <div className='table-responsive'>
        <table className='table colored-header datatable project-list'>
          <thead>
            <tr>
              <th>Code</th>
              <th>Standard</th>
              <th>Relase Date</th>
              <th>End Date</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><Link to='/orders/12/certificates/1'>C.143213</Link></td>
              <td>ISO 9001 : 2008</td>
              <td>21 Juni 2017</td>
              <td>21 Juni 2019</td>
              <td><span className='label label-warning'>DRAFT</span></td>
            </tr>
            <tr>
              <td><Link to='/orders/12/certificates/1'>C.143213</Link></td>
              <td>ISO 9001 : 2008</td>
              <td>21 Juni 2017</td>
              <td>21 Juni 2019</td>
              <td><span className='label label-default'>EXPIRED</span></td>
            </tr>
            <tr>
              <td><Link to='/orders/12/certificates/1'>C.143213</Link></td>
              <td>ISO 9001 : 2008</td>
              <td>21 Juni 2017</td>
              <td>21 Juni 2019</td>
              <td><span className='label label-default'>CANCELED</span></td>
            </tr>
            <tr>
              <td><Link to='/orders/12/certificates/1'>C.143213</Link></td>
              <td>ISO 9001 : 2008</td>
              <td>21 Juni 2017</td>
              <td>21 Juni 2019</td>
              <td><span className='label label-success'>PUBLISHED</span></td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}
