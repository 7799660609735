export const initialState = {
  ncList: { data: null, pagination: null, error: null, loading: false },
  globalNcList: { data: null, pagination: null, error: null, loading: false },
  nc: { data: null, error: null, loading: false }
}
export default (state = initialState, action) => {
  switch (action.type) {
    case 'CREATE_NC_PENDING' :
      return { ...state, nc: { data: null, error: null, loading: true } }
    case 'CREATE_NC_REJECTED':
      return { ...state, nc: { data: null, error: action.payload, loading: false } }
    case 'CREATE_NC_FULFILLED' :
      return { ...state, nc: { data: { ...action.payload }, error: null, loading: false } }

    case 'FETCH_NCS_PENDING':
      return { ...state, ncList: { data: null, error: null, loading: true } }
    case 'FETCH_NCS_REJECTED':
      return { ...state, ncList: { data: null, error: action.payload, loading: false } }
    case 'FETCH_NCS_FULFILLED':
      return { ...state,
        ncList: {
          data: action.payload.data,
          pagination: action.payload.pagination,
          error: null,
          loading: false
        }
      }

    case 'FETCH_GLOBAL_NCS_PENDING':
      return { ...state, globalNcList: { data: null, error: null, loading: true } }
    case 'FETCH_GLOBAL_NCS_REJECTED':
      return { ...state, globalNcList: { data: null, error: action.payload, loading: false } }
    case 'FETCH_GLOBAL_NCS_FULFILLED':
      return { ...state,
        globalNcList: {
          data: action.payload.data,
          pagination: action.payload.pagination,
          error: null,
          loading: false
        }
      }

    case 'FETCH_NC_PENDING' :
      return { ...state, nc: { data: null, error: null, loading: true } }
    case 'FETCH_NC_REJECTED':
      return { ...state, nc: { data: null, error: action.payload, loading: false } }
    case 'FETCH_NC_FULFILLED' :
      return { ...state, nc: { data: { ...action.payload }, error: null, loading: false } }

    case 'UPDATE_NC_PENDING' :
      return { ...state, nc: { ...state.nc, error: null, loading: true } }
    case 'UPDATE_NC_REJECTED':
      return { ...state, nc: { ...state.nc, error: action.payload, loading: false } }
    case 'UPDATE_NC_FULFILLED' :
      return { ...state, nc: { data: { ...action.payload }, error: null, loading: false } }

    case 'DELETE_NC_PENDING' :
      return { ...state, nc: { ...state.nc, error: null, loading: true } }
    case 'DELETE_NC_REJECTED':
      return { ...state, nc: { ...state.nc, error: action.payload, loading: false } }
    case 'DELETE_NC_FULFILLED' :
      return { ...state, nc: { data: null, error: null, loading: false } }

    case 'LOGOUT_FULFILLED':
      return { ...initialState }

    default:
      return state
  }
}
