import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form'

import { DisabledUserField, PositionField, DateField, OptionsField } from './ScheduleFields'

const selector = formValueSelector('ScheduleGroupForm')

const validate = (values) => {
  const errors = {}
  if (!values.username) {
    errors.username = 'Required'
  }
  if (!values.password) {
    errors.password = 'Required'
  }
  return errors
}

const warn = (values) => {
  const warnings = {}
  if (values.username === 'test') {
    warnings.username = 'No test please'
  }
  return warnings
}

const Auditors = ({ label, fields, order, meta: { error, submitFailed } }) => {
  const locations = order ? order.questionnaire.locations.map((x, i) => {
    return ({
      value: x.location.id,
      label: x.location.name + ' - ' + x.location.city
    })
  }) : [{
    value: null,
    label: 'n/a'
  }]

  return (
    <div className='auditor-field'>
      <div className='form-group'>
        <label htmlFor={label} className='col-sm-3 control-label'>{label}</label>
      </div>
      <table className='table table-condensed'>
        {fields.map((x, i) => (
          <tbody key={i}>
            <tr>
              <th>
                <Field
                  name={`${x}.user`}
                  component={DisabledUserField}
                />
              </th>
              <td>
                <Field
                  name={`${x}.role`}
                  component={PositionField}
                  options={[
                    'Lead Auditor',
                    'Auditor',
                    'Senior Auditor',
                    'Expert',
                    'Trainee'
                  ]}
                />
              </td>
              <td className='text-right'>
                <button
                  onClick={() => fields.remove(i)}
                  className='btn btn-xs btn-default'
                >
                  <i className='fa fa-xs fa-remove' />
                </button>
              </td>
            </tr>
            {order.phase === 'Certification Audit' &&
            <tr>
              <td>Location</td>
              <td colSpan={3}>
                <Field
                  name={`${x}.location_id`}
                  component={OptionsField}
                  type='select'
                  options={locations}
                />
              </td>
            </tr>
            }
            {order.phase === 'Certification Audit' &&
            <tr>
              <td>Stage</td>
              <td colSpan={3}>
                <Field
                  name={`${x}.type`}
                  component={OptionsField}
                  type='select'
                  options={[
                    'Stage 1',
                    'Stage 2',
                    'Follow-up'
                  ]}
                />
              </td>
            </tr>
            }
            <tr>
              <td>Start Date</td>
              <td colSpan={3}>
                <Field name={`${x}.start_date`} component={DateField} label='Start Date' />
              </td>
            </tr>
            <tr>
              <td>End Date</td>
              <td colSpan={3}>
                <Field name={`${x}.end_date`} component={DateField} label='End Date' />
              </td>
            </tr>
            <tr>
              <td>Include Manday</td>
              <td colSpan={3}>
                <Field
                  name={`${x}.detail.includeManday`}
                  component={OptionsField}
                  type='checkbox'
                />
              </td>
            </tr>
          </tbody>
        ))}
      </table>
    </div>
  )
}

export class ScheduleGroupForm extends Component {
  render () {
    const { handleSubmit, submitting, order, locations } = this.props
    return (
      <form className='form-horizontal schedule-group-form' onSubmit={handleSubmit}>

        <FieldArray name='auditors' component={Auditors} label='Auditors' order={order} locations={locations} />

        <div className='form-group'>
          <div className='col-sm-offset-3 col-sm-9'>
            <button disabled={submitting} type='submit' className='btn btn-default btn-block'>Save </button>
            <button disabled={submitting} type='submit' className='btn btn-primary btn-block' onClick={handleSubmit(values => this.props.onSubmit({ ...values, advance: true }))}>Save & Submit</button>
          </div>
        </div>
      </form>
    )
  }
}

ScheduleGroupForm = reduxForm({ // eslint-disable-line 
  form: 'ScheduleGroupForm',
  validate,
  warn
})(ScheduleGroupForm)

ScheduleGroupForm = connect(state => { // eslint-disable-line 
  const selectedRole = selector(state, 'role')
  return {
    selectedRole
  }
})(ScheduleGroupForm)

export default ScheduleGroupForm
