import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'

import { TextField, OptionsField } from '../common/FormFields'
import FileField from '../common/FileField'

import roles from '../../const/roles'
import { services } from '../../const/services'

const validate = (values) => {
  const errors = {
    data: {}
  }
  if (!values.username) {
    errors.username = 'Required'
  }
  if (!values.email) {
    errors.email = 'Required'
  }
  if (!values.new_password) {
    errors.new_password = 'Required'
  }

  if (!values.data || !values.data.role || values.data.role.length === 0) {
    errors.data.role = 'Required'
  }
  if (!values.data || !values.data.display_name) {
    errors.data.display_name = 'Required'
  }
  return errors
}

const validateUpdate = (values) => {
  const errors = {}
  if (!values.username) {
    errors.username = 'Required'
  }
  if (!values.role) {
    errors.role = 'Required'
  }
  return errors
}

class UserForm extends Component {
  render () {
    const { submitting, full } = this.props

    return (

      <div>
        <form className='form-horizontal' onSubmit={this.props.handleSubmit}>

          <Field name='username' type='text' component={TextField} label='User Name' />

          <Field name='email' type='email' component={TextField} label='Email' />

          <Field name='data.display_name' type='text' component={TextField} label='Display Name' />

          { full &&
            <Field name='data.role' type='multiCheckbox' component={OptionsField} label='Role' options={roles} />
          }

          {this.props.selectedRole && this.props.selectedRole.includes('Sales') && full &&
            <Field name='data.services' type='multiCheckbox' component={OptionsField} label='Services' options={services} />
          }
          {
            this.props.selectedRole && this.props.selectedRole && this.props.selectedRole.includes('Auditor') &&
              <Fragment>
                <Field name='data.auditor_code' type='text' component={TextField} label='Auditor Code' />
                <Field name='data.managed_by_admin' type='checkbox' component={OptionsField} label='Managed by Admin' />
                <Field name='data.certificate' component={FileField} label='IRCA LA Course Certificate' api={this.props.fileAPI} />
              </Fragment>
          }
          {
            <Field name='data.profile_picture' component={FileField} label='Profile Picture' api={this.props.fileAPI} />
          }

          <Field name='new_password' type='password' component={TextField} label='Password' />

          <div className='form-group'>
            <div className='col-sm-offset-3 col-sm-9'>
              <button disabled={submitting} type='submit' className='btn btn-primary btn-block'>Submit</button>
            </div>
          </div>

        </form>
      </div>
    )
  }
}

export default UserForm

const BaseUserCreateForm = props => <UserForm {...props} mode='create' />
const BaseUserUpdateForm = props => <UserForm {...props} mode='update' />

let UserCreateForm = reduxForm({
  validate,
  form: 'userCreate' //, validate, warn
})(BaseUserCreateForm)

const selectorCreate = formValueSelector('userCreate')

UserCreateForm = connect(state => { // eslint-disable-line 
  return {
    selectedRole: selectorCreate(state, 'data.role')
  }
})(UserCreateForm)

let UserUpdateForm = reduxForm({
  validate: validateUpdate,
  form: 'userUpdate' //, validate, warn
})(BaseUserUpdateForm)

const selectorUpdate = formValueSelector('userUpdate')

UserUpdateForm = connect(state => { // eslint-disable-line 
  return {
    selectedRole: selectorUpdate(state, 'data.role')
  }
})(UserUpdateForm)

export { UserCreateForm, UserUpdateForm }
