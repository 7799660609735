import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { fetchUser } from '../../actions/users'

function mapStateToProps (state) {
  return {
    activeUser: state.users.activeUser,
    specification: [
      {
        id: 12,
        standard: {
          id: 12,
          name: 'ISO 14001 : 2012'
        },
        scope: {
          id: 12,
          name: 'Elektronik'
        },
        leadAuditor: true,
        auditor: true,
        expert: true
      },
      {
        id: 13,
        standard: {
          id: 12,
          name: 'ISO 14001 : 2005'
        },
        scope: {
          id: 12,
          name: 'Elektronik'
        },
        leadAuditor: true,
        auditor: true,
        expert: true
      },
      {
        id: 12,
        standard: {
          id: 12,
          name: 'ISO 14001 : 2005'
        },
        scope: {
          id: 12,
          name: 'Semen'
        },
        expert: true
      }
    ]

  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchUser: (id, key) => dispatch(fetchUser(id, key))
  }
}

export class UserUpdateSpec extends React.Component {
  componentWillMount () {
    return this.props.fetchUser(this.props.match.params.id, 'some-key')
  }

  render () {
    return (
      <div className='content'>
        <div className='main-header'>
          <h2>Update Auditor Specification</h2>{/* TODO : change title if updating user */}
          {/* <em>normal sidebar with minified mode by default</em> */}
        </div>
        <div className='main-content'>
          <div className='row'>
            <div className='col-md-8'>
              <table className='table table-condensed'>
                <thead>
                  <tr>
                    <th>Standard</th>
                    <th className='text-center' width='15%'>Lead Auditor</th>
                    <th className='text-center' width='15%'>Auditor</th>
                    <th className='text-center' width='15%'>Expert</th>
                    <th className='text-center' width={40} />
                  </tr>
                </thead>
                <tbody>
                  <tr className='bg-grey'>
                    <th colSpan='5'>ISO 14001 : 2012</th>
                  </tr>
                  <tr>
                    <th>Elektronik</th>
                    <td className='text-center'><i className='fa fa-check' /></td>
                    <td className='text-center'><i className='fa fa-check' /></td>
                    <td className='text-center'><i className='fa fa-check' /></td>
                    <td className='text-center'><i className='fa fa-gear' /></td>
                  </tr>
                  <tr className='bg-grey'>
                    <th colSpan='5'>ISO 14001 : 2005</th>
                  </tr>
                  <tr>
                    <th>Elektronik</th>
                    <td className='text-center'><i className='fa fa-check' /></td>
                    <td className='text-center'><i className='fa fa-check' /></td>
                    <td className='text-center'><i className='fa fa-check' /></td>
                    <td className='text-center'><i className='fa fa-gear' /></td>
                  </tr>
                  <tr>
                    <th>Semen</th>
                    <td className='text-center' />
                    <td className='text-center'><i className='fa fa-check' /></td>
                    <td className='text-center' />
                    <td className='text-center'><i className='fa fa-gear' /></td>
                  </tr>
                  <tr>
                    <th>Ban Motor</th>
                    <td className='text-center' />
                    <td className='text-center' />
                    <td className='text-center'><i className='fa fa-check' /></td>
                    <td className='text-center'><button className='btn btn-default btn-xs'><i className='fa fa-gear' /></button></td>
                  </tr>
                  <tr className='spec-edit-container'>
                    <td className='standad-scope-container'>
                      <select className='form-control input-sm'>
                        <option>ISO 14001 : 2015</option>
                        <option>ISO 14002</option>
                        <option>ISO 9001</option>
                      </select>
                      <select className='form-control input-sm'>
                        <option>Ban Mobil</option>
                        <option>Elektronik</option>
                        <option>Semen</option>
                      </select>
                    </td>
                    <td className='text-center'>
                      <input type='checkbox' />
                    </td>
                    <td className='text-center'>
                      <input type='checkbox' />
                    </td>
                    <td className='text-center'>
                      <input type='checkbox' />
                    </td>
                    <td className='text-center'>
                      <i className='fa fa-check' />{' '}
                      <i className='fa fa-remove' />
                    </td>
                  </tr>
                  <tr className='spec-edit-container bg-grey'>
                    <td className='standad-scope-container'>
                      <select className='form-control input-sm'>
                        <option>ISO 14001 : 2015</option>
                        <option>ISO 14002</option>
                        <option>ISO 9001</option>
                      </select>
                      <select className='form-control input-sm'>
                        <option>Ban Mobil</option>
                        <option>Elektronik</option>
                        <option>Semen</option>
                      </select>
                    </td>
                    <td className='text-center'>
                      <input type='checkbox' />
                    </td>
                    <td className='text-center'>
                      <input type='checkbox' />
                    </td>
                    <td className='text-center'>
                      <input type='checkbox' />
                    </td>
                    <td className='text-center'>
                      <button className='btn btn-default btn-xs'><i className='fa fa-check' /></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='col-md-4'>
              {
                this.props.activeUser.user !== null &&
                  <Link to={`/users/${this.props.activeUser.user.id}`} className='btn btn-default'>Update Data</Link>
              }
              <div className='widget hide'>
                <div className='widget-header'>
                  <h3><i className='fa fa-edit' /> Basic Input</h3>
                </div>
                <div className='widget-content'>
                  <p>The Gardens are a tremendous big place, with millions and hundreds of trees and first you come to the Figs, but you scorn to loiter there, for the Figs is the resort of superior little persons, who are forbidden to mix with the commonalty, and is so named, according to legend, because they dress in full fig. These dainty ones are themselves contemptuously called Figs by David and other heroes, and you have a key to the manners and customs of this dandiacal section of the Gardens when I tell you that cricket is called crickets here. Occasionally a rebel Fig climbs over the fence into the world, and such a one was Miss Mabel Grey, of whom I shall tell you when we come to Miss Mabel Grey's gate. She was the only really celebrated Fig.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserUpdateSpec)
