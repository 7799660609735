import axios from 'axios'
import { push } from 'react-router-redux'
import reduce from 'lodash/reduce'
import { createFlash, createError } from './flasher'

const baseAPI = process.env.REACT_APP_BASE_API

export const fetchNcs = (id, accessToken, query = '') => {
  const queryString = typeof query === 'object'
    ? reduce(query, (x, val, key) => (
      x + key + '=' + val + '&'
    ), '')
    : query
  return (dispatch) => {
    dispatch({
      type: 'FETCH_NCS_PENDING'
    })
    return axios({
      method: 'get',
      headers: {'Authorization': `Bearer ${accessToken}`},
      url: `${baseAPI}/orders/${id}/ncs${queryString}`
    }).then((x) => {
      dispatch({
        type: 'FETCH_NCS_FULFILLED',
        payload: {
          data: x.data,
          pagination: {
            currentPage: x.headers['x-pagination-current-page'],
            pageCount: x.headers['x-pagination-page-count'],
            perPage: x.headers['x-pagination-per-page'],
            totalCount: x.headers['x-pagination-total-count']
          }
        }
      })
    }).catch((e) => {
      console.error(e);
      dispatch(createError(e.response.data))
      dispatch({
        type: 'FETCH_NCS_REJECTED',
        payload: e
      })
    })
  }
}

export const fetchGlobalNcs = (query = '', accessToken) => {
  const queryString = typeof query === 'object'
    ? reduce(query, (x, val, key) => (
      x + key + '=' + val + '&'
    ), '')
    : query
  return (dispatch) => {
    dispatch({
      type: 'FETCH_GLOBAL_NCS_PENDING'
    })
    return axios({
      method: 'get',
      headers: {'Authorization': `Bearer ${accessToken}`},
      url: `${baseAPI}/ncs?${queryString}`
    }).then((x) => {
      dispatch({
        type: 'FETCH_GLOBAL_NCS_FULFILLED',
        payload: {
          data: x.data,
          pagination: {
            currentPage: x.headers['x-pagination-current-page'],
            pageCount: x.headers['x-pagination-page-count'],
            perPage: x.headers['x-pagination-per-page'],
            totalCount: x.headers['x-pagination-total-count']
          }
        }
      })
    }).catch((e) => {
      console.error(e)
      dispatch(createError(e.response.data))
      dispatch({
        type: 'FETCH_GLOBAL_NCS_REJECTED',
        payload: e
      })
    })
  }
}

export const fetchNc = (orderId, id, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_NC_PENDING'
    })
    return axios({
      method: 'get',
      headers: {'Authorization': `Bearer ${accessToken}`},
      url: `${baseAPI}/orders/${orderId}/ncs/${id}`
    }).then((x) => {
      dispatch({
        type: 'FETCH_NC_FULFILLED',
        payload: x.data
      })
    }).catch((e) => {
      console.error(e)
      dispatch({
        type: 'FETCH_NC_REJECTED',
        payload: e
      })
    })
  }
}

export const createNc = (data, accessToken, target) => {
  return (dispatch) => {
    dispatch({
      type: 'CREATE_NC_PENDING'
    })
    return axios({
      data,
      method: 'post',
      headers: {'Authorization': `Bearer ${accessToken}`},
      url: `${baseAPI}/orders/${data.order_id}/ncs`
    }).then((x) => {
      dispatch({
        type: 'CREATE_NC_FULFILLED',
        payload: x.data
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Nc created with the id ${x.data.id}`
      }))
      if (target) {
        dispatch(push(target))
      } else {
        dispatch(push(`/orders/${x.data.order.id}/ncs/${x.data.id}`))
      }
    }).catch((e) => {
      console.error(e);
      dispatch(createError(e.response.data))
      dispatch({
        type: 'CREATE_NC_REJECTED',
        payload: e
      })
    })
  }
}

export const updateNc = (data, accessToken, target) => {
  return (dispatch) => {
    dispatch({
      type: 'UPDATE_NC_PENDING'
    })
    return axios({
      data,
      method: 'put',
      headers: {'Authorization': `Bearer ${accessToken}`},
      url: `${baseAPI}/orders/${data.order_id}/ncs/${data.id}`
    }).then((x) => {
      dispatch({
        type: 'UPDATE_NC_FULFILLED',
        payload: x.data
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Report with the id ${x.data.id} updated`
      }))
      if (target) {
        dispatch(push(target))
      }
    }).catch((e) => {
      console.error(e);
      dispatch(createError(e.response.data))
      dispatch({
        type: 'UPDATE_NC_REJECTED',
        payload: e
      })
    })
  }
}
