import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import Inside from './Inside'
import OptionList from './Options/OptionList'
import OptionUpdate from './Options/OptionUpdate'

function mapStateToProps (state) {
  return {

  }
}

export class Options extends React.Component {
  render () {
    return (
      <Inside>
        <Switch>
          <Route path='/options/:id' component={OptionUpdate} />
          <Route exact path='/options' component={OptionList} />
        </Switch>
      </Inside>
    )
  }
}

export default connect(
  mapStateToProps
// Implement map dispatch to props
)(Options)
