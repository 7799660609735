import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import upperFirst from 'lodash/upperFirst'

import {
  TextareaField,
  TextField,
  OptionsField
} from '../common/FormFields'
import DateField from '../common/DateField'
import FileField from '../common/FileField'

function CertificateForm (props) {
  const { handleSubmit, submitting, questionnaire, location, changeForm, fileAPI, mode } = props
  const statuses = ['Draft', 'Published', 'Approved', 'Sent to Customer', 'Expired', 'Canceled']
  const standards = questionnaire.scopes.map(x => x.standard.name)
  const locations = questionnaire.locations.map((x, i) => ({
    value: i,
    label: x.location.name
  }))

  useEffect(() => {
    if (typeof location !== 'undefined') {
      const formName = `certificate${upperFirst(mode)}`
      changeForm(formName, 'detail.scope_wording', questionnaire.locations[parseInt(location, 10)].detail.scope_of_business)
    }
  }, [location, changeForm, mode, questionnaire.locations])

  return (
    <div>
      <form className='form-horizontal certificate-form' onSubmit={handleSubmit}>
        <Field name='detail.standard' type='select' component={OptionsField} label='Standard' options={standards} />
        <Field name='detail.location' type='select' component={OptionsField} label='Location' options={locations} />
        <Field name='status' type='select' component={OptionsField} label='Status' options={statuses} />
        <Field name='detail.logo' type='select' component={FileField} label='Logo' api={fileAPI} />
        <Field name='detail.scope_wording' component={TextareaField} label='Scope Wording' />
        <Field name='detail.airwaybill' component={TextField} label='Airway Bill' />
        <Field name='end_date' component={DateField} label='End Date' />
        <Field name='release_date' component={DateField} label='Release Date' />
        <Field name='remarks' component={TextareaField} label='Remarks' />
        <Field name='detail.support_file' type='select' component={FileField} label='Support File' api={fileAPI} />
        <Field name='detail.signed_file' type='select' component={FileField} label='Signed Issue File' api={fileAPI} />
        <div className='form-group'>
          <div className='col-sm-offset-3 col-sm-9'>
            <button disabled={submitting} type='submit' className='btn btn-default btn-block'>Save</button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default CertificateForm

const BaseCertificateCreateForm = props => <CertificateForm {...props} mode='create' />
const selectorCreate = formValueSelector('certificateCreate')

let CertificateCreateFormX = reduxForm({
  form: 'certificateCreate',
  enableReinitialize: true
})(BaseCertificateCreateForm)

export const CertificateCreateForm = connect(state => { // eslint-disable-line
  return {
    location: selectorCreate(state, 'detail.location')
  }
})(CertificateCreateFormX)

const BaseCertificateUpdateForm = props => <CertificateForm {...props} mode='update' />
const selectorUpdate = formValueSelector('certificateUpdate')

let CertificateUpdateFormX = reduxForm({
  form: 'certificateUpdate',
  enableReinitialize: true
})(BaseCertificateUpdateForm)

export const CertificateUpdateForm = connect(state => { // eslint-disable-line
  return {
    location: selectorUpdate(state, 'detail.location')
  }
})(CertificateUpdateFormX)
