import React from 'react'
import DownloadLink from '../../components/common/DownloadLink'

export default function MandaysCalculationWidget ({ accessToken, questionnaire: q, props }) {
  return (
    <div className='widget'>
      <div className='widget-header'>
        <h3><i className='fa fa-calculator' /> Audit Mandays</h3>
      </div>
      <div className='widget-content'>
        <table className='table table-hover table-striped'>
          <tbody>
            <tr>
              <th> A Team </th>
              <td> <DownloadLink accessToken={accessToken} file={q.detail.a_team} /> </td>
            </tr>

            <tr>
              <th> Mandays by Formula</th>
              <td> {q.detail.mandays} mandays </td>
            </tr>

            <tr>
              <th> Mandays by A Team </th>
              <td> {q.detail.mandays_by_a_team} mandays </td>
            </tr>

            <tr>
              <th> Mandays Roundup </th>
              <td> {q.detail.mandays_roundup} mandays </td>
            </tr>

            <tr>
              <th> Mandays Adjustment </th>
              <td> {q.detail.mandays_adjustment} % </td>
            </tr>

            <tr>
              <th> Mandays Justification </th>
              <td> {q.detail.mandays_justification} </td>
            </tr>

            <tr>
              <th> Reduction Factor </th>
              <td>
                {q.detail.reduction_factor
                  ? q.detail.reduction_factor.map((x, i) => (
                    <p key={i}>{`${x.name}`} - <strong> Value: {`${x.value} %`}</strong></p>
                  ))
                  : <span>n/a</span>
                }
              </td>
            </tr>

            <tr>
              <th> Enhancement Factor </th>
              <td> 
                
                {q.detail.enhancement_factor
                  ? q.detail.enhancement_factor.map((x, i) => (
                    
                    <p key={i}>{`${x.name}`} - <strong>Value: {`${x.value} %`}</strong></p>        

                  ))
                  : <span>n/a</span>
                }
                
              </td>
            </tr>

            <tr>
              <th> Audit Cycle </th>
              <td>
              <ul className='list-group mb-0'>
                {q.detail.audit_cycle
                  ? q.detail.audit_cycle.map((x, i) => (
                    
                      <li className='list-group-item' key={i}>
                          {`${x.phase}`}  <span className='badge'>{`${x.day} days`} </span>
                      </li>
                      
                  ))
                  : <span>n/a</span>
                }
                </ul>
              </td>
            </tr>

            <tr>
              <th>Surveillance Interval</th>
              <td>{q.detail.surveillance_interval} months</td>
            </tr>

            <tr>
              <th>Recertification</th>
              <td>{q.detail.include_recertification ? 'Include Recertification' : 'n/a'}</td>
            </tr>

          </tbody>
        </table>

      </div>
    </div>
  )
}
