import React from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'

export class DisabledUserField extends React.Component {
  render () {
    return <div>{this.props.input.value ? this.props.input.value.data.display_name : ''}</div>
  }
}

export class PositionField extends React.Component {
  render () {
    return (
      <select {...this.props.input} className='form-control'>
        { this.props.options.map((x, y) => {
          let option
          if (typeof x === 'object') {
            option = <option value={x.value} key={y} >{x.label}</option>
          } else {
            option = <option value={x} key={y} >{x}</option>
          }
          return option
        })}
      </select>
    )
  }
}

export class DateField extends React.Component {
  constructor (props) {
    super(props)
    let value = moment()
    if (this.props.input.value && typeof this.props.input.value === 'number') {
      value = moment.unix(this.props.input.value)
    }
    if (this.props.input.value && typeof this.props.input.value === 'string') {
      value = moment(this.props.input.value)
    }
    this.state = {
      startDate: value
    }
  }

  componentWillUpdate (nextProps, nextState) {
    if (nextProps.input.value !== this.state.startDate.format('YYYY-MM-DD')) {
      this.setState({
        startDate: moment(nextProps.input.value)
      })
    }
  }

  onChange (value) {
    this.setState({
      startDate: value
    })
    this.props.input.onChange(value.format('YYYY-MM-DD'))
  }

  render () {
    const { meta: {touched, error} } = this.props
    return (
      <div>
        <DatePicker
          dateFormat='YYYY-MM-DD'
          style={{fontSize: '1rem'}}
          selected={this.state.startDate}
          onChange={this.onChange.bind(this)}
          className='form-control'
        />
        { touched && error &&
          <span className='error'>{error}</span>
        }
      </div>
    )
  }
}

export class OptionsField extends React.Component {
  componentWillMount () {
    this.setState({
      value: (this.props.input.value || ''),
      select: true,
      multiCheckbox: []
    })
  }

  onChangeToInput (ev) {
    this.setState({
      value: ev.target.value,
      select: (ev.target.value !== 'other' ? this.state.select : !this.state.select)
    })
  }

  onCheckBoxChange (ev) {
    const checked = ev.target.checked
    const option = ev.target.value
    let current = this.state.multiCheckbox
    if (checked && current.indexOf(option) === -1) {
      current = [ ...current, option ]
    } else {
      current.splice(current.indexOf(option), 1)
    }
    this.props.input.onChange(current)
    this.setState({
      multiCheckbox: current
    })
  }

  showSelect () {
    this.setState({
      value: null,
      select: true
    })
  }

  getInput () {
    let { options, input, label, meta: {touched, error, warning} } = this.props

    switch (this.props.type) {
      case 'select' :
        return (
          <select {...this.props.input} className='form-control'>
            { this.props.options.map((x, y) => {
              let option
              if (typeof x === 'object') {
                option = <option value={x.value} key={y} >{x.label}</option>
              } else {
                option = <option value={x} key={y} >{x}</option>
              }
              return option
            })}
          </select>
        )
      case 'selectOrInput' :
        if (!this.state.value || options.indexOf(this.state.value) > -1) {
          return (
            <select {...input} className='form-control' value={this.state.value} onChange={this.onChangeToInput.bind(this)} >
              { options.map((x, y) => {
                let option
                if (typeof x === 'object') {
                  option = <option value={x.value} key={x.value} >{x.label}</option>
                } else {
                  option = <option value={x} key={x} >{x}</option>
                }
                return option
              })}
              <option value={false}>Other</option>
            </select>
          )
        } else {
          return (
            <div>
              <div className='input-group'>
                <input {...input} type='text' className='form-control' id={label} placeholder={label} />
                <span className='input-group-btn'>
                  <button onClick={this.showSelect.bind(this)}className='btn btn-default' type='button'><i className='fa fa-bars' /></button>
                </span>

              </div>
              {touched && ((error && <div className='alert alert-danger'>{error}</div>) || (warning && <div className='alert alert-warning'>{warning}</div>))}
            </div>
          )
        }
      case 'checkbox' :
        return (
          <div>
            <div className='checkbox'>
              <label>
                <input {...input} type='checkbox' checked={input.value} /> { this.props.label }
              </label>
            </div>
          </div>
        )
      case 'multiCheckbox' :
        return (
          <div>
            { options.map((x, y) => {
              let result
              if (typeof x === 'string') {
                result = <div className='checkbox' key={y}>
                  <label><input
                    type='checkbox'
                    name={y}
                    value={x}
                    onChange={this.onCheckBoxChange.bind(this)}
                    defaultChecked={input.value.indexOf(x) > -1}
                  /> {x}</label>
                </div>
              } else if (typeof x === 'object') {
                // const values = input.value ? input.value.map((x) => x.value) : []
                // console.log(input.value);
                result = <div className='checkbox' key={y}>
                  <label><input
                    type='checkbox'
                    name={x.value}
                    value={x.value}
                    onChange={this.onCheckBoxChange.bind(this)}
                    defaultChecked={input.value.indexOf(x.value) > -1}
                  /> {x.label}</label>
                </div>
              }
              return result
            })}
          </div>
        )
      default :
        break
    }
  }

  render () {
    return (
      <div className={`${this.props.input.name.split('.').join('_')}`}>
        { this.getInput() }
        { this.props.help && <p className='help-text'>{this.props.help}</p> }
        {this.props.meta.touched && ((this.props.meta.error && <div className='alert alert-danger'>{this.props.meta.error}</div>))}
      </div>
    )
  }
}
