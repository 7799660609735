export const toggleSidebar = () => {
  return {
    type: 'TOGGLE_SIDEBAR'
  }
}

export const toggleSubMenu = (menu) => {
  return {
    type: 'TOGGLE_SUBMENU',
    payload: menu
  }
}
