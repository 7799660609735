import React from 'react'

export default class DetailWidget extends React.Component {
  render () {
    const { accessToken } = this.props
    const o = this.props.order
    const r = o.detail

    if (!r) {
      return ''
    }

    return (
      <div className='widget'>
        <div className='widget-header'>
          <h3><i className='fa fa-tasks' /> Order Prerequisite</h3>
        </div>
        <div className='widget-content'>
          <div className='row'>
            <div className='col-md-6 key-val'>
              <p>Business Process Scheme</p>
            </div>
            <div className='col-md-6'>
              { r.business_process_map && r.business_process_map.map((x, i) => (
                <p key={i} className=''><a href={`${process.env.REACT_APP_BASE_API}/download/${x.id}?token=${accessToken}`} className='btn btn-link btn-xs'>
                  <i className='fa fa-download' /> {x.detail.name}
                </a></p>
              ))}
            </div>
          </div>
          <hr />

          <div className='row'>
            <div className='col-md-6 key-val'>
              <p>Legal Document</p>
            </div>
            <div className='col-md-6'>
              { r.legal_documents && r.legal_documents.map((x, i) => (
                <p key={i} className=''><a href={`${process.env.REACT_APP_BASE_API}/download/${x.id}?token=${accessToken}`} className='btn btn-link btn-xs'>
                  <i className='fa fa-download' /> {x.detail.name}
                </a></p>
              ))}
            </div>
          </div>
          <hr />

          <div className='row'>
            <div className='col-md-6 key-val'>
              <p>Organization Structure</p>
            </div>
            <div className='col-md-6'>
              { r.organisation_chart && r.organisation_chart.map((x, i) => (
                <p key={i} className=''><a href={`${process.env.REACT_APP_BASE_API}/download/${x.id}?token=${accessToken}`} className='btn btn-link btn-xs'>
                  <i className='fa fa-download' /> {x.detail.name}
                </a></p>
              ))}
            </div>
          </div>
          <hr />
          {
            // <div className='row'>
            //   <div className='col-md-6 key-val'>
            //     <p>Support Document</p>
            //   </div>
            //   <div className='col-md-6'>
            //     { r.legal_documents && r.legal_documents.map((x, i) => (
            //       <p key={i} className=''><a href={`${process.env.REACT_APP_BASE_API}/download/${x.id}?token=${accessToken}`} className='btn btn-link btn-xs'>
            //         <i className='fa fa-download' /> {x.detail.name}
            //       </a></p>
            //     ))}
            //   </div>
            // </div>
          }
        </div>
      </div>
    )
  }
}
