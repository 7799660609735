import React from 'react'
import { connect } from 'react-redux'
import { Link, Switch, Route, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import ItemLog from '../../components/common/ItemLog'
import Pagination from '../../components/common/Pagination'
// import ReviewForm from '../../components/common/ReviewForm'

import ReportTable from '../../components/Orders/ReportTable'
import ReportDetail from '../../components/Orders/ReportDetail'
import { ReportCreateForm, ReportUpdateForm } from '../../components/Orders/ReportForm'
import { VetoReviewCreateForm, VetoReviewUpdateForm } from '../../components/Orders/VetoReviewForm'

import { fetchOrder } from '../../actions/orders'
import { fetchReports, fetchReport, createReport, updateReport } from '../../actions/reports'
import { isVeto, isLeadAuditor, isAuditor } from '../../selectors/order'

export class OrderReportView extends React.Component {
  componentWillMount () {
    this.props.fetchOrder(this.props.match.params.orderId, this.props.accessToken)

    if (this.props.match.params.id && this.props.match.params.id !== 'new') {
      this.props.fetchReport(
        this.props.match.params.orderId,
        this.props.match.params.id,
        this.props.accessToken
      )
    }
    if (!this.props.match.params.id) {
      this.props.fetchReports(this.props.match.params.orderId, this.props.accessToken)
    }
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.report && nextProps.report) {
    }
  }

  handleSubmitNew (e) {
    const request = { ...e,
      order_id: this.props.match.params.orderId,
      status: e.advance ? 'Published' : 'Draft',
      log: {
        remarks: e.remarks
      }
    }
    return this.props.createReport(
      request,
      this.props.accessToken,
      request.status === 'Published' ? `/orders/${request.order_id}` : null
    )
  }

  handleSubmitUpdate (e) {
    const request = { ...e,
      order_id: this.props.match.params.orderId,
      status: e.advance ? 'Published' : 'Draft',
      log: {
        remarks: e.remarks
      }
    }
    return this.props.updateReport(
      request,
      this.props.accessToken,
      request.status === 'Published' ? `/orders/${request.order_id}` : null
    )
  }

  getStatus (order) {
    // let message = 'No status'
    // switch (order.status) {
    //   case 'Veto Assigned':
    //     message = 'Current Order status is : Veto Assigned.'
    //     break
    //   default:
    //     message = ''
    //     break
    // }
    return <div className='alert alert-info'>Current Order status is <strong>{order.status}</strong></div>
  }

  render () {
    const { order, report, reports, user } = this.props
    let baseReport = null
    if (report) {
      const { log, ...reportNoLog } = report
      baseReport = reportNoLog
    }

    return (
      <div>
        <div className='content'>
          <Switch>
            <Route path='/orders/:orderId/reports/new'>
              <div>
                <Helmet>
                  <title>{order ? `Order ${order.id} - Create report` : `Loading`}</title>
                </Helmet>
                <div className='main-header'>
                  <h2>New Report</h2>
                  { order && <em>For Order <Link to={`/orders/${order.id}`}>{order.id}</Link></em> }
                </div>
                <div className='row'>
                  <div className='col-md-8'>
                    { order && user
                      ? <div>
                        { this.getStatus(order) }

                        { [
                          'Plan Approved by Lead Auditor',
                          'Stage 1 - On Audit',
                          'Stage 1 - Audited'
                        ].indexOf(order.status) > -1 &&
                        (
                          isLeadAuditor(user, order) ||
                          isAuditor(user, order) ||
                          user.data.role === 'Super Admin'
                        ) &&
                          <ReportCreateForm
                            order={order}
                            initialValues={{ type: 'Stage 1' }}
                            onSubmit={this.handleSubmitNew.bind(this)}
                            fileAPI={{
                              url: process.env.REACT_APP_BASE_API,
                              accessToken: this.props.accessToken
                            }}
                          />
                        }

                        { [
                          'Stage 2 - On Audit',
                          'Stage 2 - Audited'
                        ].indexOf(order.status) > -1 &&
                        (
                          isLeadAuditor(user, order) ||
                          isAuditor(user, order) ||
                          user.data.role === 'Super Admin'
                        ) &&
                          <ReportCreateForm
                            order={order}
                            initialValues={{ type: 'Stage 2' }}
                            onSubmit={this.handleSubmitNew.bind(this)}
                            fileAPI={{
                              url: process.env.REACT_APP_BASE_API,
                              accessToken: this.props.accessToken
                            }}
                          />
                        }

                        { [
                          'Follow up - On Audit',
                          'Follow up - Audited'
                        ].indexOf(order.status) > -1 &&
                        (
                          isLeadAuditor(user, order) ||
                          isAuditor(user, order) ||
                          user.data.role === 'Super Admin'
                        ) &&
                          <ReportCreateForm
                            order={order}
                            initialValues={{
                              type: 'Stage 3'
                            }}
                            onSubmit={this.handleSubmitNew.bind(this)}
                            fileAPI={{
                              url: process.env.REACT_APP_BASE_API,
                              accessToken: this.props.accessToken
                            }}
                          />
                        }

                        { ['Veto Assigned'].indexOf(order.status) > -1 && // status
                          (isVeto(user, order) || user.data.role === 'Super Admin') && // user
                          <div>
                            <VetoReviewCreateForm
                              order={order}
                              onSubmit={this.handleSubmitNew.bind(this)}
                              initialValues={{ type: 'Veto' }}
                              fileAPI={{
                                url: process.env.REACT_APP_BASE_API,
                                accessToken: this.props.accessToken
                              }}
                            />
                          </div>
                        }
                      </div>
                      : <div>loading</div>
                    }
                  </div>
                </div>
              </div>
            </Route>
            <Route path='/orders/:orderId/reports/:id'>
              <div>
                <Helmet>
                  <title>{order ? `Order ${order.id} - Update report` : `Loading`}</title>
                </Helmet>
                <div className='main-header'>
                  { report && <h2>Update Report {report.id}</h2> }
                  { order && <em>For Order <Link to={`/orders/${order.id}`}>{order.id}</Link></em> }
                </div>
                <div className='row'>
                  <div className='col-md-8'>
                    { order && report
                      ? <div>
                        { report.status === 'Draft' && report.type === 'Stage 1' && (isAuditor(user, order) || isLeadAuditor(user, order) || user.data.role === 'Super Admin') &&
                          <ReportUpdateForm
                            order={order}
                            onSubmit={this.handleSubmitUpdate.bind(this)}
                            initialValues={baseReport || {}}
                            fileAPI={{
                              url: process.env.REACT_APP_BASE_API,
                              accessToken: this.props.accessToken
                            }}
                          />
                        }
                        { report.status === 'Draft' && report.type === 'Stage 2' && (isAuditor(user, order) || isLeadAuditor(user, order) || user.data.role === 'Super Admin') &&
                          <ReportUpdateForm
                            order={order}
                            onSubmit={this.handleSubmitUpdate.bind(this)}
                            initialValues={baseReport || {}}
                            fileAPI={{
                              url: process.env.REACT_APP_BASE_API,
                              accessToken: this.props.accessToken
                            }}
                          />
                        }
                        { report.status === 'Draft' && report.type === 'Veto' && (isVeto(user, order) || user.data.role === 'Super Admin') &&
                          <VetoReviewUpdateForm
                            order={order}
                            onSubmit={this.handleSubmitUpdate.bind(this)}
                            initialValues={baseReport || {}}
                            fileAPI={{
                              url: process.env.REACT_APP_BASE_API,
                              accessToken: this.props.accessToken
                            }}
                          />
                        }
                        { report.status === 'Published' && report.decision === 'Veto Approval Pending' && report.type === 'Veto' && (isVeto(user, order) || user.data.role === 'Super Admin') &&
                          <VetoReviewUpdateForm
                            order={order}
                            onSubmit={this.handleSubmitUpdate.bind(this)}
                            initialValues={baseReport || {}}
                            fileAPI={{
                              url: process.env.REACT_APP_BASE_API,
                              accessToken: this.props.accessToken
                            }}
                          />
                        }
                        { report && report.status !== 'Draft' &&
                          <ReportDetail
                            order={order}
                            report={report}
                            accessToken={this.props.accessToken}
                          />
                        }
                      </div>
                      : <div>loading</div>
                    }
                  </div>
                  <div className='col-md-4'>
                    { report
                      ? <ItemLog log={report.log} entity='Report' />
                      : <div>loading</div>
                    }
                  </div>
                </div>
              </div>
            </Route>
            <Route path='/orders/:orderId/schedules'>
              <div>
                <div className='main-header'>
                  <h2>Reports</h2>
                  { order && <em>For Order <Link to={`/orders/${order.id}`}>{order.id}</Link></em> }
                </div>
                { reports && <ReportTable reports={reports} /> }
                <Pagination />
              </div>
            </Route>
          </Switch>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    order: state.orders.order.data,
    reports: state.reports.reportList.data,
    report: state.reports.report.data,
    accessToken: state.auth.user.access.access_token,
    user: state.auth.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOrder: (id, accessToken) => dispatch(fetchOrder(id, accessToken)),
    fetchReports: (id, accessToken) => dispatch(fetchReports(id, accessToken)),
    fetchReport: (orderId, id, accessToken) => dispatch(fetchReport(orderId, id, accessToken)),
    createReport: (data, accessToken, target) => dispatch(createReport(data, accessToken, target)),
    updateReport: (data, accessToken, target) => dispatch(updateReport(data, accessToken, target))
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
// Implement map dispatch to props
)(OrderReportView))
