import axios from 'axios'
import { push } from 'react-router-redux'
import reduce from 'lodash/reduce'
import { createFlash, createError } from './flasher'

const baseAPI = process.env.REACT_APP_BASE_API

export const fetchReports = (id, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_REPORTS_PENDING'
    })
    return axios({
      method: 'get',
      headers: {'Authorization': `Bearer ${accessToken}`},
      url: `${baseAPI}/orders/${id}/reports`
    }).then((x) => {
      dispatch({
        type: 'FETCH_REPORTS_FULFILLED',
        payload: {
          data: x.data,
          pagination: {
            currentPage: x.headers['x-pagination-current-page'],
            pageCount: x.headers['x-pagination-page-count'],
            perPage: x.headers['x-pagination-per-page'],
            totalCount: x.headers['x-pagination-total-count']
          }
        }
      })
    }).catch((e) => {
      console.error(e)
      dispatch(createError(e.response.data))
      dispatch({
        type: 'FETCH_REPORTS_REJECTED',
        payload: e
      })
    })
  }
}

export const fetchGlobalReports = (query = '', accessToken) => {
  const queryString = typeof query === 'object'
    ? reduce(query, (x, val, key) => (
      x + key + '=' + val + '&'
    ), '')
    : query
  return (dispatch) => {
    dispatch({
      type: 'FETCH_GLOBAL_REPORTS_PENDING'
    })
    return axios({
      method: 'get',
      headers: {'Authorization': `Bearer ${accessToken}`},
      url: `${baseAPI}/reports?${queryString}`
    }).then((x) => {
      dispatch({
        type: 'FETCH_GLOBAL_REPORTS_FULFILLED',
        payload: {
          data: x.data,
          pagination: {
            currentPage: x.headers['x-pagination-current-page'],
            pageCount: x.headers['x-pagination-page-count'],
            perPage: x.headers['x-pagination-per-page'],
            totalCount: x.headers['x-pagination-total-count']
          }
        }
      })
    }).catch((e) => {
      console.error(e)
      dispatch(createError(e.response.data))
      dispatch({
        type: 'FETCH_GLOBAL_REPORTS_REJECTED',
        payload: e
      })
    })
  }
}

export const fetchReport = (orderId, id, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_REPORT_PENDING'
    })
    return axios({
      method: 'get',
      headers: {'Authorization': `Bearer ${accessToken}`},
      url: `${baseAPI}/orders/${orderId}/reports/${id}`
    }).then((x) => {
      dispatch({
        type: 'FETCH_REPORT_FULFILLED',
        payload: x.data
      })
    }).catch((e) => {
      console.error(e)
      dispatch(createError(e.response.data))
      dispatch({
        type: 'FETCH_REPORT_REJECTED',
        payload: e
      })
    })
  }
}

export const createReport = (data, accessToken, target) => {
  return (dispatch) => {
    dispatch({
      type: 'CREATE_REPORT_PENDING'
    })
    return axios({
      data,
      method: 'post',
      headers: {'Authorization': `Bearer ${accessToken}`},
      url: `${baseAPI}/orders/${data.order_id}/reports`
    }).then((x) => {
      dispatch({
        type: 'CREATE_REPORT_FULFILLED',
        payload: x.data
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Report created with the id ${x.data.id}`
      }))
      if (target) {
        dispatch(push(target))
      } else {
        dispatch(push(`/orders/${x.data.order.id}/reports/${x.data.id}`))
      }
    }).catch((e) => {
      console.error(e)
      dispatch(createError(e.response.data))
      dispatch({
        type: 'CREATE_REPORT_REJECTED',
        payload: e
      })
    })
  }
}

export const updateReport = (data, accessToken, target) => {
  return (dispatch) => {
    dispatch({
      type: 'UPDATE_REPORT_PENDING'
    })
    return axios({
      data,
      method: 'put',
      headers: {'Authorization': `Bearer ${accessToken}`},
      url: `${baseAPI}/orders/${data.order_id}/reports/${data.id}`
    }).then((x) => {
      dispatch({
        type: 'UPDATE_REPORT_FULFILLED',
        payload: x.data
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Report with the id ${x.data.id} updated`
      }))
      if (target) {
        dispatch(push(target))
      } else {
        dispatch(push(`/orders/${x.data.order.id}`))
      }
    }).catch((e) => {
      console.error(e)
      dispatch(createError(e.response.data))
      dispatch({
        type: 'UPDATE_REPORT_REJECTED',
        payload: e
      })
    })
  }
}
