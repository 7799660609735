import React from 'react'

export default class SpecificationTable extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      specifications: this.props.specifications || [],
      scopes: this.props.scopes || [],
      openNew: false,
      onEdit: null,
      selectedStandard: '',
      selectedScope: '',
      requesting: false
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.specifications) {
      this.setState({ specifications: nextProps.specifications })
    }
    if (nextProps.scopes) {
      this.setState({ scopes: nextProps.scopes })
    }
  }

  toggleNew () {
    this.setState({
      requesting: true
    })
    this.props.onStandardChange({ target: { value: this.props.standards[0].id } }).then(() => {
      this.setState({
        requesting: false
      })
    })
    this.setState({
      openNew: !this.state.openNew,
      specifications: this.state.specifications.map(x => {
        delete x.editing
        return x
      }),
      onEdit: null
    })
  }

  onScopeChange (ev) {
    this.setState({
      selectedScope: ev.target.value
    })
  }

  onStandardChange (ev) {
    this.setState({
      requesting: true
    })
    this.props.onStandardChange(ev).then(() => {
      this.setState({
        requesting: false
      })
    })
    this.setState({
      selectedStandard: ev.target.value
    })
  }

  handleCreate () {
    this.setState({
      requesting: true
    })

    const capacity = []
    if (this.refs.newLeadAuditor.checked) {
      capacity.push('Lead Auditor')
    }
    if (this.refs.newSeniorAuditor.checked) {
      capacity.push('Senior Auditor')
    }
    if (this.refs.newAuditor.checked) {
      capacity.push('Auditor')
    }
    if (this.refs.newExpert.checked) {
      capacity.push('Expert')
    }
    if (this.refs.newApplicactionReviewer.checked) {
      capacity.push('Application Reviewer')
    }
    if (this.refs.newVetoPerson.checked) {
      capacity.push('Veto Person')
    }
    const specification = {
      user_id: this.props.user.id,
      standard_id: parseInt(this.refs.newStandard.value, 10),
      scope_id: parseInt(this.refs.newScope.value, 10),
      capacity
    }

    this.props.onCreate(specification).then(() => {
      this.setState({
        requesting: false,
        openNew: false
      })
    })
  }

  toggleEdit (n) {
    this.setState({
      requesting: true
    })
    const current = this.state.specifications.find((x) => x.id === n)
    this.props.onStandardChange({ target: { value: current.standard.id } }).then(() => {
      this.setState({
        requesting: false,
        openNew: false,
        selectedStandard: current.standard.id,
        selectedScope: current.scope.id,
        specifications: this.state.specifications.map(x => {
          if (x.id === n) {
            x.editing = true
          } else {
            delete x.editing
          }
          return x
        }),
        onEdit: n
      })
    })
  }

  handleUpdate () {
    this.setState({
      requesting: true
    })
    const capacity = []
    if (this.refs.updateLeadAuditor.checked) {
      capacity.push('Lead Auditor')
    }
    if (this.refs.updateSeniorAuditor.checked) {
      capacity.push('Senior Auditor')
    }
    if (this.refs.updateAuditor.checked) {
      capacity.push('Auditor')
    }
    if (this.refs.updateExpert.checked) {
      capacity.push('Expert')
    }
    if (this.refs.updateApplicactionReviewer.checked) {
      capacity.push('Application Reviewer')
    }
    if (this.refs.updateVetoPerson.checked) {
      capacity.push('Veto Person')
    }
    const specification = {
      id: this.state.onEdit,
      user_id: this.props.user.id,
      standard_id: parseInt(this.refs.updateStandard.value, 10),
      scope_id: parseInt(this.refs.updateScope.value, 10),
      capacity
    }

    this.props.onUpdate(specification).then(() => {
      this.setState((state, props) => ({
        openNew: false,
        onEdit: null,
        requesting: false
      }))
    })
  }

  handleDelete () {
    this.setState({
      requesting: true
    })
    const scope = this.state.specifications.find(x => {
      return x.editing
    })
    this.props.onDelete(scope).then(() => {
      this.setState((state, props) => ({
        openNew: false,
        onEdit: null,
        requesting: false
      }))
    })
  }

  render () {
    const parentOrder = this.state.scopes
      .map(x => {
        return ({
          ...x,
          order: x.detail.number.indexOf('.') > 0
            ? x.detail.number.split('.')[0].length === 1
              ? `0${x.detail.number.split('.')[0]}.${x.detail.number.split('.')[1]}`
              : x.detail.number
            : x.detail.number.length === 1
              ? `0${x.detail.number}`
              : x.detail.number
        })
      })
      // .map(x => {
      //   return ({
      //     ...x,
      //     order: x.order.length === 1
      //       ? `0${x.order}`
      //       : x.order
      //   })
      // })
      .sort((a, b) => {
        return a.order > b.order
      })
    console.log(111, parentOrder)
    return (
      <table className='table table-condensed'>
        <thead>
          <tr>
            <th>Standard & Scope</th>
            <th width='10%' className='text-center'>Lead Auditor</th>
            <th width='10%' className='text-center'>Senior Auditor</th>
            <th width='10%' className='text-center'>Auditor</th>
            <th width='10%' className='text-center'>Expert</th>
            <th width='10%' className='text-center'>Application Reviewer</th>
            <th width='10%' className='text-center'>Veto Person</th>
            <th width={80} className='text-center'>Actions</th>
          </tr>
        </thead>
        <tbody>
          {
            // <tr className='bg-grey'>
            //   <th colSpan='5'>ISO 14001 : 2012</th>
            // </tr>
          }
          { this.state.specifications.sort((a, b) => a.standard.id - b.standard.id).map(x => {
            if (x.editing) {
              return (
                <tr className='spec-edit-container' key={x.id}>
                  <td className='standard-scope-container'>
                    <select className='form-control input-sm' ref='updateStandard' value={this.state.selectedStandard} onChange={this.onStandardChange.bind(this)}>
                      {this.props.standards.map(y => {
                        return <option key={y.id} value={y.id}>{y.name}</option>
                      })}
                    </select>
                    <select className='form-control input-sm' ref='updateScope' value={this.state.selectedScope} onChange={this.onScopeChange.bind(this)}>
                      {parentOrder.map(y => {
                        return <option key={y.id} value={y.id}>{y.order.indexOf('.') > 0 && `-`} {y.detail.number} {y.name}</option>
                      })}
                    </select>
                  </td>
                  <td className='text-center'>
                    <input type='checkbox' ref='updateLeadAuditor' defaultChecked={x.capacity.indexOf('Lead Auditor') >= 0} />
                  </td>
                  <td className='text-center'>
                    <input type='checkbox' ref='updateSeniorAuditor' defaultChecked={x.capacity.indexOf('Senior Auditor') >= 0} />
                  </td>
                  <td className='text-center'>
                    <input type='checkbox' ref='updateAuditor' defaultChecked={x.capacity.indexOf('Auditor') >= 0} />
                  </td>
                  <td className='text-center'>
                    <input type='checkbox' ref='updateExpert' defaultChecked={x.capacity.indexOf('Expert') >= 0} />
                  </td>
                  <td className='text-center'>
                    <input type='checkbox' ref='updateApplicactionReviewer' defaultChecked={x.capacity.indexOf('Application Reviewer') >= 0} />
                  </td>
                  <td className='text-center'>
                    <input type='checkbox' ref='updateVetoPerson' defaultChecked={x.capacity.indexOf('Veto Person') >= 0} />
                  </td>
                  <td className='text-right'>
                    <button disabled={this.state.requesting} onClick={this.handleDelete.bind(this)}className='btn btn-xs btn-default'><i className='fa fa-remove' /></button>{' '}
                    <button disabled={this.state.requesting} onClick={this.handleUpdate.bind(this)} className='btn btn-xs btn-primary'><i className='fa fa-check' /></button>
                  </td>
                </tr>
              )
            } else {
              return (
                <tr key={x.id}>
                  <th >{x.standard.name} - {x.scope.detail.number} - {x.scope.name}</th>
                  <td className='text-center'>{x.capacity.indexOf('Lead Auditor') >= 0 && <i className='fa fa-check' />}</td>
                  <td className='text-center'>{x.capacity.indexOf('Senior Auditor') >= 0 && <i className='fa fa-check' />}</td>
                  <td className='text-center'>{x.capacity.indexOf('Auditor') >= 0 && <i className='fa fa-check' />}</td>
                  <td className='text-center'>{x.capacity.indexOf('Expert') >= 0 && <i className='fa fa-check' />}</td>
                  <td className='text-center'>{x.capacity.indexOf('Application Reviewer') >= 0 && <i className='fa fa-check' />}</td>
                  <td className='text-center'>{x.capacity.indexOf('Veto Person') >= 0 && <i className='fa fa-check' />}</td>
                  <td className='text-right'>
                    <button disabled={this.state.requesting} onClick={this.toggleEdit.bind(this, x.id)} className='btn btn-xs btn-default'><i className='fa fa-gear' /></button>
                  </td>
                </tr>
              )
            }
          })}
          {
            this.state.openNew
              ? <tr className='spec-edit-container bg-grey'>
                <td className='standard-scope-container'>
                  <select className='form-control input-sm' ref='newStandard' onChange={this.props.onStandardChange}>
                    {this.props.standards.map(x => {
                      return <option key={x.id} value={x.id}>{x.name}</option>
                    })}
                  </select>
                  <select className='form-control input-sm' ref='newScope'>
                    {this.state.scopes.map(x => {
                      return <option key={x.id} value={x.id}>{x.name}</option>
                    })}
                  </select>
                </td>
                <td className='text-center'>
                  <input type='checkbox' ref='newLeadAuditor' />
                </td>
                <td className='text-center'>
                  <input type='checkbox' ref='newSeniorAuditor' />
                </td>
                <td className='text-center'>
                  <input type='checkbox' ref='newAuditor' />
                </td>
                <td className='text-center'>
                  <input type='checkbox' ref='newExpert' />
                </td>
                <td className='text-center'>
                  <input type='checkbox' ref='newApplicactionReviewer' />
                </td>
                <td className='text-center'>
                  <input type='checkbox' ref='newVetoPerson' />
                </td>
                <td className='text-right'>
                  <button disabled={this.state.requesting} onClick={this.handleCreate.bind(this)} className='btn btn-block btn-xs btn-primary'><i className='fa fa-check' /></button>
                </td>
              </tr>
              : !this.state.openNew && this.state.onEdit === null
                ? <tr className=' bg-grey'>
                  <td className='standard-scope-container' colSpan={8}>
                    <button disabled={this.state.requesting} onClick={this.toggleNew.bind(this)} className='btn btn-block btn-primary'>Add Specification</button>
                  </td>
                </tr>
                : <tr className=' bg-grey' />
          }
        </tbody>
      </table>
    )
  }
}
