import React from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { Link, Route, withRouter } from 'react-router-dom'

import { toggleSidebar, toggleSubMenu } from '../../actions/layout'

export function LeftSideBar (props) {
  const { role, toggleSubMenu, openSubMenu, push, openSidebar } = props

  function goTo (id, to, event) {
    event.preventDefault()
    toggleSubMenu(id)
    push(to)
  }

  const baseMenu = [
    {
      id: 'questionnaires',
      title: 'Questionnaires',
      icon: 'fa fa-file-o',
      url: '/questionnaires',
      children: [
        {
          id: 'newQuestionnaire',
          title: 'New Questionnaire',
          url: '/questionnaires/new'
        },
        {
          id: 'questionnaireList',
          title: 'Questionnaire List',
          url: '/questionnaires'
        },
        {
          id: 'quotationList',
          title: 'Quotation List',
          url: '/quotations'
        },
        {
          id: 'surveillanceList',
          title: 'Surveillance List',
          url: '/quotation-schedules'
        }
      ]
    },
    {
      id: 'orders',
      title: 'Orders',
      icon: 'fa fa-file',
      url: '/orders',
      children: [
        {
          id: 'orderList',
          title: 'Order List',
          url: '/orders'
        },
        {
          id: 'auditPlanList',
          title: 'Audit Plan List',
          url: '/audit-plans'
        },
        {
          id: 'auditReportList',
          title: 'Audit Report List',
          url: '/audit-reports'
        },
        {
          id: 'nonComplyList',
          title: 'Non Conformity List',
          url: '/ncs'
        },
        {
          id: 'certificateList',
          title: 'Certificate List',
          url: '/certificates'
        },
        {
          id: 'daList',
          title: 'Daily Allowance List',
          url: '/daily-allowances'
        }
      ]
    },
    {
      id: 'invoices',
      title: 'Invoices',
      icon: 'fa fa-bank',
      url: '/invoices',
      children: [
        {
          id: 'invoiceList',
          title: 'Invoice List',
          url: '/invoices'
        }
      ]
    },
    {
      id: 'scheduleMatrix',
      title: 'Schedule Matrix',
      url: '/schedule-matrix',
      icon: 'fa fa-table'
    },
    {
      id: 'customers',
      title: 'Customers',
      icon: 'fa fa-user-circle',
      url: '/customers'
    },
    {
      id: 'users',
      title: 'Users',
      icon: 'fa fa-group',
      url: '/users',
      children: [
        {
          id: 'userList',
          title: 'User List',
          url: '/users'
        },
        {
          id: 'auditorExperts',
          title: 'Auditor & Experts',
          url: '/users/auditor-experts'
        }
      ]
    },
    {
      id: 'standards',
      title: 'Standards',
      icon: 'fa fa-book',
      url: '/standards'
    },
    {
      id: 'settings',
      title: 'Settings',
      icon: 'fa fa-cogs',
      url: '/settings',
      children: [
        {
          id: 'events',
          title: 'Events',
          icon: 'fa fa-calendar',
          url: '/events'
        },
        {
          id: 'options',
          title: 'Options',
          icon: 'fa fa-cogs',
          url: '/options'
        }
      ]
    }
  ]
  const customerAccess = [
    'questionnaires',
    'questionnaireList',
    'quotationList',
    'surveillanceList',
    'orders',
    'orderList',
    'auditPlanList',
    'auditReportList',
    'nonComplyList',
    'certificateList',
    'invoices',
    'invoiceList'
  ]
  const salesAccess = [
    'questionnaires',
    'newQuestionnaire',
    'questionnaireList',
    'quotationList',
    'surveillanceList',
    'orders',
    'orderList',
    'auditPlanList',
    'certificateList',
    'customers',
    'scheduleMatrix'
  ]
  const salesManagerAccess = [
    'questionnaires',
    'newQuestionnaire',
    'questionnaireList',
    'quotationList',
    'surveillanceList',
    'orders',
    'orderList',
    'auditPlanList',
    'certificateList',
    'customers',
    'scheduleMatrix'
  ]
  const applicationReviewerManagerAccess = [
    'questionnaires',
    'questionnaireList',
    'orders',
    'orderList',
    'auditPlanList',
    'auditReportList',
    'nonComplyList',
    'certificateList',
    'daList',
    'scheduleMatrix'
  ]
  const auditorAccess = [
    'questionnaires',
    'questionnaireList',
    'orders',
    'orderList',
    'auditPlanList',
    'auditReportList',
    'nonComplyList',
    'certificateList',
    'daList',
    'scheduleMatrix'
  ]
  const bodAccess = [
    'questionnaires',
    'questionnaireList',
    'quotationList',
    'surveillanceList',
    'orders',
    'orderList',
    'scheduleMatrix'
  ]
  const schedulerAccess = [
    'orders',
    'orderList',
    'scheduleMatrix'
  ]
  const headOfCBAccess = [
    'questionnaires',
    'questionnaireList',
    'quotationList',
    'surveillanceList',
    'orders',
    'orderList',
    'auditPlanList',
    'auditReportList',
    'nonComplyList',
    'certificateList',
    'daList',
    'scheduleMatrix'
  ]
  const auditPlanOperatorAccess = [
    'orders',
    'orderList',
    'auditPlanList'
  ]

  const financeAccess = [
    'orders',
    'orderList',
    'invoices',
    'invoiceList'
  ]

  const certificateAdminAccess = [
    'orders',
    'orderList',
    'certificateList'
  ]

  const superAdminAccess = [
    'questionnaires',
    'newQuestionnaire',
    'questionnaireList',
    'quotationList',
    'surveillanceList',
    'orders',
    'orderList',
    'auditPlanList',
    'auditReportList',
    'nonComplyList',
    'certificateList',
    'daList',
    'invoices',
    'invoiceList',
    'scheduleMatrix',
    'customers',
    'users',
    'userList',
    'auditorExperts',
    'standards',
    'settings',
    'events',
    'options'
  ]

  let access = []

  if (role.includes('Customer')) {
    access = [...access, ...customerAccess]
  }
  if (role.includes('Sales')) {
    access = [...access, ...salesAccess]
  }
  if (role.includes('Sales Manager')) {
    access = [...access, ...salesManagerAccess]
  }
  if (role.includes('Application Reviewer Manager')) {
    access = [...access, ...applicationReviewerManagerAccess]
  }
  if (role.includes('Auditor')) {
    access = [...access, ...auditorAccess]
  }
  if (role.includes('Board of Director')) {
    access = [...access, ...bodAccess]
  }
  if (role.includes('Scheduler')) {
    access = [...access, ...schedulerAccess]
  }
  if (role.includes('Head of CB')) {
    access = [...access, ...headOfCBAccess]
  }
  if (role.includes('Audit Plan Operator')) {
    access = [...access, ...auditPlanOperatorAccess]
  }
  if (role.includes('Super Admin')) {
    access = [...access, ...superAdminAccess]
  }
  if (role.includes('Operational Manager')) {
    access = [...access, ...superAdminAccess]
  }
  if (role.includes('Finance')) {
    access = [...access, ...financeAccess]
  }
  if (role.includes('Certificate Admin')) {
    access = [...access, ...certificateAdminAccess]
  }

  return (
    <div id='left-sidebar' className={props.openSidebar ? 'left-sidebar' : 'left-sidebar minified'}>
      <div className='sidebar-minified js-toggle-minified'>
        <i className='fa fa-exchange' onClick={props.toggleSidebar} />
      </div>
      <div className='sidebar-scroll'>
        <nav className='main-nav'>
          <ul className='main-menu'>
            <Route path='/' exact children={(match) => (
              <li className={match.match ? 'active ' : ''}><Link to='/'><i className='fa fa-dashboard' /><span className='text'> Dashboard</span></Link></li>
            )} />
            {baseMenu.map((x, i) => {
              if (access.includes(x.id)) {
                return (
                  <Route key={i} path={x.url} exact={false} children={(match) => (
                    <li className={match.match ? 'active ' : ''}>
                      <a className={`js-sub-menu-toggle ${x.title}`} onClick={(e) => goTo(x.id, x.url, e)} href='/'>
                        <i className={x.icon} />
                        <span className='text'> {x.title}
                          {/* <span className='badge element-bg-color-blue'>New</span> */}
                        </span>
                        {
                          openSubMenu === x.id ? <i className='toggle-icon fa fa-angle-down' /> : <i className='toggle-icon fa fa-angle-left' />
                        }
                      </a>
                      { x.children &&
                      <ul className={openSubMenu === x.id && openSidebar ? 'sub-menu open' : 'sub-menu'}>
                        { x.children.map((y, j) => {
                          if (access.includes(y.id)) {
                            return <Route key={j} path={y.url} exact children={(match) => (
                              <li className={match.match ? 'active ' : ''}><Link className={'link-' + y.title.toLowerCase().split(' ').join('-')} to={y.url}><span className='text'>{y.title}</span></Link></li>
                            )} />
                          } else {
                            return ''
                          }
                        }) }
                      </ul>
                      }
                    </li>
                  )} />
                )
              }
              return ''
            })}
          </ul>
        </nav>
      </div>
    </div>
  )
}

// export class LeftSideBar extends React.Component {

//   getMenu () {
//     return this.state.menu.map((x, i) => {
//       if (!x.children) {
//         return <Route key={i} path={x.url} exact children={(match) => (
//           <li className={match.match ? 'active ' : ''}><Link to={x.url}><i className={x.icon} /><span className='text'> {x.title}</span></Link></li>
//         )} />
//       } else {
//         return (
//           <Route key={i} path={x.url} exact={false} children={(match) => (
//             <li className={match.match ? 'active ' : ''}>
//               <a className={`js-sub-menu-toggle ${x.title}`} onClick={this.goTo.bind(this, x.id)} href='/'>
//                 <i className={x.icon} />
//                 <span className='text'> {x.title}
//                   {/* <span className='badge element-bg-color-blue'>New</span> */}
//                 </span>
//                 {
//                   this.props.openSubMenu === x.id ? <i className='toggle-icon fa fa-angle-down' /> : <i className='toggle-icon fa fa-angle-left' />
//                 }
//               </a>
//               <ul className={this.getSubMenuClass(x.id)}>
//                 { x.children.map((y, j) => {
//                   return <Route key={j} path={y.url} exact children={(match) => (
//                     <li className={match.match ? 'active ' : ''}><Link className={'link-' + y.title.toLowerCase().split(' ').join('-')} to={y.url}><span className='text'>{y.title}</span></Link></li>
//                   )} />
//                 }) }
//               </ul>
//             </li>
//           )} />
//         )
//       }
//     })
//   }

//   getSubMenuClass (id) {
//     if (this.props.openSubMenu === id && this.props.openSidebar) {
//       return 'sub-menu open'
//     } else {
//       return 'sub-menu'
//     }
//   }

//   render () {
//     return (
//       <div id='left-sidebar' className={this.props.openSidebar ? 'left-sidebar' : 'left-sidebar minified'}>
//         <div className='sidebar-minified js-toggle-minified'>
//           <i className='fa fa-exchange' onClick={this.props.toggleSidebar.bind(this)} />
//         </div>
//         <div className='sidebar-scroll'>
//           <nav className='main-nav'>
//             <ul className='main-menu'>
//               { this.getMenu() }
//             </ul>
//           </nav>
//         </div>
//       </div>
//     )
//   }
// }

function mapStateToProps (state) {
  return {
    role: state.auth.user.data.role,
    openSidebar: state.layout.openSidebar,
    openSubMenu: state.layout.openSubMenu
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleSidebar: () => dispatch(toggleSidebar()),
    toggleSubMenu: (menu) => dispatch(toggleSubMenu(menu)),
    push: (to) => dispatch(push(to))
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(LeftSideBar))
