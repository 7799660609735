export const initialState = {
  daList: {
    data: null,
    pagination: null,
    error: null,
    loading: false
  },
  da: {
    data: null,
    error: null,
    loading: false
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_DAS_PENDING':
      return { ...state, daList: { data: null, error: null, loading: true } }
    case 'FETCH_DAS_REJECTED':
      return { ...state, daList: { data: null, error: action.payload, loading: false } }
    case 'FETCH_DAS_FULFILLED':
      return { ...state,
        daList: {
          data: action.payload,
          pagination: action.pagination,
          error: null,
          loading: false
        }
      }

    case 'FETCH_DA_PENDING' :
      return { ...state, da: { data: null, error: null, loading: true } }
    case 'FETCH_DA_REJECTED':
      return { ...state, da: { data: null, error: action.payload, loading: false } }
    case 'FETCH_DA_FULFILLED' :
      return { ...state, da: { data: action.payload, error: null, loading: false } }

    case 'UPDATE_DA_PENDING' :
      return { ...state, da: { ...state.da, error: null, loading: true } }
    case 'UPDATE_DA_REJECTED':
      return { ...state, da: { ...state.da, error: action.payload, loading: false } }
    case 'UPDATE_DA_FULFILLED' :
      return { ...state, da: { data: action.payload, error: null, loading: false } }

    case 'DELETE_DA_PENDING' :
      return { ...state, da: { ...state.da, error: null, loading: true } }
    case 'DELETE_DA_REJECTED':
      return { ...state, da: { ...state.da, error: action.payload, loading: false } }
    case 'DELETE_DA_FULFILLED' :
      return { ...state, da: { data: null, error: null, loading: false } }

    case 'LOGOUT_FULFILLED':
      return { ...initialState }

    default:
      return state
  }
}
