import React from 'react'
import moment from 'moment'
import intersection from 'lodash/intersection'

import makeCertificationAction from '../../const/makeCertificationAction'
import makeAuditAction from '../../const/makeAuditAction'
import makeTrainingAction from '../../const/makeTrainingAction'

export default class OrderAction extends React.Component {
  getAction (status, type) {
    let actions = []
    if (type === 'Certification Audit' || type.indexOf('Surveillance Audit') > -1) {
      // action set for certification audit and surveillance
      actions = makeCertificationAction(this.props)
    } else if (type === 'Gap Analysis / Pre Audit') {
      // action set for general audit
      actions = makeAuditAction(this.props)
    } else if (type === 'Training') {
      // action set for training
      actions = makeTrainingAction(this.props)
    }

    return actions.find((x) => x.status === status)
  }

  render () {
    const { order, user } = this.props
    const current = this.getAction(order.status, order.phase)
    const dueDate = order.due_date ? moment.unix(order.due_date) : null

    console.log('props', this.props)
    console.log('current', current)
    console.log('prerequisite', current.primary.pre)
    console.log('authorized role', current.primary.role)
    console.log('authorized id', current.primary.users ? current.primary.users.map((x) => x.user.id) : 'no user')
    console.log('user', user.id, user.data.role)

    return (
      <div className='widget widget-hide-header widget-reminder'>
        <div className='widget-content'>
          <div className='today-reminder'>
            <h4 className='reminder-title'>Next Action</h4>
            <p className='reminder-time'><i className='fa fa-clock-o' /> {current.todo}</p>
            {dueDate &&
              <p>
                <em className='reminder-notes'>Due Date : {dueDate.format('dddd, DD-MM-YYYY')}</em>
              </p>
            }
            <i className='fa fa-bell' />
            { current.primary.pre || current.primary.pre === undefined
              ? <div>
                {
                  (intersection(current.primary.role, user.data.role).length > 0) ||
                  (current.primary.users && current.primary.users.filter((x) => x.user.id === user.id).length > 0)
                    ? <p><button disabled={this.props.disabled} onClick={current.primary.action} className='btn-action btn btn-primary'>{current.primary.text}</button></p>
                    : <div className='alert alert-info'>{current.primary.alternative || 'Prerequisite not met.'}</div>
                }
              </div>
              : <div className='alert alert-info'>{current.primary.alternative || 'Prerequisite not met.'}</div>
            }
            { current.secondary && current.secondary.map((x, i) => {
              return (
                <span key={i}>
                  {
                    (intersection(x.role, user.data.role).length > 0) ||
                    (x.users && x.users.filter((x) => x.user.id === user.id).length > 0) ||
                    (1 === 2)
                      ? <span><button disabled={this.props.disabled} onClick={x.action} className='btn btn-default'>{x.text}</button> &nbsp;</span>
                      : <span />
                  }
                </span>
              )
            })}
            { user.data.role.includes('Super Admin') &&
              <button disabled={this.props.disabled} onClick={this.props.onAuditCancel} className='btn btn-default'>Cancel audit</button>
            }
            <div className='btn-group btn-group-xs'>
              <button disabled={this.props.disabled} onClick={this.props.handleReminder} type='button' className='btn btn-warning'><i className='fa fa-bell' /> Send Reminder</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
