import React, { useState, Fragment } from 'react'
import Dropzone from 'react-dropzone'
import CSV from 'csvtojson'

import Required from './Required'

export default function FileField ({ api, input, label, required, template, meta: { touched, error, warning } }) {
  const [expand, setExpand] = useState(false)
  function readFiles (file, callback) {
    const reader = new window.FileReader()
    reader.onerror = function (event) {
      console.error(new Error(event.target.error.code).stack)
    }
    reader.onloadend = function (evt) {
      callback(this.result)
    }
    reader.readAsText(file)
  }

  async function parseCsv (text) {
    const csv = new CSV()
    const result = await csv.fromString(text)
    const format = result
      .map(x => {
        const format = {}
        for (let k in x) {
          format[k] = parseInt(x[k], 10)
        }
        return format
      })
    input.onChange(format)
  }

  let matrix = input.value
  return (
    <div className={`form-group ${input.name.split('.').join('_')}`}>
      <label htmlFor={label} className='col-sm-3 control-label'>{label} {required && <Required />}</label>
      <div className='col-sm-9'>
        <Dropzone
          name={label}
          style={{ marginBottom: 0 }}
          className='well'
          onDrop={(filesToUpload, e) => {
            readFiles(filesToUpload[0], parseCsv)
          }}
        >
          <div>Drop a files here, or click to select files to upload.</div>
        </Dropzone>
        { template &&
          <p>Download matrix template <a href={`${process.env.PUBLIC_URL}/templates/${template}`}>here</a></p>
        }
        {touched && ((error && <div className='alert alert-danger'>{error}</div>) || (warning && <div className='alert alert-warning'>{warning}</div>))}

        { matrix &&
          <Fragment>
            <p>Matrix with {matrix.length} {matrix.length === 1 ? 'element' : 'elements'} uploaded button <button className='btn btn-xs btn-link' type='button' onClick={x => setExpand(!expand)}>{expand ? 'Hide' : 'Show'}</button></p>
            { expand &&
              <div>{JSON.stringify(matrix)}</div>
            }

          </Fragment>
        }
      </div>
    </div>
  )
}
