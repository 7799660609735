const provinces = [
  {
    name: 'Aceh',
    regencies: [
      'Kabupaten Simeulue',
      'Kabupaten Aceh Singkil',
      'Kabupaten Aceh Selatan',
      'Kabupaten Aceh Tenggara',
      'Kabupaten Aceh Timur',
      'Kabupaten Aceh Tengah',
      'Kabupaten Aceh Barat',
      'Kabupaten Aceh Besar',
      'Kabupaten Pidie',
      'Kabupaten Bireuen',
      'Kabupaten Aceh Utara',
      'Kabupaten Aceh Barat Daya',
      'Kabupaten Gayo Lues',
      'Kabupaten Aceh Tamiang',
      'Kabupaten Nagan Raya',
      'Kabupaten Aceh Jaya',
      'Kabupaten Bener Meriah',
      'Kabupaten Pidie Jaya',
      'Kota Banda Aceh',
      'Kota Sabang',
      'Kota Langsa',
      'Kota Lhokseumawe',
      'Kota Subulussalam'
    ]
  },
  {
    name: 'Sumatera Utara',
    regencies: [
      'Kabupaten Nias',
      'Kabupaten Mandailing Natal',
      'Kabupaten Tapanuli Selatan',
      'Kabupaten Tapanuli Tengah',
      'Kabupaten Tapanuli Utara',
      'Kabupaten Toba Samosir',
      'Kabupaten Labuhan Batu',
      'Kabupaten Asahan',
      'Kabupaten Simalungun',
      'Kabupaten Dairi',
      'Kabupaten Karo',
      'Kabupaten Deli Serdang',
      'Kabupaten Langkat',
      'Kabupaten Nias Selatan',
      'Kabupaten Humbang Hasundutan',
      'Kabupaten Pakpak Bharat',
      'Kabupaten Samosir',
      'Kabupaten Serdang Bedagai',
      'Kabupaten Batu Bara',
      'Kabupaten Padang Lawas Utara',
      'Kabupaten Padang Lawas',
      'Kabupaten Labuhan Batu Selatan',
      'Kabupaten Labuhan Batu Utara',
      'Kabupaten Nias Utara',
      'Kabupaten Nias Barat',
      'Kota Sibolga',
      'Kota Tanjung Balai',
      'Kota Pematang Siantar',
      'Kota Tebing Tinggi',
      'Kota Medan',
      'Kota Binjai',
      'Kota Padangsidimpuan',
      'Kota Gunungsitoli'
    ]
  },
  {
    name: 'Sumatera Barat',
    regencies: [
      'Kabupaten Kepulauan Mentawai',
      'Kabupaten Pesisir Selatan',
      'Kabupaten Solok',
      'Kabupaten Sijunjung',
      'Kabupaten Tanah Datar',
      'Kabupaten Padang Pariaman',
      'Kabupaten Agam',
      'Kabupaten Lima Puluh Kota',
      'Kabupaten Pasaman',
      'Kabupaten Solok Selatan',
      'Kabupaten Dharmasraya',
      'Kabupaten Pasaman Barat',
      'Kota Padang',
      'Kota Solok',
      'Kota Sawah Lunto',
      'Kota Padang Panjang',
      'Kota Bukittinggi',
      'Kota Payakumbuh',
      'Kota Pariaman'
    ]
  },
  {
    name: 'Riau',
    regencies: [
      'Kabupaten Kuantan Singingi',
      'Kabupaten Indragiri Hulu',
      'Kabupaten Indragiri Hilir',
      'Kabupaten Pelalawan',
      'Kabupaten Siak',
      'Kabupaten Kampar',
      'Kabupaten Rokan Hulu',
      'Kabupaten Bengkalis',
      'Kabupaten Rokan Hilir',
      'Kabupaten Kepulauan Meranti',
      'Kota Pekanbaru',
      'Kota Dumai'
    ]
  },
  {
    name: 'Jambi ',
    regencies: [
      'Kabupaten Kerinci',
      'Kabupaten Merangin',
      'Kabupaten Sarolangun',
      'Kabupaten Batang Hari',
      'Kabupaten Muaro Jambi',
      'Kabupaten Tanjung Jabung Timur',
      'Kabupaten Tanjung Jabung Barat',
      'Kabupaten Tebo',
      'Kabupaten Bungo',
      'Kota Jambi',
      'Kota Sungai Penuh'
    ]
  },
  {
    name: 'Sumatera Selatan',
    regencies: [
      'Kabupaten Ogan Komering Ulu',
      'Kabupaten Ogan Komering Ilir',
      'Kabupaten Muara Enim',
      'Kabupaten Lahat',
      'Kabupaten Musi Rawas',
      'Kabupaten Musi Banyuasin',
      'Kabupaten Banyu Asin',
      'Kabupaten Ogan Komering Ulu Selatan',
      'Kabupaten Ogan Komering Ulu Timur',
      'Kabupaten Ogan Ilir',
      'Kabupaten Empat Lawang',
      'Kabupaten Penukal Abab Lematang Ilir',
      'Kabupaten Musi Rawas Utara',
      'Kota Palembang',
      'Kota Prabumulih',
      'Kota Pagar Alam',
      'Kota Lubuklinggau'
    ]
  },
  {
    name: 'Bengkulu',
    regencies: [
      'Kabupaten Bengkulu Selatan',
      'Kabupaten Rejang Lebong',
      'Kabupaten Bengkulu Utara',
      'Kabupaten Kaur',
      'Kabupaten Seluma',
      'Kabupaten Mukomuko',
      'Kabupaten Lebong',
      'Kabupaten Kepahiang',
      'Kabupaten Bengkulu Tengah',
      'Kota Bengkulu'
    ]
  },
  {
    name: 'Lampung ',
    regencies: [
      'Kabupaten Lampung Barat',
      'Kabupaten Tanggamus',
      'Kabupaten Lampung Selatan',
      'Kabupaten Lampung Timur',
      'Kabupaten Lampung Tengah',
      'Kabupaten Lampung Utara',
      'Kabupaten Way Kanan',
      'Kabupaten Tulangbawang',
      'Kabupaten Pesawaran',
      'Kabupaten Pringsewu',
      'Kabupaten Mesuji',
      'Kabupaten Tulang Bawang Barat',
      'Kabupaten Pesisir Barat',
      'Kota Bandar Lampung',
      'Kota Metro'
    ]
  },
  {
    name: 'Kepulauan Bangka Belitung ',
    regencies: [
      'Kabupaten Bangka',
      'Kabupaten Belitung',
      'Kabupaten Bangka Barat',
      'Kabupaten Bangka Tengah',
      'Kabupaten Bangka Selatan',
      'Kabupaten Belitung Timur',
      'Kota Pangkal Pinang'
    ]
  },
  {
    name: 'Kepulauan Riau',
    regencies: [
      'Kabupaten Karimun',
      'Kabupaten Bintan',
      'Kabupaten Natuna',
      'Kabupaten Lingga',
      'Kabupaten Kepulauan Anambas',
      'Kota Batam',
      'Kota Tanjung Pinang'
    ]
  },
  {
    name: 'DKI',
    regencies: [
      'Kabupaten Kepulauan Seribu',
      'Kota Jakarta Selatan',
      'Kota Jakarta Timur',
      'Kota Jakarta Pusat',
      'Kota Jakarta Barat',
      'Kota Jakarta Utara'
    ]
  },
  {
    name: 'Jawa Barat',
    regencies: [
      'Kabupaten Bogor',
      'Kabupaten Sukabumi',
      'Kabupaten Cianjur',
      'Kabupaten Bandung',
      'Kabupaten Garut',
      'Kabupaten Tasikmalaya',
      'Kabupaten Ciamis',
      'Kabupaten Kuningan',
      'Kabupaten Cirebon',
      'Kabupaten Majalengka',
      'Kabupaten Sumedang',
      'Kabupaten Indramayu',
      'Kabupaten Subang',
      'Kabupaten Purwakarta',
      'Kabupaten Karawang',
      'Kabupaten Bekasi',
      'Kabupaten Bandung Barat',
      'Kabupaten Pangandaran',
      'Kota Bogor',
      'Kota Sukabumi',
      'Kota Bandung',
      'Kota Cirebon',
      'Kota Bekasi',
      'Kota Depok',
      'Kota Cimahi',
      'Kota Tasikmalaya',
      'Kota Banjar'
    ]
  },
  {
    name: 'Jawa Tengah ',
    regencies: [
      'Kabupaten Cilacap',
      'Kabupaten Banyumas',
      'Kabupaten Purbalingga',
      'Kabupaten Banjarnegara',
      'Kabupaten Kebumen',
      'Kabupaten Purworejo',
      'Kabupaten Wonosobo',
      'Kabupaten Magelang',
      'Kabupaten Boyolali',
      'Kabupaten Klaten',
      'Kabupaten Sukoharjo',
      'Kabupaten Wonogiri',
      'Kabupaten Karanganyar',
      'Kabupaten Sragen',
      'Kabupaten Grobogan',
      'Kabupaten Blora',
      'Kabupaten Rembang',
      'Kabupaten Pati',
      'Kabupaten Kudus',
      'Kabupaten Jepara',
      'Kabupaten Demak',
      'Kabupaten Semarang',
      'Kabupaten Temanggung',
      'Kabupaten Kendal',
      'Kabupaten Batang',
      'Kabupaten Pekalongan',
      'Kabupaten Pemalang',
      'Kabupaten Tegal',
      'Kabupaten Brebes',
      'Kota Magelang',
      'Kota Surakarta',
      'Kota Salatiga',
      'Kota Semarang',
      'Kota Pekalongan',
      'Kota Tegal'
    ]
  },
  {
    name: 'Di Yogyakarta ',
    regencies: [
      'Kabupaten Kulon Progo',
      'Kabupaten Bantul',
      'Kabupaten Gunung Kidul',
      'Kabupaten Sleman',
      'Kota Yogyakarta'
    ]
  },
  {
    name: 'Jawa Timur',
    regencies: [
      'Kabupaten Pacitan',
      'Kabupaten Ponorogo',
      'Kabupaten Trenggalek',
      'Kabupaten Tulungagung',
      'Kabupaten Blitar',
      'Kabupaten Kediri',
      'Kabupaten Malang',
      'Kabupaten Lumajang',
      'Kabupaten Jember',
      'Kabupaten Banyuwangi',
      'Kabupaten Bondowoso',
      'Kabupaten Situbondo',
      'Kabupaten Probolinggo',
      'Kabupaten Pasuruan',
      'Kabupaten Sidoarjo',
      'Kabupaten Mojokerto',
      'Kabupaten Jombang',
      'Kabupaten Nganjuk',
      'Kabupaten Madiun',
      'Kabupaten Magetan',
      'Kabupaten Ngawi',
      'Kabupaten Bojonegoro',
      'Kabupaten Tuban',
      'Kabupaten Lamongan',
      'Kabupaten Gresik',
      'Kabupaten Bangkalan',
      'Kabupaten Sampang',
      'Kabupaten Pamekasan',
      'Kabupaten Sumenep',
      'Kota Kediri',
      'Kota Blitar',
      'Kota Malang',
      'Kota Probolinggo',
      'Kota Pasuruan',
      'Kota Mojokerto',
      'Kota Madiun',
      'Kota Surabaya',
      'Kota Batu'
    ]
  },
  {
    name: 'Banten',
    regencies: [
      'Kabupaten Pandeglang',
      'Kabupaten Lebak',
      'Kabupaten Tangerang',
      'Kabupaten Serang',
      'Kota Tangerang',
      'Kota Cilegon',
      'Kota Serang',
      'Kota Tangerang Selatan'
    ]
  },
  {
    name: 'Bali',
    regencies: [
      'Jembrana',
      'Tabanan',
      'Badung',
      'Gianyar',
      'Klungkung',
      'Bangli',
      'Karang Asem',
      'Buleleng',
      'Kota Denpasar'
    ]
  },
  {
    name: 'Nusa Tenggara Barat ',
    regencies: [
      'Lombok Barat',
      'Lombok Tengah',
      'Lombok Timur',
      'Sumbawa',
      'Dompu',
      'Bima',
      'Sumbawa Barat',
      'Lombok Utara',
      'Kota Mataram',
      'Kota Bima'
    ]
  },
  {
    name: 'Nusa Tenggara Timur ',
    regencies: [
      'Sumba Barat',
      'Sumba Timur',
      'Kupang',
      'Timor Tengah Selatan',
      'Timor Tengah Utara',
      'Belu',
      'Alor',
      'Lembata',
      'Flores Timur',
      'Sikka',
      'Ende',
      'Ngada',
      'Manggarai',
      'Rote Ndao',
      'Manggarai Barat',
      'Sumba Tengah',
      'Sumba Barat Daya',
      'Nagekeo',
      'Manggarai Timur',
      'Sabu Raijua',
      'Malaka',
      'Kota Kupang'
    ]
  },
  {
    name: 'Kalimantan Barat',
    regencies: [
      'Sambas',
      'Bengkayang',
      'Landak',
      'Mempawah',
      'Sanggau',
      'Ketapang',
      'Sintang',
      'Kapuas Hulu',
      'Sekadau',
      'Melawi',
      'Kayong Utara',
      'Kubu Raya',
      'Kota Pontianak',
      'Kota Singkawang'
    ]
  },
  {
    name: 'Kalimantan Tengah ',
    regencies: [
      'Kotawaringin Barat',
      'Kotawaringin Timur',
      'Kapuas',
      'Barito Selatan',
      'Barito Utara',
      'Sukamara',
      'Lamandau',
      'Seruyan',
      'Katingan',
      'Pulang Pisau',
      'Gunung Mas',
      'Barito Timur',
      'Murung Raya',
      'Kota Palangka Raya'
    ]
  },
  {
    name: 'Kalimantan Selatan',
    regencies: [
      'Tanah Laut',
      'Kota Baru',
      'Banjar',
      'Barito Kuala',
      'Tapin',
      'Hulu Sungai Selatan',
      'Hulu Sungai Tengah',
      'Hulu Sungai Utara',
      'Tabalong',
      'Tanah Bumbu',
      'Balangan',
      'Kota Banjarmasin',
      'Kota Banjar Baru'
    ]
  },
  {
    name: 'Kalimantan Timur',
    regencies: [
      'Paser',
      'Kutai Barat',
      'Kutai Kartanegara',
      'Kutai Timur',
      'Berau',
      'Penajam Paser Utara',
      'Mahakam Hulu',
      'Kota Balikpapan',
      'Kota Samarinda',
      'Kota Bontang'
    ]
  },
  {
    name: 'Kalimantan Utara',
    regencies: [
      'Malinau',
      'Bulungan',
      'Tana Tidung',
      'Nunukan',
      'Kota Tarakan'
    ]
  },
  {
    name: 'Sulawesi Utara',
    regencies: [
      'Bolaang Mongondow',
      'Minahasa',
      'Kepulauan Sangihe',
      'Kepulauan Talaud',
      'Minahasa Selatan',
      'Minahasa Utara',
      'Bolaang Mongondow Utara',
      'Siau Tagulandang Biaro',
      'Minahasa Tenggara',
      'Bolaang Mongondow Selatan',
      'Bolaang Mongondow Timur',
      'Kota Manado',
      'Kota Bitung',
      'Kota Tomohon',
      'Kota Kotamobagu'
    ]
  },
  {
    name: 'Sulawesi Tengah ',
    regencies: [
      'Banggai Kepulauan',
      'Banggai',
      'Morowali',
      'Poso',
      'Donggala',
      'Toli-toli',
      'Buol',
      'Parigi Moutong',
      'Tojo Una-una',
      'Sigi',
      'Banggai Laut',
      'Morowali Utara',
      'Kota Palu'
    ]
  },
  {
    name: 'Sulawesi Selatan',
    regencies: [
      'Kepulauan Selayar',
      'Bulukumba',
      'Bantaeng',
      'Jeneponto',
      'Takalar',
      'Gowa',
      'Sinjai',
      'Maros',
      'Pangkajene Dan Kepulauan',
      'Barru',
      'Bone',
      'Soppeng',
      'Wajo',
      'Sidenreng Rappang',
      'Pinrang',
      'Enrekang',
      'Luwu',
      'Tana Toraja',
      'Luwu Utara',
      'Luwu Timur',
      'Toraja Utara',
      'Kota Makassar',
      'Kota Parepare',
      'Kota Palopo'
    ]
  },
  {
    name: 'Sulawesi Tenggara ',
    regencies: [
      'Buton',
      'Muna',
      'Konawe',
      'Kolaka',
      'Konawe Selatan',
      'Bombana',
      'Wakatobi',
      'Kolaka Utara',
      'Buton Utara',
      'Konawe Utara',
      'Kolaka Timur',
      'Konawe Kepulauan',
      'Muna Barat',
      'Buton Tengah',
      'Buton Selatan',
      'Kota Kendari',
      'Kota Baubau'
    ]
  },
  {
    name: 'Gorontalo ',
    regencies: [
      'Boalemo',
      'Gorontalo',
      'Pohuwato',
      'Bone Bolango',
      'Gorontalo Utara',
      'Kota Gorontalo'
    ]
  },
  {
    name: 'Sulawesi Barat',
    regencies: [
      'Majene',
      'Polewali Mandar',
      'Mamasa',
      'Mamuju',
      'Mamuju Utara',
      'Mamuju Tengah'
    ]
  },
  {
    name: 'Maluku',
    regencies: [
      'Maluku Tenggara Barat',
      'Maluku Tenggara',
      'Maluku Tengah',
      'Buru',
      'Kepulauan Aru',
      'Seram Bagian Barat',
      'Seram Bagian Timur',
      'Maluku Barat Daya',
      'Buru Selatan',
      'Kota Ambon',
      'Kota Tual'
    ]
  },
  {
    name: 'Maluku Utara',
    regencies: [
      'Halmahera Barat',
      'Halmahera Tengah',
      'Kepulauan Sula',
      'Halmahera Selatan',
      'Halmahera Utara',
      'Halmahera Timur',
      'Pulau Morotai',
      'Pulau Taliabu',
      'Kota Ternate',
      'Kota Tidore Kepulauan'
    ]
  },
  {
    name: 'Papua Barat ',
    regencies: [
      'Fakfak',
      'Kaimana',
      'Teluk Wondama',
      'Teluk Bintuni',
      'Manokwari',
      'Sorong Selatan',
      'Sorong',
      'Raja Ampat',
      'Tambrauw',
      'Maybrat',
      'Manokwari Selatan',
      'Pegunungan Arfak',
      'Kota Sorong'
    ]
  },
  {
    name: 'Papua ',
    regencies: [
      'Merauke',
      'Jayawijaya',
      'Jayapura',
      'Nabire',
      'Kepulauan Yapen',
      'Biak Numfor',
      'Paniai',
      'Puncak Jaya',
      'Mimika',
      'Boven Digoel',
      'Mappi',
      'Asmat',
      'Yahukimo',
      'Pegunungan Bintang',
      'Tolikara',
      'Sarmi',
      'Keerom',
      'Waropen',
      'Supiori',
      'Mamberamo Raya',
      'Nduga',
      'Lanny Jaya',
      'Mamberamo Tengah',
      'Yalimo',
      'Puncak',
      'Dogiyai',
      'Intan Jaya',
      'Deiyai',
      'Kota Jayapura'
    ]
  }
]

export default provinces
