import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

const loginValidation = (values) => {
  const errors = {}
  if (!values.username) {
    errors.username = 'Required'
  }
  if (!values.password) {
    errors.password = 'Required'
  }
  return errors
}

const usernameField = ({input, label, type, meta: {touched, error, warning}}) => (
  <div className='form-group'>
    <label htmlFor='username' className='control-label sr-only'>Username</label>
    <div className='col-sm-12'>
      <div className='input-group'>
        <input {...input} placeholder={label} type={type} className='form-control' />
        <span className='input-group-addon'><i className='fa fa-user' /></span>
      </div>
      {touched && ((error && <div><span className='text-danger'>{error}</span></div>) || (warning && <div><span className='text-danger'>{warning}</span></div>))}
    </div>
  </div>
)

const passwordField = ({input, label, type, meta: {touched, error, warning}}) => (
  <div className='form-group'>
    <label htmlFor='username' className='control-label sr-only'>Username</label>
    <div className='col-sm-12'>
      <div className='input-group'>
        <input {...input} placeholder={label} type={type} className='form-control' />
        <span className='input-group-addon'><i className='fa fa-lock' /></span>
      </div>
      {touched && ((error && <div><span className='text-danger'>{error}</span></div>) || (warning && <div><span className='text-danger'>{warning}</span></div>))}
    </div>
  </div>
)

export class BaseForm extends Component {
  render () {
    const { handleSubmit, submitting, type } = this.props
    return (

      <form className='form-horizontal' onSubmit={handleSubmit}>

        
        { type === 'request-reset' &&
          <p className='title'>Please insert your email address</p>
        }
        { type === 'reset' &&
          <p className='title'>Create your new password</p>
        }
        { type === 'login' &&
          <Field name='username' type='text' component={usernameField} label='Username' />
        }
        { type === 'request-reset' &&
          <Field name='email' type='text' component={usernameField} label='Email' />
        }
        { type !== 'request-reset' &&
          <Field name='password' type='password' component={passwordField} label='Password' />
        }
        { type === 'login' &&
          <label className='fancy-checkbox'>
            <input type='checkbox' />
            <span>Remember me next time</span>
          </label>
        }
        <button disabled={submitting} type='submit' className='btn btn-custom-primary btn-lg btn-block btn-auth'><i className='fa fa-arrow-circle-o-right' />
          { type === 'login' ? <span>Login</span> : <span>Submit</span> }
        </button>
      </form>
    )
  }
}

let LoginForm = (props) => <BaseForm {...props} type='login' />
let RequestResetPasswordForm = (props) => <BaseForm {...props} type='request-reset' />
let ResetPasswordForm = (props) => <BaseForm {...props} type='reset' />

LoginForm = reduxForm({ // eslint-disable-line 
  form: 'Login',
  validate: loginValidation
})(LoginForm)

RequestResetPasswordForm = reduxForm({ // eslint-disable-line 
  form: 'RequestResetPassword'
})(RequestResetPasswordForm)

ResetPasswordForm = reduxForm({ // eslint-disable-line 
  form: 'ResetPassword'
})(ResetPasswordForm)

export default LoginForm

export { LoginForm, RequestResetPasswordForm, ResetPasswordForm }
