import axios from 'axios'
import { push } from 'react-router-redux'
import { createFlash, createError } from './flasher'

const baseAPI = process.env.REACT_APP_BASE_API

export const fetchStandards = (params = '', accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_STANDARDS_PENDING'
    })
    return axios({
      method: 'get',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/standards${params}`
    }).then((x) => {
      dispatch({
        type: 'FETCH_STANDARDS_FULFILLED',
        payload: x.data,
        pagination: {
          currentPage: x.headers['x-pagination-current-page'],
          pageCount: x.headers['x-pagination-page-count'],
          perPage: x.headers['x-pagination-per-page'],
          totalCount: x.headers['x-pagination-total-count']
        }
      })
    }).catch((e) => {
      console.error(e)
      dispatch(createError(e.message))
      dispatch({
        type: 'FETCH_STANDARDS_REJECTED',
        payload: e
      })
    })
  }
}

export const fetchStandard = (id, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_STANDARD_PENDING'
    })
    return axios({
      method: 'get',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/standards/${id}?expand=annex,detail`
    }).then((x) => {
      dispatch({
        type: 'FETCH_STANDARD_FULFILLED',
        payload: x.data
      })
    }).catch((e) => {
      dispatch({
        type: 'FETCH_STANDARD_REJECTED',
        payload: e
      })
    })
  }
}

export const createStandard = (data, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'CREATE_STANDARD_PENDING'
    })
    return axios({
      data,
      method: 'post',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/standards`
    }).then((x) => {
      dispatch({
        type: 'CREATE_STANDARD_FULFILLED',
        payload: x.data
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Standar created`
      }))
      dispatch(push(`/standards/${x.data.id}`))
    }).catch((e) => {
      dispatch({
        type: 'CREATE_STANDARD_REJECTED',
        payload: e
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'error',
        message: `Update error`
      }))
    })
  }
}

export const updateStandard = (data, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'UPDATE_STANDARD_PENDING'
    })
    return axios({
      data,
      method: 'put',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/standards/${data.id}?expand=annex,detail`
    }).then((x) => {
      dispatch({
        type: 'UPDATE_STANDARD_FULFILLED',
        payload: x.data
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `standar created with the id ${x.data.id}`
      }))
    }).catch((e) => {
      dispatch({
        type: 'UPDATE_STANDARD_REJECTED',
        payload: e
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'error',
        message: `Update error`
      }))
    })
  }
}

export const deleteStandard = (id, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'DELETE_STANDARD_PENDING'
    })
    return axios({
      method: 'delete',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/standards/${id}`
    }).then((x) => {
      dispatch({
        type: 'DELETE_STANDARD_FULFILLED',
        payload: x
      })
      dispatch(push('/standards'))
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `standar ${id} deleted`
      }))
    }).catch((e) => {
      dispatch({
        type: 'DELETE_STANDARD_REJECTED',
        payload: e
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'error',
        message: `delete error`
      }))
    })
  }
}

export const fetchStandardScopes = (id, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_SCOPES_PENDING'
    })
    return axios({
      method: 'get',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/standards/${id}/scopes`
    }).then((x) => {
      dispatch({
        type: 'FETCH_SCOPES_FULFILLED',
        payload: x.data
      })
      return x.data
    }).catch((e) => {
      dispatch({
        type: 'FETCH_SCOPES_REJECTED',
        payload: e
      })
    })
  }
}

export const createStandardScope = (id, data, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'CREATE_SCOPE_PENDING'
    })
    return axios({
      data,
      method: 'post',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/standards/${id}/scopes`
    }).then((x) => {
      dispatch({
        type: 'CREATE_SCOPE_FULFILLED',
        payload: x.data
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Scope ${x.data.id} created`
      }))
      return x.data
      // dispatch(push(`/standards/${x.data.id}`))
    }).catch((e) => {
      dispatch({
        type: 'CREATE_SCOPE_REJECTED',
        payload: e
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'error',
        message: `Update error`
      }))
    })
  }
}

export const updateScope = (data, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'UPDATE_SCOPE_PENDING'
    })
    return axios({
      data,
      method: 'put',
      headers: { 'Authorization': `Bearer ${accessToken}` },
      url: `${baseAPI}/standards/${data.standard_id}/scopes/${data.id}`
    }).then((x) => {
      dispatch({
        type: 'UPDATE_SCOPE_FULFILLED',
        payload: x.data
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'success',
        message: `Scope ${x.data.id} updated`
      }))
    }).catch((e) => {
      dispatch({
        type: 'UPDATE_SCOPE_REJECTED',
        payload: e
      })
      dispatch(createFlash({
        id: Date.now(),
        type: 'error',
        message: `Update error`
      }))
    })
  }
}

export const _deleteScope = (data, accessToken) => {
  return axios({
    method: 'delete',
    headers: { 'Authorization': `Bearer ${accessToken}` },
    url: `${baseAPI}/standards/${data.standard_id}/scopes/${data.id}`
  })
}

export const deleteScope = (data, accessToken) => {
  return (dispatch) => {
    dispatch({
      type: 'DELETE_SCOPE_PENDING'
    })
    return _deleteScope(data, accessToken)
      .then((x) => {
        dispatch({
          type: 'DELETE_SCOPE_FULFILLED',
          payload: data
        })
        dispatch(createFlash({
          id: Date.now(),
          type: 'success',
          message: `Scope ${data.id} deleted`
        }))
      })
      .catch((e) => {
        dispatch({
          type: 'DELETE_SCOPE_REJECTED',
          payload: e
        })
        dispatch(createFlash({
          id: Date.now(),
          type: 'error',
          message: `delete error`
        }))
      })
  }
}
