import React from 'react'
import { Link } from 'react-router-dom'

export default class ContactTable extends React.Component {
  render () {
    const contacts = this.props.contacts || []
    return (
      <table className='table table-condensed'>
        <thead>
          <tr>
            <th>Name</th>
            <th>Job Title</th>
            <th>Department</th>
            <th>Phone</th>
            <th className='text-center' width={80}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {contacts.map(x => {
            return (
              <tr key={x.id}>
                <td><Link to={`/customers/${this.props.customer.id}/contacts/${x.id}`}>{x.title} {x.first_name} {x.middle_name} {x.surname}</Link></td>
                <td>{x.job_title}</td>
                <td>{x.department}</td>
                <td>{x.mobile_phone}</td>
                <td className='text-right'>
                  <Link className='btn btn-xs btn-default' to={`/customers/${this.props.customer.id}/contacts/${x.id}`}><i className='fa fa-gear' /></Link>
                </td>
              </tr>
            )
          })}
          {this.props.customer &&
            <tr className=' bg-grey'>
              <td className='customer-scope-container' colSpan={5}>
                <Link className='btn btn-block btn-default' to={`/customers/${this.props.customer.id}/contacts/new`}>Add Contact</Link>
              </td>
            </tr>
          }
        </tbody>
      </table>
    )
  }
}
