import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router'
import { ConnectedRouter } from 'react-router-redux'

import store, { history } from './store/store'

// import App from './containers/App'
import PrivateRoute from './containers/PrivateRoute'
import Dashboard from './containers/Dashboard'
import Questionnaires from './containers/Questionnaires'
import Quotations from './containers/Quotations'
import QuotationSchedules from './containers/QuotationSchedules'
import Orders from './containers/Orders'

import Users from './containers/Users'
import Standards from './containers/Standards'
import Customers from './containers/Customers'
import Events from './containers/Events'
import Options from './containers/Options'

import AuditPlans from './containers/AuditPlans'
import AuditReports from './containers/AuditReports'
import NonComplies from './containers/NonComplies'
import Certificates from './containers/Certificates'
import Invoices from './containers/Invoices'
import Das from './containers/Das'

import ScheduleMatrix from './containers/ScheduleMatrix'
import About from './containers/About'
import Login from './containers/Login'
// import Register from './containers/Register'
import NoMatch from './containers/NoMatch'

import registerServiceWorker from './registerServiceWorker'
import './css/index.css'
import 'animate.css/animate.min.css'

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path='/about' component={About} />
        <Route path='/login' component={Login} />
        {
          // <Route path='/register' component={Register} />
        }
        <PrivateRoute exact path='/' component={Dashboard} />
        <PrivateRoute path='/questionnaires' component={Questionnaires} />
        <PrivateRoute path='/quotations' component={Quotations} />
        <PrivateRoute path='/quotation-schedules' component={QuotationSchedules} />
        <PrivateRoute path='/orders' component={Orders} />

        <PrivateRoute path='/audit-plans' component={AuditPlans} />
        <PrivateRoute path='/audit-reports' component={AuditReports} />
        <PrivateRoute path='/ncs' component={NonComplies} />
        <PrivateRoute path='/certificates' component={Certificates} />
        <PrivateRoute path='/invoices' component={Invoices} />
        <PrivateRoute path='/daily-allowances' component={Das} />

        <PrivateRoute path='/users' component={Users} />
        <PrivateRoute path='/standards' component={Standards} />
        <PrivateRoute path='/customers' component={Customers} />
        <PrivateRoute path='/events' component={Events} />
        <PrivateRoute path='/options' component={Options} />
        <PrivateRoute path='/schedule-matrix' component={ScheduleMatrix} />

        <Route path='/surveys' component={About} />

        <Route component={NoMatch} />
      </Switch>
    </ConnectedRouter>
  </Provider>
  , document.getElementById('root'))
registerServiceWorker()
