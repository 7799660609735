import get from 'lodash/get'
import forEach from 'lodash/forEach'

function asOptions (x, path, value) {
  return x.map(y => {
    const label = (path ? get(y, path) : y.name)
    const optionValue = (value ? get(y, value) : y)
    return {
      label,
      value: optionValue
    }
  })
}

function asQueryObject (x) {
  if (x !== '') {
    let string = x.slice(1, x.length)
    return JSON.parse('{"' + decodeURI(string).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
  }
  return {}
}

function asQueryString (filter) {
  let string = ''
  forEach(filter, function (x, y) {
    if (x !== '') {
      string = string + '&' + y + '=' + x
    }
  })
  return string.slice(1, string.length)
}

function roundBy (value, limit) {
  const base = Math.floor(value)
  return base + (value % 1 >= limit ? 1 : 0)
}

export { asOptions, asQueryObject, asQueryString, roundBy }
