import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import moment from 'moment'
import { TextField, OptionsField } from '../common/FormFields'
import { AllowanceField } from '../Das/DaFields'

class DaForm extends Component {
  render () {
    const { handleSubmit, submitting, schedules } = this.props

    return (
      <div>
        <form className='form-horizontal' onSubmit={handleSubmit}>

          <Field name='auditor' type='text' component={TextField} label='Auditor Name' disabled />

          <Field name='date' type='text' component={TextField} label='Date' disabled />

          <Field name='status' type='select' component={OptionsField} label='Date' options={['Draft', 'Approved', 'Paid']} />

          {schedules.map((x, i) => (
            <div key={i}>
              <hr />
              <h4>{x.customer.name} - {x.order.scopes[0].standard.name} <small>{x.order.phase} {x.type} - as {x.role}</small></h4>
              <p><strong>Start : </strong>{moment(x.start_date).format('DD MMM YYYY')} - <strong>End : </strong>{moment(x.end_date).format('DD MMM YYYY')} - <strong>Duration : </strong> {x.duration} day(s)</p>
              <p><strong>Location : </strong>{x.location.name} {x.location.city} {x.location.country}</p>

              <Field
                name={`detail.fees.fees-${x.id}`}
                component={AllowanceField}
                label='Allowances'
                options={[
                  'Audit(weekday)',
                  'Audit(weekend)',
                  'Travelling',
                  'Overtime',
                  // 'Reporting',
                  // 'Desk Audit'
                ]}
              />
            </div>
          ))}

          <div className='form-group'>
            <div className='col-sm-offset-3 col-sm-9'>
              <button disabled={submitting} type='submit' className='btn btn-primary btn-block'>Submit</button>
            </div>
          </div>

        </form>
      </div>
    )
  }
}

export default DaForm

const BaseDaCreateForm = props => <DaForm {...props} mode='create' />

export const DaCreateForm = reduxForm({
  form: 'daCreate' // validate, warn
})(BaseDaCreateForm)

const BaseDaUpdateForm = props => <DaForm {...props} mode='update' />

export const DaUpdateForm = reduxForm({
  form: 'daUpdate' // validate, warn
})(BaseDaUpdateForm)
