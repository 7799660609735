import React from 'react'
import { Doughnut } from 'react-chartjs-2'

const options = {
  legend: {
    display: true,
    position: 'right',
    align: 'center'
  }
}

export default class ChartsTop extends React.Component {
  render () {
    // const data = {
    //   labels: [
    //     'Blue',
    //     'Purple',
    //     'Orange',
    //     'Yellow'
    //   ],
    //   datasets: [{
    //     data: [300, 50, 100, 200],
    //     backgroundColor: [
    //       '#55D7FD',
    //       '#9E9BF2',
    //       '#FB8373',
    //       '#FDDA83'
    //     ],
    //     hoverBackgroundColor: [
    //       '#55CAFE',
    //       '#A38CFB',
    //       '#FB8364',
    //       '#FDD083'
    //     ]
    //   }]
    // }

    const topCustomerData = {
      labels: this.props.topCustomer.data.slice(0, 4).map(x => x.name),
      datasets: [{
        data: this.props.topCustomer.data.slice(0, 4).map(x => x.count),
        backgroundColor: [
          '#55D7FD',
          '#9E9BF2',
          '#FB8373',
          '#FDDA83'
        ]
      }]
    }

    const topStandardData = {
      labels: this.props.topStandard.data.slice(0, 4).map(x => x.name),
      datasets: [{
        data: this.props.topStandard.data.slice(0, 4).map(x => x.count),
        backgroundColor: [
          '#55D7FD',
          '#9E9BF2',
          '#FB8373',
          '#FDDA83'
        ]
      }]
    }

    const topScopeData = {
      labels: this.props.topScope.data.slice(0, 4).map(x => x.name),
      datasets: [{
        data: this.props.topScope.data.slice(0, 4).map(x => x.count),
        backgroundColor: [
          '#55D7FD',
          '#9E9BF2',
          '#FB8373',
          '#FDDA83'
        ]
      }]
    }

    return (
      <div className='row'>
        <div className='col-md-4'>
          <div className='new-card'>
            <div className='new-card-header'>
              <h4>Top Customers</h4>
            </div>
            <div className='new-card-body'>
              <div>
                <Doughnut data={topCustomerData} options={options} />
              </div>
            </div>
          </div>
        </div>

        <div className='col-md-4'>
          <div className='new-card'>
            <div className='new-card-header'>
              <h4> Top Services / Scheme </h4>
            </div>
            <div className='new-card-body'>
              <div>
                <Doughnut data={topStandardData} options={options} />
              </div>
            </div>
          </div>
        </div>

        <div className='col-md-4'>
          <div className='new-card'>
            <div className='new-card-header'>
              <h4>Top Customers Scope</h4>
            </div>
            <div className='new-card-body'>
              <div>
                <Doughnut data={topScopeData} options={options} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
