export const initialState = {
  orderList: { data: null, paginaton: null, error: null, loading: false },
  order: { data: null, error: null, loading: false }
}
export default (state = initialState, action) => {
  // const order = { ...state.order }
  switch (action.type) {
    case 'FETCH_ORDERS_PENDING':
      return { ...state, orderList: { data: null, error: null, loading: true } }
    case 'FETCH_ORDERS_REJECTED':
      return { ...state, orderList: { data: null, error: action.payload, loading: false } }
    case 'FETCH_ORDERS_FULFILLED':
      return { ...state,
        orderList: {
          data: action.payload.data,
          pagination: action.payload.pagination,
          error: null,
          loading: false
        }}

    case 'FETCH_ORDER_PENDING' :
      return { ...state, order: { data: null, error: null, loading: true } }
    case 'FETCH_ORDER_REJECTED':
      return { ...state, order: {data: null, error: action.payload, loading: false} }
    case 'FETCH_ORDER_FULFILLED' :
      return { ...state, order: { data: { ...action.payload }, error: null, loading: false } }

    case 'UPDATE_ORDER_PENDING' :
      return { ...state, order: { ...state.order, error: null, loading: true } }
    case 'UPDATE_ORDER_REJECTED':
      return { ...state, order: {...state.order, error: action.payload, loading: false} }
    case 'UPDATE_ORDER_FULFILLED' :
      return { ...state, order: { data: { ...action.payload }, error: null, loading: false } }

    case 'DELETE_ORDER_PENDING' :
      return { ...state, order: {...state.order, error: null, loading: true} }
    case 'DELETE_ORDER_REJECTED':
      return { ...state, order: {...state.order, error: action.payload, loading: false} }
    case 'DELETE_ORDER_FULFILLED' :
      return { ...state, order: { data: null, error: null, loading: false } }

    case 'LOGOUT_FULFILLED':
      return { ...initialState }

    default:
      return state
  }
}
