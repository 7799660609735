import React, { Component } from 'react'
import NumberFormat from 'react-number-format'
import { extendMoment } from 'moment-range'
import forOwn from 'lodash/forOwn'
import moment from 'moment'

const xmoment = extendMoment(moment)

export default class AllowanceTableForm extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }
  handleChange (date, ev) {
    this.setState((state, props) => {
      const stateObject = {}
      let newDate = {...state[date]} || {}

      newDate[ev.target.name] = ev.target.value
      stateObject[date] = newDate

      return stateObject
    })
  }

  getDayTotal (date, state) {
    if (state[date]) {
      let sum = 0
      forOwn(state[date], x => (sum += parseInt(x.replace(/,/g, ''), 10)))
      return sum
    } else {
      return 0
    }
  }

  render () {
    const { schedules, userId } = this.props
    const userSchedules = schedules.filter((x) => userId && x.user.id === parseInt(userId, 10))
    const userDays = []

    for (var i = 0; i < userSchedules.length; i++) {
      const range = xmoment.range(moment(userSchedules[i].start_date, 'YYYY-MM-DD'), moment(userSchedules[i].end_date, 'YYYY-MM-DD'))
      const days = Array.from(range.by('days'))
      for (let day of days) {
        userDays.push(day)
      }
    }

    return (
      <div className='allowance-table'>
        <div className='table-responsive'>
          <table className='table'>
            <thead>
              <tr>
                <th>Drink & Lunch or Dinner</th>
                <th>City Transport</th>
                <th>Inter City Transport</th>
                <th>Tel.,Fax, Toll, Photos</th>
                <th>Overtime</th>
                <th>Others</th>
              </tr>
            </thead>
            { userDays.map((x, i) => (
              <tbody key={i}>
                <tr>
                  <td colSpan={3} className>{x.format('DD MMMM YYYY')}</td>
                  <td colSpan={3} className='text-right'> Total : Rp {this.getDayTotal(x.format('YYYY-MM-DD'), this.state).toLocaleString()}</td>
                </tr>
                <tr>
                  <td>
                    <div className='input-group'>
                      <div className='input-group-addon'>Rp</div>
                      <NumberFormat
                        className='form-control input-xs'
                        onChange={this.handleChange.bind(this, x.format('YYYY-MM-DD'))}
                        name='food'
                        thousandSeparator
                      />
                    </div>
                  </td>
                  <td>
                    <div className='input-group'>
                      <div className='input-group-addon'>Rp</div>
                      <NumberFormat
                        className='form-control input-xs'
                        onChange={this.handleChange.bind(this, x.format('YYYY-MM-DD'))}
                        name='city-transportation'
                        thousandSeparator
                      />
                    </div>
                  </td>
                  <td>
                    <div className='input-group'>
                      <div className='input-group-addon'>Rp</div>
                      <NumberFormat
                        className='form-control input-xs'
                        onChange={this.handleChange.bind(this, x.format('YYYY-MM-DD'))}
                        name='inter-city-transportation'
                        thousandSeparator
                      />
                    </div>
                  </td>
                  <td>
                    <div className='input-group'>
                      <div className='input-group-addon'>Rp</div>
                      <NumberFormat
                        className='form-control input-xs'
                        onChange={this.handleChange.bind(this, x.format('YYYY-MM-DD'))}
                        name='communication'
                        thousandSeparator
                      />
                    </div>
                  </td>
                  <td>
                    <div className='input-group'>
                      <div className='input-group-addon'>Rp</div>
                      <NumberFormat
                        className='form-control input-xs'
                        onChange={this.handleChange.bind(this, x.format('YYYY-MM-DD'))}
                        name='overtime'
                        thousandSeparator
                      />
                    </div>
                  </td>
                  <td>
                    <div className='input-group'>
                      <div className='input-group-addon'>Rp</div>
                      <NumberFormat
                        className='form-control input-xs'
                        onChange={this.handleChange.bind(this, x.format('YYYY-MM-DD'))}
                        name='other'
                        thousandSeparator
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
      </div>
    )
  }
}
