import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getFormValues, change } from 'redux-form'
import { push } from 'react-router-redux'

import QuestionnaireGeneralInformationForm from '../../components/Questionnaires/QuestionnaireGeneralInformationForm'

import { createQuestionnaire } from '../../actions/questionnaires'
import { _fetchCustomer } from '../../actions/customers'

import { asQueryObject } from '../../const/helper'

export function QuestionnaireNew ({
  location,
  accessToken,
  createQuestionnaire,
  changeForm,
  formValue,
  push
}) {
  const [ customer, setCustomer ] = useState(null)
  const { customer: nowCustomer } = formValue

  // automatically set customer data if is set in query parameters
  useEffect(() => {
    const search = asQueryObject(location.search)
    if (search.customer) {
      // eslint-disable-next-line
      async function fetchData () {
        const customerData = await _fetchCustomer(search.customer, accessToken)
        setCustomer({
          label: customerData.name,
          value: customerData
        })
      }
      fetchData()
    }
  }, [location])

  function handleSubmit (e) {
    const request = {
      customer_id: e.customer.value.id,
      detail: {
        main_location: {
          id: e.location.value.id,
          ...e.main_location
        },
        main_contact: {
          id: e.contact.value.id,
          ...e.main_contact
        },
        general_information_complete: true,
        copy: { ...e.copy }
      }
    }
    return createQuestionnaire(request, accessToken)
  }

  return (
    <div className='content'>
      <div className='main-header'>
        <h2>Create Questionnaire </h2>
      </div>
      <div className='row'>
        <div className='col-md-8'>
          <QuestionnaireGeneralInformationForm
            onSubmit={handleSubmit.bind(this)}
            changeForm={changeForm}
            initialValues={{
              customer
            }}
            fileAPI={{
              url: process.env.REACT_APP_BASE_API,
              accessToken: accessToken
            }}
            customerAPI={{
              url: process.env.REACT_APP_BASE_API + '/customers',
              accessToken: accessToken
            }}
            contactAPI={{
              url: process.env.REACT_APP_BASE_API + '/customers/' + (nowCustomer ? nowCustomer.value.id : -1) + '/contacts',
              accessToken: accessToken
            }}
            locationAPI={{
              url: process.env.REACT_APP_BASE_API + '/customers/' + (nowCustomer ? nowCustomer.value.id : -1) + '/locations',
              accessToken: accessToken
            }}
            push={push}
          />
        </div>
        <div className='col-md-4' />
      </div>
    </div>
  )
}

function mapStateToProps (state) {
  return {
    formValue: getFormValues('QuestionnaireGeneralInformationForm')(state) || {},
    standards: state.standards.standardList.data || [],
    accessToken: state.auth.user.access.access_token
  }
}

function mapDispatchToProps (dispatch) {
  return {
    createQuestionnaire: (data, key) => dispatch(createQuestionnaire(data, key)),
    changeForm: (field, data) => dispatch(change('QuestionnaireGeneralInformationForm', field, data)),
    push: (route) => dispatch(push(route))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionnaireNew)
