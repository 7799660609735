import React, { useEffect } from 'react'
import { change } from 'redux-form'
import { connect } from 'react-redux'

import QuestionnaireAdditionalInformationForm from '../../components/Questionnaires/QuestionnaireAdditionalInformationForm'
import { asOptions } from '../../const/helper'

import { fetchStandards, fetchStandardScopes } from '../../actions/standards'

export function QuestionnaireAdditionalInformation (props) {
  const {
    accessToken,
    changeForm,
    fetchQuestionnaire,
    fetchStandards,
    fetchStandardScopes,
    questionnaire,
    standards,
    updateQuestionnaire
  } = props

  useEffect(() => {
    fetchStandards('', accessToken)
  }, [fetchStandards, accessToken])

  function fetchScopes (id) {
    return fetchStandardScopes(id, accessToken)
  }

  function prepareInitialValues (e) {
    let xstandards = []
    if (e.scopes) {
      xstandards = e.scopes.reduce((sum, x, i) => {
        if (!sum.map[x.standard.name]) {
          sum.map[x.standard.name] = {
            standard: x.standard,
            scope: [x.scope]
          }
          sum.array.push(x.standard.name)
        } else {
          sum.map[x.standard.name].scope.push(x.scope)
        }
        return sum
      }, { map: {}, array: [] })
    }
    let data = {
      standards: xstandards.array.map(x => xstandards.map[x]),
      detail: { ...e.detail },
      advance: e.detail.certification_information_complete
    }

    return data
  }

  function handleSubmit (e) {
    let target
    const request = {
      id: questionnaire.id,
      scopes: e.standards ? e.standards
        .reduce((sum, x, i) => ([...x.scope, ...sum]), [])
        .map(x => ({
          scope_id: x.id,
          standard_id: x.standard_id
        }))
        : null,
      detail: {
        ...e.detail
      }
    }
    if (e.advance) {
      request.detail.additional_information_complete = true
      target = `/questionnaires/${questionnaire.id}/annex`
    }
    return updateQuestionnaire(request, accessToken, target).then((x) => {
      fetchQuestionnaire(questionnaire.id, accessToken)
    })
  }

  if (!questionnaire) {
    return (<div>loading questionnaire</div>)
  }

  return (
    <div className='tab-pane active'>
      <h1>Additional information/project history</h1>
      <div className='row'>
        <div className='col-md-8'>
          <p>Dear Sales Personnel, please fill in the customer detail form.</p>
          <QuestionnaireAdditionalInformationForm
            standards={asOptions(standards, 'name', 'id')}
            changeForm={changeForm}
            onSubmit={handleSubmit.bind(this)}
            initialValues={prepareInitialValues(questionnaire)}
            onFetchScope={fetchScopes.bind(this)}
            fileAPI={{
              url: process.env.REACT_APP_BASE_API,
              accessToken: accessToken
            }}
          />
        </div>
      </div>
    </div>
  )
}

function mapStateToProps (state) {
  return {
    scopesByStandard: state.standards.scopesByStandard,
    standards: state.standards.standardList.data || []
  }
}

function mapDispatchToProps (dispatch) {
  return {
    changeForm: (field, value) => dispatch(change('QuestionnaireAdditionalInformationForm', field, value)),
    fetchStandards: (params, key) => dispatch(fetchStandards(params, key)),
    fetchStandardScopes: (id, key) => dispatch(fetchStandardScopes(id, key))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionnaireAdditionalInformation)
