import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

import {
  TextField,
  OptionsField,
  TextareaField
} from '../common/FormFields'
import FileField from '../common/FileField'

const statuses = ['Scheduled', 'Draft', 'Created', 'Paid']

class InvoiceForm extends Component {
  render () {
    const { handleSubmit, submitting } = this.props
    return (

      <div>
        <form className='form-horizontal invoice-form' onSubmit={handleSubmit}>

          <Field name='invoice_no' type='text' component={TextField} label='Invoice Number' />

          <Field name='detail.faktur_pajak' component={FileField} label='Faktur Pajak' api={this.props.fileAPI} />

          <Field name='detail.payment_detail' component={FileField} label='Bukti Pembayaran' api={this.props.fileAPI} />

          <Field name='detail.terms' component={TextField} label='Terms of Payment' disabled />

          <Field name='total' type='number' component={TextField} label='Value' disabled />

          <Field name='status' type='select' component={OptionsField} label='Status' options={statuses} />

          <Field name='remarks' type='text' component={TextareaField} label='Remarks' />

          <div className='form-group'>
            <div className='col-sm-offset-3 col-sm-9'>
              <button disabled={submitting} type='submit' className='btn btn-primary btn-block'>Save</button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default InvoiceForm

const BaseInvoiceCreateForm = props => <InvoiceForm {...props} mode='create' />

export const InvoiceCreateForm = reduxForm({
  form: 'invoiceCreate',
  enableReinitialize: true
})(BaseInvoiceCreateForm)

const BaseInvoiceUpdateForm = props => <InvoiceForm {...props} mode='create' />

export const InvoiceUpdateForm = reduxForm({
  form: 'invoiceUpdate',
  enableReinitialize: true
})(BaseInvoiceUpdateForm)
